import React from "react";
import { Grid } from "@mui/material";
// import RegisterComponent from "../auth/RegisterComponent";
import NewRegister from "../auth/registerSteps/NewRegister";

const Register = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <NewRegister />
      </Grid>
    </Grid>
  );
};

export default Register;
