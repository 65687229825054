import {
  Grid,
  Button,
  Stack,
  Card,
  CardContent,
  CardHeader,
  Pagination,
  Drawer,
  Typography,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import { useState } from "react";
import EnlargeCountyForm from "./EnlargeCountyForm";
import { useEffect } from "react";
import Footer from "../layout/Footer";
import CheckIcon from "@mui/icons-material/Check";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { useTour } from "@reactour/tour";
import { tourSteps } from "../../data/walkthroughDemo/steps";
import {
  setPrevTourStep,
  setShowTourNav,
} from "../../store/actions/dataActions";

const SelectCountyForm = ({
  downloadName,
  setPdf,
  setOpenDefaultModal,
  setDisableSelect,
  disableSelect,
  setSelectedLicenseIndex,
  invalid,
  licenseNumber,
  setLicenseNumber,
  licenseText,
  copySelected,
  setCopySelected,
  licenseType,
  setLicenseType,
  noc_data,
  setFilteredCountiesData,
  setCountiesData,
  favoritesOpen,
  currentUser,
  isSmallScreen,
  intervalStarts,
  elapsedTime,
  targetTime,
  progressDisabled,
  progressValue,
  moreResultsText,
  setFullRetrieve,
  selectedPreForm,
  setSelectedPreForm,
  openDrawer,
  setOpenDrawer,
  editFromCreate,
  defaultAddress,
  pdf,
  selectedName,
  selectCards,
  setLoading,
  cardPerPage,
  currentPage,
  setCurrentPage,
  enlargeType,
  enlargeHeader,
  enlargeNote,
  enlargeFormInput,
  setEnlargeFormInput,
  formik,
  type,
  setIsPreview,
  preview,
  setPreview,
  previewImg,
  previewLoading,
  setCounty,
  createNocLoading,
  getCurrentTasks,
  filteredCountiesData,
  isReadyToDownload,
  setIsReadyToDownload,
  text,
  freeCounty,
  lockedNames,
  setSelectedRow,
  addressRow,
  cardFlip,
  setAddressRow,
  setCardFlip,
  setText,
  retrieveLoading,
  isRetrievingAddress,
  setIsRetrievingAddress,
  setRetrieveLoading,
  defaultValues,
  setCreateNocLoading,
  setPreviewLoading,
  setDefaultAddress,
  setEditFromCreate,
  isFormCreated,
  setNocStartedDrawer,
  setNewRequestID,
  setNewRequest,
  setInQueueMessage,
  setInQueueDrawer,
  selectedPreName,
  setSelectedPreName,
  formCreated,
  Nloading,
  setDefCounty,
  setDefForm,
  isSuretyRadio,
  setIsSuretyRadio,
  monthlyRestart,
  openDocumentDrawer,
  setOpeDocumentDrawer,
  load,
  setStartPermitOpen,
  setAvailableCitiesNum,
  setAvailableFormsNum,
  setDisplayImage,
  setReadyLoad,
}) => {
  const dispatch = useDispatch();
  const { isOpen, setCurrentStep, currentStep } = useTour();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const [tooltipText, setTooltipText] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [isCurrentCard, setIsCurrentCard] = useState("");
  const [currentTimezone, setCurrentTimezone] = useState(
    currentUser?.user?.personal?.[0]?.timezone || currentUser?.subuser?.timezone
  );
  const [isAnyFavorite, setIsAnyFavorite] = useState(false);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (currentSession === "subUserSession") {
      setCurrentTimezone(currentUser?.subuser?.timezone);
    } else {
      setCurrentTimezone(currentUser?.user?.personal?.[0]?.timezone);
    }
  }, [currentUser]);

  useEffect(() => {
    const checkFavoriteStatus = () => {
      const anyFavorite = getCurrentTasks(
        sortTasksByName(filteredCountiesData)
      ).some((county) => isFavorite(county));
      setIsAnyFavorite(anyFavorite);
    };

    checkFavoriteStatus();
  }, [filteredCountiesData]);

  const isFavorite = (county) => {
    const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    return favorites.some((favorite) => favorite.name === county.name);
  };

  const handleAddToFavorites = (county) => {
    // Get the existing favorites from local storage or initialize an empty array
    const existingFavorites =
      JSON.parse(localStorage.getItem("favorites")) || [];

    // Check if the county is already in the favorites
    const existingIndex = existingFavorites.findIndex(
      (favorite) => favorite.name === county.name
    );

    if (type === "create-noc") {
      if (existingIndex === -1) {
        // Add the county to the favorites array
        existingFavorites.push(county);
      } else {
        // Remove the county from the favorites array
        existingFavorites.splice(existingIndex, 1);
      }

      // Save the updated favorites array to local storage
      localStorage.setItem("favorites", JSON.stringify(existingFavorites));

      // Create a deep copy of filteredCountiesData to update the buttonText immutably
      const updatedFilteredCountiesData = filteredCountiesData.map((item) =>
        item.name === county.name
          ? { ...item, buttonText: existingIndex === -1 ? "Added" : "Add" }
          : item
      );

      // Update the state to reflect the changes
      setFilteredCountiesData(updatedFilteredCountiesData);

      if (favoritesOpen) {
        setCountiesData(existingFavorites);
        setFilteredCountiesData(existingFavorites);
      }
    }
  };
  const handleCardAction = (name, form, nocForm, isDisabledCard) => {
    if (type === "create-noc") {
      const isWarning = currentUser.user.selectedCountiesNOC.some((noc) => {
        return (
          noc.name === name &&
          ((currentUser?.subuser &&
            noc.added_email !== currentUser?.subuser?.email &&
            !noc?.saved) ||
            (!currentUser?.subuser &&
              noc.added_email !== currentUser?.user?.email &&
              !noc?.saved))
        );
      });

      setDisableSelect(false);

      if (
        monthlyRestart &&
        (freeCounty - selectedName.length === 0 ||
          freeCounty - selectedName.length < 0) &&
        !selectedName.includes(name)
      ) {
        return;
        // } else if (selectedName.includes(name)) {
        //   selectCards(name, form);
      } else {
        setSelectedPreName(name);
        setSelectedPreForm(form);
        if (!isWarning) {
          setOpenDrawer(true);
          setPdf("");
        } else {
          setOpenDefaultModal(true);
        }
      }

      return;
    }

    let isLocked = lockedNames?.includes(name);

    if (isDisabledCard && type === "welcome" && isLocked == false) {
      // Set the tooltip text
      setTooltipText(
        "Modifying this county is not possible, as another member within your organization has made recent changes to it."
      );
      setIsCurrentCard(name);
      // Show the tooltip
      setShowTooltip(true);

      // Hide the tooltip after 2 seconds
      setTimeout(() => {
        setShowTooltip(false);
      }, 4000);
      return;
    } else if (type === "welcome" && isLocked == true) {
      // Set the tooltip text
      setTooltipText(
        "Modifications for this county are restricted because it has already been used."
      );
      setIsCurrentCard(name);
      // Show the tooltip
      setShowTooltip(true);

      // Hide the tooltip after 2 seconds
      setTimeout(() => {
        setShowTooltip(false);
      }, 4000);
      return;
    }

    if (type === "welcome") {
      if (
        (freeCounty - selectedName.length === 0 ||
          freeCounty - selectedName.length < 0) &&
        !selectedName.includes(name)
      ) {
        return;
      } else {
        selectCards(name, form, nocForm);
      }

      // } else if (selectedName.includes(name)) {
      //   selectCards(name, form);
      // } else {
      //   setSelectedPreName(name);
      //   setSelectedPreForm(form);
      //   setOpenDrawer(true);
      // }
    }
    // Add your other logic here
  };

  useEffect(() => {
    if (!openDrawer && enlargeType !== "firstSelect") {
      formik?.resetForm();
      setIsReadyToDownload(false);
      if (setPreview) {
        setPreview(false);
      }
      if (enlargeType === "createNOC") {
        setText(
          "All other fields will be filled automatically. Some fields are not visible in the preview."
        );
        setAddressRow([]);
        setCardFlip(false);
        setEditFromCreate(false);
      }
    }
  }, [openDrawer, preview, previewImg]);

  const getStyles = (countyName, isDisabledCard) => {
    if (lockedNames?.includes(countyName)) {
      if (isSmallScreen) {
        return {
          position: "relative",
          minWidth: "14rem",
          paddingLeft: 1,
          paddingRight: 1,
          border: "4px solid #fff",
          backgroundColor: "#c1d7e1",
          minHeight: "10rem",
          "&:hover": {
            backgroundColor: "#c1d7e1", // Set the same background color on hover
          },
          cursor: "not-allowed",
        };
      } else {
        return {
          position: "relative",
          minWidth: "14rem",
          maxWidth: "14rem",
          border: "8px solid #fff",
          backgroundColor: "#c1d7e1",
          minHeight: "15.9rem",
          "&:hover": {
            backgroundColor: "#c1d7e1", // Set the same background color on hover
          },
          cursor: "not-allowed",
        };
      }
    } else if (selectedName.includes(countyName)) {
      if (isSmallScreen) {
        return {
          cursor: isDisabledCard ? "not-allowed" : load ? "normal" : "pointer",
          position: "relative",
          minWidth: "14rem",
          maxWidth: "14rem",
          paddingLeft: 1,
          paddingRight: 1,
          border: "4px solid #76A0B9",
          backgroundColor: "#EEF2F6",
          minHeight: "10rem",
        };
      } else {
        return {
          cursor: isDisabledCard ? "not-allowed" : load ? "normal" : "pointer",
          position: "relative",
          minWidth: "14rem",
          maxWidth: "14rem",
          // maxHeight: "15rem",
          border: "8px solid #76A0B9",
          backgroundColor: "#EEF2F6",
          minHeight: "15.9rem",
        };
      }
    } else if (type === "create-noc") {
      if (isSmallScreen) {
        return {
          position: "relative",
          minWidth: "10rem",
          // maxHeight: "15rem",
          paddingLeft: 1,
          paddingRight: 1,
          border: "4px solid #fff",
          backgroundColor: "#EEF2F6",
          minHeight: "10rem",
        };
      } else {
        return {
          cursor: noc_data?.failed_counties?.includes(countyName)
            ? "normal"
            : "pointer",
          position: "relative",
          minWidth: "14rem",
          maxWidth: "14rem",
          // maxHeight: "15rem",
          border: noc_data?.failed_counties?.includes(countyName)
            ? "8px solid #800000"
            : "8px solid #fff",
          backgroundColor: "#EEF2F6",
          minHeight: "15.9rem",
        };
      }
    } else {
      if (isSmallScreen) {
        return {
          position: "relative",
          minWidth: "14rem",
          paddingLeft: 1,
          paddingRight: 1,
          border: "4px solid #fff",
          backgroundColor: "#EEF2F6",
          minHeight: "10rem",
        };
      } else {
        return {
          cursor: isDisabledCard ? "not-allowed" : load ? "normal" : "pointer",
          position: "relative",
          maxWidth: "14rem",
          minWidth: "14rem",
          // maxHeight: "15rem",
          border: "8px solid #fff",
          backgroundColor: "#EEF2F6",
          minHeight: "15.9rem",
        };
      }
    }
  };

  function getTooltipTitle(county) {
    for (let i = 0; i < currentUser.user.selectedCountiesNOC.length; i++) {
      const selectedCounty = currentUser.user.selectedCountiesNOC[i];
      if (county.name === selectedCounty.name) {
        if (currentUser.subuser) {
          if (
            selectedCounty.added_email !== currentUser.subuser.email &&
            selectedCounty.added_email !== currentUser.user.email
          ) {
            return `The selection made by ${selectedCounty.added_name}.`;
          } else if (selectedCounty.added_email === currentUser.subuser.email) {
            return "The selection made by you.";
          } else {
            return "The selection made by your administrator.";
          }
        } else {
          if (selectedCounty.added_email !== currentUser.user.email) {
            return `The selection made by ${selectedCounty.added_name}.`;
          } else {
            return "The selection made by you.";
          }
        }
      }
    }

    return ""; // Return an empty string if not found in selectedCountiesNOC
  }

  function getIsDisabled(county) {
    for (let i = 0; i < currentUser?.user?.selectedCountiesNOC.length; i++) {
      const selectedCounty = currentUser?.user?.selectedCountiesNOC[i];
      if (county?.name === selectedCounty?.name) {
        if (currentUser?.subuser) {
          if (selectedCounty?.added_email !== currentUser?.subuser?.email) {
            return true;
          } else if (
            selectedCounty?.added_email === currentUser?.subuser?.email
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          if (selectedCounty?.added_email !== currentUser?.user?.email) {
            return false;
          } else {
            return false;
          }
        }
      }
    }

    return false; // Return an empty string if not found in selectedCountiesNOC
  }

  const sortTasksByName = (tasks) => {
    if (type === "create-noc") {
      return tasks.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    } else {
      return tasks;
    }
  };

  const getCountyData = (county, noc_data, currentTimezone) => {
    let avgCreationTime = "";
    let isAppraiserSuccess = "";
    let checkedDate = "";
    let numCities = 0;
    let numForms = 0;

    const currentCounty = noc_data?.appraiser_results?.[county.name];

    numCities = currentCounty?.cities;
    numForms = currentCounty?.forms;

    const matchingEntry = noc_data?.avg_nocs?.find(
      (entry) => entry?._id === county?.name
    );

    avgCreationTime = matchingEntry
      ? Math.floor(matchingEntry?.avg_second_took)
      : "N/A";

    // APPraiser
    isAppraiserSuccess = currentCounty?.is_success ? true : false;
    const checkedDateTimestamp = currentCounty?.date * 1000;
    const checkedDateMoment = DateTime.fromMillis(checkedDateTimestamp, {
      zone: currentTimezone,
    });

    // Get the current date in the specified timezone
    const currentDate = DateTime.now().setZone(currentTimezone);

    // Check if the date is today in the specified timezone
    const isToday = checkedDateMoment.hasSame(currentDate, "day");

    // Format the date based on whether it's today or not
    if (isToday) {
      checkedDate = checkedDateMoment.toFormat("h:mm a"); // Today, show time only
    } else {
      checkedDate = checkedDateMoment.toFormat("MMM d, h:mm a"); // Other days, show date and time
    }

    return {
      avgCreationTime,
      isAppraiserSuccess,
      checkedDate,
      numCities,
      numForms,
    };
  };

  return (
    <>
      <Grid container justifyContent="center" gap={1}>
        {!isAnyFavorite && favoritesOpen && type === "create-noc" ? (
          <Typography variant="h6" color="error">
            No Pinned Counties Found
          </Typography>
        ) : (
          type !== "trial-ended" &&
          getCurrentTasks(sortTasksByName(filteredCountiesData)).map(
            (county, i) => {
              let avgCreationTime = "";
              let isAppraiserSuccess = "";
              let checkedDate = "";
              let numCities = 0;
              let numForms = 0;
              let isDisabledCard = getIsDisabled(county);

              if (noc_data) {
                const countyData = getCountyData(
                  county,
                  noc_data,
                  currentTimezone
                );
                avgCreationTime = countyData.avgCreationTime;
                isAppraiserSuccess = countyData.isAppraiserSuccess;
                checkedDate = countyData.checkedDate;
                numCities = countyData.numCities;
                numForms = countyData.numForms;
              }
              const inFavorite = isFavorite(county);

              let buttonText;
              let btnDisabled = false;

              if (type === "create-noc") {
                if (inFavorite && !favoritesOpen) {
                  buttonText = "Added";
                  btnDisabled = true;
                } else if (!inFavorite && !favoritesOpen) {
                  buttonText = "Add";
                  btnDisabled = false;
                } else if (inFavorite && favoritesOpen) {
                  buttonText = "Remove";
                  btnDisabled = false;
                }
              }
              return (
                <Card
                  onClick={(e) => {
                    if (
                      load ||
                      noc_data?.failed_counties?.includes(county.name)
                    ) {
                      return;
                    } else {
                      if (type === "create-noc") {
                        // if (isOpen) {
                        //   setTimeout(() => {
                        //     setCurrentStep((s) =>
                        //       s === tourSteps?.length - 1 ? 0 : s + 1
                        //     );
                        //   }, 200);
                        // }
                        setDisplayImage(county.nocForm);
                        setSelectedPreForm(county.form);
                        setSelectedPreName(county.name);
                        setAvailableCitiesNum(numCities);
                        setAvailableFormsNum(numForms);
                        setReadyLoad(true);
                        setStartPermitOpen(true);
                      } else {
                        handleCardAction(
                          county.name,
                          county.form,
                          county.nocForm,
                          isDisabledCard
                        );
                      }
                    }
                  }}
                  key={county.name}
                  sx={getStyles(county.name, isDisabledCard)}
                >
                  {/* Tooltip */}
                  {showTooltip &&
                    type === "welcome" &&
                    isCurrentCard === county.name && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          width: "100%",
                          transform: "translate(-50%, -50%)",
                          background: "#fff",
                          padding: "10px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <Typography sx={{ px: 1 }}>{tooltipText}</Typography>
                      </div>
                    )}
                  {currentUser?.user?.selectedCountiesNOC.some(
                    (item) => item.name === county.name
                  ) &&
                    type === "welcome" && (
                      <Tooltip title={getTooltipTitle(county)} placement="top">
                        <CheckIcon
                          color="primary"
                          sx={{ position: "absolute", right: 5, top: 5 }}
                        />
                      </Tooltip>
                    )}
                  {type === "create-noc" && (
                    <Box>
                      {buttonText === "Add" ? (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!btnDisabled) {
                              handleAddToFavorites(county);
                            }
                          }}
                          sx={{ position: "absolute", right: 0, top: 0 }}
                        >
                          <PushPinOutlinedIcon
                            color="error"
                            sx={{
                              fontSize: "30px",
                              transform: "rotate(45deg)",
                            }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            // if (!btnDisabled) {
                            handleAddToFavorites(county);
                            // }
                          }}
                          sx={{ position: "absolute", right: 0, top: 0 }}
                        >
                          <PushPinIcon
                            color="error"
                            sx={{
                              fontSize: "30px",
                              transform: "rotate(45deg)",
                            }}
                          />
                        </IconButton>
                      )}
                    </Box>
                  )}

                  <CardContent
                    className="bottomCard"
                    sx={
                      isSmallScreen ? { p: 0, minHeight: "8.6rem" } : { p: 0 }
                    }
                  >
                    <img
                      alt={county.name}
                      src={county.form}
                      style={{ width: "100%", height: "auto" }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        mb: 1,
                      }}
                    >
                      {noc_data?.failed_counties?.includes(county.name) ? (
                        <>
                          <Typography
                            mt={1}
                            variant="h7"
                            // color={isAppraiserSuccess ? "#008000" : "#800000"}
                            sx={{
                              color: "#800000",
                              textAlign: "center",
                              // ml: 1, // Add margin for separation
                              fontWeight: "bold",
                            }}
                          >
                            County Appraiser's website is temporarily
                            unavailable
                          </Typography>
                        </>
                      ) : (
                        <>
                          {" "}
                          <Typography
                            variant="h7"
                            // color={isAppraiserSuccess ? "#008000" : "#800000"}
                            sx={{
                              color: "#004976",
                              textAlign: "center",
                              // ml: 1, // Add margin for separation
                              fontWeight: "bold",
                            }}
                          >
                            Available Cities:{" "}
                            <span style={{ fontSize: "20px" }}>
                              {numCities}
                            </span>
                          </Typography>
                          <Typography
                            variant="h7"
                            sx={{
                              color: "#004976",
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            Total Documents:{" "}
                            <span style={{ fontSize: "20px" }}>{numForms}</span>
                          </Typography>{" "}
                        </>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              );
            }
          )
        )}
      </Grid>
      {/* <Stack spacing={2} ml="auto" mr="auto" mt={4}>
        <Pagination
          count={Math.ceil(filteredCountiesData.length / cardPerPage)}
          page={currentPage}
          onChange={(e) => setCurrentPage(e.target.value)}
        />
      </Stack> */}
      <Drawer
        PaperProps={{
          sx: {
            width: "100vw",
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
          setDefCounty && setDefCounty(null);
          setDefForm && setDefForm(null);
        }}
      >
        {
          <EnlargeCountyForm
            openDocumentDrawer={openDocumentDrawer}
            setOpenDocumentDrawer={setOpeDocumentDrawer}
            setDefCounty={setDefCounty}
            setDefForm={setDefForm}
            downloadName={downloadName}
            Nloading={Nloading}
            formCreated={formCreated}
            disableSelect={disableSelect}
            setSelectedLicenseIndex={setSelectedLicenseIndex}
            invalid={invalid}
            licenseNumber={licenseNumber}
            setLicenseNumber={setLicenseNumber}
            licenseText={licenseText}
            copySelected={copySelected}
            setCopySelected={setCopySelected}
            licenseType={licenseType}
            setLicenseType={setLicenseType}
            currentUser={currentUser}
            intervalStarts={intervalStarts}
            elapsedTime={elapsedTime}
            targetTime={targetTime}
            progressDisabled={progressDisabled}
            progressValue={progressValue}
            moreResultsText={moreResultsText}
            setFullRetrieve={setFullRetrieve}
            setNewRequestID={setNewRequestID}
            setNewRequest={setNewRequest}
            setInQueueMessage={setInQueueMessage}
            setInQueueDrawer={setInQueueDrawer}
            setNocStartedDrawer={setNocStartedDrawer}
            editFromCreate={editFromCreate}
            setRetrieveLoading={setRetrieveLoading}
            isRetrievingAddress={isRetrievingAddress}
            setIsRetrievingAddress={setIsRetrievingAddress}
            retrieveLoading={retrieveLoading}
            defaultAddress={defaultAddress}
            setCardFlip={setCardFlip}
            cardFlip={cardFlip}
            addressRow={addressRow}
            setAddressRow={setAddressRow}
            setSelectedRow={setSelectedRow}
            text={text}
            pdf={pdf}
            isReadyToDownload={isReadyToDownload}
            pdfImg={selectedPreForm}
            createNocLoading={createNocLoading}
            setCounty={setCounty}
            previewLoading={previewLoading}
            setLoading={setLoading}
            setIsPreview={setIsPreview}
            type={type}
            formik={formik}
            selectCards={selectCards}
            enlargeType={enlargeType}
            selectedPreName={selectedPreName}
            selectedPreForm={selectedPreForm}
            enlargeHeader={enlargeHeader}
            enlargeNote={enlargeNote}
            enlargeFormInput={enlargeFormInput}
            setEnlargeFormInput={setEnlargeFormInput}
            setOpenDrawer={setOpenDrawer}
            preview={preview}
            setPreview={setPreview}
            previewImg={previewImg}
            defaultValues={defaultValues}
            setCreateNocLoading={setCreateNocLoading}
            setPreviewLoading={setPreviewLoading}
            setDefaultAddress={setDefaultAddress}
            isFormCreated={isFormCreated}
            isSuretyRadio={isSuretyRadio}
            setIsSuretyRadio={setIsSuretyRadio}
          />
        }
      </Drawer>
    </>
  );
};

export default SelectCountyForm;
