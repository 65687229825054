import { Box, Typography } from "@mui/material";

const calculateDynamicPosition = (
  positionProps,
  prevRect,
  { left = 10, top = 10, xLeft = 10, xTop = 0 } = {}
) => {
  const { innerWidth, innerHeight } = window;

  // Get previous window width and height (you can track this globally or within the component)
  const previousWidth = positionProps.previousWidth || innerWidth;
  const previousHeight = positionProps.previousHeight || innerHeight;

  // Calculate the difference in size
  const widthDifference = innerWidth - previousWidth;
  const heightDifference = innerHeight - previousHeight;

  // Store the current window dimensions for the next resize event
  positionProps.previousWidth = innerWidth;
  positionProps.previousHeight = innerHeight;

  // Ensure the popup does not go off screen or disappear
  return [
    Math.min(left, innerWidth - prevRect.width), // Adjust to prevent overflow horizontally
    Math.min(top, innerHeight - prevRect.height), // Adjust to prevent overflow vertically
  ];
};

export const tourSteps = [
  {
    selector: ".step1",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          1. Counties List:
        </Typography>
        <Typography
          variant="body1"
          mt={1}
          fontWeight="bold"
          color="textSecondary"
        >
          Click on Select Counties to choose the counties you want to work with.
        </Typography>
        <Box mt={1}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            Hint:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The menu item you need to select is highlighted with a white border.
            If it's not visible, try scrolling through the menu to find it.
          </Typography>
        </Box>
      </Box>
    ),
    styles: {
      popover: (base) => ({
        ...base,
        marginTop: "10px",
        marginLeft: "20px",
        "--reactour-accent": "#ef5a3d",
        borderRadius: 20,
      }),
    },
    padding: 0,
  },
  {
    selector: ".step2",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          2. Select Counties:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          You’ll see a list of counties. At the top left, you can track how many
          you've selected based on your plan. To select a county, just click a
          county and hit Save. Navigate pages using the numbers or arrows at the
          bottom, or search for a county using the box at the top right. If
          choosing multiple counties, select them all first, then click Save.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { top: 200 });
    },
  },
  {
    selector: ".step3",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          3. Preset Configurations:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Here, we save some default values to make filling your forms more
          straightforward and faster. Click on Preset Configurations menu to
          learn more.
        </Typography>
        <Box mt={1}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            Hint:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The menu item you need to select is highlighted with a white border.
            If it's not visible, try scrolling through the menu to find it.
          </Typography>
        </Box>
      </Box>
    ),
    padding: 0,
  },
  {
    selector: ".step4",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          4. Notice of Commencement Presets:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Select a county to view the default presets and adjust them if needed.
          When you're finished, click Submit Changes. Click Next to go to the
          next step.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { xTop: -50 });
    },
  },
  {
    selector: ".step5",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          5. Design Professionals:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Add the architects, engineers, or other design professionals you
          frequently work with to easily fill in their details when needed for
          various forms later. Add one now, you can always come back later to
          delete or modify it. Once you are done entering the info, click on
          Submit.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect);
    },
  },
  {
    selector: ".step6",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          6. Licenses:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          In this menu, you will add the licensed contractors you use to pull
          permits. If your plan allows it, you can add more than one, e.g.,
          General Contractor, Roofing Contractor, etc.
        </Typography>
        <Box mt={1}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            Hint:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The menu item you need to select is highlighted with a white border.
            If it's not visible, try scrolling through the menu to find it.
          </Typography>
        </Box>
      </Box>
    ),
    padding: 0,
  },
  {
    selector: ".step7",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          7. Manage Contractors:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          If you still need to add a contractor license, you can do so here.
          Enter the license number, and the system will automatically retrieve
          the associated details. Duplicates aren't allowed. You can edit or
          delete a license later if needed. A valid email and phone number are
          required for the licensed contractor, as we use them to send
          notifications when permits are ready to sign and notarize.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { top: 20 });
    },
  },
  {
    selector: ".step8",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          8. Permits Hub:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          This is where you can manage all your permit packages. Click to move
          forward.
        </Typography>
        <Box mt={1}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            Hint:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The menu item you need to select is highlighted with a white border.
            If it's not visible, try scrolling through the menu to find it.
          </Typography>
        </Box>
      </Box>
    ),
    padding: 0,
  },
  {
    selector: ".step9",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          9. Create Permit Package:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          This is where you go to start creating a permit package for any
          county. Click to get started.
        </Typography>
        <Box mt={1}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            Hint:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The menu item you need to select is highlighted with a white border.
            If it's not visible, try scrolling through the menu to find it.
          </Typography>
        </Box>
      </Box>
    ),
    padding: 0,
  },
  {
    selector: ".step10",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          10. Creating New Permit Package:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          The first step is to select the county for which you want to create a
          permit package. You'll see two buttons: My Counties (shows all the
          counties you've selected) and Pinned Counties (displays counties
          you've pinned for quick access). This is helpful when you work with
          many counties but have a few you use more often. To pin a county,
          simply click the red pin. Once you select a county, you'll be taken to
          the screen where you choose the forms you need for that permit.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { xTop: -50 });
    },
  },
  {
    selector: ".step11",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          11. Create Your Permit Package:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On this screen, you can select the city within the county where you
          want to pull a permit. Different cities in the same county may use
          unique forms for permits but share forms like the NOC. Choose the
          appropriate city before proceeding. Once selected, the available forms
          will be displayed, with the NOC form preselected. You only need to
          choose the forms relevant to the type of permit you're pulling. After
          selecting the forms, the software will guide you through filling them
          out.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { xTop: -50 });
    },
  },
  {
    selector: ".step12",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          12. Selected Contractor:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On this screen you need to select the contractor you will use to pull
          this permit. If you have more than one license click on the arrow to
          select it. If there is only one licensed contractor, the software will
          preselect by default. Adding a permit package name will make it easier
          to identify it later, so add one. You can use the customer name or the
          address or a combination. For the purposes of this demo, we won't fill
          the forms right now. Instead we will move to My Permit Packages screen
          to show you how you manage these packages.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return [prevRect.left, prevRect.top];
    },
  },
  {
    selector: ".step13",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          13. Permit Packages:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Select a permit package to navigate this screen. The one you just
          created should be at the top.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step14",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          14. Forms Navigation:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Once you select a permit package. You can see the different forms
          inside that package and the status of each one.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step15",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          15. Manage Forms:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          By clicking on the name of each form you can get a status and can
          proceed to create or update them.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step16",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          16. Property Owner:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On the right side of the screen you will see information related to
          the permit package like Property Owner.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step17",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          17: Contractor:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Here you'll see the contractor information being used to create this
          permit package.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step18",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          18. Notarization:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On this tab, you'll see the status of the notarization for this permit
          package or you can get started with one.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step19",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          19. Recording:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          This tab displays the current status of the NOC recording with the
          courthouse.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step20",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          20. City Permit:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          If this permit is being created for one of our supported cities, we
          will display here all the information related to the permit and a link
          to connect to the city.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step21",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          21. Settings:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On this tab, you can add additional documents to the permit package,
          edit the package name, send reminder emails to homeowners, archive the
          package, or delete it (with certain restrictions).
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step22",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          22. Users:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On this menu you can manage the users enabled to work with this
          application.
        </Typography>
        <Box mt={1}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            Hint:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The menu item you need to select is highlighted with a white border.
            If it's not visible, try scrolling through the menu to find it.
          </Typography>
        </Box>
      </Box>
    ),
    padding: 0,
  },
  {
    selector: ".step23",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          23. Sub-Users:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          If your plan allows it, you can add other people here that can help
          you create permit packages (office staff, project managers, etc)
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { xTop: -50 });
    },
  },
  {
    selector: ".step24",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          24. Manage Subscription:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On this menu you can check your plan or upgrade it.
        </Typography>
        <Box mt={1}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            Hint:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The menu item you need to select is highlighted with a white border.
            If it's not visible, try scrolling through the menu to find it.
          </Typography>
        </Box>
      </Box>
    ),
    padding: 0,
  },
  {
    selector: ".step25",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          25. Current Plan:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          This screen shows your current plan with key details like plan type,
          cost, subscription interval (monthly or annual), and renewal date. To
          make changes, click Change Plan. If no changes are needed, click Next
          to continue the guided demo.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { xTop: -50 });
    },
  },
  {
    selector: ".step26",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          26. Payment:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Here is where you manage your payment information.
        </Typography>
        <Box mt={1}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            Hint:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The menu item you need to select is highlighted with a white border.
            If it's not visible, try scrolling through the menu to find it.
          </Typography>
        </Box>
      </Box>
    ),
    padding: 0,
  },
  {
    selector: ".step27",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          27. Manage Cards:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          This screen shows your credit cards on file. You can add, edit, or
          delete them.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { xTop: -50 });
    },
  },
  {
    selector: ".step28",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          28. Subscription Invoices:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          If you scroll down on the credit card page, you can see your
          subscription invoices.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { xTop: -50 });
    },
  },
  {
    selector: ".step29",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          29. Notarization and Recording Invoices:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Here you can see the invoices for notarization and recording services.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { xTop: -50 });
    },
  },
  {
    // selector: ".step30",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          Tour Completed!
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            alt="avatar_circle"
            src="/img/website_img/pp_avatar_circle.png"
            style={{ width: "8rem", height: "auto", zIndex: 2 }}
          />
        </Box>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Here is where this guided tour finishes. You can always take it back
          by clicking on the "Guided Tour". Click Finish to close this window
          and return to the main dashboard. Additional help and specific videos
          can be found in the chat sections or email customer service.
        </Typography>
        <Typography
          mt={3}
          align="center"
          fontWeight="bold"
          variant="body1"
          color="primary"
        >
          We wish you all the best and hope you enjoy using Permit Rockstar to
          make your work easier.
        </Typography>
      </Box>
    ),
    position: "center",
  },
  // STEP_INDEX -> 30
  {
    selector: ".step12",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          12. Selected Contractor:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On this screen you need to select the contractor you will use to pull
          this permit. If you have more than one license click on the arrow to
          select it. If there is only one licensed contractor, the software will
          preselect by default. Adding a permit package name will make it easier
          to identify it later, so add one. You can use the customer name or the
          address or a combination. For the purposes of this demo, we won't fill
          the forms right now. Instead we will move to My Permit Packages screen
          to show you how you manage these packages.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return [prevRect.left, prevRect.top];
    },
  },
];

export const noTourPaths = ["/", "/login", "/register", "/resetpassword"];

export const subUserTourSteps = [
  {
    selector: ".step1",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          1. Counties List:
        </Typography>
        <Typography
          variant="body1"
          mt={1}
          fontWeight="bold"
          color="textSecondary"
        >
          Click on Select Counties to choose the counties you want to work with.
        </Typography>
        <Box mt={1}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            Hint:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The menu item you need to select is highlighted with a white border.
            If it's not visible, try scrolling through the menu to find it.
          </Typography>
        </Box>
      </Box>
    ),
    styles: {
      popover: (base) => ({
        ...base,
        marginTop: "10px",
        marginLeft: "20px",
        "--reactour-accent": "#ef5a3d",
        borderRadius: 20,
      }),
    },
    padding: 0,
  },
  {
    selector: ".step2",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          2. Select Counties:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          You’ll see a list of counties. At the top left, you can track how many
          you've selected based on your plan. To select a county, just click a
          county and hit Save. Navigate pages using the numbers or arrows at the
          bottom, or search for a county using the box at the top right. If
          choosing multiple counties, select them all first, then click Save.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { top: 200 });
    },
  },
  {
    selector: ".step3",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          3. Preset Configurations:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Here, we save some default values to make filling your forms more
          straightforward and faster. Click on Preset Configurations menu to
          learn more.
        </Typography>
        <Box mt={1}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            Hint:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The menu item you need to select is highlighted with a white border.
            If it's not visible, try scrolling through the menu to find it.
          </Typography>
        </Box>
      </Box>
    ),
    padding: 0,
  },
  {
    selector: ".step4",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          4. Notice of Commencement Presets:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Select a county to view the default presets and adjust them if needed.
          When you're finished, click Submit Changes. Click Next to go to the
          next step.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { xTop: -50 });
    },
  },
  {
    selector: ".step5",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          5. Design Professionals:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Add the architects, engineers, or other design professionals you
          frequently work with to easily fill in their details when needed for
          various forms later. Add one now, you can always come back later to
          delete or modify it. Once you are done entering the info, click on
          Submit.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect);
    },
  },
  {
    selector: ".step8",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          6. Permits Hub:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          This is where you can manage all your permit packages. Click to move
          forward.
        </Typography>
        <Box mt={1}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            Hint:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The menu item you need to select is highlighted with a white border.
            If it's not visible, try scrolling through the menu to find it.
          </Typography>
        </Box>
      </Box>
    ),
    padding: 0,
  },
  {
    selector: ".step9",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          7. Create Permit Package:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          This is where you go to start creating a permit package for any
          county. Click to get started.
        </Typography>
        <Box mt={1}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            Hint:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The menu item you need to select is highlighted with a white border.
            If it's not visible, try scrolling through the menu to find it.
          </Typography>
        </Box>
      </Box>
    ),
    padding: 0,
  },
  {
    selector: ".step10",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          8. Creating New Permit Package:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          The first step is to select the county for which you want to create a
          permit package. You'll see two buttons: My Counties (shows all the
          counties you've selected) and Pinned Counties (displays counties
          you've pinned for quick access). This is helpful when you work with
          many counties but have a few you use more often. To pin a county,
          simply click the red pin. Once you select a county, you'll be taken to
          the screen where you choose the forms you need for that permit.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { xTop: -50 });
    },
  },
  {
    selector: ".step11",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          9. Create Your Permit Package:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On this screen, you can select the city within the county where you
          want to pull a permit. Different cities in the same county may use
          unique forms for permits but share forms like the NOC. Choose the
          appropriate city before proceeding. Once selected, the available forms
          will be displayed, with the NOC form preselected. You only need to
          choose the forms relevant to the type of permit you're pulling. After
          selecting the forms, the software will guide you through filling them
          out.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return calculateDynamicPosition(positionProps, prevRect, { xTop: -50 });
    },
  },
  {
    selector: ".step12",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          10. Selected Contractor:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On this screen you need to select the contractor you will use to pull
          this permit. If you have more than one license click on the arrow to
          select it. If there is only one licensed contractor, the software will
          preselect by default. Adding a permit package name will make it easier
          to identify it later, so add one. You can use the customer name or the
          address or a combination. For the purposes of this demo, we won't fill
          the forms right now. Instead we will move to My Permit Packages screen
          to show you how you manage these packages.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return [prevRect.left, prevRect.top];
    },
  },
  {
    selector: ".step13",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          11. Permit Packages:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Select a permit package to navigate this screen. The one you just
          created should be at the top.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step14",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          12. Forms Navigation:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Once you select a permit package. You can see the different forms
          inside that package and the status of each one.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step15",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          13. Manage Forms:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          By clicking on the name of each form you can get a status and can
          proceed to create or update them.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step16",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          14. Property Owner:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On the right side of the screen you will see information related to
          the permit package like Property Owner.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step17",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          15: Contractor:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Here you'll see the contractor information being used to create this
          permit package.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step18",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          16. Notarization:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On this tab, you'll see the status of the notarization for this permit
          package or you can get started with one.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step19",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          17. Recording:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          This tab displays the current status of the NOC recording with the
          courthouse.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step20",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          18. City Permit:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          If this permit is being created for one of our supported cities, we
          will display here all the information related to the permit and a link
          to connect to the city.
        </Typography>
      </Box>
    ),
  },
  {
    selector: ".step21",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          19. Settings:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On this tab, you can add additional documents to the permit package,
          edit the package name, send reminder emails to homeowners, archive the
          package, or delete it (with certain restrictions).
        </Typography>
      </Box>
    ),
  },
  {
    // selector: ".step20",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          Tour Completed!
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            alt="avatar_circle"
            src="/img/website_img/pp_avatar_circle.png"
            style={{ width: "8rem", height: "auto", zIndex: 2 }}
          />
        </Box>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          Here is where this guided tour finishes. You can always take it back
          by clicking on the "Guided Tour". Click Finish to close this window
          and return to the main dashboard. Additional help and specific videos
          can be found in the chat sections or email customer service.
        </Typography>
        <Typography
          mt={3}
          align="center"
          fontWeight="bold"
          variant="body1"
          color="primary"
        >
          We wish you all the best and hope you enjoy using Permit Rockstar to
          make your work easier.
        </Typography>
      </Box>
    ),
    position: "center",
  },
  // STEP_INDEX -> 20
  {
    selector: ".step12",
    content: (
      <Box>
        <Typography variant="h6" color="primary">
          10. Selected Contractor:
        </Typography>
        <Typography
          mt={1}
          fontWeight="bold"
          variant="body1"
          color="textSecondary"
        >
          On this screen you need to select the contractor you will use to pull
          this permit. If you have more than one license click on the arrow to
          select it. If there is only one licensed contractor, the software will
          preselect by default. Adding a permit package name will make it easier
          to identify it later, so add one. You can use the customer name or the
          address or a combination. For the purposes of this demo, we won't fill
          the forms right now. Instead we will move to My Permit Packages screen
          to show you how you manage these packages.
        </Typography>
      </Box>
    ),
    position: (positionProps, prevRect) => {
      return [prevRect.left, prevRect.top];
    },
  },
];
