import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, useTheme, useMediaQuery } from "@mui/material";
import ReactPlayer from "react-player";
import { useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";

const HomeComponent = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [navigateLoading, setNavigateLoading] = useState(false);

  return (
    <Grid
      container
      sx={
        isXs
          ? {
              backgroundImage: "url('/img/website_img/homeImg.jpeg')",
              backgroundSize: "cover",
              height: "37vh",
            }
          : isMd
          ? {
              backgroundImage: "url('/img/website_img/homeImg.jpeg')",
              backgroundSize: "cover",
              height: "82vh",
            }
          : {
              backgroundImage: "url('/img/website_img/homeImg.jpeg')",
              height: "100vh",
            }
      }
    >
      <Grid item sm={12} xs={12}>
        <Box
          display="flex"
          // justifyContent="left"
          sx={{ flexDirection: "column", position: "relative" }}
        >
          <img
            style={
              isXs
                ? {
                    width: "8rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "0.5rem",
                    marginBottom: "4.2rem",
                  }
                : {
                    width: "10rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "1rem",
                  }
            }
            alt="Permit Rockstar Logo"
            src="/img/website_img/PermitRockstar.png"
          />
          <Box
            sx={{
              mt: 1,
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: isXs ? 1 : 1,
              width: "100%",
            }}
          >
            <Button
              onClick={(e) => {
                navigate("/login");
              }}
              fullWidth
              sx={{ maxWidth: "50%" }}
              color="primary"
              size={isXs ? "medium" : "large"}
              // sx={{
              //   // minWidth: "50%",
              //   backgroundColor: "#F2CD00",
              //   borderRadius: 0,
              //   ":hover": { backgroundColor: "#CDAF0B" },
              // }}
              variant="contained"
            >
              L O G I N
            </Button>
            <LoadingButton
              loading={navigateLoading}
              onClick={(e) => {
                setNavigateLoading(true);
                window.location.href = "https://permitrockstar.com/pricing/";
              }}
              color="primary"
              size={isXs ? "medium" : "large"}
              fullWidth
              sx={{ maxWidth: "50%" }}
              // sx={{
              //   // minWidth: "50%",
              //   backgroundColor: "#F2CD00",
              //   borderRadius: 0,
              //   ":hover": { backgroundColor: "#CDAF0B" },
              // }}
              variant="contained"
            >
              C R E A T E &nbsp; A N &nbsp; A C C O U N T
            </LoadingButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HomeComponent;
