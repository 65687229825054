import {
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  IconButton,
  Drawer,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Preview } from "@mui/icons-material";
import { useEffect, useState } from "react";
import ReadMessage from "../admin/ReadMessage";

const MessageGrid = ({
  title,
  messagesDataRows,
  setReasonsData,
  selectedName,
  setMessagesDataRows,
  isSm,
}) => {
  const [selectedRow, setSelectedRow] = useState();
  const [openDrawerMessage, setOpenDrawerMessage] = useState();
  const [read, setRead] = useState(true);
  const [textField, setTextField] = useState("");
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpenDrawer = (params) => {
    setOpenDrawerMessage(true);
    setSelectedRow(params.row);
  };

  useEffect(() => {
    if (errorText !== "") {
      const errorInterval = setInterval(() => {
        setErrorText("");
        if (errorText === "You have successfully sent the message") {
          setOpenDrawerMessage(false);
        }
      }, 3000);
      return () => {
        clearInterval(errorInterval);
      };
    }
  }, [errorText]);

  const messagesDataColumns = [
    {
      field: "actions",
      headerName: "",
      type: "actions",
      width: 20,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="Open message">
              <IconButton
                onClick={() => {
                  handleOpenDrawer(params);
                }}
              >
                <Preview />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      editable: false,
    },
    {
      field: "time",
      headerName: "Time",
      width: 150,
      editable: false,
    },
    {
      field: "contractor_name",
      headerName: "Contractor",
      width: 150,
      editable: false,
    },
    {
      field: "contractor_license",
      headerName: "License Num",
      width: 150,
      editable: false,
    },
    {
      field: "name",
      headerName: "Customer",
      width: 150,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 130,
      editable: false,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 130,
      editable: false,
    },
    {
      field: "user_status",
      headerName: "Status",
      width: 80,
      editable: false,
    },
  ];

  return (
    <>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <DataGrid
            isRowSelectable={false}
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
            }}
            rows={messagesDataRows}
            columns={messagesDataColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            disableRowSelectionOnClick
          />
        </CardContent>
      </Card>
      <Drawer
        PaperProps={
          isSm
            ? {
                sx: {
                  width: "100vw",
                },
              }
            : {
                sx: {
                  width: "60vw",
                },
              }
        }
        anchor="right"
        open={openDrawerMessage}
        onClose={() => {
          setOpenDrawerMessage(false);
          setRead(true);
        }}
      >
        <ReadMessage
          isSm={isSm}
          setMessagesDataRows={setMessagesDataRows}
          selectedName={selectedName}
          setReasonsData={setReasonsData}
          loading={loading}
          setLoading={setLoading}
          setErrorText={setErrorText}
          errorText={errorText}
          setTextField={setTextField}
          textField={textField}
          setOpenDrawerMessage={setOpenDrawerMessage}
          messageId={selectedRow?.id}
          customerEmail={selectedRow?.email}
          title={
            read
              ? `Message - ${selectedRow?.contractor_name} - ${selectedRow?.name}`
              : `Send Message - ${selectedRow?.contractor_name} - ${selectedRow?.name}`
          }
          message={selectedRow?.message}
          read={read}
          setRead={setRead}
        />
      </Drawer>
    </>
  );
};

export default MessageGrid;
