import BellIcon from "@heroicons/react/24/solid/BellIcon";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
  Modal,
} from "@mui/material";
import ScrollingText from "./ScrollingText";
import { usePopover } from "../../hooks/use-popover";
import { AccountPopover } from "../popover/account-popover";
import { NotificationPopover } from "../popover/notification-popover";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { NotaryNotification } from "../notary/NotaryNotification";
import { timezoneMappings } from "../../data/timezones";
import TimezoneSelect from "./TimezoneSelect";
import {
  clearNotificationCount,
  setPrevTourStep,
  setShowTourNav,
} from "../../store/actions/dataActions";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTour } from "@reactour/tour";
import { useNavigate } from "react-router-dom";
const TOP_NAV_HEIGHT = 64;

const TopNav = ({
  currentUser,
  currentSession,
  onNavOpen,
  noc_data,
  mobileSize,
  tabletSize,
  navigate,
  dispatch,
  setTourOpen,
}) => {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const accountPopover = usePopover();
  const notificationPopover = usePopover();
  const [failedCounties, setFailedCounties] = useState([]);
  const [expSubText, setExpSubText] = useState("");
  const renewDateObj = new Date(currentUser?.user?.cancellation_time * 1000);
  const [open, setOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [timezone, setTimezone] = useState("");
  const [currentTime, setCurrentTime] = useState(new Date());
  const value = new Date();
  const { setIsOpen, setCurrentStep } = useTour();
  const location = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // Function to update the current time every second
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []); // Run effect only once when the component mounts

  useEffect(() => {
    if (currentUser?.avatar) {
      setAvatarUrl(currentUser?.avatar);
    }
  }, [currentUser?.avatar]);

  useEffect(() => {
    const isTimezoneInList = (timezone) =>
      timezoneMappings.some((tz) => tz.value === timezone);

    if (currentUser?.role === "notary") {
      if (currentUser?.timezone && isTimezoneInList(currentUser?.timezone)) {
        setTimezone(currentUser?.timezone);
      } else {
        setTimezone("America/New_York");
      }
    } else if (!currentUser?.subuser) {
      if (
        currentUser?.user?.personal &&
        currentUser?.user?.personal[0]?.timezone &&
        isTimezoneInList(currentUser?.user?.personal[0]?.timezone)
      ) {
        setTimezone(currentUser?.user?.personal[0]?.timezone);
      } else {
        setTimezone("America/New_York");
      }
    } else if (currentUser?.subuser) {
      if (
        currentUser?.subuser &&
        currentUser?.subuser?.timezone &&
        isTimezoneInList(currentUser?.subuser?.timezone)
      ) {
        setTimezone(currentUser?.subuser?.timezone);
      } else {
        setTimezone("America/New_York");
      }
    }
    if (currentUser?.user?.expired) {
      if (!currentUser?.subuser) {
        setExpSubText(
          'Payment Failed *** Your subscription has expired! Please create a new subscription to continue using NOC Creator by visiting the "Manage Subscription" page.'
        );
      } else {
        setExpSubText(
          "Payment Failed *** Your subscription has expired! Please contact your Administrator to create a  new subscription to continue using NOC Creator."
        );
      }
    } else if (currentUser?.user?.cancellation_time) {
      if (!currentUser?.subuser) {
        setExpSubText(
          `Canceled Subscription *** Your subscription will be canceled on ${renewDateObj.toLocaleDateString(
            "en-US"
          )}. You can reactivate it anytime on the "Manage Subscription" page.`
        );
      } else {
        setExpSubText(
          `Canceled Subscription *** Your subscription will be canceled on ${renewDateObj.toLocaleDateString(
            "en-US"
          )}. Please contact your Administrator to reactivate it.`
        );
      }
    } else if (noc_data) {
      if (noc_data?.failed_counties?.length === 0) {
        setExpSubText("");
        setFailedCounties("");
      } else {
        setFailedCounties(noc_data?.failed_counties);
      }
    } else {
      setExpSubText("");
    }
  }, [currentUser, noc_data, expSubText]);

  return (
    <>
      <Box>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          {currentUser?.role === "notary" ? (
            <>
              <div
                style={{
                  marginRight: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {format(
                  utcToZonedTime(currentTime, timezone),
                  "MMMM do yyyy, h:mm:ss a",
                  { timezone }
                )}
                <IconButton
                  onClick={handleOpen}
                  sx={{ ml: 1 }}
                  aria-label="calendar"
                >
                  <CalendarTodayIcon />
                </IconButton>

                <TimezoneSelect
                  options={timezoneMappings}
                  timezone={timezone}
                  setTimezone={setTimezone}
                  availabilities={currentUser?.availabilities}
                />
              </div>
              {/* {currentUser?.status !== "Inactive" && (
                <div>
                  <Typography variant="h6">
                    Yesterday's Activity: {currentUser?.yesterday_activity}{" "}
                    hours
                  </Typography>
                </div>
              )} */}
            </>
          ) : (
            <>
              {!tabletSize && (
                <div>
                  <TimezoneSelect
                    options={timezoneMappings}
                    timezone={timezone}
                    setTimezone={setTimezone}
                  />
                </div>
              )}

              <div className="scroll-wrapper">
                <ScrollingText
                  expiredSubscription={expSubText}
                  failedCounties={failedCounties}
                />
              </div>
            </>
          )}

          <Stack alignItems="center" direction="row" spacing={2}>
            {currentUser?.role !== "notary" ? (
              <>
                {lgUp ? (
                  <Tooltip title="Start Guided Tour">
                    <IconButton
                      onClick={() => {
                        // if (location.pathname !== "/dashboard") {
                        //   navigate("/dashboard");
                        // }
                        setCurrentStep(0);
                        // setIsOpen(true);
                        dispatch(setShowTourNav(false));
                        dispatch(setPrevTourStep(0));
                        setTourOpen(true);
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          sx={{ mb: -1 }}
                          variant="body2"
                          fontWeight="bold"
                          color={!lgUp ? "textSecondary" : "primary"}
                        >
                          Guided
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          color={!lgUp ? "textSecondary" : "primary"}
                        >
                          Tour
                        </Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton disabled>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{ mb: -1 }}
                        variant="body2"
                        fontWeight="bold"
                        color={!lgUp ? "textSecondary" : "primary"}
                      >
                        Guided
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color={!lgUp ? "textSecondary" : "primary"}
                      >
                        Tour
                      </Typography>
                    </Box>
                  </IconButton>
                )}
              </>
            ) : (
              ""
            )}

            <Tooltip
              title="Notifications"
              sx={
                currentUser?.role === "notary"
                  ? { visibility: "hidden" }
                  : { visibility: "block" }
              }
            >
              <IconButton
                disabled={currentUser?.user?.expired ? true : false}
                onClick={() => {
                  notificationPopover.handleOpen();
                  if (
                    noc_data?.notifications?.count &&
                    parseInt(noc_data?.notifications?.count) > 0
                  ) {
                    dispatch(clearNotificationCount());
                  }
                }}
                ref={notificationPopover.anchorRef}
              >
                <Badge
                  badgeContent={noc_data?.notifications?.count || null}
                  color="success"
                  variant="number"
                >
                  <SvgIcon fontSize="medium">
                    <BellIcon />
                  </SvgIcon>
                </Badge>
              </IconButton>
            </Tooltip>
            <Avatar
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                cursor: "pointer",
                height: 40,
                width: 40,
              }}
              src={avatarUrl}
            />
          </Stack>
        </Stack>
      </Box>
      <AccountPopover
        currentSession={currentSession}
        setAvatarUrl={setAvatarUrl}
        avatarUrl={avatarUrl}
        user={currentUser}
        anchorEl={notificationPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
        navigate={navigate}
        dispatch={dispatch}
        timezoneMappings={timezoneMappings}
        timezone={timezone}
        setTimezone={setTimezone}
        tabletSize={tabletSize}
      />
      {currentUser?.role === "notary" ? (
        <NotaryNotification
          user={currentUser}
          anchorEl={notificationPopover.anchorRef.current}
          open={notificationPopover.open}
          onClose={notificationPopover.handleClose}
        />
      ) : (
        <NotificationPopover
          currentUser={currentUser}
          currentSession={currentSession}
          anchorEl={notificationPopover.anchorRef.current}
          open={notificationPopover.open}
          onClose={notificationPopover.handleClose}
          timezone={timezone}
          noc_data={noc_data}
        />
      )}
      {/* CALENDAR MODAL */}
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            // Ensure there's enough space for the larger calendar
            maxWidth: "90vw",
            maxHeight: "90vh",
            overflow: "auto", // Add scrolling if necessary
          }}
        >
          <Calendar value={value} />
        </div>
      </Modal>
    </>
  );
};

export default TopNav;
