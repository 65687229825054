import React, { useState } from "react";
import { Modal, Box, Typography, Button, FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function DisplayLogs({ infoModal, setInfoModal }) {
  return (
    <Modal open={infoModal.logsOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          color="primary"
          id="modal-modal-title"
          variant="h4"
          align="center"
          component="h2"
        >
          RON Session Logs
        </Typography>
        <Typography align="center" id="modal-modal-description">
          Are you sure you want to assign this job to yourself?
        </Typography>

        {/* {selectedJob && (
          <>
            <Typography sx={{ mt: 4 }}>
              <strong>Date:</strong> {selectedJob.ronDateString}
            </Typography>
            <Typography>
              <strong>Urgency Level:</strong> {selectedJob.urgency}
            </Typography>
            <Typography
              align="left"
              color="primary"
              variant="body2"
              sx={{ mt: 2, fontWeight: "bold" }}
            >
              Selected jobs can be assigned back to the queue two hours before
              the scheduled time and date
            </Typography>
          </>
        )} */}

        <Box sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}>
          <Button
            sx={{ mr: 1 }}
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => setInfoModal({ ...infoModal, logsOpen: false })}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{ ml: 1 }}
            fullWidth
            variant="contained"
            color="primary"
            // onClick={onConfirm}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default DisplayLogs;
