import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateMyPermitsState } from "../../store/actions/pageActions/myPermitsActions";

function DocumentAddedDialog({ open, setOpen, closeParent }) {
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          //   backgroundColor: "#90EE90",
          boxShadow: "3px 4px 12px 5px rgba(0, 0, 0, 0.4)",
          borderRadius: "20px",
          border: "2px solid #d3d3d3",
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "8rem",
            width: "30rem",
          }}
        >
          <Typography align="center" variant="h5" sx={{ color: "#238061" }}>
            The documents have been added successfully
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{ width: "10rem" }}
            onClick={() => {
              setOpen(false);
              closeParent(false);
              dispatch(updateMyPermitsState({ rightValue: 0 }));
            }}
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DocumentAddedDialog;
