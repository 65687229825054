import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSessionWsConnection } from "../store/actions/sessionsActions";

const usePersistentWebSocket = (sessionType) => {
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_WS_URL;

  // Get the token for the specific session type
  const token = useSelector((state) => {
    switch (sessionType) {
      case "userSession":
        return state.sessions?.userSession?.token;
      case "notarySession":
        return state.sessions?.notarySession?.token;
      case "guestSession":
        return state.sessions?.guestSession?.token;
      case "subUserSession":
        return state.sessions?.subUserSession?.token;
      default:
        return null;
    }
  });

  // Map sessionType to the appropriate WebSocket route
  const getWebSocketUrl = () => {
    switch (sessionType) {
      case "guestSession":
        return `${baseUrl}/nocApi_ws/guest_ws?token=${token}`;
      case "notarySession":
        return `${baseUrl}/nocApi_ws/notary_ws?token=${token}`;
      case "userSession":
        return `${baseUrl}/nocApi_ws/user_ws?token=${token}`;
      case "subUserSession":
        return `${baseUrl}/nocApi_ws/subUser_ws?token=${token}`;
      default:
        console.error("Invalid session type for WebSocket connection.");
        return null;
    }
  };

  const url = getWebSocketUrl();

  const reconnectAttemptsRef = useRef(0);
  const maxReconnectAttempts = 6;
  const reconnectDelay = 5000; // 5 seconds delay for reconnection

  // Get the WebSocket connection status from the store
  const wsConnected = useSelector(
    (state) => state.sessions[sessionType]?.wsConnected
  );

  useEffect(() => {
    let socket;
    let reconnectTimer;

    const connectWebSocket = () => {
      socket = new WebSocket(url);
      console.log("wsConnected", wsConnected);
      socket.onopen = () => {
        console.log(`${sessionType} WebSocket connected`);
        reconnectAttemptsRef.current = 0;
        dispatch(updateSessionWsConnection({ sessionType, wsConnected: true }));
      };

      socket.onmessage = (event) => {
        // Handle incoming WebSocket messages here if needed
      };

      socket.onclose = (event) => {
        if (event.code === 1000) {
          console.log(`${sessionType} WebSocket closed normally`);
        } else {
          console.error(`${sessionType} WebSocket closed unexpectedly`);
        }
        // Attempt to reconnect regardless of the reason for closure
        handleReconnect();
      };

      socket.onerror = (error) => {
        console.error(`${sessionType} WebSocket error:`, error);
        socket.close();
      };
    };

    const handleReconnect = () => {
      if (reconnectAttemptsRef.current < maxReconnectAttempts) {
        reconnectTimer = setTimeout(() => {
          reconnectAttemptsRef.current += 1;
          console.log(
            `Reconnecting ${sessionType} WebSocket... Attempt ${reconnectAttemptsRef.current}`
          );
          connectWebSocket();
        }, reconnectDelay * reconnectAttemptsRef.current); // Exponential backoff
      } else {
        console.error("Max reconnect attempts reached");
        // Optionally redirect to login or show a notification
        // window.location.href = "/login";
      }
    };

    return () => {
      if (socket) {
        socket.onclose = null;
        socket.close();
      }
      clearTimeout(reconnectTimer);
    };
  }, [dispatch, sessionType, url, token]);

  return wsConnected;
};

export default usePersistentWebSocket;
