import Intercom from "@intercom/messenger-js-sdk";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const ChatComponent = () => {
  const currentSession = useSelector(
    (state) => state?.sessions?.currentSession
  );

  const currentUser = useSelector((state) => state?.[currentSession]?.user);

  Intercom({
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    user_id:
      currentSession === "userSession"
        ? currentUser?.user?.customerId
        : currentUser?.user?.customerId + "_" + currentUser?.subuser?.email, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: currentUser?.user?.personal?.[0]?.firstName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email:
      currentSession === "userSession"
        ? currentUser?.user?.email
        : currentUser?.subuser?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    user_hash: Cookies.get(process.env.REACT_APP_INTERCOM_MSG_C_N),
    created_at:
      currentSession === "userSession"
        ? currentUser?.user?.createdAt
        : currentUser?.subuser?.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });
  return <div></div>;
};

export default ChatComponent;
