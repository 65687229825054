import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "./pdfLoading.json"; // Adjust the path as needed

const LottieAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Player
        autoplay
        loop
        src={animationData}
        style={{ height: "200px", width: "200px" }}
      />
    </div>
  );
};

export default LottieAnimation;
