import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";

const NotaryCard = ({
  title,
  value,
  icon: IconComponent,
  color,
  tooltip,
  isUpcoming,
}) => {
  return (
    <Card
      raised
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" color="GrayText">
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pt: 2,
          }}
        >
          <Typography variant={isUpcoming ? "h6" : "h5"}>{value}</Typography>
          <Tooltip title={tooltip}>
            <IconComponent fontSize="large" sx={{ color }} />
          </Tooltip>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NotaryCard;
