import { useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SideNavAd = ({ handleMouseUp, handleMouseDown, handleMouseDownUp }) => {
  return (
    <>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        sx={{
          height: "2.6rem",
          backgroundColor: "neutral.700",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          size="small"
          onMouseDown={handleMouseDown} // Scrolls down when mouse is held
          onMouseUp={handleMouseUp} // Stops scrolling when the mouse is released
          onTouchStart={handleMouseDown} // Handles touch start
          onTouchEnd={handleMouseUp} // Handles touch end
          sx={{
            color: "neutral.400",
            position: "relative",
            overflow: "hidden",
            "& .MuiTouchRipple-root": {
              color: "#9e9e9e", // Change ripple color
            },
          }}
        >
          <KeyboardArrowDownIcon
            sx={{ color: "neutral.400", fontSize: "40px" }}
          />
        </Button>
        <div style={{ marginLeft: "5px", marginRight: "5px" }}></div>
        <Button
          size="small"
          onMouseDown={handleMouseDownUp} // Scrolls up when mouse is held
          onMouseUp={handleMouseUp} // Stops scrolling when the mouse is released
          onTouchStart={handleMouseDownUp} // Handles touch start
          onTouchEnd={handleMouseUp} // Handles touch end
          sx={{
            color: "neutral.400",
            position: "relative",
            overflow: "hidden",
            "& .MuiTouchRipple-root": {
              color: "#9e9e9e", // Change ripple color
            },
          }}
        >
          <KeyboardArrowUpIcon
            sx={{ color: "neutral.400", fontSize: "40px" }}
          />
        </Button>
      </Box>
      <Box
        sx={{
          mb: 2,
          px: 2,
          py: 1,
        }}
      >
        <Typography color="neutral.100" align="center" variant="h6">
          Powered By
        </Typography>
        <Typography color="neutral.500" align="center" variant="body2">
          SR360 Solutions
        </Typography>

        <Box
          component="a"
          href="https://www.sr360solutions.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: "flex",
            mx: "auto",
            width: "160px",
            "& img": {
              width: "100%",
            },
          }}
        >
          <img alt="SR360solutions" src="/img/logos/Ball-icon.png" />
        </Box>
      </Box>
    </>
  );
};

export default SideNavAd;
