import {
  Box,
  Grid,
  Container,
  Card,
  Button,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  TextField,
  InputAdornment,
  Pagination,
} from "@mui/material";
import InQueueDrawer from "../noc/InQueueDrawer";
import { useNavigate, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {
  clearPdfProgress,
  enableEmptyCountyFlag,
  setCreatedNOC,
} from "../../store/actions/dataActions";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import DefaultModal from "../modals/DefaultModal";
import PdfProgressModal from "../modals/PdfProgressModal";
import SelectCountyFormSelect from "../noc/SelectCountyFormSelect";
import CreateNocMobile from "../modals/CreateNocMobile";
import EmptyPermitPopover from "../popover/EmptyPermitPopover";
import StartPermit from "../permitComponents/StartPermit";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useTour } from "@reactour/tour";

const CreateNoc = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { isOpen, currentStep, setCurrentStep } = useTour();

  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const largePC = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const [defCounty, setDefCounty] = useState(location?.state?.county);
  const [defForm, setDefForm] = useState(location?.state?.form);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedForm, setSelectedForm] = useState([]);
  const [cardRemain, setCardRemain] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const cardPerPage = 8;
  const [countiesData, setCountiesData] = useState([]);
  const [filteredCountiesData, setFilteredCountiesData] = useState([
    countiesData,
  ]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [targetTime, setTargetTime] = useState(60);
  const [intervalId, setIntervalId] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [createNocLoading, setCreateNocLoading] = useState(false);
  const [selectedPreForm, setSelectedPreForm] = useState("");

  //? REDUX CURRENT USER/SESSION #######################################################
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const noc_data = useSelector((state) => state.nocData.nocData);
  const pdfProgress = useSelector((state) => state.nocData.pdfProgress);
  //? ##################################################################################
  const [isClosed, setIsClosed] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [preview, setPreview] = useState(false);
  const [previewImg, setPreviewImg] = useState([]);
  const [county, setCounty] = useState("");
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);
  const [fullRetrieve, setFullRetrieve] = useState(false);
  const [pdf, setPdf] = useState("");
  const [text, setText] = useState(
    "All other fields will be filled automatically. Some fields are not visible in the preview."
  );
  const [moreResultsText, setMoreResultsText] = useState("");
  const [addressRow, setAddressRow] = useState([]);
  const [cardFlip, setCardFlip] = useState(false);
  const [isRetrievingAddress, setIsRetrievingAddress] = useState(false);
  const [retrieveLoading, setRetrieveLoading] = useState(false);
  const [openDefaultModal, setOpenDefaultModal] = useState(false);
  const [inQueueMessage, setInQueueMessage] = useState(
    // "Calculating queue position..."
    ""
  );
  const [Nloading, seNtLoading] = useState(false);
  const [favoritesOpen, setFavoritesOpen] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState({
    street: "",
    number: "",
    unit: "",
    suffix: "",
    zip: "",
    fullAddress: "",
    retrieved: "",
    fullRetrieve: "",
  });
  const theme = useTheme();

  const [defaultValues, setDefaultValues] = useState();
  const [editFromCreate, setEditFromCreate] = useState(false);
  const [formCreated, setFormCreated] = useState();
  const [isFormCreated, setIsFormCreated] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const [inQueueDrawer, setInQueueDrawer] = useState(false);
  const [newRequest, setNewRequest] = useState(false);
  const [newRequestID, setNewRequestID] = useState("");
  const [selectedPreName, setSelectedPreName] = useState("");
  const [waitingList, setWaitingList] = useState(false);

  const progressDisabled = false;
  const progressValue = 0;
  const [intervalStarts, setIntervalStarts] = useState(false);
  const [cancelDefaultLoading, setCancelDefaultLoading] = useState(false);

  const [downloadName, setDownloadName] = useState("");

  const [licenseType, setLicenseType] = useState("");
  const [copySelected, setCopySelected] = useState({});
  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseText, setLicenseText] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [selectedLicenseIndex, setSelectedLicenseIndex] = useState(0);
  const [disableSelect, setDisableSelect] = useState(false);

  const [pdfProgressModalOpen, setPdfProgressModalOpen] = useState(false);

  const [isSuretyRadio, setIsSuretyRadio] = useState("no");

  const [emptyPermitOpen, setEmptyPermitOpen] = useState(false);
  const [emptyPermitConfirmed, setEmptyPermitConfirmed] = useState(false);

  const [openDocumentDrawer, setOpenDocumentDrawer] = useState(false);

  //! START PERMIT ///////////////////////////////////////////////////
  const [startPermitOpen, setStartPermitOpen] = useState(false);
  const [availableCitiesNum, setAvailableCitiesNum] = useState(0);
  const [availableFormsNum, setAvailableFormsNum] = useState(0);
  const [displayImage, setDisplayImage] = useState(null);
  const [readyLoad, setReadyLoad] = useState(false);

  //! ////////////////////////////////////////////////////////////////

  //! Mobile /////////////////////////////////////////
  const [visitEnabled, setVisitEnabled] = useState(true);
  const [favoritEnabled, setFavoritEnabled] = useState(false);
  const [addEnabled, setAddEnabled] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const [rowData, setRowData] = useState(null);

  const [createEnabled, setCreateEnabled] = useState(true);
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [listEnabled, setListEnabled] = useState(false);
  const [startEnabled, setStartEnabled] = useState(false);
  const [exitModal, setExitModal] = useState(false);
  const [beforeCreateModal, setBeforeCreateModal] = useState(false);
  const [moreResultsNotificationData, setMoreResultsNotificationData] =
    useState(location.state?.selectedRow);

  const [fromPermit, setFromPermit] = useState(location.state);

  const handleToggleFavorites = () => {
    setCurrentPage(1);
    setFavoritesOpen(!favoritesOpen);
    const inputElement = document.getElementById("searchInput");
    if (inputElement) {
      inputElement.value = ""; // Clear the search input value
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (currentSession === "userSession") {
        if (currentStep === 9 && startPermitOpen === true) {
          setStartPermitOpen(false);
        }
      } else if (currentSession === "subUserSession") {
        if (currentStep === 7 && startPermitOpen === true) {
          setStartPermitOpen(false);
        }
      }
    }
  }, [currentStep]);

  useEffect(() => {
    if (fromPermit?.fromPermit) {
      setSelectedPreName(fromPermit?.county);
      setAvailableFormsNum(fromPermit?.availableFormsNum);
      setDisplayImage(fromPermit?.displayImage);
      setStartPermitOpen(true);
    }
  }, []);

  //! MOBILE - DATA FROM NOTIFICATIONS ////////////
  useEffect(() => {
    if (moreResultsNotificationData && filteredCountiesData) {
      const currentIndex = filteredCountiesData.findIndex(
        (item) =>
          item.name === moreResultsNotificationData?.originalValues?.county
      );

      const originalValues = moreResultsNotificationData?.originalValues;

      if (currentIndex !== -1) {
        handleSwipe(currentIndex);
        setAddEnabled(true);
        setRowData(filteredCountiesData[currentIndex]);
        // formik.setValues({ ...formik.values, ...originalValues });
        setSelectedPreName(originalValues?.county);

        if (moreResultsNotificationData?.type === "moreResults") {
          setAddressRow(moreResultsNotificationData?.moreResults);
          setListEnabled(true);
          setCreateEnabled(false);
          setPreviewEnabled(false);
        }
      }
    }
  }, [moreResultsNotificationData, filteredCountiesData]);
  //! ////////////////////////////////////////////

  useEffect(() => {
    setVisitEnabled(true);
  }, []);

  useEffect(() => {
    if (addEnabled && !tabletSize) {
      setAddEnabled(false);
    }
  }, [addEnabled, tabletSize]);

  const handleSwipe = (index) => {
    setRowIndex(index);
    setRowData(filteredCountiesData[index]);
    setCounty(filteredCountiesData[index]?.name);
    // setCopySelected(filteredCountiesData[index]);
  };

  const handleVisitClick = () => {
    if (visitEnabled) {
      return;
    }
    const inputElement = document.getElementById("searchInput");
    if (inputElement) {
      inputElement.value = ""; // Clear the search input value
    }
    setVisitEnabled(!visitEnabled);
    if (!visitEnabled) {
      setFavoritEnabled(false);
    }
  };

  const handleFavoritClick = () => {
    if (favoritEnabled) {
      return;
    }
    setFavoritEnabled(!favoritEnabled);
    setRowIndex(0);
    const inputElement = document.getElementById("searchInput");
    if (inputElement) {
      inputElement.value = ""; // Clear the search input value
    }
    if (!favoritEnabled) {
      setVisitEnabled(false);
    }
  };

  const handleAddClick = () => {
    if (!rowData && filteredCountiesData?.length > 0) {
      setRowData(filteredCountiesData[0]);
      setCounty(filteredCountiesData[0]?.name);
    }

    setAddEnabled(!addEnabled);
    setVisitEnabled(true);
    setFavoritEnabled(false);
    setCreateEnabled(true);
    setPreviewEnabled(false);
    setListEnabled(false);

    setLicenseNumber(currentUser?.user?.contractors[0]?.licenseNumber);
    setLicenseType(currentUser?.user?.contractors[0]?.licenseType);
    setCopySelected(currentUser?.user?.contractors[0]);

    // formik?.resetForm();
  };

  const handleClose = () => {
    if (pdfProgressModalOpen && isMd) {
      return;
    } else {
      setExitModal(true);
    }
  };

  const handleStartCreate = () => {
    setEditFromCreate(false);
    setIsPreview(false);
    // formik.submitForm();
    setBeforeCreateModal(false);
  };

  const confirmedHandleClose = () => {
    setExitModal(false);
    setAddEnabled(false);
    setAddressRow([]);
    setCopySelected([]);
    setMoreResultsNotificationData(null);
  };

  const handleCreateClick = () => {
    if (createEnabled) {
      return;
    }
    setCreateEnabled(true);
    setPreviewEnabled(false);
    setListEnabled(false);
  };

  const handlePreviewClick = async () => {
    if (previewEnabled) {
      return;
    }

    try {
      setPreviewLoading(true);

      const values = {};
      const { cName, cAddress } = determineContractorDetails();
      const newValues = preparePreviewValues(values, cName, cAddress);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/createPreview`,
        {
          values: newValues,
        },
        { withCredentials: true }
      );

      if (resp?.data?.success === true) {
        setPreviewImg(resp?.data?.data?.signed_urls);
      }
    } catch (e) {
      setPreviewLoading(false);
    }
    setPreviewLoading(false);
    setPreviewEnabled(true);
    setCreateEnabled(false);
    setListEnabled(false);
  };

  const handleListClick = () => {
    if (addressRow?.length === 0) {
      return;
    }
    setListEnabled(true);
    setCreateEnabled(false);
    setPreviewEnabled(false);
  };

  // ! ///////////////////////////////////////////////

  useEffect(() => {
    if (isMd && openDrawer === true) {
      setDisableSelect(false);
      setOpenDrawer(false);
      setEditFromCreate(false);
    }

    if (!isMd && moreResultsNotificationData) {
      setMoreResultsNotificationData(null);
    }
  }, [isMd, openDrawer, moreResultsNotificationData]);

  const determineContractorDetails = () => {
    let cName = "";
    let cAddress = "";

    if (currentUser?.user?.contractors[selectedLicenseIndex]?.record_name) {
      if (
        currentUser.user.contractors[selectedLicenseIndex].record_name[0]
          .type === "ORGANIZATION"
      ) {
        cName =
          currentUser.user.contractors[selectedLicenseIndex].record_name[0]
            .nameUnparsed;

        if (
          currentUser.user.contractors[selectedLicenseIndex].type === "custom"
        ) {
          cAddress = currentUser.user.contractors[selectedLicenseIndex].address;
        } else {
          cAddress =
            currentUser.user.contractors[selectedLicenseIndex].proAddress;
        }
      } else {
        if (
          currentUser.user.contractors[selectedLicenseIndex].record_name[0]
            .middleName !== ""
        ) {
          cName =
            currentUser.user.contractors[selectedLicenseIndex].record_name[0]
              .firstName +
            " " +
            currentUser.user.contractors[selectedLicenseIndex].record_name[0]
              .middleName +
            " " +
            currentUser.user.contractors[selectedLicenseIndex].record_name[0]
              .lastName;
        } else {
          cName =
            currentUser.user.contractors[selectedLicenseIndex].record_name[0]
              .firstName +
            " " +
            currentUser.user.contractors[selectedLicenseIndex].record_name[0]
              .lastName;
        }

        if (
          currentUser.user.contractors[selectedLicenseIndex].type === "custom"
        ) {
          cAddress = currentUser.user.contractors[selectedLicenseIndex].address;
        } else {
          cAddress =
            currentUser.user.contractors[selectedLicenseIndex].proAddress;
        }
      }
    } else {
      if (
        currentUser.user.contractors[selectedLicenseIndex].type === "custom"
      ) {
        cName = currentUser.user.contractors[selectedLicenseIndex].name;
        cAddress = currentUser.user.contractors[selectedLicenseIndex].address;
      } else {
        cName = currentUser.user.contractors[selectedLicenseIndex].proName;
        cAddress =
          currentUser.user.contractors[selectedLicenseIndex].proAddress;
      }
    }

    return { cName, cAddress };
  };

  const preparePreviewValues = (values, cName, cAddress) => {
    let newValues = values;

    newValues["_id"] = String(currentUser.user._id);
    newValues["allRetrieve"] = false;
    newValues["county"] = county;
    newValues["customerId"] = currentUser.user.customerId;
    newValues["email"] = currentUser?.subuser
      ? currentUser?.subuser?.email
      : currentUser.user.email;
    newValues["record_name"] =
      currentUser.user.contractors[selectedLicenseIndex]?.record_name;
    newValues["contractorName"] = cName;
    newValues["contractorAddress"] = cAddress;

    newValues["contractorID"] =
      currentUser.user.contractors[selectedLicenseIndex]?.id;
    newValues["contractorConfirmed"] =
      currentUser.user.contractors[selectedLicenseIndex]?.confirmed;

    newValues["contractorPhone"] =
      currentUser.user.contractors[selectedLicenseIndex].phone;
    newValues["contractorFax"] =
      currentUser.user.contractors[selectedLicenseIndex].fax;
    newValues["contractorEmail"] =
      currentUser.user.contractors[selectedLicenseIndex].email;
    newValues["licenseNumber"] =
      currentUser.user.contractors[selectedLicenseIndex]?.licenseNumber;
    newValues["licenseType"] =
      currentUser.user.contractors[selectedLicenseIndex]?.licenseType;
    newValues["licenseStatus"] =
      currentUser.user.contractors[selectedLicenseIndex]?.licenseStatus;
    newValues["img"] = selectedPreForm;
    setDefaultValues(newValues);
    newValues["isSuretyRadio"] = isSuretyRadio;

    return newValues;
  };

  const updateNotification = async (task_id) => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/update-notification`,
      { task_id: task_id, currentSession: currentSession },
      { withCredentials: true }
    );
  };

  useEffect(() => {
    if (
      currentUser &&
      currentUser.user &&
      currentUser.user.selectedCountiesNOC &&
      !currentUser.user.selectedCountiesNOC.some(
        (county) => selectedPreForm === county.form
      )
    ) {
      setOpenDrawer(false);
    }

    if (defCounty && defForm && openDrawer == false) {
      setSelectedPreName(defCounty);
      setSelectedPreForm(defForm);
      setOpenDrawer(true);
    }
  }, [selectedPreForm, currentUser?.user?.selectedCountiesNOC]);

  useEffect(() => {
    if (newRequest === true) {
      const targetTask = currentUser?.nocUserTasks.find(
        (task) => task.task_id === newRequestID
      );
      if (targetTask) {
        if (targetTask.status === "completed") {
          clearInterval(intervalId);
          setElapsedTime(0);
          if (targetTask.success === "more") {
            if ((openDrawer || addEnabled) && !inQueueDrawer) {
              setDefaultAddress({
                ...defaultAddress,
                retrieved: !targetTask.original_values.allRetrieve
                  ? String(targetTask.listPerPage)
                  : targetTask.return_values.length,
                fullRetrieve: !targetTask.original_values.allRetrieve
                  ? String(targetTask.numberOfLists)
                  : targetTask.return_values.length,
              });

              if (targetTask.listPages === 1) {
                setFullRetrieve(true);
                setIsRetrievingAddress(true);
              } else {
                setIsRetrievingAddress(false);
              }
              if (tabletSize) {
                setPreviewLoading(false);
              }
              setAddressRow(targetTask.return_values);
              if (tabletSize) {
                setListEnabled(true);
                setCreateEnabled(false);
                setPreviewEnabled(false);
              }
              setCardFlip(true);
              updateNotification(targetTask.task_id);
              setPreviewLoading(false);
              setCreateNocLoading(false);
              setDisableSelect(false);
              setRetrieveLoading(false);
              setText(targetTask.message);
              if (fullRetrieve) {
                setMoreResultsText("Addresses are retrieved successfully!");
              }
            }
            setRetrieveLoading(false);
            // setIsRetrievingAddress(false);
            setNewRequest(false);
            setNewRequestID("");
            setFullRetrieve(false);
          } else if (
            targetTask.success === true &&
            targetTask?.return_values?.signed
          ) {
            if ((openDrawer || addEnabled) && !inQueueDrawer) {
              if (tabletSize === true) {
                navigate("/dashboard", {
                  state: { nocReady: true },
                });
                return;
              }

              setText(targetTask.message);
              setPreview(true);
              setIsReadyToDownload(true);
              setPreviewImg(targetTask.return_values?.signed);
              setPdf(targetTask.return_values.pdfUrl);
              setDownloadName(targetTask.return_values?.pdfName);
              updateNotification(targetTask.task_id);
              setPreviewLoading(false);
              setCreateNocLoading(false);
              setDisableSelect(true);
              setEditFromCreate(true);

              const formWithTaskId = {
                ...targetTask.return_values,
                task_id: targetTask.task_id,
                second_took: targetTask.second_took,
              };

              setFormCreated(formWithTaskId);
              setIsFormCreated(true);
              // formik?.setFieldValue(
              //   "owner",
              //   targetTask.return_values?.ownersName
              // );
              //! AUTO NAVIGATE
              // setTimeout(() => {
              //   dispatch(setCreatedNOC(formWithTaskId));
              //   navigate("/dashboard");
              //   setOpenDrawer(false);
              // }, 4000);
            }

            setNewRequest(false);
            setNewRequestID("");
            //? OPEN THE NEW METHOD //////////////////////
            // setOpenDrawer(false);

            // setNotarizeDrawer(true);

            //? //////////////////////////////////////////
          } else if (targetTask.success === false) {
            if ((openDrawer || addEnabled) && !inQueueDrawer) {
              setText(targetTask.message);
              updateNotification(targetTask.task_id);
              setCreateNocLoading(false);
              setRetrieveLoading(false);
              setDisableSelect(false);
              setPreviewLoading(false);
            }
            setNewRequest(false);
            setNewRequestID("");
          } else if (targetTask.success === "waiting_list") {
            if ((openDrawer || addEnabled) && !inQueueDrawer) {
              setText(targetTask.message);

              setCreateNocLoading(false);
              setRetrieveLoading(false);
              setDisableSelect(false);
              setPreviewLoading(false);
            }
            setNewRequest(false);
            setNewRequestID("");
          }
        }
      }
    }
  }, [currentUser, newRequest]);
  useEffect(() => {
    if (copySelected?.contractorConfirmed === true) {
      setLicenseText("Verified License Number");
      setInvalid(false);
      setRetrieveLoading(false);
    } else if (
      copySelected?.licenseStatus === "not_found" ||
      copySelected?.licenseStatus === "error"
    ) {
      setLicenseText("License Number not Found");
      if (copySelected?.tried && copySelected?.tried >= 4) {
        setInvalid(true);
        setRetrieveLoading(true);
      } else {
        setInvalid(true);
        setRetrieveLoading(false);
      }
    } else if (copySelected?.licenseStatus === "unverified") {
      setLicenseText("License Number not Found");
      if (copySelected?.tried && copySelected?.tried >= 4) {
        setInvalid(true);
        setRetrieveLoading(true);
      } else {
        setInvalid(true);
        setRetrieveLoading(false);
      }
    } else if (copySelected?.process_status === "queued") {
      setLicenseText("Waiting for Validation");
      if (copySelected?.tried && copySelected?.tried >= 4) {
        setInvalid(true);
        setRetrieveLoading(true);
      } else {
        setInvalid(true);
        setRetrieveLoading(false);
      }
    } else if (
      copySelected?.valid === false &&
      copySelected?.expiry !== "" &&
      copySelected?.licenseStatus !== "not_found"
    ) {
      setLicenseText(copySelected?.licenseStatus);
      if (copySelected?.tried || copySelected?.tried >= 4) {
        setInvalid(true);
        setRetrieveLoading(true);
      } else {
        setInvalid(true);
        setRetrieveLoading(false);
      }
    } else if (
      copySelected?.valid === true &&
      copySelected?.confirmed === false
    ) {
      setLicenseText(copySelected?.licenseStatus);
      if (copySelected?.tried && copySelected?.tried >= 4) {
        setInvalid(true);
        setRetrieveLoading(true);
      } else {
        setInvalid(true);
        setRetrieveLoading(false);
      }
    } else {
      setLicenseText("Verified License Number");
      setInvalid(false);
      setRetrieveLoading(false);
    }
  }, [copySelected, licenseText]);

  useEffect(() => {
    setCopySelected(currentUser?.user?.contractors[selectedLicenseIndex]);
    if (currentUser?.user?.expired === true) {
      navigate("/subscription");
    } else if (currentUser.user.selectedCountiesNOC.length === 0) {
      dispatch(enableEmptyCountyFlag(location.pathname));
      navigate("/selectcounties");
    } else {
      setLicenseType(
        currentUser?.user.contractors[selectedLicenseIndex]?.licenseType
      );
      setLicenseNumber(
        currentUser?.user.contractors[selectedLicenseIndex]?.licenseNumber
      );
      if (
        currentUser?.user.contractors[selectedLicenseIndex]?.licenseStatus ===
          "not_found" ||
        currentUser?.user.contractors[selectedLicenseIndex]?.licenseStatus ===
          "error"
      ) {
        setInvalid(true);
      } else {
        setInvalid(false);
      }

      const favoritesExist =
        JSON.parse(localStorage.getItem("favorites")) || [];

      if (
        favoritesExist.length !== 0 &&
        currentUser.user.selectedCountiesNOC.length === 0
      ) {
        // Clear local storage
        localStorage.removeItem("favorites");
      }

      const updatedFavorites = favoritesExist.filter((favorite) =>
        currentUser?.user?.selectedCountiesNOC.some(
          (selectedCounty) => selectedCounty.name === favorite.name
        )
      );
      localStorage.setItem("favorites", JSON.stringify(updatedFavorites));

      try {
        if (favoritesOpen || favoritEnabled) {
          // Load favorites from local storage
          const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
          setCountiesData(favorites);
          setFilteredCountiesData(favorites);
        } else {
          setCountiesData([...currentUser.user.selectedCountiesNOC]);
          setFilteredCountiesData([...currentUser.user.selectedCountiesNOC]);
        }

        setDataLoading(false);
      } catch (e) {
        setDataLoading(false);

        // navigate("/login");
      }
    }
  }, [currentUser, favoritesOpen, favoritEnabled, noc_data]);

  useEffect(() => {
    if (!openDrawer && intervalId) {
      clearInterval(intervalId);
      setElapsedTime(0);
    }
  }, [openDrawer, intervalId]);

  const handleSearch = (event) => {
    if (tabletSize) {
      setRowIndex(0);
    } else {
      setCurrentPage(1);
    }
    let value = event.target.value.toLowerCase();
    let result = [];
    result = countiesData.filter((data) => {
      return data.name.toLowerCase().search(value) !== -1;
    });
    // setTasks(result)
    setFilteredCountiesData(result);
  };

  const getCurrentTasks = (tasks) => {
    const indexOfLastCard = currentPage * cardPerPage;
    const indexOfFirstCard = indexOfLastCard - cardPerPage;
    return tasks.slice(indexOfFirstCard, indexOfLastCard);
  };
  const handleDisplayNOC = () => {
    let nocLeft = "";

    if (
      currentUser?.user.role === "admin" ||
      currentUser?.user.role === "tester" ||
      currentUser?.user?.admin === true
    ) {
      nocLeft = 1000 - currentUser?.user.analytics.nocCreatedInPeriod;
    } else {
      nocLeft = currentUser?.user.analytics.availableNocInPeriod;
    }

    if (nocLeft === 0 || nocLeft < 0) {
      return (
        <Typography
          ml="auto"
          my={10}
          mr="auto"
          textAlign="center"
          variant="h5"
          color="error"
        >
          You have reached the limit of available NOC. <br />
          Please update your package or come back later.
        </Typography>
      );
    } else {
      return (
        <SelectCountyFormSelect
          setDefCounty={setDefCounty}
          setDefForm={setDefForm}
          downloadName={downloadName}
          setPdf={setPdf}
          Nloading={Nloading}
          formCreated={formCreated}
          selectedPreName={selectedPreName}
          setSelectedPreName={setSelectedPreName}
          setOpenDefaultModal={setOpenDefaultModal}
          setDisableSelect={setDisableSelect}
          disableSelect={disableSelect}
          setSelectedLicenseIndex={setSelectedLicenseIndex}
          invalid={invalid}
          setLicenseNumber={setLicenseNumber}
          licenseNumber={licenseNumber}
          licenseText={licenseText}
          copySelected={copySelected}
          setCopySelected={setCopySelected}
          licenseType={licenseType}
          setLicenseType={setLicenseType}
          noc_data={noc_data}
          setFilteredCountiesData={setFilteredCountiesData}
          setCountiesData={setCountiesData}
          favoritesOpen={favoritesOpen}
          currentUser={currentUser}
          isSmallScreen={isSmallScreen}
          intervalStarts={intervalStarts}
          elapsedTime={elapsedTime}
          targetTime={targetTime}
          progressDisabled={progressDisabled}
          progressValue={progressValue}
          setNewRequestID={setNewRequestID}
          setNewRequest={setNewRequest}
          moreResultsText={moreResultsText}
          setFullRetrieve={setFullRetrieve}
          selectedPreForm={selectedPreForm}
          setSelectedPreForm={setSelectedPreForm}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          editFromCreate={editFromCreate}
          setRetrieveLoading={setRetrieveLoading}
          isRetrievingAddress={isRetrievingAddress}
          setIsRetrievingAddress={setIsRetrievingAddress}
          retrieveLoading={retrieveLoading}
          defaultAddress={defaultAddress}
          setText={setText}
          text={text}
          pdf={pdf}
          isReadyToDownload={isReadyToDownload}
          setIsReadyToDownload={setIsReadyToDownload}
          setCounty={setCounty}
          filteredCountiesData={filteredCountiesData}
          getCurrentTasks={getCurrentTasks}
          createNocLoading={createNocLoading}
          previewLoading={previewLoading}
          previewImg={previewImg}
          setPreviewImg={setPreviewImg}
          preview={preview}
          setPreview={setPreview}
          setIsPreview={setIsPreview}
          type="create-noc"
          isClosed={isClosed}
          setIsClosed={setIsClosed}
          // formik={formik}
          selectedName={selectedName}
          selectCards={selectCards}
          cardRemain={cardRemain}
          loading={dataLoading}
          totalCounty={countiesData.length}
          cardPerPage={cardPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          enlargeType="createNOC"
          enlargeHeader="Create PDF"
          enlargeNote="To create your PDF, please enter the values below."
          addressRow={addressRow}
          cardFlip={cardFlip}
          setAddressRow={setAddressRow}
          setCardFlip={setCardFlip}
          defaultValues={defaultValues}
          setCreateNocLoading={setCreateNocLoading}
          setPreviewLoading={setPreviewLoading}
          setDefaultAddress={setDefaultAddress}
          setEditFromCreate={setEditFromCreate}
          isFormCreated={isFormCreated}
          isSuretyRadio={isSuretyRadio}
          setIsSuretyRadio={setIsSuretyRadio}
          // MOBILE
          visitEnabled={visitEnabled}
          handleSwipe={handleSwipe}
          rowIndex={rowIndex}
          setRowIndex={setRowIndex}
          rowData={rowData}
          favoritEnabled={favoritEnabled}
          handleFavoritClick={handleFavoritClick}
          handleVisitClick={handleVisitClick}
          addEnabled={addEnabled}
          handleAddClick={handleAddClick}
          handleSearch={handleSearch}
          openDocumentDrawer={openDocumentDrawer}
          setOpenDocumentDrawer={setOpenDocumentDrawer}
          setStartPermitOpen={setStartPermitOpen}
          setAvailableCitiesNum={setAvailableCitiesNum}
          setAvailableFormsNum={setAvailableFormsNum}
          setDisplayImage={setDisplayImage}
          setReadyLoad={setReadyLoad}
        />
      );
    }
  };

  const selectCards = (countyName, form) => {
    if (selectedName.includes(countyName)) {
      let copyArray = [...selectedName];
      let index = copyArray.indexOf(countyName);
      copyArray.splice(index, 1);

      let copyForm = [...selectedForm];
      let indexForm = copyForm.indexOf(form);
      copyForm.splice(indexForm, 1);

      setCardRemain(cardRemain + 1);
      setSelectedName(copyArray);
      setSelectedForm(copyForm);
    } else {
      if (cardRemain !== 0) {
        setSelectedName([...selectedName, countyName]);
        setSelectedForm([...selectedForm, form]);
        setCardRemain(cardRemain - 1);
      }
    }
  };

  return (
    <>
      {dataLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            py={1}
            sx={{
              width: "100%",
              height: "calc(100vh - 175px)", // Ensure it takes up 100% of the viewport height
              maxWidth: tabletSize ? undefined : "1500px",
              mx: "auto",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
            }}
          >
            {!tabletSize ? (
              <Card
                className="step10"
                sx={{
                  py: 2,
                  mx: 2,
                  position: "relative",
                  minHeight: "480px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* HEADER */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: 2,
                      gap: 2,
                      mb: 1,
                    }}
                  >
                    <Box
                      sx={{ width: 150, flexShrink: 0, whiteSpace: "nowrap" }}
                    ></Box>
                    <Box sx={{ flexGrow: 1, flexShrink: 1, minWidth: 0 }}>
                      {/* Middle Box */}
                      <Typography
                        mb={0}
                        textAlign="center"
                        variant="h5"
                        color="primary"
                      >
                        Creating New Permit Package
                      </Typography>
                      <Typography
                        color="primary"
                        textAlign="center"
                        variant="subtitle2"
                      >
                        {currentUser?.user.selectedCountiesNOC.length === 0
                          ? "Please select Counties before start creating Permit Package"
                          : "Begin by selecting the county for which you need to create a permit package."}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 2,
                          mt: 2,
                        }}
                      >
                        <Button
                          size="small"
                          color="primary"
                          sx={{ fontWeight: "bold", maxWidth: "20rem" }}
                          onClick={handleToggleFavorites}
                          variant={favoritesOpen ? "outlined" : "contained"}
                          fullWidth
                        >
                          MY COUNTIES
                        </Button>
                        <Button
                          size="small"
                          color="error"
                          sx={{ fontWeight: "bold", maxWidth: "20rem" }}
                          onClick={handleToggleFavorites}
                          variant={favoritesOpen ? "contained" : "outlined"}
                          fullWidth
                          endIcon={
                            <PushPinIcon sx={{ transform: "rotate(45deg)" }} />
                          }
                        >
                          PINNED COUNTIES
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          display: "Flex",
                          justifyContent: "center",
                          mt: 2,
                          mb: -1,
                        }}
                      >
                        <Pagination
                          count={Math.ceil(
                            filteredCountiesData.length / cardPerPage
                          )}
                          page={currentPage}
                          onChange={(e, value) => setCurrentPage(value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{ width: 200, flexShrink: 0, whiteSpace: "nowrap" }}
                    >
                      <TextField
                        id="searchInput"
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                        fullWidth
                        placeholder="SEARCH"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Box>

                  {/* HEADER END */}
                  <Box
                    sx={{
                      mt: 1,
                      px: 2,
                      flexGrow: 1,
                      overflowY: "auto", // Enable vertical scrolling if content overflows
                      maxHeight: "calc(100vh - 400px)", // Adjust height to account for padding and spacing
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        overflowY: "auto",
                        maxHeight: "100%",
                      }}
                    >
                      {handleDisplayNOC()}
                    </Grid>
                  </Box>
                </Box>
              </Card>
            ) : (
              handleDisplayNOC()
            )}
          </Box>
          {/* NOC STARTED DRAWER */}
          <InQueueDrawer
            inQueueDrawer={inQueueDrawer}
            setInQueueDrawer={setInQueueDrawer}
            inQueueMessage={inQueueMessage}
            isMd={isMd}
          />
          <DefaultModal
            openDefaultModal={openDefaultModal}
            setOpenDefaultModal={setOpenDefaultModal}
            setOpenDrawer={setOpenDrawer}
            cancelDefaultLoading={cancelDefaultLoading}
            setCancelDefaultLoading={setCancelDefaultLoading}
            selectedPreName={selectedPreName}
          />
        </>
      )}
      {/* <PdfProgressModal
        waitingList={waitingList}
        setWaitingList={setWaitingList}
        open={pdfProgressModalOpen}
        handleClose={pdfProgressModalHandleClose}
        selectedPreName={selectedPreName}
        noc_data={noc_data}
        pdfProgress={pdfProgress}
        items={items}
        setItems={setItems}
        dotStates={dotStates}
        setDotStates={setDotStates}
        completed={completed}
        setCompleted={setCompleted}
        intervalIds={intervalIds}
        setIntervalIds={setIntervalIds}
        isFinished={isFinished}
        setIsFinished={setIsFinished}
        initializeQueue={initializeQueue}
        setInitializeQueue={setInitializeQueue}
        isMoreError={isMoreError}
        setIsMoreError={setIsMoreError}
        isFailedError={isFailedError}
        setIsFailedError={setIsFailedError}
        setOpenDrawer={setOpenDrawer}
        setAddEnabled={setAddEnabled}
      /> */}
      {/* EMPTY PERMIT NUMBER POPOVER */}
      <EmptyPermitPopover
        emptyPermitOpen={emptyPermitOpen}
        setEmptyPermitOpen={setEmptyPermitOpen}
        setEmptyPermitConfirmed={setEmptyPermitConfirmed}
        // formik={formik}
      />
      {/* CREATE NOC MOBILE */}
      {addEnabled && (
        <CreateNocMobile
          visitEnabled={visitEnabled}
          rowData={rowData}
          favoritEnabled={favoritEnabled}
          handleFavoritClick={handleFavoritClick}
          handleVisitClick={handleVisitClick}
          addEnabled={addEnabled}
          handleAddClick={handleAddClick}
          handleClose={handleClose}
          tabletSize={tabletSize}
          mobileSize={mobileSize}
          // formik={formik}
          handleCreateClick={handleCreateClick}
          handleListClick={handleListClick}
          handlePreviewClick={handlePreviewClick}
          createEnabled={createEnabled}
          listEnabled={listEnabled}
          previewEnabled={previewEnabled}
          previewLoading={previewLoading}
          copySelected={copySelected}
          setCopySelected={setCopySelected}
          currentUser={currentUser}
          disableSelect={disableSelect}
          licenseType={licenseType}
          setLicenseType={setLicenseType}
          setSelectedLicenseIndex={setSelectedLicenseIndex}
          setLicenseNumber={setLicenseNumber}
          licenseNumber={licenseNumber}
          type="create-noc"
          isSuretyRadio={isSuretyRadio}
          setIsSuretyRadio={setIsSuretyRadio}
          editFromCreate={editFromCreate}
          previewImg={previewImg}
          exitModal={exitModal}
          setExitModal={setExitModal}
          confirmedHandleClose={confirmedHandleClose}
          beforeCreateModal={beforeCreateModal}
          setBeforeCreateModal={setBeforeCreateModal}
          handleStartCreate={handleStartCreate}
          addressRow={addressRow}
          defaultAddress={defaultAddress}
          setCreateEnabled={setCreateEnabled}
          setListEnabled={setListEnabled}
          emptyPermitConfirmed={emptyPermitConfirmed}
          setEmptyPermitConfirmed={setEmptyPermitConfirmed}
          setEmptyPermitOpen={setEmptyPermitOpen}
          mobile={true}
        />
      )}

      <StartPermit
        startPermitOpen={startPermitOpen}
        setStartPermitOpen={setStartPermitOpen}
        county={selectedPreName}
        countyImg={selectedPreForm}
        availableCitiesNum={availableCitiesNum}
        availableFormsNum={availableFormsNum}
        displayImage={displayImage}
        setDisplayImage={setDisplayImage}
        readyLoad={readyLoad}
        setReadyLoad={setReadyLoad}
        currentUser={currentUser}
        fromPermit={fromPermit}
      />
    </>
  );
};

export default CreateNoc;
