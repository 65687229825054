import React, { useState } from "react";
import { Modal, Box, Typography, Button, FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function MarkRonAsExpired({
  infoModal,
  setInfoModal,
  elapsed,
  handleExpireRON,
}) {
  console.log(elapsed.hours > 0);
  return (
    <Modal open={infoModal.rescheduleOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          color="primary"
          id="modal-modal-title"
          variant="h4"
          align="center"
          component="h2"
        >
          Mark RON Session as Expired
        </Typography>
        {elapsed.minutes < 0 ? (
          <>
            <Typography align="center" variant="h6" mt={2} color="GrayText">
              RON Session will Starts in:
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                mb: 3,
                gap: 0.5,
              }}
            >
              <Typography
                sx={{
                  color: "#10b981",
                }}
                variant="h3"
              >
                {Math.abs(elapsed.minutes) < 10
                  ? `0${Math.abs(elapsed.minutes)}`
                  : Math.abs(elapsed.minutes)}
              </Typography>
              <Typography
                sx={{
                  color: "#10b981",
                }}
                variant="body1"
                fontWeight="bold"
              >
                Min
              </Typography>
            </Box>
          </>
        ) : (
          <Typography align="center" variant="h6" mt={2} color="GrayText">
            Elapsed Time Since Scheduled RON Session:
          </Typography>
        )}

        {elapsed.minutes >= 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              mb: 3,
              gap: 0.5,
            }}
          >
            {elapsed.hours > 0 && (
              <>
                <Typography color="error" variant="h3">
                  {elapsed.hours}
                </Typography>
                <Typography variant="body1" fontWeight="bold" color="error">
                  {elapsed.hours > 1 ? "Hours" : "Hour"}
                </Typography>
              </>
            )}

            <Typography
              sx={{
                ml: 2,
                color:
                  (elapsed.hours === 0 || !elapsed.hours) &&
                  elapsed.minutes <= 15
                    ? "#10b981"
                    : "#f04438",
              }}
              variant="h3"
            >
              {elapsed.minutes < 10 ? `0${elapsed.minutes}` : elapsed.minutes}
            </Typography>
            <Typography
              sx={{
                color:
                  (elapsed.hours === 0 || !elapsed.hours) &&
                  elapsed.minutes <= 15
                    ? "#10b981"
                    : "#f04438",
              }}
              variant="body1"
              fontWeight="bold"
            >
              Min
            </Typography>
          </Box>
        )}

        <Typography
          align="center"
          variant="body1"
          fontWeight="bold"
          color="primary"
        >
          {(elapsed.hours === 0 || !elapsed.hours) && elapsed.minutes <= 15
            ? "You have to wait at least 15 minutes before marking this RON session as expired."
            : "Marking this RON session as expired will make it unavailable, requiring the signer to reschedule a new appointment. Additionally, your 'Expired' session count will increase by one."}
        </Typography>
        <Typography
          variant="h5"
          mt={2}
          align="center"
          fontWeight="bold"
          color="error"
        >
          {(elapsed.hours === 0 || !elapsed.hours) && elapsed.minutes <= 15
            ? ""
            : " Are you sure?"}
        </Typography>

        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
          <Button
            disabled={infoModal.rescheduleLoading}
            sx={{ mr: 1 }}
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() =>
              setInfoModal({ ...infoModal, rescheduleOpen: false })
            }
          >
            Cancel
          </Button>
          <LoadingButton
            // TODO:, ENABLE THIS
            disabled={
              (elapsed.hours === 0 || !elapsed.hours) && elapsed.minutes <= 15
            }
            sx={{ ml: 1 }}
            loading={infoModal.rescheduleLoading}
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleExpireRON}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default MarkRonAsExpired;
