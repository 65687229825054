export const noRecordTitle = {
  0: "No Permit Package has been created yet.",
  1: "You haven't sent any permit package to the contractor for notarization yet.",
  2: "You don't have any archived permit packages yet.",
  3: "You don't have any requested permit packages to notarize yet.",
  4: "You don't have any archived permit packages yet.",
};

export const packageRowDetailsEmptyTitle = {
  0: "You can get more information about a Project and manage it by selecting it.",
  1: "You can get more information about the sent permit package and manage it by selecting it.",
  2: "You can get more information about an archived permit package and manage it by selecting it.",
  3: "You can get more information about the requested permit package to notarize by selecting it.",
  4: "You can get more information about an archived permit package and manage it by selecting it.",
};
