// actions/pageActions/myPermitsActions.js
import axios from "axios";
import { DateTime } from "luxon";
import {
  getPermitPackages,
  updatePermitPackage,
  updatePermitPackageName,
} from "../dataActions";
import { loadStripe } from "@stripe/stripe-js";

export const updateMyPermitsState = (updates) => ({
  type: "UPDATE_MY_PERMITS_STATE",
  payload: updates,
});

export const updateEditObj = (updates) => ({
  type: "UPDATE_EDIT_OBJ",
  payload: updates,
});

export const updateNocStates = (updates) => ({
  type: "UPDATE_NOC_STATES",
  payload: updates,
});

export const updatePaisStates = (updates) => ({
  type: "UPDATE_PAIS_STATES",
  payload: updates,
});

export const updateBuildingStates = (updates) => ({
  type: "UPDATE_BUILDING_STATES",
  payload: updates,
});

export const updatePermitFormStates = (updates) => ({
  type: "UPDATE_PERMIT_FORM_STATES",
  payload: updates,
});

export const updateCompanyState = (key, list) => ({
  type: "UPDATE_COMPANY_STATES",
  payload: { key, list }, // Send both key and list as part of the payload
});

//? FUNCTIONS ///////////////////////////////////////////////////////////
export const handlePaginationModelChange =
  (model, details) => async (dispatch) => {
    const newPage = model.page;
    const newRowsPerPage = model.pageSize;
    dispatch(updateMyPermitsState({ page: newPage, pageSize: newRowsPerPage }));
    dispatch(fetchData(newPage, newRowsPerPage));
  };

export const fetchData = (newPage, newRowsPerPage) => async (dispatch) => {
  dispatch(updateMyPermitsState({ dataLoading: true }));
  await dispatch(getPermitPackages(newPage, newRowsPerPage));
  dispatch(updateMyPermitsState({ dataLoading: false }));
};

export const handleDownloadNoc =
  (selectedRow, type, currentSession, creatingDoc, leftValue) =>
  async (dispatch) => {
    try {
      const currentDate = DateTime.now().toFormat("yyyy-MM-dd");
      let pdfName, downloadPDF;

      // Set downloading state
      if (type === "all") {
        dispatch(updateMyPermitsState({ ppDownloading: true }));
      } else {
        dispatch(
          updateMyPermitsState({
            downloading:
              selectedRow?.selected_documents?.[leftValue]?.name +
              selectedRow?.task_id,
          })
        );
      }

      // Determine download target
      downloadPDF =
        type === "all" ? "all" : selectedRow?.documents?.[creatingDoc]?.pdfUrl;
      pdfName =
        type === "all"
          ? `notarized_permit_package_${currentDate}.zip`
          : selectedRow?.documents?.[creatingDoc]?.pdfName ||
            `permit_document_${creatingDoc}_${currentDate}.pdf`;

      // Perform the download request
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/download-created-file`,
        {
          pdf: downloadPDF,
          currentSession,
          type: type === "all" ? "all" : creatingDoc,
          task_id: selectedRow?.task_id,
        },
        {
          withCredentials: true,
          responseType: "blob",
        }
      );

      // Create a URL and initiate download
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", pdfName);
      document.body.appendChild(fileLink);
      fileLink.click();

      // Clean up
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL);
    } catch (e) {
      console.error("Download error:", e);
    } finally {
      // Reset downloading state
      if (type === "all") {
        dispatch(updateMyPermitsState({ ppDownloading: false }));
      } else {
        dispatch(
          updateMyPermitsState({
            downloading: false,
          })
        );
      }
    }
  };

// ? DOWNLOAD INSTRUCTIONS
export const handleDownloadInstructions =
  (selectedRow, currentSession, leftValue) => async (dispatch) => {
    try {
      dispatch(
        updateMyPermitsState({
          instructionDownloading:
            selectedRow?.selected_documents?.[leftValue]?.name +
            "inst" +
            selectedRow?.task_id,
        })
      );

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/download-instructions`,
        {
          task_id: selectedRow?.task_id,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
          responseType: "blob",
        }
      );
      // Create a blob URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Extract the filename from the response headers if available, or default to 'download.zip'
      let fileNameRaw =
        selectedRow?.project_name + "-instructions" || "download-instructions";
      let fileName =
        fileNameRaw
          .toLowerCase()
          .replace(/\s+/g, "_")
          .replace(/[^\w\-]+/g, "") + ".zip";

      link.setAttribute("download", fileName);

      // Append the link to the body
      document.body.appendChild(link);

      // Simulate a click to download the file
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Download failed", error);
    } finally {
      dispatch(
        updateMyPermitsState({
          instructionDownloading: false,
        })
      );
    }
  };

// ? EDIT PACKAGE NAME
export const handleEditPackageName =
  (selectedRow, currentSession, editObj) => async (dispatch) => {
    try {
      dispatch(updateMyPermitsState({ cancelLoading: true }));

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/edit-package-name`,
        {
          task_id: selectedRow?.task_id,
          packageName: editObj?.packageName,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
        }
      );
      if (resp?.data?.success) {
        dispatch(updateEditObj({ nameRespText: resp?.data?.message }));
        dispatch(
          updatePermitPackageName({
            task_id: selectedRow?.task_id,
            package_name: editObj?.packageName,
          })
        );
        dispatch(updateMyPermitsState({ editPNameOpen: false }));
        dispatch(updateMyPermitsState({ snackOpen: true }));
      }
    } catch (error) {
    } finally {
      dispatch(updateMyPermitsState({ cancelLoading: false }));
    }
  };

//? HANDLE CANCEL PERMIT
export const handleCancelPermit =
  (selectedRow, currentSession, cancelPermitComment) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(updateMyPermitsState({ cancelLoading: true }));
      const voidPermit = selectedRow?.city_scraper?.permit_id;

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/cancel-permit-package`,
        {
          task_id: selectedRow?.task_id,
          currentSession: currentSession,
          voidPermit: voidPermit,
          cancelPermitComment: cancelPermitComment,
        },
        {
          withCredentials: true,
        }
      );
      if (resp?.data?.success) {
        dispatch(updateMyPermitsState({ selectedRow: null }));
        dispatch(
          handlePaginationModelChange(
            {
              page: state.pages.myPermits.page,
              pageSize: state.pages.myPermits.pageSize,
            },
            "detail"
          )
        );
        // Update row count
        dispatch(
          updateMyPermitsState({ rowCount: state.pages.myPermits.rowCount - 1 })
        );
      }
    } catch (error) {
    } finally {
      dispatch(updateMyPermitsState({ cancelLoading: false }));
    }
  };

export const removeIntervalId = (item) => (dispatch, getState) => {
  const state = getState(); // Get the current Redux state
  const { nocStates } = state.pages.myPermits; // Access nocStates from Redux state

  // Create a new object without the target item
  const newIntervalIds = { ...nocStates.intervalIds };
  delete newIntervalIds[item]; // Remove the specific key from intervalIds

  // Dispatch the updated state to Redux
  dispatch(updateNocStates({ intervalIds: newIntervalIds }));
};

//? HANDLE ADDITION PAYMENT
export const handlePayment =
  (currentSession, paymentCards, activeStep) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { selectedRow, currentPopup } = state.pages.myPermits;

      dispatch(updateMyPermitsState({ paymentLoading: true }));
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/process-addition-payment`,
        {
          paymentCard: paymentCards[activeStep]?.id,
          task_id: selectedRow?.task_id,
          currentSession: currentSession,
          addition: currentPopup,
        },
        { withCredentials: true }
      );
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE); // Replace with your actual publishable key
      const result = await stripe.confirmCardPayment(resp.data.data);
      if (!result.error) {
        // The payment has been processed!
        if (
          result.paymentIntent.status === "succeeded" &&
          resp.data.valid === true
        ) {
          const paymentIntent = result.paymentIntent;
          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/finish-addition-payment`,
            {
              paymentIntent,
              task_id: selectedRow?.task_id,
              currentSession: currentSession,
              addition: currentPopup,
            },
            { withCredentials: true }
          );
          if (resp?.data?.success) {
            await dispatch(updatePermitPackage(resp?.data?.data));
            dispatch(
              updateMyPermitsState({
                promoModalOpen: false,
                paymentModalOpen: false,
                snackOpen: true,
              })
            );
          }
        }
      }
    } catch (e) {
    } finally {
      dispatch(updateMyPermitsState({ paymentLoading: false }));
    }
  };

//? HANDLE MANUAL NOC RECORD SEND
export const handleStartRecord =
  (currentSession) => async (dispatch, getState) => {
    const state = getState();
    const { selectedRow } = state.pages.myPermits;
    try {
      dispatch(updateMyPermitsState({ recordLoading: true }));
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/start-manual-recording`,
        {
          task_id: selectedRow?.task_id,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
        }
      );
      if (resp?.data?.success) {
        await dispatch(updatePermitPackage(resp?.data?.data));
      }
    } catch (error) {
    } finally {
      dispatch(updateMyPermitsState({ recordLoading: false }));
    }
  };

export const checkForDuplicatesRedux =
  ({ fieldsToCheck, fieldMapping, formikValues, listState, entityName }) =>
  (dispatch, getState) => {
    let hasDuplicate = false;

    fieldsToCheck.forEach((field) => {
      const formikField = fieldMapping[field] || field;
      const formikValue = formikValues?.[formikField]?.trim().toLowerCase();

      if (formikValue) {
        const isDuplicate =
          formikValue !== "" &&
          listState.some((item) => {
            const itemValue = item[field]?.trim().toLowerCase();
            return (
              itemValue === formikValue && item.id !== formikValues[entityName]
            );
          });

        if (formikValues[entityName]) {
          if (
            isDuplicate ||
            (formikValues[fieldMapping.nameField] === "" &&
              formikValues[entityName]) ||
            (formikValues[fieldMapping.addressField] === "" &&
              formikValues[entityName])
          ) {
            hasDuplicate = true;

            // Dispatch an action to set form errors
            dispatch({
              type: "SET_FORM_ERROR",
              payload: {
                field: formikField,
                message: `${fieldMapping.labels[field]} already exists`,
              },
            });
          } else {
            // Dispatch an action to clear the error if the message matches
            dispatch({
              type: "CLEAR_FORM_ERROR",
              payload: {
                field: formikField,
                existingMessage: `${fieldMapping.labels[field]} already exists`,
              },
            });
          }
        }
      }
    });

    // Update submitDisabled in Redux state based on duplicate check
    dispatch({
      type: "UPDATE_BUILDING_STATE",
      payload: {
        [`${entityName}SubmitDisabled`]: hasDuplicate,
      },
    });
  };
