// PromoDialogContent.js
import React from "react";
import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Pop2 = () => {
  return (
    <>
      <DialogTitle
        mt={1}
        color="primary"
        variant="h4"
        id="responsive-dialog-title"
        sx={{ textAlign: "center" }}
      >
        Get All Your Building Permits Done Fast with AI Assistance!
      </DialogTitle>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img
          alt="avatar_circle"
          src="/img/website_img/pp_avatar_circle.png"
          style={{ width: "15rem", height: "auto", zIndex: 2 }}
        />
      </Box>
      <DialogContent sx={{ px: 2 }}>
        <DialogContentText
          color="primary"
          variant="h6"
          sx={{ mb: 3, mt: 0, textAlign: "center" }}
        >
          What about letting Permit Rockstar help you complete all your building
          permit forms in addition to your NOC?
        </DialogContentText>
        <DialogContentText
          color="primary"
          sx={{ mb: 3, mt: 2, textAlign: "center" }}
        >
          Our advanced AI will hep you fill your forms in no time and we'll take
          care of gathering all the signatures needed and notarize them as
          required. Once the package is ready we will notify you or upload it
          for you.
        </DialogContentText>
        <DialogContentText
          color="primary"
          sx={{ mb: 3, textAlign: "center", fontWeight: "bold" }}
        >
          Save hundreds of dollars and 4 to 6 hours of your valuable time, by
          not having to search for information, fill forms, chase signatures
          around town, scanning and more.
        </DialogContentText>
        <DialogContentText
          color="primary"
          sx={{ textAlign: "center", fontWeight: "bold", px: 1 }}
        >
          <span style={{ fontSize: "26px", color: "#10b981" }}>Only</span>{" "}
          <span
            style={{
              textDecoration: "line-through",
              color: "red",
              fontSize: "20px",
            }}
          >
            $199
          </span>{" "}
          <span style={{ fontSize: "30px", color: "#10b981" }}>$</span>
          <span style={{ fontSize: "36px", color: "#10b981" }}>149</span>{" "}
        </DialogContentText>
        <DialogContentText
          color="primary"
          sx={{ mb: 5, textAlign: "center", fontWeight: "bold" }}
        >
          (Include all permit forms and NOC)
        </DialogContentText>

        <Box
          sx={{
            gap: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Button
            color="success"
            onClick={() => {}}
            fullWidth
            variant="contained"
          >
            YES, SAVE ME TIME AND MONEY
          </Button>
          <LoadingButton onClick={() => {}} color="error" fullWidth>
            No, I prefer to waste my time and do this the old-fashioned way
          </LoadingButton>
        </Box>
      </DialogContent>
    </>
  );
};

export default Pop2;
