import axios from "axios";
import { invalidateSession, setRole } from "./sessionsActions";

export const setInstantRonAccepted = () => {
  return { type: "SET_INSTANT_RON_ACCEPTED" };
};
export const setInstantRonStop = () => {
  return { type: "SET_INSTANT_RON_STOP" };
};
export const setInstantRonSuccess = () => {
  return { type: "SET_INSTANT_RON_SUCCESS" };
};
export const setInstantRonInitialState = () => {
  return { type: "SET_INSTANT_RON_INITIAL_STATE" };
};

export const subUserFirstLoginOff = () => {
  return {
    type: "SUB_USER_FIRST_LOGIN_OFF",
  };
};

export const updateAnalytics = (analytics) => {
  return {
    type: "UPDATE_ANALYTICS",
    payload: analytics,
  };
};

export const updateSubAnalytics = (analytics) => {
  return {
    type: "UPDATE_SUB_ANALYTICS",
    payload: analytics,
  };
};

export const updateSubUser = (userData) => {
  return {
    type: "UPDATE_SUB_USER",
    payload: userData,
  };
};

export const updateNotarySubUserForms = (notaryForm) => {
  return {
    type: "UPDATE_NOTARY_SUB_USER_FORMS",
    payload: notaryForm,
  };
};

export const updateNocSubUserTasks = (nocTasks) => {
  return {
    type: "UPDATE_NOC_SUB_USER_TASKS",
    payload: nocTasks,
  };
};

export const broadcastSubUserUpdate = (sharedUserData) => {
  return {
    type: "BROADCAST_SUB_USER_UPDATE",
    payload: sharedUserData,
  };
};

export const updateSubUserURLs = (urlData) => {
  return {
    type: "UPDATE_SUB_USER_URLS",
    payload: urlData,
  };
};

export const getSubUserNotification = (notification) => {
  return {
    type: "GET_SUB_USER_NOTIFICATION",
    payload: notification,
  };
};

export const updateSubUserPaymentCards = (cards) => {
  return {
    type: "UPDATE_SUB_USER_PAYMENT_CARDS",
    payload: cards,
  };
};

//? ACTION FUNCTIONS ///////////////////////////////////////////////////////////////////

export const fetchSubUserData = (sessionName) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/auth/getSubUser`,
      { sessionName: sessionName },
      { withCredentials: true }
    );

    // Dispatch the action to update user data
    dispatch(updateSubUser(res.data.data));
    return res.data;
  } catch (error) {
    const { sessions } = getState();
    dispatch(invalidateSession(sessions.currentSession));
    return;
  }
};

export const fetchNotarySubUserForms = () => async (dispatch, getState) => {
  try {
    const { sessions } = getState();
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/data/update-notary-user-forms`,
      { currentSession: sessions?.currentSession },
      { withCredentials: true }
    );

    // Dispatch the action to update user data
    dispatch(updateNotarySubUserForms(res.data.data));

    return res.data;
  } catch (error) {}
};

export const updateSigneeSubUserEmailAction =
  (source, id, newEmail) => async (dispatch, getState) => {
    const { subUserSession } = getState();

    // Check if user exists in the state and if not, return the current state
    if (!subUserSession?.user) return subUserSession;

    // Update the list within notary_forms based on the source
    const updatedList = subUserSession?.user?.notary_forms[source].map((item) =>
      item.id === id
        ? { ...item, email: newEmail, signee_reg_email: newEmail }
        : item
    );

    dispatch({
      type: "UPDATE_SUB_USER_SIGNEE_EMAIL",
      payload: { source, updatedList },
    });
  };

export const addURLtoCreatedFormSubUser = () => async (dispatch, getState) => {
  const { subUserSession } = getState();
  // Check if user, nocUserForms, and urls exist in the state, if not, return the current state.
  if (
    !subUserSession?.user ||
    !subUserSession?.user?.nocUserForms ||
    !subUserSession?.urls
  )
    return subUserSession;

  // Create a copy of nocUserForms to update
  let updatedNocUserForms = [...subUserSession?.user?.nocUserForms];

  // Loop through each url in the urls array
  subUserSession?.urls?.forEach((url) => {
    // Extract noc_id and signed from the url object, assuming these properties exist
    const { noc_id, signed } = url;

    // Find the index of the form in nocUserForms that matches the noc_id
    const formIndex = updatedNocUserForms.findIndex(
      (form) => form.id === noc_id
    );

    // If a matching form is found, update its signed property
    if (formIndex !== -1) {
      updatedNocUserForms[formIndex] = {
        ...updatedNocUserForms[formIndex],
        signed,
      };
    }
  });

  dispatch({
    type: "ADD_URL_TO_CREATED_FORM_SUB_USER",
    payload: updatedNocUserForms,
  });
};

export const addSubUserFullDesignProfessionals = (data) => {
  return {
    type: "ADD_SUB_USER_FULL_DESIGN_PROFESSIONALS",
    payload: data,
  };
};

export const updateSubUserBuildingPermit = (data) => {
  return {
    type: "UPDATE_NOC_FORMS_SUB_USER",
    payload: data,
  };
};

export const updateNocFormsSubUser =
  (sessionName) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/update-urls`,
        { sessionName: sessionName },
        { withCredentials: true }
      );

      // Dispatch the action to update user data
      dispatch({
        type: "UPDATE_NOC_FORMS_SUB_USER",
        payload: res?.data?.data,
      });
      return res?.data?.data;
    } catch (error) {
      console.error(error);
    }
  };

// export const sendSubUserWebhookData =
//   (payload) => async (dispatch, getState) => {
//     try {
//       await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/nocApi_webhook/process-recording-status`,
//         payload,
//         { withCredentials: true }
//       );
//     } catch (error) {
//       // Handle the error
//     }
//   };

export const addSubUserURL = (urlData) => {
  return {
    type: "ADD_SUB_USER_URL",
    payload: urlData,
  };
};

//! ##########################################################################################
