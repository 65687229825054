import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  DialogActions,
  Button,
} from "@mui/material";

const primary = {
  0: ["VIP Pass:", "* First 30 days only", "** First 30 Days only"],
  1: ["VIP Pass:", "Advantages:"],
  2: ["VIP Pass:", "Advantages:"],
  3: ["VIP Pass:", "Advantages:"],
};
const secondary = {
  0: [
    "Includes permit forms creations, electronic signatures for all parties, Notarization for all parties, NOC recording and permit submission where possible",
    "$9.95/each afterward - Creation only - Notarization and recording priced separately",
    "$39.95/each afterward. Creation only - Notarization and recording priced separately",
  ],
  1: [
    "Includes permit forms creations, electronic signatures for all parties, Notarization for all parties, NOC recording and permit submission where possible",
    "More flexibility with the ability to generate unlimited permit packages for up to 3 counties of your choice and to manage up to 2 contractor licenses (building, roofing, solar, etc.). In addition, with this plan, you can enable other users in your company to join.",
  ],
  2: [
    "Includes permit forms creations, electronic signatures for all parties, Notarization for all parties, NOC recording and permit submission where possible",
    "Ideal if your area of operations encompasses more than three counties or if you do business with more than 2 licenses or if your team is larger than 3 people. More power for a very low monthly fee.",
  ],
  3: [
    "Includes permit forms creations, electronic signatures for all parties, Notarization for all parties, NOC recording and permit submission where possible",
    "Access to all the counties in the State of Florida. Ideal for contractors with operations across the state and large distributed teams.",
  ],
};

const PopupInfo = ({ open, setOpen, currentIndex, cardTitle }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle
        color="primary"
        sx={{ fontSize: "30px", pb: 0 }}
        align="center"
      >
        {cardTitle === "Pay As You Go" ? "Starter" : cardTitle}
      </DialogTitle>
      <Typography sx={{ fontWeight: "bold" }} align="center">
        Plan Information
      </Typography>
      <DialogContent>
        <List component="nav">
          {secondary?.[currentIndex]?.map((secondaryInfo, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemText
                  primary={primary?.[currentIndex]?.[index]}
                  secondary={secondaryInfo}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupInfo;
