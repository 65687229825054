import {
  TextField,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Checkbox,
  FormHelperText,
  IconButton,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useRef, useState } from "react";
import { cityScraperList } from "../../data/cityScraperList";
import { KeyboardArrowUp } from "@mui/icons-material";

const TextFields = ({
  formCreated,
  nocStates,
  setNocStates,
  formik,
  county,
  city,
  prefill,
  type,
  inputs,
  propertyOwner,
  mobileLoading,
  isCurrentCountyNotFound,
  mobile,
  creatingDoc,
  selectedRow,
  initials,
  fieldRefs,
}) => {
  const [optionalOpen, setOptionalOpen] = useState(false);
  const [optionalClosing, setOptionalClosing] = useState(false);

  const handleToggle = () => {
    if (optionalOpen) {
      // Start the "closing" animation
      setOptionalClosing(true);
      setTimeout(() => {
        setOptionalOpen(false);
        // Remove closing class once animation completes
      }, 100); // Set timeout to match animation duration
    } else {
      setOptionalOpen(true); // Open the fields
    }
  };

  useEffect(() => {
    if (!optionalOpen && optionalClosing) {
      setTimeout(() => {
        setOptionalClosing(false); // Remove closing class
      }, 300);
    }
  }, [optionalOpen]);

  useEffect(() => {
    const getInitials = () => {
      const initialData = initials.find((initial) => initial.county === county);

      if (initialData) {
        const initialValues = initialData.values[0];

        const filteredInitialValues = Object.keys(initialValues).reduce(
          (acc, key) => {
            if (initialValues[key] !== "" && initialValues[key] !== undefined) {
              acc[key] = initialValues[key];
            }
            return acc;
          },
          {}
        );

        // if (prefill) {
        const prefillValues = Object.keys(initialValues).reduce((acc, key) => {
          // Only update formik values if they are currently undefined or empty
          if (
            inputs.hasOwnProperty(key) &&
            (formik.values[key] === undefined || formik.values[key] === "")
          ) {
            acc[key] = inputs[key];
          }
          return acc;
        }, {});

        // Update Formik values with prefill values
        formik.setValues({
          ...formik.values,
          ...filteredInitialValues,
          ...prefillValues,
        });
      }
    };

    if (initials) {
      getInitials();
    }
  }, [selectedRow]);

  const formatMoneyValue = (value) => {
    if (value === "") {
      return "";
    }
    // Remove any non-numeric characters except the decimal point
    value = value.replace(/[^\d.]/g, "");

    // Split the input into integer and decimal parts
    let [integer, decimal] = value.split(".");

    // Limit the integer part to 10 digits
    integer = integer ? integer.slice(0, 10) : "";

    // Format the integer part with commas
    let formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Handle cases for decimal parts
    if (decimal === undefined) {
      // If there's no decimal part, ensure it ends with ".00"
      formattedInteger += ".00";
    } else if (decimal.length === 0) {
      // If the decimal part is empty, assume ".00"
      formattedInteger += ".00";
    } else if (decimal.length === 1) {
      // If there's only one decimal digit, add a trailing "0"
      formattedInteger += `.${decimal}0`;
    } else {
      // Otherwise, limit the decimal part to two digits
      formattedInteger += `.${decimal.slice(0, 2)}`;
    }

    // Add the dollar sign
    return `${formattedInteger}`;
  };

  const handleBlur = (event, formik, name) => {
    if (name === "amountOfBond") {
      let { value } = event.target;
      const formattedValue = formatMoneyValue(value);
      formik.setFieldValue(event.target.name, formattedValue);
    } else {
      formik.handleBlur(event);
    }
  };

  const handlePermitChange = (name, value) => {
    // Remove non-alphanumeric characters and convert to uppercase
    if (value.length >= 15) {
      return;
    }
    const alphanumericOnly = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

    let formattedPermitNumber = "";

    // Add a hyphen after the 3rd character and a period after the 9th character
    if (alphanumericOnly.length <= 3) {
      formattedPermitNumber = alphanumericOnly; // First 3 characters
    } else if (alphanumericOnly.length <= 9) {
      formattedPermitNumber =
        alphanumericOnly.substring(0, 3) + "-" + alphanumericOnly.substring(3); // e.g., B24-123456
    } else {
      formattedPermitNumber =
        alphanumericOnly.substring(0, 3) +
        "-" +
        alphanumericOnly.substring(3, 9) +
        "." +
        alphanumericOnly.substring(9); // e.g., B24-123456.000
    }

    formik.setFieldValue(name, formattedPermitNumber); // Update formik field value
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the formik values using setFieldValue

    if (
      name === "permitNumber" &&
      county === "Duval" &&
      city === "jacksonville"
    ) {
      handlePermitChange(name, value);
    } else if (name === "permitNumber") {
      formik.setFieldValue(name, value.toUpperCase());
    } else {
      formik.setFieldValue(name, value);
    }
    // No need to update dynamicValues here
  };

  return (
    <>
      {type === "create-noc" && initials && (
        <>
          {!formCreated && (
            <Box sx={{ py: 1, pl: 1 }}>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ mb: 0, pb: 0 }}
              >
                Address of Property Being Improved {!mobile && "(Mandatory)"}
              </Typography>
              <Typography
                fontWeight="bold"
                color="primary"
                variant="body2"
                sx={{
                  m: 0,
                  p: 0,
                  pb: mobile ? 1 : undefined,
                  lineHeight: "10px",
                  marginTop: "0px !important",
                  marginBottom: "0px !important",
                }}
              >
                (Can be partial or full)
              </Typography>
            </Box>
          )}

          {!formCreated ? (
            <>
              <TextField
                ref={fieldRefs.current["streetNumber"]}
                disabled={nocStates.createNocLoading}
                error={
                  Boolean(formik.touched.streetNumber) &&
                  Boolean(formik.errors.streetNumber)
                }
                fullWidth
                helperText={
                  Boolean(formik.touched.streetNumber) &&
                  formik.errors.streetNumber
                }
                label="Street Number*"
                name="streetNumber"
                onBlur={formik.handleBlur}
                onChange={handleInputChange}
                value={formik.values.streetNumber || ""}
              />

              {county === "Duval" && (
                <Grid container gap={1}>
                  <Grid item lg={8} md={12}>
                    <TextField
                      ref={fieldRefs.current["streetName"]}
                      disabled={nocStates.createNocLoading}
                      error={
                        Boolean(formik.touched.streetName) &&
                        Boolean(formik.errors.streetName)
                      }
                      fullWidth
                      helperText={
                        Boolean(formik.touched.streetName) &&
                        formik.errors.streetName
                      }
                      label="Street Name*"
                      name="streetName"
                      onBlur={formik.handleBlur}
                      onChange={handleInputChange}
                      value={formik.values.streetName || ""}
                    />
                    {!formik.errors.streetName && (
                      <FormHelperText
                        sx={{ fontWeight: "bold", color: "#175982", ml: 1 }}
                      >
                        Do not include street type
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item lg={3.8} md={12}>
                    <TextField
                      ref={fieldRefs.current["streetType"]}
                      disabled={nocStates.createNocLoading}
                      error={
                        Boolean(formik.touched.streetType) &&
                        Boolean(formik.errors.streetType)
                      }
                      fullWidth
                      helperText={
                        Boolean(formik.touched.streetType) &&
                        formik.errors.streetType
                      }
                      label="Street Type"
                      name="streetType"
                      onBlur={formik.handleBlur}
                      onChange={handleInputChange}
                      value={formik.values.streetType || ""}
                    />
                  </Grid>
                </Grid>
              )}

              {county !== "Duval" && (
                <TextField
                  ref={fieldRefs.current["streetName"]}
                  disabled={nocStates.createNocLoading}
                  error={
                    Boolean(formik.touched.streetName) &&
                    Boolean(formik.errors.streetName)
                  }
                  fullWidth
                  helperText={
                    Boolean(formik.touched.streetName) &&
                    formik.errors.streetName
                  }
                  label="Street Name*"
                  name="streetName"
                  onBlur={formik.handleBlur}
                  onChange={handleInputChange}
                  value={formik.values.streetName || ""}
                />
              )}

              <TextField
                disabled={nocStates.createNocLoading}
                fullWidth
                label="Unit Number"
                name="unitNumber"
                onBlur={formik.handleBlur}
                onChange={handleInputChange}
                value={formik.values.unitNumber || ""}
              />

              <TextField
                disabled={nocStates.createNocLoading}
                error={
                  Boolean(formik.touched.zip) && Boolean(formik.errors.zip)
                }
                fullWidth
                helperText={Boolean(formik.touched.zip) && formik.errors.zip}
                label="ZIP Code"
                name="zip"
                onBlur={formik.handleBlur}
                onChange={handleInputChange}
                value={formik.values.zip || ""}
              />

              {
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ flex: 1 }}>
                    <TextField
                      disabled={
                        nocStates.createNocLoading ||
                        formik?.values?.permitCheck === true
                      }
                      error={
                        Boolean(formik.touched.permitNumber) &&
                        Boolean(formik.errors.permitNumber)
                      }
                      fullWidth
                      helperText={
                        Boolean(formik.touched.permitNumber) &&
                        formik.errors.permitNumber
                      }
                      label="Permit Number"
                      name="permitNumber"
                      onBlur={formik.handleBlur}
                      onChange={handleInputChange}
                      value={formik.values.permitNumber || ""}
                    />
                  </Box>

                  <Box
                    sx={{
                      flex: 1,
                      alignSelf: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {cityScraperList.includes(city) ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="permitCheck"
                              checked={formik.values.permitCheck}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          }
                          label={
                            <Typography
                              fontWeight="bold"
                              variant="body2"
                              color="primary"
                            >
                              Generate Building Permit
                            </Typography>
                          }
                        />
                      ) : (
                        <Typography
                          px={1}
                          align="center"
                          fontWeight="bold"
                          variant="body2"
                          color="primary"
                        >
                          Automatic permit generation is not yet available for
                          this city.
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              }
            </>
          ) : (
            <TextField
              disabled
              fullWidth
              label="Property Address"
              value={formik?.values?.address}
              name="address"
              onChange={formik.handleChange}
            />
          )}
          {!formCreated && (
            <>
              <Box sx={{ pb: 1 }}></Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{ width: "75%", borderBottom: "4px solid #f3f3f3" }}
                ></Box>
              </Box>
            </>
          )}
          <Box sx={{ py: 1, pl: 1 }}>
            {formCreated && (
              <>
                <Typography
                  variant={formCreated ? "h6" : "body1"}
                  fontWeight="bold"
                  sx={{ mb: 0, pb: 0 }}
                >
                  The following fields are available for updating:
                </Typography>
                <Typography
                  fontWeight="bold"
                  color="primary"
                  variant="body2"
                  sx={{
                    m: 0,
                    p: 0,
                    lineHeight: "10px",
                    marginTop: "0px !important",
                    marginBottom: "0px !important",
                  }}
                >
                  (Click update after adding new information into NOC)
                </Typography>
              </>
            )}
          </Box>
        </>
      )}

      {(formCreated || type === "edit") && initials && (
        <TextField
          disabled={isCurrentCountyNotFound || nocStates.createNocLoading}
          fullWidth
          label="Owner's Name"
          name="owner"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik?.values?.owner}
        />
      )}

      {formCreated && (
        <>
          <TextField
            disabled={nocStates.createNocLoading}
            error={
              Boolean(formik.touched.permitNumber) &&
              Boolean(formik.errors.permitNumber)
            }
            fullWidth
            helperText={
              Boolean(formik.touched.permitNumber) && formik.errors.permitNumber
            }
            label="Permit Number"
            name="permitNumber"
            onBlur={formik.handleBlur}
            onChange={handleInputChange}
            value={formik.values.permitNumber || ""}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",

              pt: 2,
            }}
          >
            <Box sx={{ width: "75%", borderBottom: "4px solid #f3f3f3" }}></Box>
          </Box>
        </>
      )}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant="h5"
          color="primary"
          fontWeight="bold"
          sx={{ mb: 0, pb: 0 }}
        >
          Optional Fields
          <IconButton
            sx={{ ml: 1 }}
            onClick={handleToggle}
            variant="contained"
            color="primary"
          >
            {!optionalOpen ? (
              <KeyboardArrowDownIcon sx={{ fontSize: "30px" }} />
            ) : (
              <KeyboardArrowUp sx={{ fontSize: "30px" }} />
            )}
          </IconButton>
        </Typography>
      </Box>

      {(optionalOpen || optionalClosing) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
          className={optionalOpen ? "open" : optionalClosing ? "closing" : ""}
        >
          <Box className="field field-1">
            <TextField
              disabled={
                nocStates.createNocLoading ||
                mobileLoading ||
                isCurrentCountyNotFound
              }
              fullWidth
              label="General Description of Improvements"
              name="general"
              onChange={formik.handleChange}
              value={formik?.values?.general}
            />
          </Box>

          <Box className="field field-2">
            <TextField
              disabled={
                nocStates.createNocLoading ||
                mobileLoading ||
                isCurrentCountyNotFound
              }
              fullWidth
              label="Owner's Interest in Site of the Improvement"
              name="interest"
              onChange={formik.handleChange}
              value={formik?.values?.interest}
            />
          </Box>

          {initials &&
            initials?.map((initial) =>
              initial.county === county
                ? initial.fields.map(
                    (field, i) =>
                      field.name !== "permitNumber" && (
                        <Box key={i} className={`field field-${i + 2}`}>
                          {field.name === "surety" && county === "Collier" ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <FormControl
                                component="fieldset"
                                disabled={
                                  mobileLoading || nocStates.createNocLoading
                                }
                                sx={{ flex: 1 }}
                              >
                                <FormLabel component="legend">
                                  Surety?
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-label="option"
                                  name="row-radio-buttons-group"
                                  value={nocStates.isSuretyRadio}
                                  onChange={(e) => {
                                    setNocStates({
                                      ...nocStates,
                                      isSuretyRadio: e.target.value,
                                    });

                                    const fieldsToReset = [
                                      "surety",
                                      "suretyAddress",
                                      "suretyPhone",
                                      "amountOfBond",
                                    ];
                                    fieldsToReset.forEach((field) =>
                                      formik.setFieldValue(field, "")
                                    );
                                  }}
                                >
                                  <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          ) : (
                            <TextField
                              ref={fieldRefs.current[field.name]}
                              disabled={
                                formik?.values?.permitCheck === true &&
                                field.name === "permitNumber"
                                  ? true
                                  : nocStates.createNocLoading ||
                                    (county === "Collier" &&
                                      nocStates.isSuretyRadio === "no" &&
                                      [
                                        "surety",
                                        "suretyAddress",
                                        "suretyPhone",
                                        "amountOfBond",
                                      ].includes(field.name)) ||
                                    mobileLoading ||
                                    isCurrentCountyNotFound
                              }
                              fullWidth
                              label={field.label}
                              name={field.name}
                              onBlur={(e) => handleBlur(e, formik, field.name)}
                              onChange={handleInputChange}
                              value={formik.values[field.name] || ""}
                              error={
                                field.name === "permitNumber" &&
                                Boolean(
                                  formik.touched[field.name] &&
                                    formik.errors[field.name]
                                )
                              }
                              helperText={
                                formik.touched[field.name] &&
                                formik.errors[field.name]
                              }
                            />
                          )}
                        </Box>
                      )
                  )
                : null
            )}
        </Box>
      )}

      {formik?.errors?.submit && (
        <Typography color="error" sx={{ mt: 3 }} variant="body2">
          {formik?.errors?.submit}
        </Typography>
      )}
    </>
  );
};

export default TextFields;
