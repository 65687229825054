import React from "react";
import { Grid } from "@mui/material";
import ResetPasswordComponent from "../auth/ResetPasswordComponent";

const ResetPassword = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ResetPasswordComponent />
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
