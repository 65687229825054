import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";

import axios from "axios";
import { DateTime } from "luxon";
import {
  Paper,
  Typography,
  Box,
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Container,
  CardContent,
  CircularProgress,
  Chip,
  Button,
  CardHeader,
  Tooltip,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AssignMeConfirm from "./modals/AssignMeConfirm";
import ScheduledModal from "./modals/ScheduledModal";
import CompletedModal from "./modals/CompletedModal";
import { LoadingButton } from "@mui/lab";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";

const NotaryJobManagement = () => {
  const navigate = useNavigate();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const notaryJobs = useSelector((state) => state.notarySession.notaryJobs);
  const currentUser = useSelector((state) => state.notarySession.user);
  const [loading, setLoading] = useState(true);
  const [scheduledModalOpen, setScheduledModalOpen] = useState(false);
  const [completedModalOpen, setCompletedModalOpen] = useState(false);
  const [selectedScheduledJob, setSelectedScheduledJob] = useState(null);
  const [selectedCompletedJob, setSelectedCompletedJob] = useState(null);
  const [jobTakenText, setJobTakenText] = useState("");
  const [confirmScheduledLoading, setConfirmScheduledLoading] = useState(false);
  const [completedJobs, setCompletedJobs] = useState([]);
  const [failedJobs, setFailedJobs] = useState([]);
  const [myCurrentJobs, setMyCurrentJobs] = useState([]);
  const [selectedButton, setSelectedButton] = useState("reminder");
  const [cancelErrorText, setCancelErrorText] = useState("");
  const [rescheduleErrorText, setRescheduleErrorText] = useState("");
  const [cancelLoading, setCancelLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [downloadingRowId, setDownloadingRowId] = useState(null);

  let count = 0;

  const handleScheduledOpenModal = (job) => {
    setSelectedScheduledJob(job);
    setScheduledModalOpen(true);
  };

  const handleCompletedOpenModal = (job) => {
    setSelectedCompletedJob(job);
    setCompletedModalOpen(true);
  };

  const handleCompletedCloseModal = () => {
    setCompletedModalOpen(false);
  };

  const handleScheduledCloseModal = () => {
    if (confirmScheduledLoading === false) {
      setScheduledModalOpen(false);
    } else {
      return;
    }
  };

  const handleDownloadNoc = async (selectedRow) => {
    try {
      setDownloadingRowId(selectedRow.id);
      const pdf_file = selectedRow?.document?.pdf_url;

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/download-customer-notary-file`,
        { pdf: pdf_file, currentSession: currentSession },
        {
          withCredentials: true,
          responseType: "blob", // Important: This tells Axios to expect a binary response
        }
      );
      // Create a URL for the blob
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      // Create an anchor (`<a>`) element
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      // Set the download attribute to the desired file name
      fileLink.setAttribute(
        "download",
        selectedRow?.document?.property_address + ".pdf"
      ); // Customize the file name if necessary
      // Append to the document and trigger the download
      document.body.appendChild(fileLink);
      fileLink.click();
      // Clean up
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL); // Release the object URL
      // setDownloadingFile(true);
      setDownloadingRowId(null);
    } catch (e) {
      setDownloadingRowId(null);
    }
  };

  const parseJobDate = (dateStr) => {
    // Remove ordinal suffixes like 'st', 'nd', 'rd', 'th' from the day
    const cleanedDateStr = dateStr?.replace(/(\d+)(st|nd|rd|th)/, "$1");
    return new Date(cleanedDateStr);
  };
  const sortCurrentJobsByDate = (jobs) => {
    return jobs.slice().sort((a, b) => {
      const dateA = parseJobDate(a.date);
      const dateB = parseJobDate(b.date);
      return dateA - dateB;
    });
  };
  const sortFailedJobsByDate = (jobs) => {
    return jobs.slice().sort((a, b) => {
      const dateA = parseJobDate(a.failed_at);
      const dateB = parseJobDate(b.failed_at);
      return dateB - dateA;
    });
  };

  const sortFinishedJobsByDate = (jobs) => {
    return jobs.slice().sort((a, b) => {
      const dateA = parseJobDate(a.notarized_at);
      const dateB = parseJobDate(b.notarized_at);
      return dateB - dateA;
    });
  };
  useEffect(() => {
    const getCompletedJobs = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/my_completed_jobs`,
          { currentSession: currentSession },

          { withCredentials: true }
        );
        if (response.data.success === true) {
          setCompletedJobs(
            sortFinishedJobsByDate(response.data?.data?.completed)
          );
          setFailedJobs(sortFailedJobsByDate(response.data?.data?.failed));
        }
      } catch (e) {
        if (e.response?.status === 401) {
          navigate("/login");
        }
      }
      setLoading(false);
    };

    if (!currentUser || !notaryJobs) {
      return;
    }
    if (!currentUser?.role === "notary") {
      navigate("/login");
    }

    if (
      currentUser?.on_site_registered === false ||
      currentUser?.bn_registered === false ||
      currentUser?.availabilities.length === 0
    ) {
      navigate("/notary/finalize");
      return;
    }

    if (count < 1) {
      count += 1;
      getCompletedJobs();
    }
  }, [currentUser, notaryJobs]);

  const notaryJobsColumn = [
    {
      field: "booked_date",
      headerName: "Date and Time of Request",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        // Parse the UTC datetime string using Luxon
        const utcDate = DateTime.fromISO(params.value, { zone: "utc" });
        // Convert to US timezone (example: America/New_York)
        const usDate = utcDate.setZone(currentUser?.timezone);
        // Format the date in the desired format
        const formattedDate = usDate.toFormat("MMMM dd, yyyy hh:mm a");
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "signer",
      headerName: "Signer",
      flex: 0.5,
      editable: false,
    },
    {
      field: "project_address",
      headerName: "Project Address",
      flex: 1,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        // Parse the booked_date in UTC using Luxon
        const bookedDateUtc = DateTime.fromISO(params.row.booked_date, {
          zone: "utc",
        });

        const nowUTC = DateTime.utc();

        const timeDifference = bookedDateUtc - nowUTC;

        // Check if the difference is less than or equal to 5 minutes (300,000 milliseconds)
        if (timeDifference <= 300000) {
          return <Typography variant="body2">RON Started</Typography>;
        } else {
          return <Typography variant="body2">Waiting for RON Start</Typography>;
        }
      },
    },
    // {
    //   field: "pick",
    //   headerName: "",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         sx={{ py: "5px" }}
    //         onClick={() => {
    //           handleScheduledOpenModal(params.row);
    //           setJobTakenText("");
    //         }}
    //       >
    //         Actions
    //       </Button>
    //     );
    //   },
    // },
  ];

  const notaryCompletedJobsColumn = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      renderCell: (params) => {
        // Extract last 5 characters from the ID field
        const lastFiveChars = params.value.slice(-6);
        return <span>{lastFiveChars}</span>;
      },
    },
    {
      field: "notarized_at",
      headerName: "Date and Time of Completion",
      flex: 2,
      editable: false,
      renderCell: (params) => {
        // Parse the UTC datetime string using Luxon
        const utcDate = DateTime.fromISO(params.value, { zone: "utc" });
        // Convert to US timezone (example: America/New_York)
        const usDate = utcDate.setZone(currentUser?.timezone);
        // Format the date in the desired format
        const formattedDate = usDate.toFormat("MMMM dd, yyyy hh:mm a");
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "county",
      headerName: "County",
      flex: 1,
      editable: false,
    },
    {
      field: "completed_status",
      headerName: "Status",
      flex: 1,
      editable: false,
    },
    {
      field: "pick",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            sx={{ py: "5px" }}
            onClick={() => {
              handleCompletedOpenModal(params.row);
              setJobTakenText("");
            }}
          >
            Details
          </Button>
        );
      },
    },
    {
      field: "download",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        const isLoading = downloadingRowId === params.row.id;
        if (isLoading === true) {
          return (
            <div>
              <CachedIcon
                className="notary-rotate"
                sx={{ color: "#004976", ml: 1 }}
                width={25}
              />
            </div>
          );
        } else {
          return (
            <div>
              <Tooltip title="Download" arrow>
                <IconButton
                  onClick={() => handleDownloadNoc(params?.row)}
                  variant="contained"
                  color="primary"
                >
                  <DocumentArrowDownIcon width={25} />
                </IconButton>
              </Tooltip>
            </div>
          );
        }
      },
    },
  ];

  const notaryFailedJobsColumn = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      renderCell: (params) => {
        // Extract last 5 characters from the ID field
        const lastFiveChars = params.value.slice(-6);
        return <span>{lastFiveChars}</span>;
      },
    },
    {
      field: "failed_at",
      headerName: "Date and Time of Completion",
      flex: 2,
      editable: false,
      renderCell: (params) => {
        // Parse the UTC datetime string using Luxon
        const utcDate = DateTime.fromISO(params.value, { zone: "utc" });
        // Convert to US timezone (example: America/New_York)
        const usDate = utcDate.setZone(currentUser?.timezone);
        // Format the date in the desired format
        const formattedDate = usDate.toFormat("MMMM dd, yyyy hh:mm a");
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "county",
      headerName: "County",
      flex: 1,
      editable: false,
    },
    {
      field: "completed_status",
      headerName: "Status",
      flex: 1,
      editable: false,
    },
    {
      field: "pick",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            sx={{ py: "5px" }}
            onClick={() => {
              handleCompletedOpenModal(params.row);
              setJobTakenText("");
            }}
          >
            Details
          </Button>
        );
      },
    },
    {
      field: "download",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        const isLoading = downloadingRowId === params.row.id;
        if (isLoading === true) {
          return (
            <div>
              <CachedIcon
                className="notary-rotate"
                sx={{ color: "#004976", ml: 1 }}
                width={25}
              />
            </div>
          );
        } else {
          return (
            <div>
              <Tooltip title="Download" arrow>
                <IconButton
                  onClick={() => handleDownloadNoc(params?.row)}
                  variant="contained"
                  color="primary"
                >
                  <DocumentArrowDownIcon width={25} />
                </IconButton>
              </Tooltip>
            </div>
          );
        }
      },
    },
  ];
  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Container maxWidth="xxl">
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Card>
                  <CardContent>
                    <Typography variant="h4" align="center">
                      Scheduled RON Appointments
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sx={{ padding: 0 }}>
                <Card
                  sx={{
                    backgroundColor: "#FCF2F0",
                  }}
                >
                  <CardContent sx={{ padding: 0 }}>
                    <DataGrid
                      sx={{
                        "& .MuiDataGrid-columnHeaders": {
                          padding: 0, // Adjust as needed
                          margin: 0, // Adjust as needed
                        },
                        "& .MuiDataGrid-columnHeadersInner": {
                          width: "100%", // Adjust as needed
                        },
                        border: "1px solid #FCF2F0", // Change the border color of the entire DataGrid
                        "& .MuiDataGrid-columnHeader": {
                          backgroundColor: "#333333", // Change column header background color
                          color: "white", // Optional: Change text color for contrast
                        },
                        "& .MuiDataGrid-cell": {
                          borderColor: "#333333", // Change cell border color
                        },
                        "& .MuiDataGrid-columnSeparator": {
                          display: "none", // Optional: Hide the column separators
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                          outline: "none !important",
                        },
                        "& .MuiDataGrid-columnHeader:focus-within": {
                          outline: "none !important",
                        },
                      }}
                      rows={currentUser?.my_current_jobs || []}
                      columns={notaryJobsColumn}
                      getRowId={(row) => row?.id}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 25, 50, 100]}
                      disableRowSelectionOnClick
                    />
                  </CardContent>
                </Card>
              </Grid>
              {/* ################## */}
              {/* COMPLETED */}
              {/* <Grid item xs={12} sx={{ mt: 3 }}>
                <Card>
                  <CardContent>
                    <Typography variant="h4" align="center">
                      RON Sessions: Completed
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sx={{ padding: 0 }}>
                <Card
                  sx={{
                    backgroundColor: "#FCF2F0",
                  }}
                >
                  <CardContent sx={{ padding: 0 }}>
                    <DataGrid
                      sx={{
                        "& .MuiDataGrid-columnHeaders": {
                          padding: 0, // Adjust as needed
                          margin: 0, // Adjust as needed
                        },
                        "& .MuiDataGrid-columnHeadersInner": {
                          width: "100%", // Adjust as needed
                        },
                        border: "1px solid #FCF2F0", // Change the border color of the entire DataGrid
                        "& .MuiDataGrid-columnHeader": {
                          backgroundColor: "#333333", // Change column header background color
                          color: "white", // Optional: Change text color for contrast
                        },
                        "& .MuiDataGrid-cell": {
                          borderColor: "#333333", // Change cell border color
                        },
                        "& .MuiDataGrid-columnSeparator": {
                          display: "none", // Optional: Hide the column separators
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                          outline: "none !important",
                        },
                        "& .MuiDataGrid-columnHeader:focus-within": {
                          outline: "none !important",
                        },
                      }}
                      rows={completedJobs}
                      columns={notaryCompletedJobsColumn}
                      getRowId={(row) => row?.id}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 25, 50, 100]}
                      disableRowSelectionOnClick
                    />
                  </CardContent>
                </Card>
              </Grid> */}
              {/* ################## */}
              {/* FAILED */}
              {/* <Grid item xs={12} sx={{ mt: 3 }}>
                <Card>
                  <CardContent>
                    <Typography variant="h4" color="error" align="center">
                      RON Sessions: Failed
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sx={{ padding: 0 }}>
                <Card
                  sx={{
                    backgroundColor: "#FCF2F0",
                  }}
                >
                  <CardContent sx={{ padding: 0 }}>
                    <DataGrid
                      sx={{
                        "& .MuiDataGrid-columnHeaders": {
                          padding: 0, // Adjust as needed
                          margin: 0, // Adjust as needed
                        },
                        "& .MuiDataGrid-columnHeadersInner": {
                          width: "100%", // Adjust as needed
                        },
                        border: "1px solid #FCF2F0", // Change the border color of the entire DataGrid
                        "& .MuiDataGrid-columnHeader": {
                          backgroundColor: "#FFCCCC", // Change column header background color
                          color: "black", // Optional: Change text color for contrast
                        },
                        "& .MuiDataGrid-cell": {
                          borderColor: "#FCF2F0", // Change cell border color
                        },
                        "& .MuiDataGrid-columnSeparator": {
                          display: "none", // Optional: Hide the column separators
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                          outline: "none !important",
                        },
                        "& .MuiDataGrid-columnHeader:focus-within": {
                          outline: "none !important",
                        },
                      }}
                      rows={failedJobs}
                      columns={notaryFailedJobsColumn}
                      getRowId={(row) => row?.id}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 25, 50, 100]}
                      disableRowSelectionOnClick
                    />
                  </CardContent>
                </Card>
              </Grid> */}
            </Grid>
            <ScheduledModal
              open={scheduledModalOpen}
              cancelErrorText={cancelErrorText}
              setCancelErrorText={setCancelErrorText}
              rescheduleErrorText={rescheduleErrorText}
              setRescheduleErrorText={setRescheduleErrorText}
              setScheduledModalOpen={setScheduledModalOpen}
              onClose={handleScheduledCloseModal}
              // onConfirm={handleConfirmJob}
              selectedJob={selectedScheduledJob}
              setSelectedScheduledJob={setSelectedScheduledJob}
              jobTakenText={jobTakenText}
              confirmLoading={confirmScheduledLoading}
              selectedButton={selectedButton}
              setSelectedButton={setSelectedButton}
              cancelLoading={cancelLoading}
              setCancelLoading={setCancelLoading}
              emailLoading={emailLoading}
              setEmailLoading={setEmailLoading}
            />
            <CompletedModal
              currentUser={currentUser}
              open={completedModalOpen}
              cancelErrorText={cancelErrorText}
              setCancelErrorText={setCancelErrorText}
              rescheduleErrorText={rescheduleErrorText}
              setRescheduleErrorText={setRescheduleErrorText}
              setCompletedModalOpen={setCompletedModalOpen}
              onClose={handleCompletedCloseModal}
              // onConfirm={handleConfirmJob}
              selectedJob={selectedCompletedJob}
              setSelectedScheduledJob={setSelectedScheduledJob}
              jobTakenText={jobTakenText}
              confirmLoading={confirmScheduledLoading}
              selectedButton={selectedButton}
              setSelectedButton={setSelectedButton}
              cancelLoading={cancelLoading}
              setCancelLoading={setCancelLoading}
            />
          </Container>
        </>
      )}
    </>
  );
};

export default NotaryJobManagement;
