import React, { useState } from "react";
import { Dialog, useMediaQuery } from "@mui/material";
import { DateTime } from "luxon";
import Pop1 from "./Pop1";
import Pop2 from "./Pop2";
import axios from "axios";
import Vip from "./Vip";
import PpPaymentPop from "./PpPaymentPop";
import NocPaymentPop from "./NocPaymentPop";

function PromoModal(props) {
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [paymentState, setPaymentState] = useState(false);
  const [animationState, setAnimationState] = useState("popupGrow");
  const [textAnimationState, setTextAnimationState] = useState("popupGrow");
  const [downloading, setDownloading] = useState(false);

  const handleTransition = (popup) => {
    if (popup === "paymentState") {
      setTextAnimationState("popupShrink");
      setTimeout(() => {
        setPaymentState(true);
        setTextAnimationState("popupGrow");
      }, 300);
    } else {
      // Start the shrinking animation
      setAnimationState("popupShrink");

      // After the shrinking animation ends (0.3s), switch the popup and grow
      setTimeout(() => {
        props?.setCurrentPopup(popup);
        setAnimationState("popupGrow");
      }, 300); // Time here matches the duration of the shrink animation
    }
  };

  const handleCancel = () => {};
  const handleConfirm = () => {};

  return (
    <div>
      <Dialog
        open={props?.open}
        fullWidth
        maxWidth={tabletSize ? false : undefined}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          className: animationState, // Apply the animation class dynamically
          style: tabletSize
            ? {
                width: "100%", // Set Paper width to 100%
                maxWidth: "100%", // Ensure it does not exceed 100%
                height: "auto", // Optional: in case you also want full height
                margin: 0, // Remove margins to ensure it fits to the edge
              }
            : {},
        }}
      >
        {/* First, ask notarization */}
        {props?.currentPopup === "pop1" ? (
          <Pop1
            handleCancel={handleCancel}
            handleTransition={handleTransition}
            handleConfirm={handleConfirm}
            setCurrentPopup={props?.setCurrentPopup}
            textAnimationState={textAnimationState}
            paymentState={paymentState}
            setPaymentModalOpen={props?.setPaymentModalOpen}
          />
        ) : props?.currentPopup === "pop2" ? (
          //? Second, reject notarization, ask for permit package
          <Pop2
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
            setCurrentPopup={props?.setCurrentPopup}
            selectedRow={props?.selectedRow}
            downloading={downloading}
          />
        ) : props?.currentPopup === "vip" ? (
          <Vip
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
            setCurrentPopup={props?.setCurrentPopup}
            selectedRow={props?.selectedRow}
            downloading={downloading}
            currentUser={props?.currentUser}
            setPaymentModalOpen={props?.setPaymentModalOpen}
            setOpen={props?.setOpen}
            handleTransition={handleTransition}
          />
        ) : props?.currentPopup === "nocPaymentPop" ? (
          <NocPaymentPop
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
            setCurrentPopup={props?.setCurrentPopup}
            selectedRow={props?.selectedRow}
            downloading={downloading}
            currentUser={props?.currentUser}
            setPaymentModalOpen={props?.setPaymentModalOpen}
            setOpen={props?.setOpen}
            handleTransition={handleTransition}
            setPermitDrawerOpen={props?.setPermitDrawerOpen}
          />
        ) : props?.currentPopup === "ppPaymentPop" ? (
          <PpPaymentPop
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
            setCurrentPopup={props?.setCurrentPopup}
            selectedRow={props?.selectedRow}
            downloading={downloading}
            currentUser={props?.currentUser}
            setPaymentModalOpen={props?.setPaymentModalOpen}
            setOpen={props?.setOpen}
            handleTransition={handleTransition}
            setPermitDrawerOpen={props?.setPermitDrawerOpen}
          />
        ) : null}
      </Dialog>
    </div>
  );
}

export default PromoModal;
