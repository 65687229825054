import { Box, Container, Unstable_Grid2 as Grid } from "@mui/material";
import CustomersGrid from "../../admin/CustomersGrid";

const Customers = () => {
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1,
          backgroundColor: "#EEF2F6",
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12} md={12} lg={12}>
              <CustomersGrid
                // countyData={countyData}
                payments={false}
                title="Customers"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Customers;
