import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Magnifier from "../customComponents/Magnifier";
import SelectPermitPackageForms from "../buildingComponents.js/SelectPermitPackageForms";
import { useDispatch, useSelector } from "react-redux";
import {
  addAvailablePermitForms,
  offReadyToFetch,
  setNewTempPermitData,
  setShowTourNav,
} from "../../store/actions/dataActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LoadingButton } from "@mui/lab";
import { setExpiredSession } from "../../store/actions/sessionsActions";
import { disconnectWebsocket } from "../../store/actions/websocketActions";
import { tourSteps } from "../../data/walkthroughDemo/steps";
import { useTour } from "@reactour/tour";

const StartPermit = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const leftGridRef = useRef(null);
  const [selectedCity, setSelectedCity] = useState("");
  const [availableForms, setAvailableForms] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedCountyForms, setSelectedCountyForms] = useState([]);
  const [stormwaterSelection, setStormwaterSelection] = useState(null);
  const [privateProviderSignerType, setPrivateProviderSignerType] =
    useState(null);

  const [countyLoading, setCountyLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const [showSelectedCity, setShowSelectedCity] = useState("");
  const [formDisplay, setFormDisplay] = useState(false);
  const [page, setPage] = useState(0);
  const [contractorIndex, setContractorIndex] = useState(0);
  const [startPermitLoading, setStartPermitLoading] = useState(false);
  const [ppName, setPpName] = useState("");
  const [readyFetchHelper, setReadyFetchHelper] = useState(true);

  const { isOpen, currentStep, setCurrentStep } = useTour();
  const dispatch = useDispatch();

  const currentSession = useSelector((state) => state.sessions.currentSession);
  // const isReadyToFetch = useSelector((state) => state.nocData?.isReadyToFetch);

  const up1177 = useMediaQuery("(min-width:1177px)");

  useEffect(() => {
    if (isOpen) {
      const num = currentSession === "userSession" ? 30 : 20;
      if (currentStep === num && page === 1) {
        handleStartPermitPackage();
      }
    }
  }, [currentStep]);

  useEffect(() => {
    if (isOpen) {
      if (currentSession === "userSession") {
        if (currentStep === 11 && page === 0) {
          setPage(1);
        } else if (currentStep === 10 && page === 1) {
          setPage(0);
        }
      } else if (currentSession === "subUserSession") {
        if (currentStep === 9 && page === 0) {
          setPage(1);
        } else if (currentStep === 8 && page === 1) {
          setPage(0);
        }
      }
    }
  }, [currentStep]);

  useEffect(() => {
    if (isOpen) {
      const num = currentSession === "userSession" ? 9 : 7;
      if (!countyLoading && currentStep === num) {
        setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
      }
    }
  }, [countyLoading]);

  //! //////////////////////////////////////////////////////////////

  useEffect(() => {
    if (props?.fromPermit && Object.keys(props.fromPermit).length > 0) {
      const tempCity =
        props.fromPermit.selectedCity?.toLowerCase().replace(/\s+/g, "_") || "";
      setSelectedCity(tempCity);
      setAvailableForms(props?.fromPermit?.availableForms);
      setSelectedForms(props?.fromPermit?.selectedForms);
      setCities(props?.fromPermit?.cities);
      setPage(1);
      setCountyLoading(false);
    }
  }, [props?.fromPermit]);

  const handleStartPermitPackage = async () => {
    try {
      setStartPermitLoading(true);

      // Check if selectedForms contains "stormwater" and replace it with your string
      const updatedSelectedForms = selectedForms.map((form) =>
        form === "stormwater" ? stormwaterSelection : form
      );

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/start-create-permit-package`,
        {
          county: props?.county,
          city: selectedCity,
          selectedForms: updatedSelectedForms,
          selectedCountyForms: selectedCountyForms,
          contractor: props?.currentUser?.user?.contractors?.[contractorIndex],
          currentSession: currentSession,
          ppName: ppName,
          privateProviderSignerType: privateProviderSignerType,
        },
        { withCredentials: true }
      );

      if (resp?.data?.success) {
        await dispatch(setNewTempPermitData(resp?.data?.data));

        navigate("/permits/my-permits");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setStartPermitLoading(false);
      // dispatch(offReadyToFetch());
    }
  };

  const handleCloseStartPermit = () => {
    if (isOpen) {
      return;
    }
    setPpName("");
    setPage(0);
    props?.setStartPermitOpen(false);
    props?.setDisplayImage([]);
    setSelectedCountyForms([]);
    setSelectedForms([]);
    if (props?.fromPermit?.fromPermit) {
      navigate(location.pathname, { replace: true, state: {} });
    }
  };

  useEffect(() => {
    if (leftGridRef.current) {
      leftGridRef.current.scrollTo(0, 0);
    }
  }, [props?.displayImage]);

  useEffect(() => {
    if (!props?.fromPermit || Object.keys(props.fromPermit).length === 0) {
      setAvailableForms([]);
      setSelectedCity("");
      setFormDisplay(false);
    }
  }, [props.countyImg, props?.startPermitOpen]);

  useEffect(() => {
    const loadPermitForms = async () => {
      try {
        setCountyLoading(true);
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/get-county-permit`,
          { currentSession: currentSession, county: props?.county },

          {
            withCredentials: true,
          }
        );
        await dispatch(addAvailablePermitForms(resp?.data?.forms));
        props?.setReadyLoad(false);
      } catch (e) {
        if (e.response?.status === 401) {
          await dispatch(disconnectWebsocket(currentSession));
          sessionStorage.removeItem("__nocCreator__r_rid");
          sessionStorage.removeItem("exp");
          setTimeout(() => {
            dispatch(setExpiredSession(true));
            navigate("/login");
          }, 100);
          navigate("/login");
        }
      } finally {
        setCountyLoading(false);
      }
    };

    if (props?.readyLoad) {
      loadPermitForms();
    }
  }, [props?.readyLoad]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "100vw",
        },
      }}
      anchor="right"
      open={props?.startPermitOpen}
      onClose={handleCloseStartPermit}
    >
      <Grid
        container
        sx={{
          display: "flex",
          overflow: "hidden",
          width: "100%",
        }}
      >
        {/* LEFT SIDE */}
        <Grid
          item
          md={!up1177 ? 6 : 6}
          ref={leftGridRef}
          sx={{
            overflow: "auto",
            position: "relative",
            mb: 4,
          }}
        >
          <Box
            sx={{
              mx: "auto",
              width: "100%",
              maxWidth: "1000px",
              height: "100vh",
            }}
          >
            {page === 0 ? (
              props?.displayImage ? (
                props?.displayImage?.map((item, index) => (
                  <Magnifier
                    key={index}
                    src={item}
                    lensSize={400}
                    zoomFactor={4}
                  />
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <CircularProgress color="warning" size="5rem" />
                </Box>
              )
            ) : (
              <Box
                sx={{
                  mt: 10,
                  // display: "flex",
                  mx: "auto",
                  height: "100vh",
                  // justifyContent: "center",
                  alignItems: "center",
                  // flexDirection: "column",
                }}
              >
                <Typography
                  mt={1}
                  variant="h3"
                  color="primary"
                  fontWeight="bold"
                  align="center"
                >
                  Forms to be Created
                </Typography>

                <Box
                  sx={{
                    overflow: "auto",
                    width: "100%",
                    pb: "80px",
                  }}
                >
                  <Box
                    sx={{
                      mt: 5,
                      width: "100%", // Adjust the max height as needed
                      px: 2, // Add padding on left and right
                      boxSizing: "border-box",
                    }}
                  >
                    {availableForms?.filter((form) =>
                      selectedForms?.includes(form.name)
                    )?.length > 0 ? (
                      <Grid
                        container
                        spacing={2}
                        sx={{ height: "100%" }}
                        mt={2}
                        mb={2}
                      >
                        {availableForms
                          ?.filter((form) => selectedForms?.includes(form.name))
                          ?.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                              <Card
                                sx={{
                                  border: "3px solid #d3d3d3",
                                  boxShadow:
                                    "6px 12px 16px 3px rgba(0, 0, 0, 0.5)",
                                  p: 1,
                                  minHeight: "14rem",
                                  // backgroundColor: "rgba(56, 142, 60, 0.3)",
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  height="140"
                                  image={`${process.env.REACT_APP_URL}${item?.cover_img}`}
                                  alt={item.label}
                                />
                                <CardContent
                                  className="bottomCard"
                                  sx={{ px: 0, pt: 1 }}
                                >
                                  <Typography
                                    align="center"
                                    variant="body2"
                                    color="primary"
                                    fontWeight="bold"
                                    component="div"
                                  >
                                    {item.name === "stormwater" &&
                                    stormwaterSelection
                                      ? item?.forms?.find(
                                          (storm) =>
                                            storm?.name === stormwaterSelection
                                        )?.label
                                      : item.label}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                      </Grid>
                    ) : (
                      selectedForms?.length === 0 && (
                        <Typography
                          align="center"
                          my={3}
                          variant="h6"
                          sx={{ color: "red" }}
                        >
                          There are no documents selected
                        </Typography>
                      )
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>

        {/* RIGHT SIDE */}
        <Grid
          // className={
          //   currentSession === "userSession"
          //     ? currentStep === 10
          //       ? "step11"
          //       : currentStep === 11
          //       ? "step12"
          //       : ""
          //     : currentSession === "subUserSession"
          //     ? currentStep === 8
          //       ? "step11"
          //       : currentStep === 9
          //       ? "step12"
          //       : ""
          //     : ""
          // }
          item
          md={!up1177 ? 6 : 6}
          sx={{
            position: "relative",

            // mb: 1,
            // mt: 2,
            // height: "95vh",
          }}
        >
          {/* HEADER */}

          {/* TEXTFIELD/CARD */}
          <Box
            className={
              currentSession === "userSession"
                ? currentStep === 10
                  ? "step11"
                  : currentStep === 11
                  ? "step12"
                  : ""
                : currentSession === "subUserSession"
                ? currentStep === 8
                  ? "step11"
                  : currentStep === 9
                  ? "step12"
                  : ""
                : ""
            }
            sx={{
              position: "relative",
              mx: "auto",
              overflow: "hidden",
              borderBottom:
                props?.currentUser?.user?.contractors?.length === 0
                  ? undefined
                  : "4px solid #d3d3d3",
              maxWidth: "800px",
            }}
          >
            <IconButton
              disabled={startPermitLoading}
              onClick={handleCloseStartPermit}
              sx={{ position: "absolute", right: 10, top: 10 }}
            >
              <CloseIcon />
            </IconButton>
            <SelectPermitPackageForms
              availableForms={availableForms}
              setAvailableForms={setAvailableForms}
              county={props?.county}
              selectedForms={selectedForms}
              setSelectedForms={setSelectedForms}
              selectedCountyForms={selectedCountyForms}
              setSelectedCountyForms={setSelectedCountyForms}
              displayImage={props?.displayImage}
              setDisplayImage={props?.setDisplayImage}
              selectedCity={selectedCity}
              fromPermit={props?.fromPermit?.fromPermit}
              setSelectedCity={setSelectedCity}
              showSelectedCity={showSelectedCity}
              setFormDisplay={setFormDisplay}
              availableCitiesNum={props?.availableCitiesNum}
              page={page}
              currentUser={props?.currentUser}
              contractorIndex={contractorIndex}
              setContractorIndex={setContractorIndex}
              cities={cities}
              setCities={setCities}
              countyLoading={countyLoading}
              ppName={ppName}
              setPpName={setPpName}
              stormwaterSelection={stormwaterSelection}
              setStormwaterSelection={setStormwaterSelection}
              privateProviderSignerType={privateProviderSignerType}
              setPrivateProviderSignerType={setPrivateProviderSignerType}
              currentSession={currentSession}
            />
          </Box>
          {!isOpen && (
            <Toolbar
              sx={{
                px: 0,
                pb: 1,
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                opacity: 1,
              }}
            >
              <Grid
                container
                // sx={{ display: "flex", gap: 2, justifyContent: "center" }}
              >
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{ display: "flex", gap: 2, justifyContent: "center" }}
                  >
                    <Button
                      size="large"
                      onClick={() => {
                        if (isOpen) {
                          return;
                        }
                        setPage(page - 1);
                      }}
                      disabled={page === 0 || startPermitLoading}
                      sx={{ minWidth: "125px" }}
                      variant="contained"
                      startIcon={<ArrowBackIcon />}
                    >
                      Back
                    </Button>
                    <LoadingButton
                      loading={startPermitLoading}
                      size="large"
                      color={
                        page === 1 &&
                        props?.currentUser?.user?.contractors.length > 0
                          ? "success"
                          : "primary"
                      }
                      disabled={
                        props?.currentUser?.user?.contractors.length === 0 &&
                        page == 1
                      }
                      onClick={() => {
                        if (
                          page === 1 &&
                          props?.currentUser?.user?.contractors.length > 0
                        ) {
                          handleStartPermitPackage();
                        } else {
                          setPage(page + 1);
                        }

                        if (isOpen && page === 0) {
                          setTimeout(() => {
                            setCurrentStep((s) =>
                              s === tourSteps?.length - 1 ? 0 : s + 1
                            );
                          }, 200);
                        }
                      }}
                      sx={{ minWidth: "125px" }}
                      variant="contained"
                      endIcon={
                        page === 1 &&
                        props?.currentUser?.user?.contractors.length >
                          0 ? undefined : (
                          <ArrowForwardIcon />
                        )
                      }
                    >
                      {page === 1 &&
                      props?.currentUser?.user?.contractors.length > 0
                        ? "Start Creating Permit Package"
                        : "Continue"}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default StartPermit;
