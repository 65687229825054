import React from "react";
import { Grid, useMediaQuery, useTheme, Box } from "@mui/material";
import HomeComponent from "../auth/HomeComponent";
import LandingLayout from "../layout/LandingLayout";

const Home = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <HomeComponent />
      </Grid>
      <Grid item xs={12} lg={6}>
        {isXs ? (
          <>
            <Box
              sx={{
                position: "fixed",
                top: 340,
                right: 0,
                bottom: 0,
                left: 0,
                overflow: "auto",
              }}
            >
              <LandingLayout />
            </Box>
          </>
        ) : (
          <LandingLayout />
        )}
      </Grid>
    </Grid>
  );
};

export default Home;
