import ComputerDesktopIcon from "@heroicons/react/24/solid/ComputerDesktopIcon";
import DeviceTabletIcon from "@heroicons/react/24/solid/DeviceTabletIcon";
import PhoneIcon from "@heroicons/react/24/solid/PhoneIcon";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import ApexChart from "react-apexcharts";
import { styled } from "@mui/material/styles";
import SigneeRegisterInfo from "../popover/SigneeRegisterInfo";
const Chart = styled(ApexChart)``;

const useChartOptions = (labels, isUnlimitedTier, role, admin) => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
    },
    colors: [theme.palette.primary.main, theme.palette.error.main],
    dataLabels: {
      enabled: false,
    },
    labels,
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      y: {
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          if (
            isUnlimitedTier ||
            role === "admin" ||
            role === "tester" ||
            admin === true
          ) {
            // Adjust this condition as needed
            return "Unlimited";
          }
          return value;
        },
      },
      fillSeriesColor: false,
    },
  };
};

const iconMap = {
  Desktop: (
    <SvgIcon>
      <ComputerDesktopIcon />
    </SvgIcon>
  ),
  Tablet: (
    <SvgIcon>
      <DeviceTabletIcon />
    </SvgIcon>
  ),
  Phone: (
    <SvgIcon>
      <PhoneIcon />
    </SvgIcon>
  ),
};

const AvailableNoc = (props) => {
  const { chartSeries, labels } = props;
  const chartOptions = useChartOptions(
    labels,
    props?.tier === "unlimited",
    props?.role,
    props?.admin
  );
  const title =
    "This pie chart illustrates the distribution of available NOCs within the current 30-day period. Any NOCs created during this period will impact the available count for all users in the organization.";
  return (
    <Card sx={{ position: "relative" }}>
      {props?.tabletSize ? (
        <IconButton
          onClick={() => props?.setAvailableTitle(!props?.availableTitle)}
          sx={{ position: "absolute", right: 0, top: 0 }}
        >
          <HelpOutlineIcon color="primary" />
        </IconButton>
      ) : (
        <Tooltip title={title} placement="top">
          <HelpOutlineIcon
            color="primary"
            sx={{ position: "absolute", right: 10, top: 10 }}
          />
        </Tooltip>
      )}

      {props?.availableTitle && (
        <SigneeRegisterInfo
          infoText={title}
          isFadingOut={props?.isFadingOut}
          top={35}
        />
      )}

      <CardHeader
        title={
          <>
            <Typography variant="h6">Remaining Free</Typography>
            <Typography variant="h5" sx={{ mt: 0, pt: 0 }}>
              Notice of Commencement Forms
            </Typography>
          </>
        }
        sx={{ textAlign: "center" }} // Optional: Center the text
      />
      <CardContent
        sx={{
          px: props?.tabletSize ? 1.5 : props?.between1200and1405 ? 1 : 0,
          pt: props?.mobileSize ? 1.5 : props?.tabletSize ? 3 : undefined,
          pb: props?.tabletSize ? 1.5 : undefined,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2}
        >
          {chartSeries.map((item, index) => {
            const label = labels[index];

            return (
              <Box
                key={label}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  pb: 2,
                }}
              >
                {iconMap[label]}
                <Typography
                  sx={
                    label === "Available"
                      ? { my: 1, color: "#004976" }
                      : { my: 1, color: "#F04438" }
                  }
                  variant="subtitle1"
                >
                  {label}
                </Typography>
                <Typography
                  sx={
                    label === "Available"
                      ? { color: "#004976" }
                      : { color: "#F04438" }
                  }
                  variant="h6"
                >
                  {label === "Available" &&
                  (props.tier === "unlimited" ||
                    props.role === "admin" ||
                    props.role === "tester" ||
                    props.admin === true)
                    ? "Unlimited"
                    : item}
                </Typography>
              </Box>
            );
          })}
        </Stack>
        <Chart
          height={props?.mobileSize ? 200 : props?.tabletSize ? 250 : 300}
          options={chartOptions}
          series={chartSeries}
          type="donut"
          width="100%"
        />
      </CardContent>
    </Card>
  );
};

export default AvailableNoc;
