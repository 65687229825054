import { Typography, Box, Badge } from "@mui/material";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { green, red } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export const packageColumns = {
  0: [
    {
      field: "package_name",
      headerName: "Package Name",
      flex: 0.8,
      renderCell: (params) => {
        const { value, row } = params;
        const isNocCreated = row.noc_created;

        return (
          <>
            {!isNocCreated && (
              <Badge
                badgeContent="New"
                color="success"
                sx={{ marginRight: 1, marginLeft: 2 }}
              />
            )}
            {value ? (
              <Typography variant="body2" sx={{ ml: !isNocCreated ? 2 : 0 }}>
                {value}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ ml: !isNocCreated ? 2 : 0 }}>
                N/A
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "project_address",
      headerName: "Project Address",
      flex: 1.8,
      renderCell: (params) => {
        return params.value ? (
          <Typography variant="body2">{params.value}</Typography>
        ) : (
          <Typography variant="body2">N/A</Typography>
        );
      },
    },
    {
      field: "county",
      headerName: "County",
      flex: 0.5,
      editable: false,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        // Get the original value from params
        const value = params.row.city;

        // If the value exists, transform it
        if (value) {
          // Replace underscores with spaces, then capitalize first letters
          return value
            .replace(/_/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        }
        return value;
      },
    },
    {
      field: "noc_created",
      headerName: "NOC Created",
      headerAlign: "center",
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value ? (
              <CheckCircleIcon style={{ color: green[500] }} />
            ) : (
              <CancelIcon style={{ color: red[500] }} />
            )}
          </Box>
        );
      },
    },
    {
      field: "permit_package_created",
      headerName: "Permit Package Created",
      headerAlign: "center",
      flex: 0.7,
      editable: false,
      renderCell: (params) => {
        const hasMultipleDocuments = params.row?.selected_documents?.length > 1;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value && hasMultipleDocuments ? (
              <CheckCircleIcon style={{ color: green[500] }} />
            ) : (
              <CancelIcon style={{ color: red[500] }} />
            )}
          </Box>
        );
      },
    },
    {
      field: "notarized",
      headerName: "Notarized",
      headerAlign: "center",
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value ? (
              <CheckCircleIcon style={{ color: green[500] }} />
            ) : (
              <CancelIcon style={{ color: red[500] }} />
            )}
          </Box>
        );
      },
    },

    {
      field: "recorded",
      headerName: "Recorded",
      headerAlign: "center",
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value ? (
              <CheckCircleIcon style={{ color: green[500] }} />
            ) : (
              <CancelIcon style={{ color: red[500] }} />
            )}
          </Box>
        );
      },
    },
    {
      field: "permit_package_added_at",
      headerName: "Initiated",
      flex: 0.6,
      editable: false,
      renderCell: (params) => {
        const timestamp = params.value * 1000; // Convert seconds to milliseconds
        const timezone = "America/New_York"; // Florida timezone
        const zonedDate = utcToZonedTime(new Date(timestamp), timezone);
        const formattedDate = format(zonedDate, "MM/dd/yyyy");
        return formattedDate;
      },
    },
  ],
  1: [
    {
      field: "package_name",
      headerName: "Package Name",
      flex: 0.8,
      renderCell: (params) => {
        const { value, row } = params;
        const isNocCreated = row.noc_created;

        return (
          <>
            {!isNocCreated && (
              <Badge
                badgeContent="New"
                color="success"
                sx={{ marginRight: 1, marginLeft: 2 }}
              />
            )}
            {value ? (
              <Typography variant="body2" sx={{ ml: !isNocCreated ? 2 : 0 }}>
                {value}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ ml: !isNocCreated ? 2 : 0 }}>
                N/A
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "project_address",
      headerName: "Project Address",
      flex: 1.8,
      renderCell: (params) => {
        return params.value ? (
          <Typography variant="body2">{params.value}</Typography>
        ) : (
          <Typography variant="body2">N/A</Typography>
        );
      },
    },
    {
      field: "county",
      headerName: "County",
      flex: 0.5,
      editable: false,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        // Get the original value from params
        const value = params.row.city;

        // If the value exists, transform it
        if (value) {
          // Replace underscores with spaces, then capitalize first letters
          return value
            .replace(/_/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        }
        return value;
      },
    },
    {
      field: "assigned_to",
      headerName: "Assigned To",
      flex: 2,
      editable: false,
      valueGetter: (params) => {
        // Get the original value from params
        const value = params.row?.assigned_package?.user_email;

        return value;
      },
    },
    // {
    //   field: "notarized",
    //   headerName: "Notarized",
    //   headerAlign: "center",
    //   flex: 0.5,
    //   editable: false,
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         sx={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           width: "100%",
    //           height: "100%",
    //         }}
    //       >
    //         {params.value ? (
    //           <CheckCircleIcon style={{ color: green[500] }} />
    //         ) : (
    //           <CancelIcon style={{ color: red[500] }} />
    //         )}
    //       </Box>
    //     );
    //   },
    // },

    // {
    //   field: "recorded",
    //   headerName: "Recorded",
    //   headerAlign: "center",
    //   flex: 0.5,
    //   editable: false,
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         sx={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           width: "100%",
    //           height: "100%",
    //         }}
    //       >
    //         {params.value ? (
    //           <CheckCircleIcon style={{ color: green[500] }} />
    //         ) : (
    //           <CancelIcon style={{ color: red[500] }} />
    //         )}
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "permit_package_added_at",
      headerName: "Package Sent",
      flex: 0.6,
      editable: false,
      renderCell: (params) => {
        if (!params?.row?.assigned_package?.assigned_at) {
          return "N/A";
        } else {
          const timestamp = params?.row?.assigned_package?.assigned_at * 1000; // Convert seconds to milliseconds
          const timezone = "America/New_York"; // Florida timezone
          const zonedDate = utcToZonedTime(new Date(timestamp), timezone);
          const formattedDate = format(zonedDate, "MM/dd/yyyy");
          return formattedDate;
        }
      },
    },
  ],
  2: [
    {
      field: "package_name",
      headerName: "Package Name",
      flex: 0.8,
      renderCell: (params) => {
        const { value, row } = params;
        const isNocCreated = row.noc_created;

        return (
          <>
            {!isNocCreated && (
              <Badge
                badgeContent="New"
                color="success"
                sx={{ marginRight: 1, marginLeft: 2 }}
              />
            )}
            {value ? (
              <Typography variant="body2" sx={{ ml: !isNocCreated ? 2 : 0 }}>
                {value}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ ml: !isNocCreated ? 2 : 0 }}>
                N/A
              </Typography>
            )}
          </>
        );
      },
    },

    {
      field: "county",
      headerName: "County",
      flex: 0.5,
      editable: false,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        // Get the original value from params
        const value = params.row.city;

        // If the value exists, transform it
        if (value) {
          // Replace underscores with spaces, then capitalize first letters
          return value
            .replace(/_/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        }
        return value;
      },
    },
    {
      field: "noc_created",
      headerName: "NOC Created",
      headerAlign: "center",
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value ? (
              <CheckCircleIcon style={{ color: green[500] }} />
            ) : (
              <CancelIcon style={{ color: red[500] }} />
            )}
          </Box>
        );
      },
    },

    {
      field: "recorded",
      headerName: "Recorded",
      headerAlign: "center",
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value ? (
              <CheckCircleIcon style={{ color: green[500] }} />
            ) : (
              <CancelIcon style={{ color: red[500] }} />
            )}
          </Box>
        );
      },
    },
  ],
};

export const notarizationRequestsColumn = [
  {
    field: "package_name",
    headerName: "Package Name",
    flex: 0.8,
    renderCell: (params) => {
      const { value, row } = params;
      const isNocCreated = row.noc_created;

      return (
        <>
          {!isNocCreated && (
            <Badge
              badgeContent="New"
              color="success"
              sx={{ marginRight: 1, marginLeft: 2 }}
            />
          )}
          {value ? (
            <Typography variant="body2" sx={{ ml: !isNocCreated ? 2 : 0 }}>
              {value}
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ ml: !isNocCreated ? 2 : 0 }}>
              N/A
            </Typography>
          )}
        </>
      );
    },
  },
  {
    field: "project_address",
    headerName: "Project Address",
    flex: 1.8,
    renderCell: (params) => {
      return params.value ? (
        <Typography variant="body2">{params.value}</Typography>
      ) : (
        <Typography variant="body2">N/A</Typography>
      );
    },
  },
  {
    field: "county",
    headerName: "County",
    flex: 0.5,
    editable: false,
  },
  {
    field: "city",
    headerName: "City",
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      // Get the original value from params
      const value = params.row.city;

      // If the value exists, transform it
      if (value) {
        // Replace underscores with spaces, then capitalize first letters
        return value
          .replace(/_/g, " ")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      }
      return value;
    },
  },
  {
    field: "requested_by",
    headerName: "Initiated By",
    flex: 1.5,
    editable: false,
    valueGetter: (params) => {
      // Get the original value from params
      const value = params.row.assigned_package?.initiated?.email;

      return value;
    },
  },
  {
    field: "notarized",
    headerName: "Notarized",
    headerAlign: "center",
    flex: 0.5,
    editable: false,
    renderCell: (params) => {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {params.value ? (
            <CheckCircleIcon style={{ color: green[500] }} />
          ) : (
            <CancelIcon style={{ color: red[500] }} />
          )}
        </Box>
      );
    },
  },

  {
    field: "recorded",
    headerName: "Recorded",
    headerAlign: "center",
    flex: 0.5,
    editable: false,
    renderCell: (params) => {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {params.value ? (
            <CheckCircleIcon style={{ color: green[500] }} />
          ) : (
            <CancelIcon style={{ color: red[500] }} />
          )}
        </Box>
      );
    },
  },
  {
    field: "requested_at",
    headerName: "Requested",
    flex: 0.6,
    editable: false,
    valueGetter: (params) => {
      const assignedAt = params.row?.assigned_package?.assigned_at;
      const timestamp = assignedAt * 1000; // Convert seconds to milliseconds
      const timezone = "America/New_York"; // Florida timezone
      const zonedDate = utcToZonedTime(new Date(timestamp), timezone);
      const formattedDate = format(zonedDate, "MM/dd/yyyy");
      return formattedDate;
    },
  },
];
