import { useState } from "react";
import {
  Card,
  Box,
  CardContent,
  Stack,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import { HelpOutline, Person, People, Business } from "@mui/icons-material";
import SigneeRegisterInfo from "../popover/SigneeRegisterInfo";

const TotalNoc = ({
  currentUser,
  tabletSize,
  mobileSize,
  miniSize,
  between905and1125,
  between1200and1405,
  isFadingOut,
  totalNocTitle,
  setTotalNocTitle,
  fromSub,
  pp,
}) => {
  // const currentSession = useSelector(
  //     (state) => state.sessions.currentSession
  //   );

  const [nocCreatedBy, setNocCreatedBy] = useState("user"); // subuser, company
  const [value, setValue] = useState(
    fromSub && pp
      ? currentUser?.ppCreated
      : fromSub && !pp
      ? currentUser?.nocCreated
      : currentUser?.subuser
      ? currentUser?.subuser?.ppCreated
      : currentUser?.user?.analytics?.ppCreated
  );
  const [headerText, setHeaderText] = useState(
    fromSub && !pp
      ? "Total Notice of Commencement this user created"
      : fromSub && pp
      ? "Total Permit Packages this user created"
      : "Total Permit Packages you created"
  );
  const [title, setTitle] = useState(
    fromSub && !pp
      ? "This represents the total number of Notice of Commencement this user personally created."
      : fromSub && pp
      ? "This represents the total number of Permit Packages this user personally created."
      : "This represents the total number of Permit Packages you have personally created."
  );
  return (
    <Card
      sx={{
        minHeight: tabletSize ? undefined : "12.7rem",
        position: "relative",
      }}
    >
      {tabletSize ? (
        <IconButton
          onClick={() => setTotalNocTitle(!totalNocTitle)}
          sx={{ position: "absolute", right: 0, top: 0 }}
        >
          <HelpOutline color="primary" />
        </IconButton>
      ) : (
        <Tooltip title={title} placement="top">
          <HelpOutline
            color="primary"
            sx={{ position: "absolute", right: 10, top: 10 }}
          />
        </Tooltip>
      )}

      {totalNocTitle && (
        <SigneeRegisterInfo
          infoText={title}
          isFadingOut={isFadingOut}
          top={35}
        />
      )}

      {!currentUser?.subuser &&
      // !currentUser?.tier?.monthly_restart &&
      !fromSub ? (
        <>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              position: "absolute",
              right: 10,
              bottom: 10,
            }}
          >
            <Box
              onClick={() => {
                setNocCreatedBy("user");
                setValue(
                  currentUser?.subuser
                    ? currentUser?.subuser?.ppCreated
                    : currentUser?.user?.analytics?.ppCreated
                );
                setHeaderText(
                  fromSub
                    ? "Total Permit Packages this user created"
                    : "Total Permit Packages you created"
                );
                setTitle(
                  fromSub
                    ? "This represents the total number of Permit Packages this user personally created."
                    : "This represents the total number of Permit Packages you have personally created."
                );
              }}
              sx={{
                cursor: nocCreatedBy === "user" ? "default" : "pointer",
                padding: 0.2,
                border: "1px solid #004976",
                borderRadius: "5px",
                backgroundColor: nocCreatedBy == "user" ? "#004976" : "#fff",
              }}
            >
              <Person
                sx={{
                  color: nocCreatedBy == "user" ? "#fff" : "#004976",
                  fontSize: "18px",
                }}
              />
            </Box>
            <Box
              onClick={() => {
                setNocCreatedBy("subuser");
                setValue(
                  currentUser?.user?.analytics?.total_pp_subuser_created
                );
                setHeaderText("Users Created Total Permit Packages");
                setTitle(
                  "This represents the total number of Permit Packages your users created."
                );
              }}
              sx={{
                cursor: nocCreatedBy === "subuser" ? "default" : "pointer",
                padding: 0.2,
                border: "1px solid #004976",
                borderRadius: "5px",
                backgroundColor: nocCreatedBy == "subuser" ? "#004976" : "#fff",
              }}
            >
              <People
                sx={{
                  color: nocCreatedBy == "subuser" ? "#fff" : "#004976",
                  fontSize: "18px",
                }}
              />
            </Box>
            <Box
              onClick={() => {
                setNocCreatedBy("company");
                setValue(
                  currentUser?.user?.analytics?.total_pp_subuser_created +
                    currentUser?.user?.analytics?.ppCreated
                );
                setHeaderText("Company Created Total Permit Packages");
                setTitle(
                  "This represents the total number of Permit Packages your company created."
                );
              }}
              sx={{
                cursor: nocCreatedBy === "company" ? "default" : "pointer",
                padding: 0.2,
                border: "1px solid #004976",
                borderRadius: "5px",
                backgroundColor: nocCreatedBy == "company" ? "#004976" : "#fff",
              }}
            >
              <Business
                sx={{
                  color: nocCreatedBy == "company" ? "#fff" : "#004976",
                  fontSize: "18px",
                }}
              />
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}

      <CardContent
        sx={{
          px: tabletSize ? 1.5 : between1200and1405 ? 1 : undefined,
          pt: mobileSize ? 1.5 : tabletSize ? 3 : undefined,
          pb: tabletSize ? 1.5 : undefined,
        }}
      >
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              color="text.secondary"
              variant={tabletSize ? "h6" : "overline"}
            >
              {headerText}
            </Typography>
            <Typography variant="h5">{value}</Typography>
          </Stack>
          {!fromSub ? (
            !between905and1125 &&
            !miniSize && (
              <Stack>
                <img
                  alt="noc-form"
                  src="/img/dashboard/nocformIcon.jpeg"
                  style={{
                    maxWidth: mobileSize
                      ? "6rem"
                      : between1200and1405
                      ? "8rem"
                      : "9rem",
                    height: "auto",
                    margin: 0,
                  }}
                />
              </Stack>
            )
          ) : (
            <Stack>
              <img
                alt="noc-form"
                src="/img/dashboard/nocformIcon.jpeg"
                style={{
                  maxWidth: mobileSize
                    ? "6rem"
                    : between1200and1405
                    ? "8rem"
                    : "9rem",
                  height: "auto",
                  margin: 0,
                }}
              />
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TotalNoc;
