import {
  Alert,
  Box,
  Button,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import RemovePermitPackage from "../../modals/RemovePermitPackage";
import { useEffect, useState } from "react";
import AddSinglePermitDocumentModal from "../../modals/AddSinglePermitDocumentModal";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import ChangePackageNameModal from "../../modals/ChangePackageNameModal";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTour } from "@reactour/tour";
import { useLocation } from "react-router-dom";
import { getPermitPackages } from "../../../store/actions/dataActions";

const PermitSettings = (props) => {
  const availablePermitForms = useSelector(
    (state) => state.nocData?.available_permit_forms
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const prevCityCounty = useSelector((state) => state.nocData.prevCityCounty);
  const row = props?.selectedRow;
  const { processing_permit } = row;
  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const lg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [removePermitOpen, setRemovePermitOpen] = useState(false);
  const [addDocumentModalOpen, setAddDocumentModalOpen] = useState(false);
  const [reminderLoading, setReminderLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const { isOpen } = useTour();

  const handleArchive = async () => {
    try {
      setArchiveLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/archive-package`,
        {
          task_id: props?.selectedRow?.task_id,
          currentSession: props?.currentSession,
        },
        { withCredentials: true }
      );
      if (resp.data.success === true) {
        const result = await dispatch(
          getPermitPackages(0, props?.pageSize, props?.activeTab)
        );
        props?.setSelectedRow(null);
        props?.setPage(0);
        props?.setRowCount(result);
      }
    } catch (error) {
    } finally {
      setArchiveLoading(false);
    }
  };

  const handleSendOwnerReminder = async () => {
    if (isOpen) {
      return;
    }
    try {
      setReminderLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/send-signee-register-reminder`,
        {
          task_id: props?.selectedRow?.task_id,
          currentSession: props?.currentSession,
        },
        { withCredentials: true }
      );
      if (resp.data.success === true) {
        setSnackOpen(true);
      }
    } catch (error) {
    } finally {
      setReminderLoading(false);
    }
  };

  const snackHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  useEffect(() => {
    if (props?.selectedRow) {
      props?.setEditObj((prev) => ({
        ...prev,
        nameError: "",
        nameRespText: "",
        packageName: props?.selectedRow?.package_name,
      }));
    }
  }, [props?.selectedRow]);

  const calculateEmailDisabled = () => {
    const contractorNotarization =
      processing_permit?.notarization?.contractor?.notarization;
    const ownerNotarization =
      processing_permit?.notarization?.owner?.notarization;

    if (
      ownerNotarization?.notary_failed === true ||
      contractorNotarization?.notary_failed === true
    ) {
      return true;
    }

    if (
      processing_permit?.notarization?.finalized === true ||
      !processing_permit?.notarization?.started
    ) {
      return true;
    }
    if (processing_permit?.notarization?.signing_order === "contractor") {
      if (contractorNotarization?.is_notarized === false) {
        return true;
      } else if (
        contractorNotarization?.is_notarized === true &&
        ownerNotarization?.waiting_to_schedule === false
      ) {
        return true;
      }
      return false;
    } else if (processing_permit?.notarization?.signing_order === "owner") {
      if (ownerNotarization?.is_notarized === true) {
        return true;
      } else if (ownerNotarization?.waiting_to_schedule === false) {
        return true;
      }
      return false;
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <img
          src="/img/website_img/settings.png"
          style={{
            width: "100%",
            maxWidth: "5rem",
            height: "auto",
            borderRadius: "20px",
          }}
        />
      </Box>
      <Box
        mt={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          px: 1,
          maxHeight: "15rem",
          overflow: "auto",
        }}
      >
        {location.pathname !== "/permits/invitations" && (
          <Button
            onClick={() => {
              if (isOpen) {
                return;
              }
              setAddDocumentModalOpen(true);
            }}
            disabled={processing_permit?.notarization?.started}
            startIcon={<AddIcon style={{ fontSize: "30px" }} />}
          >
            Add Document
          </Button>
        )}

        <Button
          onClick={() => {
            if (isOpen) {
              return;
            }
            props?.setEditPNameOpen(true);
          }}
          disabled={processing_permit?.notarization?.started}
          color="primary"
          startIcon={<EditIcon style={{ fontSize: "26px" }} />}
        >
          Edit Package Name
        </Button>
        <LoadingButton
          loading={reminderLoading}
          onClick={handleSendOwnerReminder}
          disabled={calculateEmailDisabled()}
          color="primary"
          startIcon={
            !reminderLoading ? (
              <EmailOutlinedIcon style={{ fontSize: "26px" }} />
            ) : undefined
          }
        >
          Send Reminder Email
        </LoadingButton>
        {props?.selectedRow?.notarized &&
        props?.selectedRow?.recorded &&
        !props?.selectedRow?.archived_package?.is_archived &&
        (props?.selectedRow?.permit_package_created ||
          (!props?.selectedRow?.permit_package_created &&
            props?.selectedRow?.selected_documents?.length === 1)) ? (
          <LoadingButton
            loading={archiveLoading}
            onClick={handleArchive}
            startIcon={<ArchiveIcon style={{ fontSize: "26px" }} />}
          >
            Archive Permit Package
          </LoadingButton>
        ) : (
          <Button
            disabled
            startIcon={<ArchiveIcon style={{ fontSize: "26px" }} />}
          >
            Archive Permit Package
          </Button>
        )}

        {location.pathname !== "/permits/invitations" && (
          <Button
            onClick={() => {
              if (isOpen) {
                return;
              }
              setRemovePermitOpen(true);
            }}
            disabled={
              processing_permit?.notarization?.started ||
              (props?.selectedRow?.free_addition?.noc === true &&
                props?.selectedRow?.noc_created) ||
              (props?.selectedRow?.free_addition?.pp === true &&
                props?.selectedRow?.noc_created) ||
              props?.selectedRow?.payments?.vip?.paid === true ||
              props?.selectedRow?.payments?.create_noc?.paid === true ||
              props?.selectedRow?.payments?.create_permit_package?.paid === true
            }
            color="error"
            startIcon={<DeleteIcon style={{ fontSize: "28px" }} />}
          >
            Remove Permit Package
          </Button>
        )}
      </Box>
      <RemovePermitPackage
        open={removePermitOpen}
        setOpen={setRemovePermitOpen}
        onConfirm={props?.handleCancelPermit}
        confirmLoading={props?.cancelLoading}
        selectedRow={props?.selectedRow}
        cancelPermitComment={props?.cancelPermitComment}
        setCancelPermitComment={props?.setCancelPermitComment}
      />
      <ChangePackageNameModal
        open={props?.editPNameOpen}
        setOpen={props?.setEditPNameOpen}
        onConfirm={props?.handleEditPackageName}
        confirmLoading={props?.cancelLoading}
        editObj={props?.editObj}
        setEditObj={props?.setEditObj}
      />
      <AddSinglePermitDocumentModal
        open={addDocumentModalOpen}
        setOpen={setAddDocumentModalOpen}
        // onConfirm={handleCancelPermit}
        // confirmLoading={props?.cancelLoading}
        selectedRow={props?.selectedRow}
        availablePermitForms={availablePermitForms}
        currentSession={props?.currentSession}
        prevCityCounty={prevCityCounty}
        setRightValue={props?.setRightValue}
      />
      <Snackbar
        sx={{ zIndex: 9999999 }}
        open={snackOpen}
        autoHideDuration={4000}
        onClose={snackHandleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={snackHandleClose}
          severity="success" // Changed severity to success
          sx={{
            width: "100%",
            marginTop: "80px",
            marginLeft: lg ? "280px" : "0px",
            backgroundColor: "#388e3c", // Green shade for success
            color: "#fff",
            fontWeight: "bold",
          }}
          iconMapping={{
            success: <CheckCircleIcon sx={{ color: "#fff" }} />, // Changed to success icon
          }}
        >
          The email reminder to the property owner has been sent successfully.
        </Alert>
      </Snackbar>
    </>
  );
};

export default PermitSettings;
