import { DateTime } from "luxon";
import { protectedPaths } from "../data/protectedRoutes";
import { ratio } from "fuzzball";

export const transformMemorableOption = (memorableOption) => {
  // Step 1: Convert the string to lowercase
  let optionLowercase = memorableOption.toLowerCase();

  // Step 2: Split the string into words to easily check the first word
  const words = optionLowercase.split(" ");

  // Check and modify the first word based on the specified conditions
  if (words[0] === "a") {
    words[0] = "your"; // Replace "a" with "your"
  } else if (words[0] === "favorite" || words[0] === "birthplace") {
    words.unshift("your"); // Add "your" to the beginning if the first word is "favorite" or "birthplace"
  }

  // Join the words back into a string
  optionLowercase = words.join(" ");

  // Step 3: Prepend "What is" to the beginning of the modified string
  return `What is ${optionLowercase}?`;
};

export const convertIsoToLocalFormat = (
  dateInput,
  timezone,
  format = "cccc, LLLL dd, yyyy hh:mm a"
) => {
  let dateTime;

  // Check if the input is a UNIX timestamp (numeric) and convert seconds to milliseconds
  if (typeof dateInput === "number") {
    dateTime = DateTime.fromMillis(dateInput * 1000, {
      zone: timezone,
    });
  } else {
    // Assume the input is an ISO string
    dateTime = DateTime.fromISO(dateInput, {
      zone: timezone,
    });
  }

  // Formatting the DateTime object
  const formattedDateTime = dateTime.toFormat(format);
  return formattedDateTime;
};

export const convertTimezoneFromObj = (
  dateObj,
  userTimezone,
  format = "MMMM dd'th' yyyy, h:mm a"
) => {
  // Create a UTC DateTime object from the raw date information

  const utcDate = DateTime.utc(
    dateObj?.year,
    dateObj?.month,
    dateObj?.day_of_month,
    parseInt(dateObj?.time?.split(":")[0]),
    parseInt(dateObj?.time?.split(":")[1])
  );

  // Convert UTC DateTime to the user's timezone using the `signee_timezone` field
  const localDate = utcDate.setZone(userTimezone || "America/New_York");

  // Format the localDate to the desired string format "April 22nd 2024, 10:30 PM"
  const formattedDate = localDate.toFormat(format);

  return formattedDate;
};

export const getSessionNameByPath = (currentPath, role) => {
  // Ensure role is an array, default to an empty array if null or undefined
  const roleArray = Array.isArray(role) ? role : [];

  for (let session in protectedPaths) {
    const paths = protectedPaths[session];
    for (let route of paths) {
      if (route.path === currentPath) {
        if (session === "userSession" || session === "subUserSession") {
          if (route.roles.some((r) => roleArray.includes(r))) {
            return {
              sessionName: session,
              sessionPath: route.path,
              requiredCookie: route.cookie,
              needSocket: route.socket,
            };
          }
        } else {
          return {
            sessionName: session,
            sessionPath: route.path,
            requiredCookie: route.cookie,
            needSocket: route.socket,
          };
        }
      }
    }
  }
  return null;
};

export const isPathProtected = (path) => {
  for (const sessionType in protectedPaths) {
    const sessionPaths = protectedPaths[sessionType];
    for (const route of sessionPaths) {
      if (route.path === path) {
        return true;
      }
    }
  }
  return false;
};

export const checkRoleValidationNeeded = (currentPath) => {
  const uniquePaths = new Set();

  // Combine paths from userSession and subUserSession into uniquePaths
  protectedPaths.userSession.forEach((route) => uniquePaths.add(route.path));
  protectedPaths.subUserSession.forEach((route) => uniquePaths.add(route.path));

  return uniquePaths.has(currentPath);
};

// Define debounce function
export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const selectSessionName = (currentSession) => {
  if (currentSession === "subUserSession") {
    return "subUserSession";
  } else if (currentSession === "notarySession") {
    return "notarySession";
  } else if (currentSession === "guestSession") {
    return "guestSession";
  } else if (currentSession === "userSession") {
    return "userSession";
  }
  return "";
};

export const formatPermitNumber = (permitNumber) => {
  if (!permitNumber) return "";

  const part1 = permitNumber.slice(0, 1); // First letter
  const part2 = permitNumber.slice(1, 3); // Next two digits
  const part3 = permitNumber.slice(3, 9); // Next six digits
  const part4 = permitNumber.slice(9); // Last three digits

  return `${part1}-${part2}-${part3}.${part4}`;
};

const compareNames = (name1, name2) => {
  // Using fuzzball to get the match score (0 to 100)
  const score = ratio(name1.toLowerCase(), name2.toLowerCase());

  // Let's assume 80 is the threshold for a good match
  return score >= 80;
};

export const checkLicenseHolderMatch = (
  licenseFirstName,
  licenseLastName,
  currentFirstName,
  currentLastName
) => {
  // Compare first and last names using the dynamic fuzzy comparison function
  const firstNameMatch = compareNames(licenseFirstName, currentFirstName);
  const lastNameMatch = compareNames(licenseLastName, currentLastName);

  if (firstNameMatch && lastNameMatch) {
    return true;
  } else {
    return false;
  }
};
