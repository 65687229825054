import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { updatePermitPackage } from "../../store/actions/dataActions";
import { updateUser } from "../../store/actions/userActions";
import { updateSubUser } from "../../store/actions/subUserActions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflow: "auto",
  maxHeight: "80%",
  borderRadius: "20px",
  p: 4,
};

const CityScraperStartModal = ({
  open,
  setOpen,
  selectedRow,
  setConsentAlert,
  setNocStates,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [counterBlock, setCounterBlock] = useState(false);
  const [counter, setCounter] = useState(0);
  const [blockError, setBlockError] = useState("");
  const [warningText, setWarningText] = useState(
    "Your credentials are securely encrypted and cannot be viewed or decrypted by anyone. We use the highest standards of data security to safeguard your information."
  );

  useEffect(() => {
    if (counter > 5 && !counterBlock) {
      setCounterBlock(true);
      setBlockError(
        "You have reached the maximum number of attempts. Please try again later."
      );
      setTimeout(() => {
        setCounterBlock(false);
        setBlockError("");
        setCounter(0);
      }, 60000);
    }
  }, [counter]);

  const handleAcceptCityScrape = async () => {
    setWarningText("");
    setCounter(counter + 1);
    try {
      setCityLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/noc/save-city_credentials`,
        {
          task_id: selectedRow?.task_id,
          currentSession: currentSession,
          username,
          password,
        },
        { withCredentials: true }
      );

      if (resp?.data?.success) {
        // await dispatch(updatePermitPackage(resp?.data?.data));
        if (currentSession === "userSession") {
          await dispatch(updateUser(resp?.data?.data));
        } else {
          await dispatch(updateSubUser(resp?.data?.data));
        }
        setNocStates((prev) => ({
          ...prev,
          isCreatingNoc: true,
        }));
        setUsername("");
        setPassword("");
        setAgreeToTerms(false);
        setUsernameError(false);
        setPasswordError(false);
        setIsError(false);
        setWarningText(
          "Your credentials are securely encrypted and cannot be viewed or decrypted by anyone. We use the highest standards of data security to safeguard your information."
        );
        setOpen(false);
      }
      setCityLoading(false);
    } catch (e) {
      setWarningText(
        "Login failed. Please check your credentials and try again."
      );
      setIsError(true);
      setUsernameError(true);
      setPasswordError(true);
      setCityLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        // onClose={() => {
        //   setOpen(false);
        // }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            color="primary"
            align="center"
            variant="h4"
            component="h2"
          >
            City Permit Automation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We need your consent to use your login credentials for the{" "}
            {selectedRow?.city.charAt(0).toUpperCase() +
              selectedRow?.city.slice(1)}{" "}
            city website to automatically fill out and upload documents required
            for creating a permit.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please note, no project will be submitted on the city website; we
            only fill and upload the necessary data. To proceed, we need your
            consent to use your login credentials for this automated process.
          </Typography>
          <Typography
            color="error"
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: "bold" }}
          >
            {warningText}
          </Typography>
          <Box component="form" sx={{ mt: 3 }}>
            <TextField
              error={usernameError}
              helperText={usernameError ? "Please enter a valid username" : ""}
              disabled={cityLoading || counterBlock}
              label="City Website Username"
              variant="filled"
              fullWidth
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                if (usernameError) setUsernameError(false);
                if (passwordError) setPasswordError(false);
                if (
                  warningText !== "" &&
                  warningText ===
                    "Login failed. Please check your credentials and try again."
                )
                  setWarningText("");
                if (isError) setIsError(false);
              }}
              sx={{ mb: 2 }}
            />
            <TextField
              error={passwordError}
              helperText={passwordError ? "Please enter a valid password" : ""}
              disabled={cityLoading || counterBlock}
              label="City Website Password"
              type="password"
              variant="filled"
              fullWidth
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                if (passwordError) setPasswordError(false);
                if (usernameError) setUsernameError(false);
                if (
                  warningText !== "" &&
                  warningText ===
                    "Login failed. Please check your credentials and try again."
                )
                  setWarningText("");
                if (isError) setIsError(false);
              }}
              sx={{ mb: 2 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={counterBlock}
                  checked={agreeToTerms}
                  onChange={(e) => setAgreeToTerms(e.target.checked)}
                  name="agreeToTerms"
                />
              }
              label={
                <Typography variant="body2">
                  I agree to the{" "}
                  <Link href="/terms-and-conditions" target="_blank">
                    Terms and Conditions
                  </Link>
                </Typography>
              }
            />
          </Box>
          <Grid container spacing={2} mt={blockError ? 2 : 3}>
            <Grid item xs={12}>
              <Typography
                mb={1}
                align="center"
                color="error"
                variant="body2"
                fontWeight="bold"
              >
                {blockError}
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button
                disabled={cityLoading}
                onClick={() => {
                  setUsername("");
                  setPassword("");
                  setAgreeToTerms(false);
                  setUsernameError(false);
                  setPasswordError(false);
                  setIsError(false);
                  setWarningText(
                    "Your credentials are securely encrypted and cannot be viewed or decrypted by anyone. We use the highest standards of data security to safeguard your information."
                  );
                  setOpen(false);
                }}
                fullWidth
                color="error"
                variant="outlined"
              >
                Cancel
              </Button>
            </Grid>

            <Grid item sm={6} xs={12}>
              <LoadingButton
                disabled={
                  !username ||
                  !password ||
                  !agreeToTerms ||
                  isError ||
                  counterBlock
                }
                loading={cityLoading}
                color="success"
                onClick={handleAcceptCityScrape}
                variant="contained"
                fullWidth
              >
                Provide Consent
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default CityScraperStartModal;
