import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import InQueueDrawer from "../noc/InQueueDrawer";
import EnlargeEditForm from "../noc/EnlargeEditForm";
import { useLocation, useNavigate } from "react-router-dom";
import {
  clearPdfProgress,
  setCreatedNOC,
} from "../../store/actions/dataActions";
import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  Grid,
  LinearProgress,
  Tooltip,
  IconButton,
  Drawer,
  useMediaQuery,
  useTheme,
  Stack,
  Tabs,
  Badge,
  Tab,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Sort } from "@mui/icons-material";
import EnlargeNotificationForm from "../noc/EnlargeNotificationForm";
import PdfProgressModal from "../modals/PdfProgressModal";
import Footer from "../layout/Footer";
import CachedIcon from "@mui/icons-material/Cached";
import VerifiedIcon from "@mui/icons-material/Verified";
import NotarizedModal from "../NotarizedModal";
import EmptyPermitPopover from "./EmptyPermitPopover";
import { selectSessionName } from "../../functions/helperFunctions";

export const NotificationPopover = (props) => {
  const theme = useTheme();
  const location = useLocation();
  const nocCreatingStatus = useSelector((state) => state.nocData.pdfProgress);
  const { anchorEl, onClose, open } = props;
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [sortDirection, setSortDirection] = useState("All");
  const [sortedTasks, setSortedTasks] = useState([]);
  const [inQueueDrawer, setInQueueDrawer] = useState(false);
  const [inQueueMessage, setInQueueMessage] = useState(
    "Calculating queue position..."
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedPreName, setSelectedPreName] = useState("");
  const [pdfImg, setPdfImg] = useState([]);
  const [currentTask, setCurrentTask] = useState([]);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [createNocLoading, setCreateNocLoading] = useState(false);
  const [enlargeFormInput, setEnlargeFormInput] = useState(
    currentTask?.original_values
  );

  const [isPreview, setIsPreview] = useState(false);
  const [preview, setPreview] = useState(false);
  const [previewImg, setPreviewImg] = useState([]);
  const [county, setCounty] = useState("");
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);
  const [pdf, setPdf] = useState("");
  const [text, setText] = useState(
    "All other fields will be filled automatically. Some fields are not visible in the preview."
  );
  const [fullRetrieve, setFullRetrieve] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [formDrawerOpen, setFormDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [addressRow, setAddressRow] = useState([]);
  const [cardFlip, setCardFlip] = useState(false);
  const [isRetrievingAddress, setIsRetrievingAddress] = useState(false);
  const [retrieveLoading, setRetrieveLoading] = useState(false);
  const [moreResultType, setMoreResultType] = useState("");
  const [copyInputs, setCopyInputs] = useState();

  const [notarizedModal, setNotarizedModal] = useState(false);

  const [defaultAddress, setDefaultAddress] = useState({
    street: "",
    number: "",
    unit: "",
    suffix: "",
    zip: "",
    fullAddress: "",
    retrieved: "",
    fullRetrieve: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [defaultValues, setDefaultValues] = useState();
  const [editFromCreate, setEditFromCreate] = useState(false);
  const [isFormCreated, setIsFormCreated] = useState(false);
  const [newRequest, setNewRequest] = useState(false);
  const [newRequestID, setNewRequestID] = useState("");
  const [moreResultsText, setMoreResultsText] = useState("");

  const [licenseType, setLicenseType] = useState("");
  const [copySelected, setCopySelected] = useState({});
  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseText, setLicenseText] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [selectedLicenseIndex, setSelectedLicenseIndex] = useState(0);
  const [disableSelect, setDisableSelect] = useState(false);

  const [downloadingFileLoading, setDownloadingFileLoading] = useState(false);

  const [isSuretyRadio, setIsSuretyRadio] = useState("no");

  const [notifyValue, setNotifyValue] = useState(0);
  const [Nloading, setNloading] = useState(false);

  const [pdfProgressModalOpen, setPdfProgressModalOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [dotStates, setDotStates] = useState({});
  const [completed, setCompleted] = useState({});
  const [intervalIds, setIntervalIds] = useState({});
  const [isFinished, setIsFinished] = useState(false);
  const [initializeQueue, setInitializeQueue] = useState(true);
  const [isMoreError, setIsMoreError] = useState(false);
  const [isFailedError, setIsFailedError] = useState(false);
  const [waitingList, setWaitingList] = useState(false);

  // PERMIT WARNING
  const [emptyPermitOpen, setEmptyPermitOpen] = useState(false);
  const [emptyPermitConfirmed, setEmptyPermitConfirmed] = useState(false);
  const [currentNocStatus, setCurrentNocStatus] = useState(null);

  const notification_tab_mapping = ["noc", "license"];

  const pdfProgressModalHandleClose = () => {
    setPdfProgressModalOpen(false);
    setItems([]);
    setDotStates({});
    setCompleted({});
    setIntervalIds({});
    setIsFinished(false);
    setInitializeQueue(true);
    setIsMoreError(false);
    setIsFailedError(false);
    setWaitingList(false);
    dispatch(clearPdfProgress());
  };

  // console.log(noc_data?.notifications);

  const updateNotificationCount = async (count, data, userObj) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/update-notification-count`,
        {
          count: count,
          data: data,
          obj: userObj,
          currentSession: props?.currentSession,
        },
        { withCredentials: true }
      );
    } catch (e) {}
  };

  const handleDownloadNoc = async () => {
    try {
      setDownloadingFileLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/download-created-file`,
        { pdf: selectedRow?.pdfUrl, currentSession: props?.currentSession },
        {
          withCredentials: true,
          responseType: "blob", // Important: This tells Axios to expect a binary response
        }
      );
      // Create a URL for the blob
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      // Create an anchor (`<a>`) element
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      // Set the download attribute to the desired file name
      fileLink.setAttribute("download", selectedRow?.pdfName); // Customize the file name if necessary
      // Append to the document and trigger the download
      document.body.appendChild(fileLink);
      fileLink.click();
      // Clean up
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL); // Release the object URL
      // setDownloadingFile(true);
      setDownloadingFileLoading(false);
    } catch (e) {
      setDownloadingFileLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setNotifyValue(newValue);
  };

  const handleLicenseClick = async (contractor) => {
    navigate("/contractors", {
      state: { option: contractor?.licenseType },
    });

    props.onClose();

    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/data/remove-license-notification`,
      {
        licenseType: contractor?.licenseType,
        currentSession: props?.currentSession,
      },
      { withCredentials: true }
    );
  };

  const handleDotStyle = (notificationStatus, foundStatus) => {
    const colorMap = {
      "More results": "#8B4513",
      "In Queue":
        foundStatus?.queue_data?.in_processing_window === false
          ? "#0a4e78"
          : "#e9c603",
      "In Progress": "#e9c603",
      Failed: "#e84034",
      Completed: "#10b981",
    };

    return colorMap[notificationStatus] || "#f4f4f4"; // Default color if not found
  };

  const updateNotification = async (task_id) => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/update-notification`,
      { task_id: task_id, currentSession: props?.currentSession },
      { withCredentials: true }
    );
  };

  useEffect(() => {
    if (isMd && openDrawer === true) {
      setDisableSelect(false);
      setOpenDrawer(false);
      setEditFromCreate(false);
    }

    if (isMd && drawerOpen === true) {
      setDrawerOpen(false);
      setEditLoading(false);
      setPreviewLoading(false);
      setDisableSelect(false);
      setNloading(false);
    }
  }, [isMd, openDrawer, drawerOpen]);

  useEffect(() => {
    if (
      copySelected?.licenseStatus === "not_found" ||
      copySelected?.licenseStatus === "error"
    ) {
      setLicenseText("License Number not Found");
      if (copySelected?.tried && copySelected?.tried >= 4) {
        setInvalid(true);
        setRetrieveLoading(true);
      } else {
        setInvalid(true);
        setRetrieveLoading(false);
      }
    } else if (copySelected?.licenseStatus === "unverified") {
      setLicenseText("License Number not Found");
      if (copySelected?.tried && copySelected?.tried >= 4) {
        setInvalid(true);
        setRetrieveLoading(true);
      } else {
        setInvalid(true);
        setRetrieveLoading(false);
      }
    } else if (copySelected?.process_status === "queued") {
      setLicenseText("Waiting for Validation");
      if (copySelected?.tried && copySelected?.tried >= 4) {
        setInvalid(true);
        setRetrieveLoading(true);
      } else {
        setInvalid(true);
        setRetrieveLoading(false);
      }
    } else if (
      copySelected?.valid === false &&
      copySelected?.expiry !== "" &&
      copySelected?.licenseStatus !== "not_found"
    ) {
      setLicenseText(copySelected?.licenseStatus);
      if (copySelected?.tried || copySelected?.tried >= 4) {
        setInvalid(true);
        setRetrieveLoading(true);
      } else {
        setInvalid(true);
        setRetrieveLoading(false);
      }
    } else if (
      copySelected?.valid === true &&
      copySelected?.confirmed === false
    ) {
      setLicenseText(copySelected?.licenseStatus);
      if (copySelected?.tried && copySelected?.tried >= 4) {
        setInvalid(true);
        setRetrieveLoading(true);
      } else {
        setInvalid(true);
        setRetrieveLoading(false);
      }
    } else {
      setLicenseText("Verified License Number");
      setInvalid(false);
      setRetrieveLoading(false);
    }
  }, [copySelected, licenseText]);

  useEffect(() => {
    if (newRequest === true) {
      const targetTask = props?.currentUser?.nocUserTasks.find(
        (task) => task.task_id === newRequestID
      );
      if (targetTask) {
        if (targetTask.status === "completed") {
          if (targetTask.success === "more") {
            if (openDrawer && !inQueueDrawer) {
              setDefaultAddress({
                ...defaultAddress,
                retrieved: !targetTask.original_values.allRetrieve
                  ? String(targetTask.listPerPage)
                  : targetTask.return_values.length,
                fullRetrieve: !targetTask.original_values.allRetrieve
                  ? String(targetTask.numberOfLists)
                  : targetTask.return_values.length,
              });

              if (targetTask.listPages === 1) {
                setFullRetrieve(true);
                setIsRetrievingAddress(true);
              } else {
                setIsRetrievingAddress(false);
              }
              if (tabletSize) {
                setPreviewLoading(false);
              }

              setAddressRow(targetTask.return_values);

              setCardFlip(true);
              updateNotification(targetTask.task_id);
              setPreviewLoading(false);
              setCreateNocLoading(false);
              setDisableSelect(false);
              setRetrieveLoading(false);
              setText(targetTask.message);
              if (fullRetrieve) {
                setMoreResultsText("Addresses are retrieved successfully!");
              }
            }
            setRetrieveLoading(false);
            setNewRequest(false);
            setDisableSelect(false);
            setNewRequestID("");
            setFullRetrieve(false);
          } else if (targetTask.success === true) {
            if (openDrawer && !inQueueDrawer) {
              setCurrentTask(targetTask);
              setCopyInputs(targetTask.return_values?.inputs);

              const formWithSecondTook = {
                ...targetTask.return_values,
                task_id: targetTask.task_id,
                second_took: targetTask.second_took,
              };

              setSelectedRow(formWithSecondTook);
              setText(targetTask.message);
              setPreview(true);
              setIsReadyToDownload(true);
              setPreviewImg(targetTask.return_values.signed);
              setPdf(targetTask.return_values.pdfUrl);
              updateNotification(targetTask.task_id);
              setPreviewLoading(false);
              setCreateNocLoading(false);
              setEditFromCreate(true);
              setDisableSelect(true);
              setIsFormCreated(true);
            }
            setNewRequest(false);
            setNewRequestID("");
          } else if (targetTask.success === false) {
            if (openDrawer && !inQueueDrawer) {
              setText(targetTask.message);
              updateNotification(targetTask.task_id);
              setCreateNocLoading(false);
              setPreviewLoading(false);
              setDisableSelect(false);
            }
            setNewRequest(false);
            setNewRequestID("");
          }
        }
      }
    }
  }, [props?.currentUser, newRequest, inQueueDrawer]);

  useEffect(() => {}, [selectedRow]);

  useEffect(() => {
    if (
      props?.noc_data?.notifications &&
      props?.noc_data?.notifications?.[notification_tab_mapping[notifyValue]]
    ) {
      const notificationCard =
        props?.noc_data?.notifications?.[notification_tab_mapping[notifyValue]];
      // Filter 'Completed' tasks and sort them by date
      const sortedTasks = notificationCard.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      // // Get the latest 5 'Completed' tasks with additional IDs
      const completedTasks = notificationCard
        .filter((task) => task.status === "Completed")
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      let finalSortedTasks = [];

      if (sortDirection === "All") {
        finalSortedTasks = sortedTasks;
      } else if (sortDirection === "Completed") {
        finalSortedTasks = completedTasks;
      } else {
        finalSortedTasks = notificationCard.filter(
          (task) => task.status === sortDirection
        );
      }

      // Update the state with the filtered and sorted tasks
      setSortedTasks(finalSortedTasks);
    }
  }, [sortDirection, props?.noc_data, notifyValue]);

  useEffect(() => {
    if (currentTask && inQueueDrawer) {
      setInQueueMessage((prevMessage) => {
        return `Your queue position is ${
          currentTask.new_position -
          parseInt(process.env.REACT_APP_QUEUE_SIZE, 10)
        }`;
      });
    }
  }, [currentTask, inQueueDrawer]);

  const handleSortClick = () => {
    // Toggle the sorting direction when the button is clicked

    if (sortDirection === "All") {
      setSortDirection("In Progress");
    } else if (sortDirection === "In Progress") {
      setSortDirection("More results");
    } else if (sortDirection === "More results") {
      setSortDirection("Completed");
    } else if (sortDirection === "Completed") {
      setSortDirection("In Queue");
    } else if (sortDirection === "In Queue") {
      setSortDirection("Failed");
    } else if (sortDirection === "Failed") {
      setSortDirection("Waiting for Appraiser Website");
    } else if (sortDirection === "Waiting for Appraiser Website") {
      setSortDirection("All");
    }
  };
  const submitMenuItem = async (task) => {
    setCurrentTask(task);
    setDisableSelect(false);
    setIsSuretyRadio(task?.original_values?.isSuretyRadio);
    const licenseTypeToFind = task?.original_values?.licenseType;

    // Assuming props.user.user.contractors is your array of objects
    const contractors = props?.currentUser?.user.contractors;

    // Find the index of the object with matching licenseType
    const index = contractors.findIndex(
      (contractor) => contractor.licenseType === licenseTypeToFind
    );
    setCopySelected(props?.currentUser?.user.contractors[index]);
    setSelectedLicenseIndex(index);
    setLicenseType(props?.currentUser?.user.contractors[index].licenseType);
    setLicenseNumber(props?.currentUser?.user.contractors[index].licenseNumber);
    if (
      props?.currentUser?.user.contractors[index].licenseStatus ===
        "not_found" ||
      props?.currentUser?.user.contractors[index].licenseStatus === "error"
    ) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
    // OPEN IN QUEUE DRAWER ///
    if (task?.notary?.is_active) {
      return;
    } else if (task?.notary?.is_notarized) {
      setNotarizedModal(true);
      return;
    } else if (
      task.notificationStatus === "More results" &&
      props?.currentUser.user.selectedCountiesNOC.some(
        (county) => county.name === task.original_values.county
      )
    ) {
      //? IF MOBILE SCREEN, SEND THE DATA //////////////////////////
      if (isMd) {
        navigate("/permits/create", {
          state: {
            selectedRow: {
              type: "moreResults",
              originalValues: task?.original_values,
              moreResults: task?.return_values,
            },
          },
        });
        //? //////////////////////////////////////////////////////////
      } else {
        setCopyInputs(task.original_values);
        setSelectedRow(task?.return_values);
        setRetrieveLoading(false);
        setOpenDrawer(true);
        setEditFromCreate(false);
        setIsReadyToDownload(false);
        setPreview(false);

        if (task?.original_values?.img === "") {
          setPdfImg(
            `${process.env.REACT_APP_URL}/images/noc/${task?.original_values?.county}.jpg`
          );
        } else {
          setPdfImg(task.original_values.img);
        }
        setSelectedPreName(task?.original_values?.county);
        setCardFlip(true);
        props.onClose();
        setDefaultAddress({
          ...defaultAddress,
          retrieved: !task.original_values.allRetrieve
            ? String(task.listPerPage)
            : task.return_values.length,
          fullRetrieve: !task.original_values.allRetrieve
            ? String(task.numberOfLists)
            : task.return_values.length,
        });

        if (task?.listPages === 1) {
          setIsRetrievingAddress(true);
        } else {
          setIsRetrievingAddress(false);
          setMoreResultType("notification");
          setCurrentTask((prevTask) => ({
            ...prevTask,
            original_values: {
              ...prevTask.original_values,
              allRetrieve: true,
            },
          }));
        }
        setAddressRow(task?.return_values);
        setCardFlip(true);
        setPreviewLoading(false);
        setCreateNocLoading(false);
        setText(task?.message);
      }
    } else if (
      task.notificationStatus === "Failed" &&
      props?.currentUser.user.selectedCountiesNOC.some(
        (county) => county.name === task.original_values.county
      )
    ) {
      //? IF MOBILE SCREEN, SEND THE DATA //////////////////////////
      if (isMd) {
        navigate("/permits/create", {
          state: {
            selectedRow: {
              type: "failed",
              originalValues: task?.original_values,
              moreResults: null,
            },
          },
        });
        //? //////////////////////////////////////////////////////////
      } else {
        setCopyInputs(task.original_values);
        setSelectedRow(task?.return_values);
        setText(task.message);
        setOpenDrawer(true);
        setAddressRow([]);
        setIsRetrievingAddress(false);
        setRetrieveLoading(false);
        setEditFromCreate(false);
        setIsReadyToDownload(false);
        setPreview(false);
        setPdfImg(task.original_values.img);
        setSelectedPreName(task?.original_values?.county);
        setCardFlip(false);
        props.onClose();
        setDefaultAddress({
          ...defaultAddress,
          retrieved: !task.original_values.allRetrieve
            ? String(task.listPerPage)
            : task.return_values.length,
          fullRetrieve: !task.original_values.allRetrieve
            ? String(task.numberOfLists)
            : task.return_values.length,
        });
      }
    } else if (task.notificationStatus === "Completed") {
      //? IF MOBILE SCREEN, SEND THE DATA //////////////////////////
      if (isMd) {
        navigate("/dashboard", {
          state: { selectedRow: task?.return_values },
        });
        //? //////////////////////////////////////////////////////////
      } else {
        setSelectedRow(task?.return_values);
        if (task.new) {
          updateNotification(task.task_id);
        }
        const nocUserForms = props?.currentUser?.nocUserForms;
        const return_obj = nocUserForms.find(
          (form) => form.task_id === task.task_id
        );
        dispatch(setCreatedNOC(return_obj));
        // if (location.pathname !== "/dashboard") {
        navigate("/dashboard");
        // }
        // setOpenDrawer(false);
        // setDrawerOpen(true);
        // setEditFromCreate(true);
        // setIsReadyToDownload(true);
        // setPreview(false);
        // setPdfImg(task.original_values.img);
        // setSelectedPreName(task?.original_values?.county);
        // setCardFlip(false);
        props.onClose();
      }
    }
    //////////////////

    if (
      (task.notificationStatus === "More results" && task.new === true) ||
      (task.notificationStatus === "Completed" && task.new === true) ||
      (task.notificationStatus === "Failed" && task.new === true)
    )
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/update-notification`,
        { task_id: task.task_id, currentSession: props?.currentSession },
        { withCredentials: true }
      );
  };
  const formik = useFormik({
    initialValues: {
      // streetNumber: currentTask?.original_values?.streetNumber,
      // unitNumber: currentTask?.original_values?.unitNumber,
      // streetName: currentTask?.original_values?.streetName,
      // zip: currentTask?.original_values?.zip,
      owner: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      streetNumber: Yup.number()
        .typeError("Must be a number")
        .required("Street Number is required"),
      unitNumber: Yup.string(),
      streetName: Yup.string().required("Street Name is required"),
      zip: Yup.string().matches(/^\d{5}([-]?\d{4})?$/, "Invalid ZIP Code"),
    }),
    onSubmit: async (values, helpers) => {
      // const formImg =
      values["_id"] = String(props?.currentUser.user._id);
      values["allRetrieve"] = false;
      values["county"] = county;
      values["customerId"] = props?.currentUser.user.customerId;
      values["email"] = props?.currentUser?.subuser
        ? props?.currentUser?.subuser?.email
        : props?.currentUser.user.email;
      values["contractorName"] =
        props?.currentUser.user.contractors[selectedLicenseIndex].name;
      values["contractorAddress"] =
        props?.currentUser.user.contractors[selectedLicenseIndex].address;
      values["contractorPhone"] =
        props?.currentUser.user.contractors[selectedLicenseIndex].phone;
      values["contractorFax"] =
        props?.currentUser.user.contractors[selectedLicenseIndex].fax;
      values["contractorEmail"] =
        props?.currentUser.user.contractors[selectedLicenseIndex].email;
      values["licenseNumber"] =
        props?.currentUser.user.contractors[selectedLicenseIndex].licenseNumber;
      values["licenseType"] =
        props?.currentUser.user.contractors[selectedLicenseIndex].licenseType;
      values["licenseStatus"] =
        props?.currentUser.user.contractors[selectedLicenseIndex].licenseStatus;
      values["img"] = currentTask?.original_values.img;

      values["isSuretyRadio"] = isSuretyRadio;
      // values["img"] = selectedPreForm;
      setDefaultValues(values);
      if (isPreview) {
        if (preview && !isMd) {
          setPreview(false);
        } else {
          setText("Please wait while we create the preview.");
          setPreviewLoading(true);
          setCreateNocLoading(true);
          setDisableSelect(true);
          try {
            const resp = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/nocApi/createPreview`,
              {
                values,
              },
              { withCredentials: true }
            );

            if (resp?.data?.success === true) {
              setPreviewImg(resp?.data?.data?.signed_urls);
              setText(resp?.data?.message);
              setIsPreview(false);
              setPreview(true);
              if (isMd) {
                setFormDrawerOpen(true);
              }
              setPreviewLoading(false);
              setCreateNocLoading(false);
              setDisableSelect(false);
            }
          } catch (err) {
            setText(err.response.data.message);
            setIsPreview(false);
            setPreviewLoading(false);
            setCreateNocLoading(false);
            setDisableSelect(false);
          }
        }
      } else if (editFromCreate) {
        try {
          setErrorText("Please wait. Editing in progress...");
          setEditLoading(true);
          setPreviewLoading(true);
          setDisableSelect(true);
          setNloading(true);
          // formCreated;
          values["pdfUrl"] = selectedRow?.pdfUrl;
          values["pdfImage"] = selectedRow?.signed
            ? selectedRow?.signed[0]
            : "";
          values["pdfImage2"] = selectedRow?.signed
            ? selectedRow?.signed[1]
            : "";
          values["county"] = selectedRow?.county;
          values["customerId"] = selectedRow?.inputs?.customerId;
          values["id"] = selectedRow?.id;
          values["contractorName"] = selectedRow?.inputs?.contractorName;
          values["contractorAddress"] = selectedRow?.inputs?.contractorAddress;
          values["contractorPhone"] = selectedRow?.inputs?.contractorPhone;
          values["contractorFax"] = selectedRow?.inputs?.contractorFax;
          values["contractorEmail"] = selectedRow?.inputs?.contractorEmail;
          values["legalDescription"] = selectedRow?.inputs?.legalDescription;
          values["primaryAddress"] = selectedRow?.inputs?.primaryAddress;
          // values["owner"] = selectedRow?.inputs?.owner;
          values["ownerAddress"] = selectedRow?.inputs?.ownerAddress;
          if (selectedRow?.inputs.county !== "Duval") {
            values["taxFolio"] = selectedRow?.inputs.taxFolio;
          }
          values["isSuretyRadio"] = isSuretyRadio;

          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/editPDF`,
            {
              values,
              currentSession: props?.currentSession,
            },
            { withCredentials: true }
          );
          setSelectedRow(resp.data.data);
          // setCopyInputs(resp.data.data);
          setCurrentTask((prevTask) => ({
            ...prevTask,
            return_values: resp.data.data,
          }));
          setNloading(false);
          setDisableEdit(true);
          setEditLoading(false);
          setPreviewLoading(false);
          setDisableSelect(true);
          setPreview(true);
          setPreviewImg(resp.data.data?.signed);
          setPdf(resp.data.data?.pdfUrl);

          ///////////////////////////////////////////////////////////////////////////////////

          setErrorText("Form edited successfully");
        } catch (e) {
          setEditLoading(true);
          setPreviewLoading(true);
          setDisableSelect(false);
        }
      } else {
        if (values?.permitNumber === "" && emptyPermitConfirmed === false) {
          setEmptyPermitOpen(true);
          return;
        } else {
          setEmptyPermitConfirmed(false);
          setPdfProgressModalOpen(true);

          try {
            setRetrieveLoading(true);
            setDisableSelect(true);
            setCreateNocLoading(true);
            if (!tabletSize) {
              setPreviewLoading(true);
            }

            // setText("Calculating queue position...");

            const resp = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/nocApi/createNoc`,
              {
                values,
                currentSession: props?.currentSession,
              },
              { withCredentials: true }
            );

            if (resp.data.queuePosition <= 0) {
              setNewRequestID(resp.data.task_id);
              setNewRequest(true);
              setText(
                "The data is being downloaded and a PDF file is being created. Please wait, this may take some time."
              );
            } else {
              setPdfProgressModalOpen(false);
              setInQueueMessage(resp.data.message);
              setInQueueDrawer(true);
            }
          } catch (err) {
            setPdfProgressModalOpen(false);
            setText(err.message);
            setCreateNocLoading(false);
            setPreviewLoading(false);
            setDisableSelect(false);
          }
        }
      }
    },
  });
  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left", // Adjusted to "right" instead of "left"
          vertical: "bottom",
        }}
        // transformOrigin={{
        //   horizontal: "right", // Adjusted to "right" instead of "left"
        //   vertical: "top",
        // }}
        onClose={onClose}
        open={open}
      >
        <Box
          sx={
            isMd
              ? {
                  display: "flex",
                  width: "100%",
                  py: 1.5,
                  px: 2,
                }
              : { display: "flex", width: "25rem", py: 1.5, px: 2 }
          }
        >
          {props?.currentUser?.subuser ? (
            <>
              <Box sx={{ flex: 2 }}>
                <Stack>
                  <Typography mt={1} variant="h6">
                    NOC Tracker
                  </Typography>

                  <Typography
                    sx={{ visibility: notifyValue !== 0 && "hidden" }}
                    variant="overline"
                  >
                    ({sortDirection})
                  </Typography>
                </Stack>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ flex: 2 }}>
                <Stack>
                  <Tabs
                    value={notifyValue}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label="NOC Tracker" />
                    <Tab label="License Notifications" />
                  </Tabs>

                  <Typography
                    sx={{ visibility: notifyValue !== 0 && "hidden" }}
                    variant="overline"
                  >
                    ({sortDirection})
                  </Typography>
                </Stack>
              </Box>
            </>
          )}

          <Box sx={isMd ? { marginRight: 2 } : { marginRight: 0 }}>
            <Tooltip
              title={sortDirection}
              sx={{ visibility: notifyValue !== 0 && "hidden" }}
            >
              <IconButton onClick={handleSortClick}>
                <Sort
                  color="inherit"
                  style={{
                    transform: `rotate(${
                      sortDirection === "asc" ? "0deg" : "180deg"
                    })`,
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Divider />
        {notifyValue === 0 ? (
          <>
            <MenuList
              disablePadding
              dense
              sx={
                isMd
                  ? {
                      width: "100vw",
                      p: "8px",
                      maxHeight: "20rem",
                      overflow: "auto",
                      "& > *": {
                        borderRadius: 1,
                      },
                    }
                  : {
                      width: "25rem",
                      p: "8px",
                      maxHeight: "20rem",
                      overflow: "auto",
                      "& > *": {
                        borderRadius: 1,
                      },
                    }
              }
            >
              {sortedTasks?.map((task, index) => {
                let tempProgress = null;
                const isCountyAvailable =
                  props?.currentUser.user.selectedCountiesNOC.some(
                    (county) => county?.name === task?.property?.county
                  );
                const foundStatus = nocCreatingStatus.find(
                  (item) => item.id === task?.pp_task_id
                );

                if (task.status === "In Queue") {
                  if (foundStatus?.queue_data?.in_processing_window === true) {
                    tempProgress = 40;
                  } else {
                    const currPosition = foundStatus?.queue_data?.position;

                    const progressBarValue = 100 / (currPosition + 1);

                    tempProgress = progressBarValue;
                  }
                }
                return (
                  <MenuItem
                    sx={{
                      mr: isMd ? "2rem" : 0,
                      borderBottom: isMd ? undefined : "3px solid #f3f3f3",
                      backgroundColor: isCountyAvailable
                        ? "inherit"
                        : "#f3f3f3",
                      cursor: "default",
                      "&:hover": {
                        backgroundColor: "inherit",
                      },
                    }}
                    disableRipple={true}
                    key={index}
                    onClick={() => {
                      return;
                      // if (isCountyAvailable) {
                      //   submitMenuItem(task);
                      // }
                    }}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        {new Date(task.created_at).toLocaleDateString("en-US", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}{" "}
                        {new Date(task.created_at).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          timeZone: props?.timezone,
                          hour12: true,
                        })}
                      </Grid>

                      <Grid item xs={6} sx={{ ml: "auto" }}>
                        {task?.new && (
                          <div
                            style={{
                              marginTop: "2px",
                              marginLeft: "auto",
                              minWidth: "0.8rem",
                              width: "0.8rem", // Set width
                              height: "0.8rem", // Set height
                              borderRadius: "50%", // To make it a circle
                              backgroundColor: handleDotStyle(
                                task?.status,
                                foundStatus
                              ),
                            }}
                          ></div>
                        )}
                      </Grid>
                      <Grid item xs={11}>
                        <Typography variant="body2" fontWeight="bold">
                          {task.property.address}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        {task?.notary?.is_active ? (
                          <Tooltip title="Notarization in Progress" arrow>
                            <IconButton
                              className="notary-rotate"
                              variant="contained"
                              sx={{ color: "#5C2C06", cursor: "wait" }}
                            >
                              <CachedIcon width={25} />
                            </IconButton>
                          </Tooltip>
                        ) : task?.notary?.is_notarized ? (
                          <Tooltip title="Notarized Document" arrow>
                            <IconButton
                              sx={{ cursor: "auto" }}
                              variant="contained"
                              color="success"
                            >
                              <VerifiedIcon width={25} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <div></div>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        {task.status !== "Waiting for Appraiser Website" && (
                          <LinearProgress
                            variant="determinate"
                            value={
                              tempProgress !== null
                                ? tempProgress
                                : task.progress_bar ?? 0
                            } // Set the progress value here
                            sx={{ mt: 1 }}
                          />
                        )}
                      </Grid>
                      {task?.notary?.is_active ? (
                        <Grid item xs={6}>
                          Notarization in Progress
                        </Grid>
                      ) : task?.notary?.is_notarized ? (
                        <Grid item xs={6}>
                          Notarized Document
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={7}
                          sx={
                            task.status === "Waiting for Appraiser Website"
                              ? {
                                  backgroundColor: "red",
                                  padding: "3px",
                                  borderRadius: "6px",
                                  color: "white",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                }
                              : isCountyAvailable
                              ? {}
                              : { color: "red", fontWeight: "bold" }
                          }
                        >
                          {task.status === "In Queue" ? (
                            <>
                              {foundStatus?.queue_data?.in_processing_window ===
                              true
                                ? "In Progress"
                                : `In Queue Position: ${foundStatus?.queue_data?.position}`}
                            </>
                          ) : isCountyAvailable ? (
                            task.status
                          ) : (
                            "County is Unselected"
                          )}
                        </Grid>
                      )}

                      <Grid item xs={5}>
                        <Typography variant="body2" align="right">
                          {task.property.county}
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                );
              })}
            </MenuList>
          </>
        ) : (
          <MenuList
            disablePadding
            dense
            sx={
              isMd
                ? {
                    width: "100vw",
                    p: "8px",
                    maxHeight: "20rem",
                    overflow: "auto",
                    "& > *": {
                      borderRadius: 1,
                    },
                  }
                : {
                    width: "25rem",
                    p: "8px",
                    maxHeight: "20rem",
                    overflow: "auto",
                    "& > *": {
                      borderRadius: 1,
                    },
                  }
            }
          >
            {/* {props?.currentUser?.user?.contractors?.map((task, index) => ( */}
            {[].map((task, index) => (
              <MenuItem
                sx={isMd ? { mr: "2rem" } : { mr: 0 }}
                key={index}
                onClick={() => {
                  handleLicenseClick(task);
                }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    {new Date(task.date * 1000).toLocaleDateString("en-US")}{" "}
                    {new Date(task.date * 1000).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      timeZone: props?.timezone,
                    })}
                  </Grid>

                  <Grid item xs={6} sx={{ ml: "auto" }}>
                    {task?.new && (
                      <div
                        style={{
                          marginTop: "2px",
                          marginLeft: "auto",
                          minWidth: "0.8rem",
                          width: "0.8rem", // Set width
                          height: "0.8rem", // Set height
                          borderRadius: "50%", // To make it a circle
                          backgroundColor: (() => {
                            if (
                              task?.valid === false &&
                              task?.process_status === "completed"
                            ) {
                              return "#e84034";
                            } else if (task?.process_status === "queued") {
                              return "#0a4e78";
                            } else if (
                              task?.valid === true &&
                              task?.process_status === "completed"
                            ) {
                              return "#10b981";
                            } else {
                              return "#8B4513";
                            }
                          })(),
                        }}
                      ></div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" fontWeight="bold">
                      {task?.licenseType}
                    </Typography>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <LinearProgress
                      variant="determinate"
                      value={task.progressBar || 0} // Set the progress value here
                      sx={{ mt: 1 }}
                    />
                  </Grid> */}
                  <Grid item xs={6}>
                    {task?.process_status === "queued"
                      ? "In Progress"
                      : task?.process_status === "completed" &&
                        task?.valid === false
                      ? "Failed"
                      : "Success"}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" align="right">
                      {task?.licenseNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </MenuList>
        )}
      </Popover>
      {/* IN QUEUEDRAWER */}
      <InQueueDrawer
        inQueueDrawer={inQueueDrawer}
        setInQueueDrawer={setInQueueDrawer}
        inQueueMessage={inQueueMessage}
        isMd={isMd}
      />
      {/* NOTIFICATION DRAWER */}
      <Drawer
        PaperProps={{
          sx: {
            width: "100vw",
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <EnlargeNotificationForm
          isSuretyRadio={isSuretyRadio}
          setIsSuretyRadio={setIsSuretyRadio}
          handleDownloadNoc={handleDownloadNoc}
          downloadingFileLoading={downloadingFileLoading}
          Nloading={Nloading}
          setCopyInputs={setCopyInputs}
          setDisableSelect={setDisableSelect}
          disableSelect={disableSelect}
          currentUser={props?.currentUser}
          setSelectedLicenseIndex={setSelectedLicenseIndex}
          invalid={invalid}
          setLicenseNumber={setLicenseNumber}
          licenseNumber={licenseNumber}
          licenseText={licenseText}
          copySelected={copySelected}
          setCopySelected={setCopySelected}
          licenseType={licenseType}
          setLicenseType={setLicenseType}
          moreResultType={moreResultType}
          currentTask={currentTask}
          moreResultsText={moreResultsText}
          setNewRequestID={setNewRequestID}
          setNewRequest={setNewRequest}
          setInQueueMessage={setInQueueMessage}
          setInQueueDrawer={setInQueueDrawer}
          setFullRetrieve={setFullRetrieve}
          fullRetrieve={fullRetrieve}
          selectedPreName={selectedPreName}
          setFormDrawerOpen={setFormDrawerOpen}
          formDrawerOpen={formDrawerOpen}
          editFromCreate={editFromCreate}
          setRetrieveLoading={setRetrieveLoading}
          isRetrievingAddress={isRetrievingAddress}
          setIsRetrievingAddress={setIsRetrievingAddress}
          retrieveLoading={retrieveLoading}
          defaultAddress={defaultAddress}
          setCardFlip={setCardFlip}
          cardFlip={cardFlip}
          addressRow={addressRow}
          setAddressRow={setAddressRow}
          setSelectedRow={setSelectedRow}
          text={text}
          pdf={pdf}
          isReadyToDownload={isReadyToDownload}
          pdfImg={pdfImg}
          createNocLoading={createNocLoading}
          setCounty={setCounty}
          previewLoading={previewLoading}
          setIsPreview={setIsPreview}
          formik={formik}
          enlargeType="createNOC"
          enlargeHeader="Create PDF"
          enlargeNote="To create your PDF, please enter the values below."
          enlargeFormInput={enlargeFormInput}
          setEnlargeFormInput={setEnlargeFormInput}
          setOpenDrawer={setOpenDrawer}
          preview={preview}
          setPreview={setPreview}
          previewImg={previewImg}
          defaultValues={defaultValues}
          setCreateNocLoading={setCreateNocLoading}
          setPreviewLoading={setPreviewLoading}
          setDefaultAddress={setDefaultAddress}
          isFormCreated={isFormCreated}
          inputs={copyInputs}
          copyInputs={selectedRow?.inputs}
          type="moreResults"
        />
      </Drawer>
      <Drawer
        PaperProps={{
          sx: {
            width: "100vw",
          },
        }}
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <EnlargeEditForm
          isSuretyRadio={isSuretyRadio}
          setIsSuretyRadio={setIsSuretyRadio}
          setCopyInputs={setCopyInputs}
          licenseType={currentTask?.original_values?.licenseType}
          licenseNumber={currentTask?.original_values?.licenseNumber}
          licenseStatus={currentTask?.original_values?.licenseStatus}
          setFormDrawerOpen={setFormDrawerOpen}
          formDrawerOpen={formDrawerOpen}
          pdf={pdf}
          disableEdit={disableEdit}
          previewImg={previewImg}
          errorText={errorText}
          editLoading={editLoading}
          previewLoading={previewLoading}
          preview={preview}
          formik={formik}
          type="edit"
          pdfImg={selectedRow?.signed ? selectedRow?.signed[0] : ""}
          pdfImg2={selectedRow?.signed ? selectedRow?.signed[1] : ""}
          inputs={selectedRow?.inputs}
          enlargeType="edit"
          selectedPreName={selectedRow?.county}
          selectedPreForm="/assets/images/nocForms/NOC-empty.jpg"
          enlargeHeader="Edit PDF Form"
          enlargeNote=""
          setOpenDrawer={setDrawerOpen}
          setSelectedRow={setSelectedRow}
          addressRow=""
          cardFlip=""
          handleDownloadNoc={handleDownloadNoc}
          downloadingFileLoading={downloadingFileLoading}
        />
      </Drawer>
      <NotarizedModal
        notarizedModal={notarizedModal}
        setNotarizedModal={setNotarizedModal}
        pdfImg={currentTask?.return_values?.createdForm?.pdfImage}
        pdfImg2={currentTask?.return_values?.createdForm?.pdfImage2}
        previewImg=""
        preview={false}
      />
      <PdfProgressModal
        waitingList={waitingList}
        setWaitingList={setWaitingList}
        open={pdfProgressModalOpen}
        handleClose={pdfProgressModalHandleClose}
        selectedPreName={selectedPreName}
        noc_data={props?.noc_data}
        pdfProgress=""
        items={items}
        setItems={setItems}
        dotStates={dotStates}
        setDotStates={setDotStates}
        completed={completed}
        setCompleted={setCompleted}
        intervalIds={intervalIds}
        setIntervalIds={setIntervalIds}
        isFinished={isFinished}
        setIsFinished={setIsFinished}
        initializeQueue={initializeQueue}
        setInitializeQueue={setInitializeQueue}
        isMoreError={isMoreError}
        setIsMoreError={setIsMoreError}
        isFailedError={isFailedError}
        setIsFailedError={setIsFailedError}
        setOpenDrawer={drawerOpen ? setDrawerOpen : setOpenDrawer}
      />

      {/* EMPTY PERMIT NUMBER POPOVER */}
      <EmptyPermitPopover
        emptyPermitOpen={emptyPermitOpen}
        setEmptyPermitOpen={setEmptyPermitOpen}
        setEmptyPermitConfirmed={setEmptyPermitConfirmed}
        formik={formik}
      />
    </>
  );
};
