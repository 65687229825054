import { Box, Grid, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import { convertObjectDateToUSString } from "../../notary/functions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import { useState } from "react";

const OwnerSignerTab = (props) => {
  const [resendLoading, setResendLoading] = useState(false);

  const { contractor } = props?.selectedRow?.processing_permit?.notarization;
  const { owner } = props?.selectedRow?.processing_permit?.notarization;
  const { notarization } = props?.selectedRow?.processing_permit;

  const { signing_order } = props?.selectedRow?.processing_permit?.notarization;

  const handleResendInvitation = async () => {
    try {
      setResendLoading(true);

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/resend-owner-notary-invitation`,
        {
          task_id: props?.selectedRow?.task_id,
          bn_id:
            props?.selectedRow?.processing_permit?.notarization?.owner
              ?.notarization?.bn_id,
          currentSession: props?.currentSession,
        },
        {
          withCredentials: true,
        }
      );
      console.log(resp);
    } catch (error) {
      console.log(error);
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <Box>
      {signing_order === "contractor" &&
      !contractor?.notarization?.is_notarized === true &&
      !contractor?.notarization?.is_signed === true ? (
        <Typography
          variant="body2"
          color="primary"
          align="center"
          fontWeight="bold"
          px={1}
          mt={2}
        >
          Please wait for the contractor's notarization/signing to complete.
        </Typography>
      ) : (signing_order === "contractor" &&
          contractor?.notarization?.is_notarized) ||
        contractor?.notarization?.is_signed ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              color="primary"
              align="center"
              fontWeight="bold"
              px={1}
              mt={1}
            >
              {!owner?.notarization?.is_notarized &&
              !owner?.notarization?.only_sign
                ? "Notarization is in progress"
                : owner?.notarization?.is_notarized &&
                  !owner?.notarization?.only_sign
                ? "Notarization is completed"
                : owner?.notarization?.only_sign &&
                  !owner?.notarization?.is_signed
                ? "Signing is in progress"
                : "Signing is completed"}
            </Typography>
            {owner?.notarization?.is_notarized && (
              <CheckCircleIcon
                style={{
                  color: green[500],
                  paddingTop: 4,
                  fontSize: "30px",
                }}
              />
            )}
          </Box>
          {!notarization?.finalized &&
          owner?.notarization?.only_sign &&
          !owner?.notarization?.is_signed ? (
            <>
              <Typography
                variant="body2"
                color="primary"
                align="center"
                fontWeight="bold"
                px={1}
                mt={2}
              >
                The invitation email has been successfully sent to the
                owner/agent, providing clear instructions to review and sign the
                required documents promptly.
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                align="center"
                fontWeight="bold"
                px={1}
                mt={2}
              >
                Please wait for the owner/agent to complete the signing process.
              </Typography>
            </>
          ) : !notarization?.finalized && !notarization?.both_only_sign ? (
            <Grid container spacing={1} px={2} mt={1}>
              <Grid item xs={10} sx={{ width: "100%" }}>
                <Typography variant="body2" fontWeight="bold">
                  The invitation email has been sent
                </Typography>
              </Grid>
              <Grid item xs={2} align="right">
                <CheckCircleIcon
                  style={{
                    color: green[500],
                  }}
                />
              </Grid>
              <Grid
                item
                xs={owner?.notarization?.waiting_to_schedule ? 10 : 6}
                sx={{ width: "100%" }}
              >
                <Typography variant="body2" fontWeight="bold">
                  RON Session Scheduled
                </Typography>
              </Grid>
              <Grid
                item
                xs={owner?.notarization?.waiting_to_schedule ? 2 : 6}
                align="right"
              >
                {owner?.notarization?.waiting_to_schedule ? (
                  <CancelIcon style={{ color: red[500] }} />
                ) : (
                  <Typography variant="body2" align="right" fontWeight="bold">
                    {convertObjectDateToUSString(
                      owner?.notarization?.appointments?.booked?.[0],
                      props?.currentUser?.user?.personal?.[0]?.timezone
                    )}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : (
            <Typography
              px={2}
              mt={2}
              align="center"
              variant="body2"
              fontWeight="bold"
              color="primary"
            >
              All documents have been successfully notarized/signed by the
              Owner/Agent.
            </Typography>
          )}
        </>
      ) : !notarization?.finalized &&
        owner?.notarization?.only_sign &&
        !owner?.notarization?.is_signed ? (
        <>
          <Typography
            variant="body2"
            color="primary"
            align="center"
            fontWeight="bold"
            px={1}
            mt={2}
          >
            The invitation email has been successfully sent to the owner/agent,
            providing clear instructions to review and sign the required
            documents promptly.
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            align="center"
            fontWeight="bold"
            px={1}
            mt={2}
          >
            Please wait for the owner/agent to complete the signing process.
          </Typography>
        </>
      ) : signing_order === "owner" &&
        !owner?.notarization?.is_notarized &&
        !notarization?.finalized &&
        owner?.notarization?.notary_failed &&
        !owner?.notarization?.failed_resent ? (
        <Box>
          <Typography
            px={2}
            mt={1}
            align="center"
            variant="h6"
            fontWeight="bold"
            color="error"
          >
            Remote Online Notarization Failed
          </Typography>
          <Typography px={2} align="center" color="primary" variant="body2">
            The notary was unable to connect with the signer to complete the
            notarization of the required documents. To expedite the rescheduling
            of a new appointment, you can send a reminder email. Click the
            button below to send the invitation to the Owner or Agent.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <LoadingButton
              loading={resendLoading}
              onClick={handleResendInvitation}
              variant="contained"
            >
              Proceed
            </LoadingButton>
          </Box>
        </Box>
      ) : !owner?.notarization?.is_notarized &&
        !notarization?.finalized &&
        !owner?.notarization?.only_sign ? (
        <Grid container spacing={0.5} px={2} mt={0}>
          <Grid item xs={10} sx={{ width: "100%" }}>
            <Typography variant="body2" fontWeight="bold">
              The invitation email has been sent
            </Typography>
          </Grid>
          <Grid item xs={2} align="right">
            <CheckCircleIcon
              style={{
                color: green[500],
              }}
            />
          </Grid>
          <Grid
            item
            xs={owner?.notarization?.waiting_to_schedule ? 10 : 6}
            sx={{ width: "100%" }}
          >
            <Typography variant="body2" fontWeight="bold">
              RON Session Scheduled
            </Typography>
          </Grid>
          <Grid
            item
            xs={owner?.notarization?.waiting_to_schedule ? 2 : 6}
            align="right"
          >
            {owner?.notarization?.waiting_to_schedule ? (
              <CancelIcon style={{ color: red[500] }} />
            ) : (
              <Typography variant="body2" align="right" fontWeight="bold">
                {convertObjectDateToUSString(
                  owner?.notarization?.appointments?.booked?.[0],
                  props?.currentUser?.user?.personal?.[0]?.timezone
                )}
              </Typography>
            )}
          </Grid>
          {!owner?.notarization?.waiting_to_schedule &&
            !owner?.notarization?.only_sign && (
              <>
                <Grid item xs={10}>
                  <Typography variant="body2" fontWeight="bold">
                    Notary Joined to the session
                  </Typography>
                </Grid>
                <Grid item xs={2} align="right">
                  {owner?.notarization?.logs?.[owner?.notarization?.attempt]
                    ?.notary_joined ? (
                    <CheckCircleIcon
                      style={{
                        color: green[500],
                      }}
                    />
                  ) : (
                    <CancelIcon style={{ color: red[500] }} />
                  )}
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2" fontWeight="bold">
                    Owner/Agent Joined to the session
                  </Typography>
                </Grid>
                <Grid item xs={2} align="right">
                  {owner?.notarization?.logs?.[owner?.notarization?.attempt]
                    ?.owner_joined ? (
                    <CheckCircleIcon
                      style={{
                        color: green[500],
                      }}
                    />
                  ) : (
                    <CancelIcon style={{ color: red[500] }} />
                  )}
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2" fontWeight="bold">
                    {owner?.notarization?.logs?.[owner?.notarization?.attempt]
                      ?.owner_joined
                      ? owner?.notarization?.logs?.[
                          owner?.notarization?.attempt
                        ]?.id_passed
                        ? "ID Verification Passed"
                        : owner?.notarization?.logs?.[
                            owner?.notarization?.attempt
                          ]?.id_failed
                        ? "ID Verification Failed"
                        : "Waiting for ID Verification..."
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={2} align="right">
                  {owner?.notarization?.logs?.[owner?.notarization?.attempt]
                    ?.id_passed ? (
                    <CheckCircleIcon
                      style={{
                        color: green[500],
                      }}
                    />
                  ) : owner?.notarization?.logs?.[owner?.notarization?.attempt]
                      ?.id_failed ? (
                    <CancelIcon style={{ color: red[500] }} />
                  ) : (
                    <></>
                  )}
                </Grid>
                {owner?.notarization?.logs?.[owner?.notarization?.attempt]
                  ?.owner_joined &&
                  owner?.notarization?.logs?.[owner?.notarization?.attempt]
                    ?.notary_joined && (
                    <Grid item xs={12}>
                      <Typography variant="body2" fontWeight="bold">
                        Signing In Progress...
                      </Typography>
                    </Grid>
                  )}
              </>
            )}
          {/*  */}
        </Grid>
      ) : signing_order === "owner" &&
        owner?.notarization?.is_notarized &&
        !notarization?.finalized ? (
        <Typography
          px={2}
          mt={2}
          align="center"
          variant="body2"
          fontWeight="bold"
          color="primary"
        >
          The owner/agent has successfully notarized/signed the documents. In
          order to access your final documents, please complete the
          notarization/signing process as the contractor.
        </Typography>
      ) : Object.keys(
          props?.selectedRow?.processing_permit?.notarization?.contractor
        ).length === 0 && notarization?.finalized ? (
        <Typography
          px={2}
          mt={2}
          align="center"
          variant="body2"
          fontWeight="bold"
          color="primary"
        >
          All documents have been successfully notarized by the Owner/Agent.
        </Typography>
      ) : (
        <Typography
          px={2}
          mt={2}
          align="center"
          variant="body2"
          fontWeight="bold"
          color="primary"
        >
          All documents have been successfully notarized/signed by the
          Owner/Agent.
        </Typography>
      )}
    </Box>
  );
};

export default OwnerSignerTab;
