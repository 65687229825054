import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchUserData } from "../../store/actions/userActions";
import MobileFooter from "../layout/MobileFooter";
import LottieAnimation from "../../animation/LottieAnimation";
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { fetchSubUserData } from "../../store/actions/subUserActions";
import Defaults from "../presetComponents/Defaults";
import DesignProfessionals from "../presetComponents/DesignProfessionals";
import {
  enableEmptyCountyFlag,
  setPrevTourStep,
  setShowTourNav,
} from "../../store/actions/dataActions";
import { useTour } from "@reactour/tour";

const Presets = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultCounty = location.state?.option;
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const scrollableBoxRef = useRef(null);
  const [defaultValues, setDefaultValues] = useState({
    general: "",
    interest: "",
  });
  const [selected, setSelected] = useState(defaultCounty || "");
  const [selectList, setSelectList] = useState();
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);

  const [loadingPage, setLoadingPage] = useState(true);
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  const { isOpen, currentStep } = useTour();

  let nocCount = 0;

  useEffect(() => {
    if (isOpen && errorText === "Default has been added/changed successfully") {
      dispatch(setPrevTourStep(currentStep));
      dispatch(setShowTourNav(true));
    }
  }, [errorText, isOpen]);

  const handleSubmit = async () => {
    if (isOpen && currentStep === 4) {
      return;
    }
    setLoading(true);
    if (selected === "") {
      setLoading(false);
      return setErrorText("Please select a County");
    }

    try {
      const changed = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/editdefaults`,
        {
          selected: selected,
          general: defaultValues.general,
          interest: defaultValues.interest,
          email: currentUser?.user.email,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
        }
      );

      if (changed.data.success) {
        if (currentSession === "userSession") {
          dispatch(fetchUserData(currentSession));
        } else {
          dispatch(fetchSubUserData(currentSession));
        }

        // const selectedList = [];
        // let form = null;
        // currentUser.user.selectedCountiesNOC.forEach((item) => {
        //   selectedList.push(item.name);
        //   if (defaultCounty) {
        //     if (item.name === defaultCounty) {
        //       form = item.form;
        //     }
        //   }
        // });
        // Construct a new list of names
        const selectedList = currentUser.user.selectedCountiesNOC
          .map((item) => item.name)
          .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

        // Find the form for a default county
        const defaultCountyItem = currentUser.user.selectedCountiesNOC.find(
          (item) => item.name === defaultCounty
        );
        const form = defaultCountyItem ? defaultCountyItem.form : undefined;

        if (defaultCounty) {
          navigate("/createnoc", {
            state: { county: defaultCounty, form: form },
          });
        }
        setSelectList(selectedList);
        setErrorText("Default has been added/changed successfully");
      }
      setLoading(false);
    } catch (err) {
      window.location.reload();
      setLoading(false);
    }
  };

  useEffect(() => {
    const updateNOC = async () => {
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/update-selected-counties`,
          { form: defaultCounty, currentSession: currentSession },
          { withCredentials: true }
        );
      } catch (e) {}
    };

    if (nocCount < 1 && defaultCounty) {
      nocCount += 1;
      updateNOC();
    }
  }, []);

  useEffect(() => {
    if (errorText !== "") {
      const timer = setTimeout(() => setErrorText(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [errorText]);

  useEffect(() => {
    if (currentUser.user.expired === true) {
      navigate("/subscription");
      return;
    } else if (currentUser.user.selectedCountiesNOC.length === 0) {
      dispatch(enableEmptyCountyFlag(location.pathname));
      navigate("/selectcounties");
      return;
    }

    const selectedList = [];
    currentUser.user.selectedCountiesNOC.forEach((item) => {
      selectedList.push(item.name);
    });
    selectedList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

    setSelectList(selectedList);

    if (selected !== "") {
      currentUser.user.selectedCountiesNOC.forEach((item, index) => {
        if (selected === item.name) {
          setDefaultValues({
            general: item.general,
            interest: item.interest,
          });
          setLoading(false);
        }
      });
    }
    setLoadingPage(false);
  }, [selected, currentUser]);

  return (
    <>
      {loadingPage ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {tabletSize ? (
              <>
                <Box sx={{ mb: 2 }}>
                  <img
                    style={{
                      width: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                <LottieAnimation />
              </>
            ) : (
              <CircularProgress color="warning" size="10rem" />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              mx: largePC ? "auto" : undefined,
              maxWidth: "1000px",
              minWidth: largePC ? "1000px" : undefined,
              py: 1,
              mt: 1,
              backgroundColor: "#EEF2F6",
              mb: tabletSize ? "5rem" : undefined,
            }}
          >
            <Grid
              container
              sx={{
                px: mobileSize ? 1 : tabletSize ? 2 : 3,
              }}
            >
              <Defaults
                tabletSize={tabletSize}
                mobileSize={mobileSize}
                loading={loading}
                defaultCounty={defaultCounty}
                selected={selected}
                setSelected={setSelected}
                selectList={selectList}
                setDefaultValues={setDefaultValues}
                defaultValues={defaultValues}
                errorText={errorText}
                handleSubmit={handleSubmit}
              />
              <DesignProfessionals
                tabletSize={tabletSize}
                mobileSize={mobileSize}
                professionalsRow={currentUser?.design_professionals}
                design_professionals_locked={
                  currentUser?.design_professionals_locked
                }
                currentSession={currentSession}
              />
            </Grid>
            {tabletSize && <MobileFooter pos="bottom" pl="0.5rem" />}
          </Box>
        </>
      )}
    </>
  );
};

export default Presets;
