import { Typography } from "@mui/material";

export const handleUserTexts = (
  extra,
  isMore,
  color,
  texts,
  lastMonthCount,
  title
) => {
  if (title === "Average NOC Creation Time" && lastMonthCount === 0) {
    return (
      <Typography variant="caption" color="textSecondary">
        There is no data from last month to compare
      </Typography>
    );
  } else if (isMore === true) {
    return (
      <Typography variant="caption" color="textSecondary">
        {texts[0]}{" "}
        <Typography
          component="span"
          variant="caption"
          sx={{ color: `${color || "primary"}.main` }}
        >
          {Math.floor(extra)}
        </Typography>{" "}
        {texts[1]}
      </Typography>
    );
  } else if (isMore === false) {
    return (
      <Typography variant="caption" color="textSecondary">
        {texts[2]}{" "}
        <Typography
          component="span"
          variant="caption"
          sx={{ color: `${color || "primary"}.main` }}
        >
          {Math.floor(extra)}
        </Typography>{" "}
        {texts[3]}
      </Typography>
    );
  } else if (isMore === 0) {
    return (
      <Typography variant="caption" color="textSecondary">
        {texts[4]}{" "}
        <Typography
          component="span"
          variant="caption"
          sx={{ color: `${color || "primary"}.main` }}
        >
          0
        </Typography>{" "}
        {texts[5]}
      </Typography>
    );
  }
};
