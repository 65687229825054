import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Divider,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
  Collapse,
} from "@mui/material";
import { items, adminItems, notaryItems } from "./side-nav-items";
import { SideNavItem } from "./side-nav-item";
import SideNavAd from "./side-nav-ad";
import { useTour } from "@reactour/tour";

const SideNav = (props) => {
  const { isOpen, currentStep } = useTour();
  const { open, onClose } = props;
  const pathname = window.location.pathname;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [hideTrial, setHideTrial] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [isBottom, setIsBottom] = useState(false);
  const [inMiddle, setInMiddle] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [openItems, setOpenItems] = useState({});
  const scrollContainerRef = useRef(null);
  const scrollDirection = useRef("down");
  const scrollIntervalRef = useRef(null);
  const activeItemRef = useRef(null);

  const scrollbarStyles = {
    overflowY: "auto",
    flexGrow: 1,
    px: 2,
    py: 3,
    msOverflowStyle: "none", // For IE and Edge
    scrollbarWidth: "thin", // For Firefox

    /* Adding WebKit Scrollbar styling using a CSS string */
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#555",
      borderRadius: "10px",
      border: "3px solid #f1f1f1",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#333",
    },
  };

  useEffect(() => {
    // Automatically scroll to the active item when component mounts
    if (activeItemRef.current && scrollContainerRef.current) {
      activeItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [pathname]);

  const handleScrollDown = () => {
    if (scrollContainerRef.current) {
      const { scrollHeight, clientHeight } = scrollContainerRef.current;
      const isAtBottom =
        scrollContainerRef.current.scrollTop + clientHeight >= scrollHeight;

      // Scroll down only if not at the bottom
      if (!isAtBottom) {
        scrollContainerRef.current.scrollTop += 15;
      }
    }
  };

  const handleScrollUp = () => {
    if (scrollContainerRef.current) {
      const isAtTop = scrollContainerRef.current.scrollTop === 0;

      // Scroll up only if not at the top
      if (!isAtTop) {
        scrollContainerRef.current.scrollTop -= 15;
      }
    }
  };

  const handleMouseUp = () => {
    setIsClicked(false);
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
  };

  const handleMouseDown = () => {
    setIsClicked(true);
    if (!scrollIntervalRef.current) {
      handleScrollDown();
      scrollIntervalRef.current = setInterval(handleScrollDown, 100);
    }
  };

  const handleMouseDownUp = () => {
    setIsClicked(true);
    if (!scrollIntervalRef.current) {
      handleScrollUp();
      scrollIntervalRef.current = setInterval(handleScrollUp, 100);
    }
  };

  useEffect(() => {
    const scrollableElement = scrollContainerRef.current;
    const lastScrollPos = localStorage.getItem("sideNavScrollPos") || 0;

    if (scrollableElement) {
      scrollableElement.scrollTop = lastScrollPos;

      const handleScroll = () => {
        localStorage.setItem("sideNavScrollPos", scrollableElement.scrollTop);
      };

      scrollableElement.addEventListener("scroll", handleScroll);

      return () => {
        scrollableElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handleToggle = (title) => {
    setOpenItems((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  //! BACKWARD NAVIGATION FOR USER
  useEffect(() => {
    if (isOpen) {
      const userNumbOne = 7;
      const userNumbTwo = 8;
      const subUserNumbOne = 5;
      const subUserNumbTwo = 6;

      const numberOne =
        props?.currentSession === "subUserSession"
          ? subUserNumbOne
          : userNumbOne;
      const numberTwo =
        props?.currentSession === "subUserSession"
          ? subUserNumbTwo
          : userNumbTwo;

      const title = "Permits Hub";

      if (currentStep === numberOne && openItems[title] === true) {
        handleToggle(title);
      } else if (currentStep === numberTwo && openItems[title] === false) {
        handleToggle(title);
      }
    }
  }, [currentStep]);
  //! ////////////////////////////////////////////////////////////

  const isActive = (item) => {
    if (item.path && pathname === item.path) return true;
    if (item.children) {
      return item.children.some((child) => pathname === child.path);
    }
    return false;
  };

  const isActiveDemoItem = (item) => {
    if (isOpen) {
      if (currentStep === 0 && item.path === "/selectcounties") return true;
      if (currentStep === 2 && item.path === "/defaults") return true;
      if (currentStep === 5 && item.path === "/contractors") return true;
      if (currentStep === 7 && item.title === "Permits Hub") return true;
      if (currentStep === 8 && item.path === "/permits/create") return true;
      if (currentStep === 21 && item.path === "/subusers") return true;
      if (currentStep === 23 && item.path === "/subscription") return true;
      if (currentStep === 25 && item.path === "/payment") return true;
    }
    return false;
  };

  const renderNavItems = (navItems) => (
    <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: "none",
        p: 0,
        m: 0,
      }}
    >
      {navItems.map((item) => {
        const active = isActive(item);
        const isParentActive = item.children && openItems[item.title];
        const itemRef = active || isActiveDemoItem ? activeItemRef : null;
        const isChildActive =
          item.children &&
          item.children.some((child) => pathname === child.path);

        let newTitle = null;
        if (
          item.title === "Manage Subscription" &&
          props?.user?.subuser?.role === "Power User"
        ) {
          newTitle = "Subscription Details";
        } else {
          newTitle = null;
        }

        return (
          <React.Fragment key={item.title}>
            <SideNavItem
              ref={itemRef}
              active={active || isChildActive}
              disabled={item.disabled}
              external={item.external}
              icon={item.icon}
              path={item.path}
              title={newTitle ? newTitle : item.title}
              hasChildren={!!item.children}
              onClick={() => {
                if (item.children) handleToggle(item.title);
              }}
              isSubUser={props?.currentSession === "subUserSession"}
            />
            {item.children && (
              <Collapse in={openItems[item.title] || isChildActive}>
                <Stack
                  component="ul"
                  spacing={0.5}
                  sx={{ listStyle: "none", pl: 2 }}
                >
                  {item.children.map((subItem) => {
                    const subActive = pathname === subItem.path;
                    return (
                      <SideNavItem
                        key={subItem.title}
                        active={subActive}
                        disabled={subItem.disabled}
                        external={subItem.external}
                        icon={subItem.icon}
                        path={subItem.path}
                        title={subItem.title}
                        isSubUser={props?.currentSession === "subUserSession"}
                      />
                    );
                  })}
                </Stack>
              </Collapse>
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );

  const subUserNavSteps =
    props?.currentSession === "subUserSession"
      ? [0, 2, 5, 6]
      : [0, 2, 5, 7, 8, 21, 23, 25];

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ p: 3, pb: 1 }}>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "transparent",
            cursor: "pointer",
            display: "flex",
            textDecoration: "none",
            borderRadius: "27px",
            // p: "12px",
          }}
        >
          <div>
            <img
              style={{
                width: "7rem",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "-3px",
                marginBottom: "-13px",
              }}
              alt="Permit Rockstar Logo"
              src="/img/website_img/PermitRockstar.png"
            />
          </div>
        </Box>

        {props?.user?.role === "notary" ? (
          <Typography align="center" variant="h5" mt={1}>
            Notary
          </Typography>
        ) : (
          <Typography
            sx={{ fontStyle: "italic" }}
            align="center"
            variant="h6"
            mt={1}
          >
            Beta Release
          </Typography>
        )}
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        className={
          props?.currentSession === "userSession"
            ? currentStep === 0
              ? "step1"
              : currentStep === 2
              ? "step3"
              : currentStep === 5
              ? "step6"
              : currentStep === 7
              ? "step8"
              : currentStep === 8
              ? "step9"
              : currentStep === 21
              ? "step22"
              : currentStep === 23
              ? "step24"
              : currentStep === 25
              ? "step26"
              : ""
            : props?.currentSession === "subUserSession"
            ? currentStep === 0
              ? "step1"
              : currentStep === 2
              ? "step3"
              : currentStep === 5
              ? "step8"
              : currentStep === 6
              ? "step9"
              : // : currentStep === 8
                // ? "step9"
                // : currentStep === 21
                // ? "step22"
                // : currentStep === 23
                // ? "step24"
                // : currentStep === 25
                // ? "step26"
                ""
            : ""
        }
        component="nav"
        ref={scrollContainerRef}
        sx={
          subUserNavSteps.includes(currentStep) && isOpen
            ? scrollbarStyles
            : {
                overflowY: "auto",
                flexGrow: 1,
                px: 2,
                py: 3,
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }
        }
      >
        {props?.user?.role === "notary"
          ? renderNavItems(notaryItems)
          : renderNavItems(items)}
        {props?.user?.user?.role === "admin" && renderNavItems(adminItems)}
      </Box>
      {!hideTrial && (
        <SideNavAd
          handleMouseUp={handleMouseUp}
          handleMouseDown={handleMouseDown}
          handleMouseDownUp={handleMouseDownUp}
        />
      )}
      <Typography
        color="neutral.500"
        sx={{ textAlign: "center" }}
        variant="body2"
      >
        2023, All Rights Reserved
      </Typography>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.800",
            color: "common.white",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.800",
          color: "common.white",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default SideNav;
