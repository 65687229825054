import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  TextField,
  Stack,
  Button,
  IconButton,
  Box,
  useMediaQuery,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import TermsAndConditionsModal from "./TermsAndConditionsModal";

const AddPaymentCardModal = ({
  addPaymentModalOpen,
  setAddPaymentModalOpen,
  stripe,
  elements,
  CardElement,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);

  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorColor, setErrorColor] = useState("#f44336");
  const [open, setOpen] = useState(false);
  const [added, setAdded] = useState(false);

  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAddPaymentModalOpen(false);
        formik.resetForm();
        setErrorText("");
        setTerms(false);
        setTermsError(false);
        setAdded(false);
      }, 2000);
    }
  }, [added]);

  const createCard = async (name) => {
    try {
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("card"),
        billing_details: {
          name: name,
          email: currentUser?.subuser
            ? currentUser?.subuser?.email
            : currentUser?.user?.email,
        },
      });
      return paymentMethod;
    } catch (err) {
      setErrorText(err.message);
    }
  };

  const handleCloseTerms = () => {
    setOpen(false);
  };

  const CARD_OPTIONS = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000",

        fontWeight: "500",
        fontSize: "16px",
      },
    },
  };

  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      cardName: "",
      expiry: "",
      cvc: "",
    },
    validationSchema: yup.object({
      cardName: yup
        .string()
        .min(5, "Name must be at least 5 characters long")
        .max(25, "Name must be less than 25 characters long")
        .required("Cardholder name is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        setAdded(true);
        if (!terms) {
          setTermsError(true);
          setLoading(false);
          setErrorColor("#f44336");
          setAdded(false);
          return setErrorText("You must accept terms and conditions");
        }

        const card = await createCard(values["cardName"]);

        if (card.error) {
          setLoading(false);
          setAdded(false);
          setErrorColor("#f44336");
          return setErrorText(card.error.message);
        }

        const send_obj = {
          new_card: card,
          new_cardName: values["cardName"],
          old_card: {},
          add: true,
        };

        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/change-card`,
          {
            send_obj,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );
        if (resp.data.success) {
          setErrorText("Payment card has been added successfully!");
          setTermsError(false);
          setErrorColor("#4caf50");
          setLoading(false);
        } else {
          setAdded(false);
          setErrorColor("#f44336");
          return setErrorText(resp.data.message);
        }
      } catch (err) {
        setAdded(false);
        setErrorColor("#f44336");
        setErrorText(err.response.data.message);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        setLoading(false);
      }
    },
  });
  return (
    <>
      <Modal open={addPaymentModalOpen} sx={{ overflow: "auto" }}>
        <Box
          sx={{
            overflowY: "none",
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "90%",
            maxWidth: "50rem",
            bgcolor: "transparent",
            animation: "fadeIn 0.5s ease-in-out",
          }}
        >
          <Box
            sx={{
              maxWidth: "45rem",
              bgcolor: "background.paper",
              mx: "auto",
              my: "auto",
              border: "3px solid #d3d3d3",
              borderRadius: "18px",
              boxShadow: "2px 0px 10px 0px #d3d3d3",
              p: 3,
            }}
          >
            <form noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} sx={{ px: "2rem", py: "2rem" }}>
                <Stack direction="row" width="100%">
                  <Typography pl={2} mb={5} mt={3} variant="h5">
                    Add Credit Card
                  </Typography>
                  <IconButton
                    disabled={loading}
                    onClick={() => {
                      setAddPaymentModalOpen(false);
                      formik.resetForm();
                      setErrorText("");
                      setTerms(false);
                      setTermsError(false);
                      setAdded(false);
                    }}
                    sx={{ mb: 5, ml: "auto" }}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>

                <Grid item xs={12}>
                  <TextField
                    error={
                      !!(formik.touched.cardName && formik.errors.cardName)
                    }
                    fullWidth
                    helperText={
                      formik.touched.cardName && formik.errors.cardName
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.cardName}
                    name="cardName"
                    label="Cardholder Name*"
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    className="card-container"
                    style={{
                      border: "2px solid #f4f4f4",
                      cursor: "text",
                      padding: "1rem",
                      borderRadius: "10px",
                    }}
                  >
                    <CardElement options={CARD_OPTIONS} />
                  </div>
                </Grid>
                <Grid item xs={12} mt={-2}>
                  <FormControlLabel
                    sx={{ mt: "5px", mr: "auto" }}
                    control={
                      <Checkbox
                        checked={terms}
                        onChange={(e) => {
                          setTerms(e.target.checked);
                          setTermsError(!termsError);
                          setErrorText("");
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          textAlign: "center",
                          lineHeight: "3px",
                          color: termsError && errorText ? "red" : "default",
                        }}
                        color="text.secondary"
                        variant="body2"
                      >
                        I agree the{" "}
                        <Link
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setOpen(true);
                          }}
                          style={{
                            color: termsError && errorText ? "red" : "inherit",
                            fontWeight: "bold",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {" "}
                          Terms & Conditions
                        </Link>
                      </Typography>
                    }
                    size="sm"
                  />
                </Grid>
                <Grid item xs={12} mt={-2}>
                  <Typography
                    variant="body1"
                    color={errorColor}
                    fontWeight="bold"
                    textAlign="center"
                    sx={{ mt: "1rem", minHeight: "1.5rem" }}
                  >
                    {errorText}
                  </Typography>
                </Grid>
              </Grid>
              <Stack mx={4}>
                <LoadingButton
                  disabled={added}
                  loading={loading}
                  size="large"
                  sx={{ mt: 0, mb: 2 }}
                  type="submit"
                  variant="contained"
                >
                  Add
                </LoadingButton>
              </Stack>
            </form>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src="/img/logos/checkoutBadge.png"
                style={{
                  width: "100%",
                  maxWidth: "40rem",
                  height: "auto",
                  borderRadius: "20px",
                }}
              />
            </Box>
            <Typography align="center" color="GrayText">
              <span style={{ fontWeight: "bold" }}>Please Note:</span> Adding
              your card here does not initiate any charges. Your information
              will be securely stored for future transactions only.
            </Typography>
          </Box>
        </Box>
      </Modal>
      <TermsAndConditionsModal open={open} onClose={handleCloseTerms} />
    </>
  );
};

export default AddPaymentCardModal;
