import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUpgradeRequested } from "../../store/actions/dataActions";

const CurrentPlan = ({
  headerText,
  subscriptionType,
  startPeriod,
  endPeriod,
  tabletSize,
  mobileSize,
  miniSize,
  between905and1125,
  between1200and1405,
  between1400and1700,
  monthly,
  trial,
  freeUser,
  isSub,
}) => {
  const showImage =
    (!between905and1125 && !miniSize) ||
    (!between1400and1700 && monthly) ||
    (monthly && !between1400and1700);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Card
      className="step25"
      sx={{ minHeight: tabletSize ? undefined : "12.7rem" }}
    >
      <CardContent
        sx={{
          px: tabletSize ? 1.5 : between1200and1405 ? 1 : undefined,
          pt: mobileSize ? 1.5 : tabletSize ? 3 : undefined,
          pb: tabletSize ? 1.5 : undefined,
        }}
      >
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent={tabletSize ? "center" : "space-between"}
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              color="text.secondary"
              variant={tabletSize ? "h5" : "overline"}
            >
              Current Plan
            </Typography>
            <Typography variant="h6" sx={{ pb: 0, mb: 0, mt: 0, pt: 0 }}>
              {headerText}
            </Typography>
            <FormHelperText
              sx={{
                fontSize: "14px",
                pt: 0,
                mt: 0,
                pb: 0,
                mb: 0,
                lineHeight: "4px",
              }}
            >
              {trial?.has_trial
                ? "Trial subscription"
                : headerText === "Starter"
                ? "Free subscription"
                : `${
                    subscriptionType?.charAt(0).toUpperCase() +
                    subscriptionType?.slice(1)
                  }ly subscription`}
            </FormHelperText>
            <FormHelperText
              sx={{ fontSize: "14px", pt: 0, mt: 0, lineHeight: "40px" }}
            >
              {startPeriod && endPeriod ? `${startPeriod} - ${endPeriod}` : ""}
            </FormHelperText>
          </Stack>
          {showImage && (
            <Stack>
              <img
                alt="noc-form"
                src={
                  trial?.has_trial
                    ? "/img/dashboard/trialIcon.jpeg"
                    : "/img/dashboard/currentPlan.png"
                }
                style={{
                  maxWidth: mobileSize
                    ? "6rem"
                    : between1200and1405
                    ? "5.5rem"
                    : "6.5rem",
                  height: "auto",
                  margin: 0,
                }}
              />
            </Stack>
          )}
        </Stack>
        {freeUser && !isSub && (
          <Button
            onClick={() => {
              dispatch(setUpgradeRequested(true));
              navigate("/subscription");
            }}
            variant="contained"
            color="success"
            endIcon={<ArrowUpward />}
          >
            Upgrade
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default CurrentPlan;
