import { globalForms, countySpecificForms } from "../data/permitFormsData";

const preprocessName = (name) => {
  if (!name) return ""; // Handle cases where name might be undefined or null
  return name.toLowerCase().replace(/\s+/g, "_");
};

const convertToInitialName = (str) => {
  // Split the string by underscores
  const words = str.split("_");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words and add 'Creating' and 'Form'
  return `${capitalizedWords.join("")}Initials`;
};

export const loadInitials = async (creatingDoc, county, city) => {
  // Preprocess county and city names
  const processedCounty = preprocessName(county);
  const processedCity = preprocessName(city);

  // Check if the document is a global component
  if (globalForms.includes(creatingDoc)) {
    const module = await import(
      `../components/permitForms/global/initials/${convertToInitialName(
        creatingDoc
      )}`
    );
    return module.Initials;
  }

  // Check if the document is a county-specific component
  if (
    countySpecificForms[processedCounty] &&
    countySpecificForms[processedCounty].includes(creatingDoc)
  ) {
    const module = await import(
      `../components/permitForms/county_specific/${processedCounty}/initials/${convertToInitialName(
        creatingDoc
      )}`
    );
    return module.Initials;
  }

  // Otherwise, assume it's county/city-specific
  if (processedCity) {
    const module = await import(
      `../components/permitForms/${creatingDoc}/${processedCounty}/${processedCity}/Initials`
    );
    return module.Initials;
  }

  return null;
};
