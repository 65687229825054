import {
  addNewNotification,
  clearPdfProgress,
  getLiveNocData,
  getPdfProgress,
  pushCityLog,
  updatePermitPackageOnFly,
} from "../dataActions";
import {
  addSubUserFullDesignProfessionals,
  addSubUserURL,
  broadcastSubUserUpdate,
  getSubUserNotification,
  sendSubUserWebhookData,
  setInstantRonAccepted,
  setInstantRonStop,
  setInstantRonSuccess,
  updateNocSubUserTasks,
  updateNotarySubUserForms,
  updateSubUser,
  updateSubUserPaymentCards,
  updateAnalytics,
  updateSubAnalytics,
} from "../subUserActions";

import {
  updatePermitPackageFromReturn,
  updateNotification,
  updateNotificationCount,
} from "../dataActions";

export const subUserSessionOnMessage =
  (receivedData) => async (dispatch, getState) => {
    switch (receivedData.event) {
      case "instant_ron_accepted":
        const ronAcceptedUpdate = receivedData.data;
        if (ronAcceptedUpdate.success === true) {
          dispatch(setInstantRonAccepted());
          break;
        } else if (ronAcceptedUpdate.success === "stop") {
          dispatch(setInstantRonStop());
          break;
        } else if (ronAcceptedUpdate.success === "success") {
          dispatch(setInstantRonSuccess());
          break;
        }
        break;
      case "subUser_update":
        dispatch(updateSubUser(receivedData?.data));
        break;
      case "add_new_notification":
        dispatch(addNewNotification(receivedData?.data));
        break;
      case "notification_update":
        dispatch(updateNotification(receivedData?.data));
        break;
      case "update_notification_count":
        dispatch(updateNotificationCount(receivedData?.data));
        break;
      case "update_notary_subUser_forms":
        dispatch(updateNotarySubUserForms(receivedData.data));
        break;
      // case "subUser_recording_update":
      //   dispatch(sendSubUserWebhookData(receivedData?.data));
      //   break;
      case "update_subUser_payment_cards":
        dispatch(updateSubUserPaymentCards(receivedData?.data));
        break;
      case "update_noc_subUser_tasks":
        dispatch(updateNocSubUserTasks(receivedData?.data));
        break;
      case "broadcast_subusers":
        dispatch(broadcastSubUserUpdate(receivedData.data));
        break;
      // case "subUser_notification_update":
      //   dispatch(getSubUserNotification([receivedData.data]));
      //   break;
      case "broadcast":
        dispatch(getLiveNocData(receivedData.data));
        break;
      case "add_subUser_url_img":
        dispatch(addSubUserURL(receivedData.data?.data));
        break;
      case "update_pdf_progress":
        dispatch(getPdfProgress(receivedData.data));
        break;
      case "broadcast_design_professionals":
        dispatch(addSubUserFullDesignProfessionals(receivedData.data));
        break;
      case "update_permit_document":
        dispatch(updatePermitPackageFromReturn(receivedData.data));
        break;
      case "update_permit_package_on_fly":
        dispatch(updatePermitPackageOnFly(receivedData.data));
        break;
      case "push_city_log":
        dispatch(pushCityLog(receivedData.data));
        break;
      case "update_analytics":
        dispatch(updateAnalytics(receivedData.data));
        break;
      case "update_sub_analytics":
        console.log("SUB ANALYTICS", receivedData.data);
        dispatch(updateSubAnalytics(receivedData.data));
        break;
      default:
        return;
    }
  };
