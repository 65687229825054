import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ApexChart from "react-apexcharts";
import { styled } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SigneeRegisterInfo from "../popover/SigneeRegisterInfo";

const Chart = styled(ApexChart)``;

const useChartOptions = (mobileSize) => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: "#e8cc44",
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: mobileSize ? "15rem" : "25rem",
      },
    },
    stroke: {
      colors: ["transparent"],
      show: true,
      width: 2,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => (value > 0 ? `${value}` : `${value}`),
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };
};

const NocUsage = (props) => {
  const { chartSeries, sx, setYear, handleChartValues, year } = props;
  const chartOptions = useChartOptions(props?.mobileSize);

  const handleChange = (event) => {
    setYear(event.target.value);
    handleChartValues();
  };
  const title =
    "This chart shows an overview of Notice of Commencement usage history.";
  return (
    <Card sx={sx} style={{ position: "relative" }}>
      {!props?.fromSub && props?.tabletSize ? (
        <IconButton
          onClick={() => props?.setUsageTitle(!props?.usageTitle)}
          sx={{ position: "absolute", right: 0, top: 0 }}
        >
          <HelpOutlineIcon
            color="primary"
            sx={{ position: "absolute", right: 10, top: 10 }}
          />
        </IconButton>
      ) : (
        !props?.fromSub && (
          <Tooltip title={title} placement="top">
            <HelpOutlineIcon
              color="primary"
              sx={{ position: "absolute", right: 10, top: 10 }}
            />
          </Tooltip>
        )
      )}

      {props?.usageTitle && (
        <SigneeRegisterInfo
          infoText={title}
          isFadingOut={props?.isFadingOut}
          top={35}
        />
      )}

      <CardHeader title="Notice of Commencement Usage" />
      <CardContent
        sx={{
          px: props?.mobileSize ? 0 : undefined,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 35,
            right: props?.mobileSize ? 5 : 30,
            width: props?.mobileSize ? "9rem" : "8rem",
          }}
        >
          {props?.nocYears?.length > 0 && (
            <FormControl fullWidth variant="filled">
              <InputLabel>Select year</InputLabel>
              <Select value={year} label="select" onChange={handleChange}>
                {props?.nocYears.map(function (item, i) {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Box>
        <Chart
          height={350}
          options={chartOptions}
          series={chartSeries}
          type="bar"
          width="100%"
        />
      </CardContent>
    </Card>
  );
};

export default NocUsage;
