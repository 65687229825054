import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const TermsAndConditionsModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Terms and Conditions</DialogTitle>
      <DialogContent dividers>
        <Typography fontWeight="bold" variant="body1">
          1. Introduction
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Welcome to [Your Website Name] (the "Website"). These Terms and
          Conditions ("Terms") govern your use of the Website. By accessing or
          using the Website, you agree to be bound by these Terms. If you do not
          agree with these Terms, please refrain from using the Website.
        </Typography>

        <Typography fontWeight="bold" variant="body1">
          2. Intellectual Property
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          The content, design, and all intellectual property rights associated
          with the Website are owned by [Your Company Name] or its licensors.
          You are not allowed to reproduce, modify, or distribute any content
          from the Website without prior written consent.
        </Typography>

        <Typography fontWeight="bold" variant="body1">
          3. User Conduct
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          You agree to use the Website only for lawful purposes and in
          compliance with these Terms. Prohibited activities include but are not
          limited to:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Violating any applicable laws or regulations" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Uploading harmful or malicious content" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Interfering with the proper functioning of the Website" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Engaging in any unauthorized data collection or harvesting" />
          </ListItem>
        </List>

        <Typography fontWeight="bold" variant="body1">
          4. Disclaimer of Warranties
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          The Website is provided on an "as-is" and "as available" basis. [Your
          Company Name] makes no warranties, express or implied, regarding the
          Website's accuracy, availability, or reliability. Your use of the
          Website is at your own risk.
        </Typography>

        <Typography fontWeight="bold" variant="body1">
          5. Limitation of Liability
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          In no event shall [Your Company Name] be liable for any direct,
          indirect, incidental, special, or consequential damages arising out of
          or in connection with the use of the Website or its content.
        </Typography>

        <Typography fontWeight="bold" variant="body1">
          6. Third-Party Links
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          The Website may contain links to third-party websites. [Your Company
          Name] is not responsible for the content or practices of these linked
          websites. Accessing third-party links is at your own risk.
        </Typography>

        <Typography fontWeight="bold" variant="body1">
          7. Modifications
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          [Your Company Name] reserves the right to modify or update these Terms
          at any time without notice. Your continued use of the Website after
          any modifications indicate your acceptance of the updated Terms.
        </Typography>

        <Typography fontWeight="bold" variant="body1">
          8. Governing Law
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          These Terms shall be governed by and construed in accordance with the
          laws of [Your Country/State], without regard to its conflict of laws
          principles.
        </Typography>

        <Typography fontWeight="bold" variant="body1">
          9. Severability
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          If any provision of these Terms is found to be invalid or
          unenforceable, the remaining provisions shall remain in full force and
          effect.
        </Typography>

        <Typography fontWeight="bold" variant="body1">
          10. Contact Us
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          If you have any questions or concerns regarding these Terms, please
          contact us at [Your Contact Email].
        </Typography>

        <Typography variant="body2" sx={{ mb: 2 }}>
          Last updated: [Date]
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsModal;
