// PromoDialogContent.js
import React, { useState } from "react";
import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { updateMyPermitsState } from "../../../store/actions/pageActions/myPermitsActions";
import { useDispatch } from "react-redux";

const Pop1 = ({ handleTransition, textAnimationState, paymentState }) => {
  const dispatch = useDispatch();
  return (
    <>
      <DialogTitle
        color="primary"
        variant="h4"
        id="responsive-dialog-title"
        sx={{ textAlign: "center" }}
      >
        Don't Miss Out on This Offer!
      </DialogTitle>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img
          alt="avatar_circle"
          src="/img/website_img/pp_avatar_circle.png"
          style={{ width: "15rem", height: "auto", zIndex: 2 }}
        />
      </Box>
      <DialogContent sx={{ px: 2 }}>
        <Box className={textAnimationState}>
          {paymentState ? <></> : <></>}
          <DialogContentText sx={{ mb: 3, mt: 2, textAlign: "center" }}>
            You can download the NOC and spend the next few hours driving around
            town chasing signatures, searching for parking, standing in line to
            record the NOC and then driving back to your office to scan it and
            upload it to the City.
          </DialogContentText>

          <DialogContentText
            color="primary"
            sx={{ mb: 3, textAlign: "center", fontWeight: "bold" }}
          >
            Or . . .
          </DialogContentText>

          <DialogContentText
            color="primary"
            sx={{ mb: 6, textAlign: "center", fontWeight: "bold", px: 1 }}
          >
            You can let Permit Rockstar get the NOC notarized and recorded for{" "}
            <span style={{ fontSize: "26px", color: "#10b981" }}>Only</span>{" "}
            <span
              style={{
                textDecoration: "line-through",
                color: "red",
                fontSize: "20px",
              }}
            >
              $99
            </span>{" "}
            <span style={{ fontSize: "30px", color: "#10b981" }}>$</span>
            <span style={{ fontSize: "36px", color: "#10b981" }}>69</span>
          </DialogContentText>
        </Box>

        <Box
          sx={{
            gap: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Button
            color="success"
            onClick={() =>
              dispatch(updateMyPermitsState({ paymentModalOpen: true }))
            }
            fullWidth
            variant="contained"
          >
            LET'S DO IT
          </Button>
          <Button
            onClick={() => handleTransition("pop2")}
            color="error"
            fullWidth
          >
            No, I prefer to waste my time and do this the old-fashioned way
          </Button>
        </Box>
      </DialogContent>
    </>
  );
};

export default Pop1;
