import { Box, Chip, Grid, Stack, Typography } from "@mui/material";

// project import
import MainCard from "./MainCard";

// assets
import { RiseOutlined, FallOutlined } from "@ant-design/icons";
import { handleUserTexts } from "./analyticsCardTexts";

const Analytics = ({
  color,
  title,
  count,
  percentage,
  isMore,
  extra,
  texts,
  lastMonthCount,
}) => {
  return (
    <MainCard contentSX={{ p: 2.25 }} sx={{ minHeight: "13.6rem" }}>
      <Stack spacing={0.5}>
        <div style={{ minHeight: "3.5rem" }}>
          <Typography variant="h6" align="center" color="textSecondary">
            {title}
          </Typography>
        </div>

        <Grid container alignItems="center">
          <Grid item sx={{ ml: "auto" }}>
            {title === "Average NOC Creation Time" ? (
              <>
                <Typography variant="h4" color="inherit">
                  {Math.floor(count)}{" "}
                  <span style={{ fontSize: "16px" }}>Sec</span>
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h4" color="inherit">
                  {count}
                </Typography>
              </>
            )}
          </Grid>
          {percentage && (
            <Grid item sx={{ mr: "auto" }}>
              <Chip
                variant="combined"
                color={
                  color === "success"
                    ? "success"
                    : color === "error"
                    ? "error"
                    : "default"
                }
                icon={
                  <>
                    {isMore === true && (
                      <RiseOutlined
                        style={{ fontSize: "0.75rem", color: "inherit" }}
                      />
                    )}
                    {isMore === false && (
                      <FallOutlined
                        style={{ fontSize: "0.75rem", color: "inherit" }}
                      />
                    )}
                    {isMore === 0 && (
                      <FallOutlined
                        style={{ fontSize: "0.75rem", color: "inherit" }}
                      />
                    )}
                  </>
                }
                label={percentage === "N/A" ? percentage : `${percentage}%`}
                sx={{ ml: 1.25, pl: 1 }}
                size="small"
              />
            </Grid>
          )}
        </Grid>
      </Stack>
      <Box sx={{ pt: 2.25 }}>
        {handleUserTexts(extra, isMore, color, texts, lastMonthCount, title)}
      </Box>
    </MainCard>
  );
};

export default Analytics;
