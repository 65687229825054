import { guestSessionOnMessage } from "../actions/onMessageActions/guestSessions";
import { noneSessionOnMessage } from "../actions/onMessageActions/noneSessions";
import { notarySessionOnMessage } from "../actions/onMessageActions/notarySessions";
import { sessionsOnMessage } from "../actions/onMessageActions/sessions";
import { userSessionOnMessage } from "../actions/onMessageActions/userSessions";
import { subUserSessionOnMessage } from "../actions/onMessageActions/subUserSessions";
import { updateSessionWsConnection } from "../actions/sessionsActions";

const onMessageActions = {
  guestSession: guestSessionOnMessage,
  notarySession: notarySessionOnMessage,
  userSession: userSessionOnMessage,
  subUserSession: subUserSessionOnMessage,
  sessions: sessionsOnMessage,
  noneSession: noneSessionOnMessage,
};

const createWebSocketMiddleware = (sessionType) => {
  let socket = null;
  let reconnectTimer = null;
  let reconnectAttempts = 0;
  const maxReconnectAttempts = 6;
  let connectionTimeout = 15000; // 10 seconds timeout for connection
  const reconnectDelay = 5000; // 5 seconds delay for reconnection
  let connectionTimer = null;

  const getSessionToken = (state, sessionType) => {
    switch (sessionType) {
      case "userSession":
        return state.sessions?.userSession.token;
      case "notarySession":
        return state.sessions?.notarySession.token;
      case "guestSession":
        return state.sessions?.guestSession.token;
      case "subUserSession":
        return state.sessions?.subUserSession.token;
      default:
        return null; // or appropriate error handling
    }
  };

  const handleWebSocketMessage = async (dispatch, getState, receivedData) => {
    const sessionToken = getSessionToken(getState(), sessionType);
    const actionCreator = onMessageActions[receivedData.type];

    if (actionCreator) {
      if (
        receivedData.type === sessionType ||
        receivedData.type === "sessions" ||
        receivedData.type === "noneSession"
      ) {
        if (
          receivedData?.event === "heartbeat" &&
          receivedData.type === sessionType
        ) {
          if (receivedData.token === sessionToken) {
            const exp = sessionStorage.getItem("exp");
            const extended = sessionStorage.getItem("extended");

            // console.log("receivedData", receivedData?.data);

            if (receivedData.data.will_expire_soon && !extended) {
              // console.log("Session will expire soon...");
              sessionStorage.setItem("willExpire", "true");
              const event = new Event("expireUpdate");
              window.dispatchEvent(event);
            } else if (!receivedData.data.will_expire_soon) {
              // console.log("Session extension confirmed...");
              sessionStorage.removeItem("willExpire");
              sessionStorage.removeItem("extended");
            }

            if (receivedData.data.session_expired && !extended) {
              // console.log("Session expired...");
              sessionStorage.setItem("expiredSession", "true");
              sessionStorage.clear(); // Clear session storage on expiration

              await dispatch({
                type: `${sessionType.toUpperCase()}_WEBSOCKET_DISCONNECT`,
              });
              window.location.href = "/login";
              return;
            }

            // Echo back the heartbeat message
            const response = JSON.stringify({
              type: receivedData.type, // Echo back the received type
              token: receivedData.token, // Echo back the token for verification
              extended: extended,
              exp: exp,
              timestamp: new Date().toISOString(),
            });

            try {
              socket.send(response);
            } catch (e) {
              console.error("Failed to send heartbeat response:", e);
            }
          } else {
            console.error(
              `Received token does not match stored token. Received: ${receivedData.token}, Expected: ${sessionToken}`
            );
          }
        } else {
          await dispatch(actionCreator(receivedData));
        }
      } else {
        // Handle unexpected session types if needed
      }
    } else {
      console.error("No action creator found for type:", receivedData.type);
    }
  };

  const connectWebSocket = (dispatch, getState, url, token) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      console.warn("WebSocket already open; skipping reconnection.");
      return;
    }
    if (socket !== null && socket.readyState !== WebSocket.CLOSED) {
      socket.close();
    }
    socket = new WebSocket(url);

    connectionTimer = setTimeout(() => {
      if (socket && socket.readyState === WebSocket.CONNECTING) {
        console.error(
          "WebSocket connection timed out. Attempting to reconnect..."
        );
        socket.close();
        reconnectWebSocket(dispatch, getState, url, token);
      }
    }, connectionTimeout);

    socket.onopen = () => {
      clearInterval(reconnectTimer);
      clearTimeout(connectionTimer);
      reconnectAttempts = 0;
      dispatch(
        updateSessionWsConnection({
          sessionType: sessionType,
          wsConnected: true,
        })
      );
    };

    socket.onmessage = (event) => {
      const receivedData = JSON.parse(event.data);
      handleWebSocketMessage(dispatch, getState, receivedData);
    };

    socket.onerror = (error) => {
      console.error(`${sessionType} WebSocket error:`, error);
    };

    socket.onclose = (event) => {
      clearTimeout(connectionTimer);
      if (event.code !== 1000 && reconnectAttempts < maxReconnectAttempts) {
        reconnectWebSocket(dispatch, getState, url, token);
      } else {
        window.location.href = "/login";
      }
    };
  };

  // const reconnectWebSocket = (dispatch, getState, url, token) => {
  //   reconnectAttempts++;
  //   if (reconnectAttempts <= maxReconnectAttempts) {
  //     reconnectTimer = setTimeout(
  //       () => connectWebSocket(dispatch, getState, url, token),
  //       reconnectDelay
  //     );
  //   } else {
  //     console.error("Max reconnect attempts reached. Redirecting to login.");
  //     window.location.href = "/login";
  //   }
  // };
  const reconnectWebSocket = (dispatch, getState, url, token) => {
    const { wsConnected } = getState().sessions[sessionType];

    if (!wsConnected) {
      // console.log(
      //   "WebSocket is deliberately disconnected. Skipping reconnection."
      // );
      return; // Prevent reconnect attempts if WebSocket was manually disconnected
    }

    reconnectAttempts++;
    if (reconnectAttempts <= maxReconnectAttempts) {
      const delay = Math.min(reconnectDelay * 2 ** reconnectAttempts, 30000); // Exponential backoff
      reconnectTimer = setTimeout(
        () => connectWebSocket(dispatch, getState, url, token),
        delay
      );
    } else {
      console.error("Max reconnect attempts reached. Redirecting to login.");
      window.location.href = "/login";
    }
  };
  return (store) => (next) => (action) => {
    const { dispatch, getState } = store;
    // Extract the session type from the action type
    const actionPrefix = action?.type?.split("_")[0].toLowerCase();

    // Only handle actions that match this middleware's sessionType
    if (actionPrefix !== sessionType.toLowerCase()) {
      return next(action);
    }

    // Process only the actions for this sessionType
    switch (action.type) {
      case `${sessionType.toUpperCase()}_WEBSOCKET_CONNECT`:
        connectWebSocket(
          dispatch,
          getState,
          action.payload.url,
          action.payload.token
        );
        break;
      case `${sessionType.toUpperCase()}_WEBSOCKET_DISCONNECT`:
        // console.log("socket", socket);
        if (socket !== null) {
          socket.onclose = null;
          socket.close();
          socket = null;
        }
        dispatch(
          updateSessionWsConnection({
            sessionType: sessionType,
            wsConnected: false,
          })
        );
        clearInterval(reconnectTimer);
        clearTimeout(connectionTimer);
        reconnectAttempts = 0;
        // console.log("reconnectTimer", reconnectTimer);
        break;
      case `${sessionType.toUpperCase()}_WEBSOCKET_SEND_MESSAGE`:
        if (socket && socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify(action.payload.message));
        }
        break;
      default:
        return next(action);
    }
  };
};

export default createWebSocketMiddleware;
