import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Drawer,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";

const EmailDrawer = ({ open, setOpen, setSubmitLoading, formik }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [inputValues, setInputValues] = useState(["", "", "", "", ""]);
  const [isPasting, setIsPasting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (index, value) => {
    if (!isPasting && value.length > 1) {
      value = value.slice(0, 1); // Allow only one character
    }
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value && index < 4) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (!value && index > 0) {
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");

    if (pasteData.length === 5) {
      const newInputValues = pasteData.split("");
      setInputValues(newInputValues);
      setIsPasting(true);
      setTimeout(() => {
        setIsPasting(false);
      }, 0);
      event.preventDefault(); // Prevent default paste behavior
    }
  };

  const handleVerifyEmail = async (values) => {
    setIsLoading(true);

    if (
      inputValues[0] === "" ||
      inputValues[1] === "" ||
      inputValues[2] === "" ||
      inputValues[3] === "" ||
      inputValues[4] === ""
    ) {
      setErrorMsg("Please enter the verification code sent to your email");
      setIsLoading(false);
      return;
    }

    const emailAddress = values["email"];
    const code =
      inputValues[0] +
      inputValues[1] +
      inputValues[2] +
      inputValues[3] +
      inputValues[4];

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/email/checkverify`,
        {
          code,
          emailAddress,
        },
        { withCredentials: true }
      );

      if (resp.data.success) {
        formik.handleSubmit();
        setSubmitLoading(true);
        setOpen(false);
        setIsLoading(false);
        setInputValues(["", "", "", "", ""]);
      } else {
        setErrorMsg(resp.data.message);
        setInputValues(["", "", "", "", ""]);
        setIsLoading(false);
      }
    } catch (e) {
      setErrorMsg(e.response.data.message);
      setIsLoading(false);
      setInputValues(["", "", "", "", ""]);
    }
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box display="flex" justifyContent="center">
        <img
          style={{
            width: "10rem",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "2rem",
          }}
          alt="Permit Rockstar Logo"
          src="/img/website_img/PermitRockstar.png"
        />
      </Box>
      <Box p={isSmallScreen ? 1 : 10} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Verify Your Email
        </Typography>
        <Typography
          sx={{ mb: 2 }}
          variant="body1"
          color="textSecondary"
          fontWeight="bold"
          gutterBottom
        >
          Please enter the verification code sent to your email.
        </Typography>
        <Grid container spacing={2}>
          {inputValues.map((value, index) => (
            <Grid item key={index} sx={{ mx: "auto" }}>
              <TextField
                id={`input-${index}`}
                value={value}
                variant="outlined"
                onChange={(e) => handleInputChange(index, e.target.value)}
                onPaste={index === 0 ? handlePaste : undefined}
                inputProps={{
                  maxLength: 1,
                  style: { fontSize: "28px", fontWeight: "bold" },
                }}
                sx={{
                  width: "48px",
                  textAlign: "center",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "rgba(0, 0, 0, 0.87)", // Border color when not focused
                    },
                    "&:hover fieldset": {
                      borderColor: "rgba(0, 0, 0, 0.87)", // Darker border on hover when not focused
                    },
                  },
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }} mt={3}>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign="center"
            color="#CDAF0B"
            mb={2}
            sx={{ minHeight: "1.5rem" }}
          >
            {errorMsg}
          </Typography>
          <LoadingButton
            sx={{ minWidth: "9rem" }}
            variant="contained"
            color="primary"
            onClick={() => handleVerifyEmail(formik.values)}
            loading={isLoading}
          >
            Verify Email
          </LoadingButton>
          <Button
            sx={{ minWidth: "9rem" }}
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default EmailDrawer;
