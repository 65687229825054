import { Box, ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShowTourNav } from "../../store/actions/dataActions";
import { useTour } from "@reactour/tour";
import { tourSteps } from "../../data/walkthroughDemo/steps";
import { forwardRef } from "react";

export const SideNavItem = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession]?.user);

  const {
    active = false,
    disabled,
    icon,
    path,
    title,
    onClick,
    hasChildren,
    isSubUser,
  } = props;

  const { isOpen, setIsOpen, currentStep, setCurrentStep } = useTour();

  const isSubuser = currentUser && currentUser.subuser;

  if (isSubuser && path === "/subusers") return null;
  if (
    currentUser?.tier?.monthly_restart === true &&
    path === "/permits/invitations"
  ) {
    return null;
  }
  if (
    isSubuser &&
    path === "/payment" &&
    currentUser?.subuser.role === "Basic User"
  )
    return null;
  if (
    isSubuser &&
    path === "/subscription" &&
    currentUser?.subuser.role === "Basic User"
  )
    return null;

  if (
    ["Basic User", "Power User"].includes(currentUser?.subuser?.role) &&
    path === "/contractors"
  )
    return null;

  const navDemoStyle = (path) => {
    if (isOpen) {
      if (isSubUser) {
        if (currentStep === 0 && path === "/selectcounties") {
          return { border: "2px solid #fff", borderRadius: "20px" };
        } else if (currentStep === 2 && path === "/defaults") {
          return { border: "2px solid #fff", borderRadius: "20px" };
        } else if (currentStep === 5 && title === "Permits Hub") {
          return { border: "2px solid #fff", borderRadius: "20px" };
        } else if (currentStep === 6 && path === "/permits/create") {
          return { border: "2px solid #fff", borderRadius: "20px" };
        }
      } else {
        if (currentStep === 0 && path === "/selectcounties") {
          return { border: "2px solid #fff", borderRadius: "20px" };
        } else if (currentStep === 2 && path === "/defaults") {
          return { border: "2px solid #fff", borderRadius: "20px" };
        } else if (currentStep === 5 && path === "/contractors") {
          return { border: "2px solid #fff", borderRadius: "20px" };
        } else if (currentStep === 7 && title === "Permits Hub") {
          return { border: "2px solid #fff", borderRadius: "20px" };
        } else if (currentStep === 8 && path === "/permits/create") {
          return { border: "2px solid #fff", borderRadius: "20px" };
        } else if (currentStep === 21 && path === "/subusers") {
          return { border: "2px solid #fff", borderRadius: "20px" };
        } else if (currentStep === 23 && path === "/subscription") {
          return { border: "2px solid #fff", borderRadius: "20px" };
        } else if (currentStep === 25 && path === "/payment") {
          return { border: "2px solid #fff", borderRadius: "20px" };
        }
      }
    }
  };

  const handleClick = () => {
    if (isOpen) {
      if (isSubUser) {
        if (currentStep === 0 && path !== "/selectcounties") {
          return;
        } else if (currentStep === 2 && path !== "/defaults") {
          return;
        } else if (currentStep === 5 && title !== "Permits Hub") {
          return;
        } else if (currentStep === 6 && path !== "/permits/create") {
          return;
        }
      } else {
        if (currentStep === 0 && path !== "/selectcounties") {
          return;
        } else if (currentStep === 2 && path !== "/defaults") {
          return;
        } else if (currentStep === 5 && path !== "/contractors") {
          return;
        } else if (currentStep === 7 && title !== "Permits Hub") {
          return;
        } else if (currentStep === 8 && path !== "/permits/create") {
          return;
        } else if (currentStep === 21 && path !== "/subusers") {
          return;
        } else if (currentStep === 23 && path !== "/subscription") {
          return;
        } else if (currentStep === 25 && path !== "/payment") {
          return;
        }
      }
    }

    if (isSubUser) {
      if (path === "/selectcounties") {
        setTimeout(() => {
          setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
        }, 200);
      } else if (path === "/defaults") {
        if (currentUser?.user?.selectedCountiesNOC.length === 0) {
          setTimeout(() => {
            setCurrentStep(1);
          }, 200);
        } else {
          setTimeout(() => {
            setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
          }, 200);
        }
      } else if (title === "Permits Hub") {
        setTimeout(() => {
          setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
        }, 200);
      } else if (path === "/permits/create") {
        setTimeout(() => {
          setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
        }, 200);
      }
    } else {
      if (path === "/selectcounties") {
        setTimeout(() => {
          setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
        }, 200);
      } else if (path === "/defaults") {
        if (currentUser?.user?.selectedCountiesNOC.length === 0) {
          setTimeout(() => {
            setCurrentStep(1);
          }, 200);
        } else {
          setTimeout(() => {
            setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
          }, 200);
        }
      } else if (path === "/contractors") {
        setTimeout(() => {
          setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
        }, 200);
      } else if (title === "Permits Hub") {
        setTimeout(() => {
          setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
        }, 200);
      } else if (path === "/permits/create") {
        setTimeout(() => {
          setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
        }, 200);
      } else if (path === "/subusers") {
        setTimeout(() => {
          setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
        }, 200);
      }
    }
    //  else if (path === "/payment") {
    //   setTimeout(() => {
    //     setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
    //   }, 1000);
    // }

    if (hasChildren) {
      if (onClick) onClick();
    } else {
      navigate(path);
    }
  };

  return (
    <li style={navDemoStyle(path)}>
      <ButtonBase
        ref={ref}
        sx={{
          alignItems: "center",
          borderRadius: 1,
          display: "flex",
          justifyContent: "flex-start",
          pl: "16px",
          pr: "16px",
          py: "6px",
          textAlign: "left",
          width: "100%",
          ...(active && {
            backgroundColor: "rgba(255, 255, 255, 0.04)",
          }),
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.04)",
          },
        }}
        onClick={handleClick}
      >
        {icon && (
          <Box
            component="span"
            sx={{
              alignItems: "center",
              color: "neutral.400",
              display: "inline-flex",
              justifyContent: "center",
              mr: 2,
              ...(active && {
                color: "#fff",
              }),
            }}
          >
            {icon}
          </Box>
        )}
        <Box
          component="span"
          sx={{
            color: "neutral.400",
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: "24px",
            whiteSpace: "nowrap",
            ...(active && {
              color: "common.white",
            }),
            ...(disabled && {
              color: "neutral.500",
            }),
          }}
        >
          {title}
        </Box>
      </ButtonBase>
    </li>
  );
});
