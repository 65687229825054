export const setLocalStorageItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
  return {
    type: "SET_LOCAL_STORAGE_ITEM",
    payload: { key, value },
  };
};

export const removeLocalStorageItem = (key) => {
  localStorage.removeItem(key);
  return {
    type: "REMOVE_LOCAL_STORAGE_ITEM",
    payload: key,
  };
};
