import {
  Box,
  Card,
  Grid,
  useMediaQuery,
  CircularProgress,
  CardHeader,
  Typography,
  IconButton,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useFormik } from "formik";
import * as Yup from "yup";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LottieAnimation from "../../animation/LottieAnimation";
import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import ContractorCard from "../permitComponents/ContractorCard";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useLocation } from "react-router-dom";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { countiesForLicense, license_types_suffix } from "../../data/dbpr";
import { LoadingButton } from "@mui/lab";
import {
  addContractor,
  updateContractor,
} from "../../store/actions/userActions";
import {
  setPrevTourStep,
  setShowTourNav,
} from "../../store/actions/dataActions";
import { useTour } from "@reactour/tour";
import {
  checkLicenseHolderMatch,
  convertIsoToLocalFormat,
} from "../../functions/helperFunctions";

const Contractors = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  //? SCREEN SIZES ##################################################################
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largeTabletSize = useMediaQuery(theme.breakpoints.down("lg"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  //? ###############################################################################

  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);

  const [fromPermit, setFromPermit] = useState(location.state);

  const [dataLoading, setDataLoading] = useState(true);
  const [contractors, setContractors] = useState([]);
  const [foundContractor, setFoundContractor] = useState(null);
  const [cardPage, setCardPage] = useState(1);
  const [licenseNumber, setLicenseNumber] = useState("");
  const [userList, setUserList] = useState([]);
  const [licenseInputError, setLicenseInputError] = useState("");
  const [addContractorError, setAddContractorError] = useState("");
  const [licenseOnError, setLicenseOnError] = useState(false);
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [addContractorLoading, setAddContractorLoading] = useState(false);

  const [showLicenseInput, setShowLicenseInput] = useState(false);
  const [showAddInput, setShowAddInput] = useState(false);
  const [addNewActive, setAddNewActive] = useState(false);
  const [licenseeMatch, setLicenseeMatch] = useState(false);
  const { isOpen, currentStep } = useTour();
  const [fetching, setFetching] = useState(false);
  const [currentContractor, setCurrentContractor] = useState({});
  const [maxPage, setMaxPage] = useState(0);
  const [editLicense, setEditLicense] = useState(false);
  const [editUserList, setEditUserList] = useState([]);

  console.log("licenseMatch", licenseeMatch);

  const ErrorTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      fontWeight: "bold",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.error.main,
    },
  }));
  console.log("currentContractor", currentContractor);

  useEffect(() => {
    if (
      currentUser?.user?.pre_license &&
      currentUser?.user?.contractors?.length === 0
    ) {
      setAddNewActive(true);
      setShowLicenseInput(true);
      setSearchDisabled(false);
      setLicenseNumber(currentUser?.user?.pre_license);
    }
  }, []);

  useEffect(() => {
    if (currentUser?.user?.expired === true) {
      navigate("/subscription");
    }

    if (currentSession === "subUserSession") {
      navigate("/dashboard");
    }
  }, [currentUser]);

  const handleNavigate = (direction) => {
    let newPage;
    if (direction === "forward") {
      newPage = cardPage + 1;
    } else if (direction === "back") {
      newPage = cardPage - 1;
    }

    setCurrentContractor(currentUser?.user?.contractors?.[newPage - 1]);
    contractorFormik.setValues(currentUser?.user?.contractors?.[newPage - 1]);
    setCardPage(newPage);
  };

  const validateLicenseNumberPrefix = (licenseNumber) => {
    // Extract the prefix part from the license number
    const prefix = licenseNumber.match(/^[A-Za-z]+/);

    if (prefix) {
      const prefixStr = prefix[0];
      // Check if the extracted prefix exists in the license_types_suffix
      for (let key in license_types_suffix) {
        if (license_types_suffix[key].includes(prefixStr)) {
          return true;
        }
      }
    }
    return false;
  };

  {
    console.log("editLicense", editLicense);
  }

  const handleEditContractor = (formik) => {
    setAddNewActive(true);
    setFoundContractor(true);
    setShowAddInput(true);
    setEditLicense(true);
    setAddContractorError("");
  };

  const handleAssignToChange = (e, formik) => {
    const { value, name } = e.target;
    formik?.setFieldValue(name, value);
  };

  const handleAddClick = () => {
    console.log(searchLoading);
    if (searchLoading || addContractorLoading) {
      return;
    }
    if (editLicense) {
      setAddNewActive(false);
      setFoundContractor(false);
      setShowAddInput(false);
      setEditLicense(false);
    }

    if (showLicenseInput || addNewActive) {
      setLicenseNumber("");
      setShowAddInput(false);
      setFoundContractor(null);
      setAddNewActive(false);
      setShowLicenseInput(false);
      if (currentUser?.user?.contractors.length > 0) {
        contractorFormik?.setValues(
          currentUser?.user?.contractors[cardPage - 1]
        );
      } else {
        contractorFormik.resetForm();
      }
    }
    // else if (addNewActive) {
    //   setAddNewActive(false);
    // }
    else {
      contractorFormik.resetForm();
      setAddNewActive(true);
      setShowLicenseInput(true);
    }
  };

  const handleLicenseNumberInputChange = (event) => {
    // Replace letters with capital letters
    const value = event?.target.value.toUpperCase();
    setLicenseInputError("");

    // Update Formik value using setFieldValue
    setLicenseNumber(value);

    if (value.length >= 7 && value?.length <= 12) {
      setSearchDisabled(false);
      setLicenseOnError(false);
    } else {
      setSearchDisabled(true);
      setLicenseOnError(true);
    }
  };

  const handleUpdateLicense = async () => {
    try {
      setFetching(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/sync-latest-license`,
        {
          license_number:
            currentUser?.user?.contractors?.length > 0
              ? currentUser?.user?.contractors?.[cardPage - 1]?.licenseNumber
              : null,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );
      contractorFormik?.setValues(resp.data.data);
      // contractorFormik?.setFieldValue("last_sync", resp?.data?.data);
      setCurrentContractor(resp.data.data);
      await dispatch(updateContractor(resp.data.data));

      console.log("resp", resp.data);
    } catch (error) {
    } finally {
      setFetching(false);
    }
  };

  // CONTRACTOR FORMIK ///
  const handlePhoneChange = (event) => {
    const { value } = event.target;

    if (value.length > 12) {
      return;
    }
    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    // setFormattedPhone(formattedPhoneNumber); // Update the formatted phone in state
    contractorFormik.setValues((oldValue) => ({
      ...oldValue,
      mobile: formattedPhoneNumber,
    })); // Update formik field value
  };

  console.log(currentUser?.user?.email);
  const handleSearchLicense = async () => {
    if (!validateLicenseNumberPrefix(licenseNumber)) {
      setLicenseInputError("License Number is Invalid");
      setLicenseOnError(true);
      return;
    }

    try {
      setSearchLoading(true);

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/validate-license`,
        {
          license_number: licenseNumber,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      if (resp?.data?.success) {
        setFoundContractor(resp?.data?.data);
        setShowAddInput(true);
        setShowLicenseInput(false);
        const cData = resp?.data?.data;

        // Determine what value should be set as `selectedUser`
        let selectedUserValue = currentUser?.user?.email;
        if (!userList.some((user) => user.email === selectedUserValue)) {
          selectedUserValue = ""; // Set to an empty value if the current user's email is not in the available list
        }

        contractorFormik?.setValues((prevValue) => ({
          ...prevValue,
          id: cData?.id,
          selectedUser: selectedUserValue,
          middleName: cData?.middle_name,
          firstName: cData?.first_name,
          lastName: cData?.last_name,
          // firstName: "Kelly",
          // lastName: "Belly",
          businessName: cData?.dba_name,
          licenseType: cData?.license_type,
          licenseNumber: cData?.license_number,
          rank: cData?.rank,
          status: cData?.status,
          expires: cData?.expires,
          mainAddress: `${cData?.address_parts?.part_1} ${cData?.address_parts?.part_2} ${cData?.address_parts?.part_3} ${cData?.address_parts?.city}, ${cData?.address_parts?.state} ${cData?.address_parts?.zip}`,
          mainAddressCounty: cData?.county,
          address1: cData?.address_parts?.part_1,
          address2: cData?.address_parts?.part_2,
          address3: cData?.address_parts?.part_3,
          city: cData?.address_parts?.city,
          state: cData?.address_parts?.state,
          zip: cData?.address_parts?.zip,
          email: cData?.email
            ? cData?.email
            : licenseeMatch
            ? currentUser?.user?.email
            : "",
        }));
      } else {
        setLicenseOnError(true);
        setLicenseInputError(resp?.data?.message);
      }
    } catch (error) {
      setLicenseOnError(true);
      setLicenseInputError(error?.response?.data?.message);
    } finally {
      setSearchLoading(false);
    }
  };
  // CBC022139
  // CBC027113

  useEffect(() => {
    if (currentUser) {
      setContractors(currentUser?.user?.contractors);
      if (currentUser?.user?.contractors.length > 0) {
        const firstContractor = currentUser?.user?.contractors[0];

        setCurrentContractor(firstContractor);

        setMaxPage(currentUser?.user?.contractors.length);
        contractorFormik?.setValues(firstContractor);
      }
      setDataLoading(false);
    }
  }, []);

  useEffect(() => {
    if (currentUser?.user?.contractors.length > 0 && currentContractor) {
      const fullName =
        currentContractor?.firstName + " " + currentContractor?.lastName;
      setEditUserList([
        {
          email: currentContractor?.email,
          fullName: fullName,
        },
      ]);
    }
  }, [maxPage, cardPage, currentContractor]);

  console.log("userList", userList);

  // CONTRACTOR FORMIK //
  const contractorFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      selectedUser: userList.length === 1 ? userList[0].email : "",
      firstName: "",
      middleName: "",
      lastName: "",
      businessName: "",
      licenseType: "",
      licenseNumber: "",
      rank: "",
      status: "",
      expires: "",
      mainAddress: "",
      mainAddressCounty: "",
      address1: "",
      address2: "",
      address3: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      mobile: "",
    },
    validationSchema: Yup.object({
      selectedUser: Yup.string().test("customValidation", function (value) {
        const { userList, firstName, lastName, licenseeMatch } = this.parent;

        if (!editLicense && userList !== undefined) {
          // if (
          //   userList?.length === 1 &&
          //   userList[0].email === value &&
          //   userList[0].fullName === `${firstName} ${lastName}`
          // ) {
          //   return true;
          // }

          if (!value || value.length < 3) {
            return this.createError({ message: "Please select a valid user" });
          }
          if (!userList || userList.length === 0) {
            return this.createError({
              message:
                "You don't have any available users to assign to a license number.",
            });
          }
          console.log("userList.length", userList.length);

          if (licenseeMatch === false) {
            return this.createError({
              message:
                "No registered user matches the licensee name you are trying to use. Please add the licensee as a registered user before proceeding.",
            });
          }
        }
        return true;
      }),
      firstName: Yup.string()
        .min(2, "First name is too short")
        .required("First name is required"),
      middleName: Yup.string(),
      lastName: Yup.string()
        .min(2, "Last name is too short")
        .required("Last name is required"),
      businessName: Yup.string()
        .min(2, "Business name is too short")
        .required("Business name is required"),
      mainAddress: Yup.string()
        .min(5, "Main address is too short")
        .required("Main address is required"),
      address1: Yup.string()
        .min(5, "Address Line 1 is too short")
        .required("Address Line 1 is required"),
      city: Yup.string()
        .min(3, "City is too short")
        .required("City is required"),
      state: Yup.string()
        .min(2, "State is too short")
        .required("State is required"),
      zip: Yup.string()
        .min(5, "Zip Code is too short")
        .required("Zip Code is required"),
      mainAddressCounty: Yup.string()
        .min(3, "County is too short")
        .required("County is required"),
      // email: Yup.string()
      //   .email("Must be a valid email")
      //   .required("Email address is required"),
      mobile: Yup.string()
        .required("Phone number is required")
        .test("PhoneTest", "Phone number is invalid", function (value) {
          const isValidPhoneNumber = validator.isMobilePhone(value);

          if (isValidPhoneNumber) {
            return true;
          } else {
            return false;
          }
        }),
    }),

    onSubmit: async (values, helpers) => {
      try {
        console.log("SS");
        setAddContractorLoading(true);
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/finalize-contractor`,
          {
            values,
            currentSession: currentSession,
            editLicense: editLicense,
          },
          { withCredentials: true }
        );

        if (resp.data.success) {
          console.log("resp1", resp.data);
          if (editLicense) {
            await dispatch(updateContractor(resp.data.data));
            setEditLicense(false);
          } else {
            await dispatch(addContractor(resp.data.data));
            setCardPage(currentUser?.user?.contractors.length + 1);
            setMaxPage(maxPage + 1);
          }
          setLicenseNumber("");
          setShowAddInput(false);
          setFoundContractor(null);
          setAddNewActive(false);
          setShowLicenseInput(false);
          contractorFormik?.setValues(resp.data.data);
          setCurrentContractor(resp.data.data);

          if (isOpen) {
            dispatch(setPrevTourStep(currentStep));
            dispatch(setShowTourNav(true));
          }
        }
      } catch (error) {
        setAddContractorError(error?.response?.data?.message);
      } finally {
        setAddContractorLoading(false);
      }
    },
  });

  useEffect(() => {
    const mergeUsers = () => {
      let combinedUsers = [];

      // Get the list of selectedUser emails from contractors
      const selectedUsers =
        currentUser?.user?.contractors?.map(
          (contractor) => contractor.selectedUser
        ) || [];

      // Get the current user's full name
      if (
        currentUser?.user?.personal &&
        currentUser?.user?.personal?.length > 0 &&
        !selectedUsers.includes(currentUser?.user?.email)
      ) {
        const currentUserFullName =
          currentUser.user.personal[0].firstName +
          " " +
          currentUser.user.personal[0].lastName;
        combinedUsers.push({
          fullName: currentUserFullName,
          email: currentUser.user.email,
        });
      }

      // Append each subuser's full name if their email is not in the selectedUsers list
      if (currentUser?.subusers && Array.isArray(currentUser.subusers)) {
        const subusersFullNames = currentUser.subusers
          .filter((subuser) => !selectedUsers.includes(subuser.email))
          .map((subuser) => ({
            fullName: subuser.first + " " + subuser.last,
            email: subuser.email,
          }));
        combinedUsers = combinedUsers.concat(subusersFullNames);
      }

      // if (combinedUsers.length === 1) {
      //   contractorFormik.setFieldValue("selectedUser", combinedUsers[0].email);
      // }

      setUserList(combinedUsers);
    };

    mergeUsers();
  }, [currentUser, contractorFormik?.values?.selectedUser]);

  useEffect(() => {
    if (
      contractorFormik?.values?.firstName &&
      contractorFormik?.values?.lastName &&
      currentUser?.user?.personal
    ) {
      const value = contractorFormik?.values?.selectedUser;
      let matchedUser = null;

      if (currentUser?.user?.email === value) {
        matchedUser = {
          first: currentUser?.user?.personal?.[0]?.firstName,
          last: currentUser?.user?.personal?.[0]?.lastName,
        };
      } else {
        // Check if the email matches any of the subusers' emails
        matchedUser = currentUser?.subusers?.find(
          (subuser) => subuser.email === value
        );
      }
      if (!matchedUser) {
        contractorFormik.setFieldError(
          "selectedUser",
          "Please select a valid user"
        );
        setLicenseeMatch(false);
        contractorFormik.setFieldValue("selectedUser", value);
        return;
      }

      const isNameMatch = checkLicenseHolderMatch(
        contractorFormik?.values?.firstName,
        contractorFormik?.values?.lastName,
        matchedUser?.first,
        matchedUser?.last
      );

      if (!isNameMatch) {
        contractorFormik.setFieldError(
          "selectedUser",
          "No registered user matches the licensee name you are trying to use. Please add the licensee as a registered user before proceeding."
        );
      } else {
        contractorFormik.setFieldError("selectedUser", "");
      }

      setLicenseeMatch(isNameMatch);

      contractorFormik.setFieldValue("selectedUser", value);
    }
  }, [
    licenseeMatch,
    contractorFormik?.values?.firstName,
    contractorFormik?.values?.lastName,
    contractorFormik?.values?.selectedUser,
  ]);

  useEffect(() => {
    // Update the mainAddress based on the individual address fields
    contractorFormik.setFieldValue(
      "mainAddress",
      `${contractorFormik?.values?.address1 || ""} ${
        contractorFormik?.values?.address2 || ""
      } ${contractorFormik?.values?.address3 || ""} ${
        contractorFormik?.values?.city || ""
      }, ${contractorFormik?.values?.state || ""} ${
        contractorFormik?.values?.zip || ""
      }`
        .replace(/\s+/g, " ") // Clean up extra spaces
        .trim()
    );
  }, [
    contractorFormik?.values?.address1,
    contractorFormik?.values?.address2,
    contractorFormik?.values?.address3,
    contractorFormik?.values?.city,
    contractorFormik?.values?.state,
    contractorFormik?.values?.zip,
  ]);

  return (
    <>
      {dataLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              backgroundColor: "#fff",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              zIndex: 9999,
            }}
          >
            {tabletSize ? (
              <>
                <Box sx={{ mb: 2 }}>
                  <img
                    style={{
                      width: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                <LottieAnimation />
              </>
            ) : (
              <CircularProgress color="warning" size="10rem" />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              mx: largePC ? "auto" : 0,
              maxWidth: "1500px",
              py: 1,
              backgroundColor: "#EEF2F6",
              mb: tabletSize ? "5rem" : undefined,
              mt: 1,
            }}
          >
            <Grid
              container
              sx={{
                px: mobileSize ? 1 : tabletSize ? 2 : 3,
              }}
            >
              <Card
                className="step7"
                py={1}
                // px={3}
                mb={1}
                component={Grid}
                sx={{
                  width: largeTabletSize ? "90vw" : "75vw",
                  maxWidth: "1200px",
                  mx: "auto",
                }}
              >
                <Box width="100%" mb={2}>
                  <Typography variant="h4" align="center">
                    Manage Contractors
                  </Typography>
                </Box>
                <Grid
                  container
                  sx={{
                    borderTop: "6px solid #f3f3f3",
                    // borderBottom: "6px solid #f3f3f3",
                    py: 2,
                  }}
                >
                  <Grid
                    item
                    xs={6}
                    px={1}
                    sx={{ borderRight: "6px solid #f3f3f3", my: 2 }}
                  >
                    <Box
                      sx={{
                        visibility: Boolean(addNewActive)
                          ? "hidden"
                          : undefined,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                      mb={2}
                    >
                      <IconButton
                        onClick={() => handleNavigate("back")}
                        sx={{
                          visibility:
                            cardPage === 1 || Boolean(addNewActive)
                              ? "hidden"
                              : "visible",
                        }}
                      >
                        <ArrowBackIosNewIcon sx={{ fontSize: "20px" }} />
                      </IconButton>

                      <Typography align="center" variant="h5">
                        {maxPage === 0 ? "0/0" : cardPage + "/" + maxPage}
                      </Typography>
                      <IconButton
                        onClick={() => handleNavigate("forward")}
                        sx={{
                          visibility:
                            maxPage === 0 ||
                            cardPage === maxPage ||
                            Boolean(addNewActive)
                              ? "hidden"
                              : "visible",
                        }}
                      >
                        <ArrowForwardIosIcon sx={{ fontSize: "20px" }} />
                      </IconButton>
                      {console.log(contractorFormik?.values)}
                    </Box>

                    <ContractorCard
                      data={
                        addNewActive
                          ? contractorFormik?.values
                          : currentContractor
                      }
                      // mb={0}
                      colorIndex={cardPage}
                      empty={
                        (Boolean(addNewActive) ||
                          currentUser?.user?.contractors.length === 0) &&
                        !Boolean(foundContractor)
                      }
                      showLicenseInput={showLicenseInput}
                      contractorSite={true}
                      handleEditContractor={handleEditContractor}
                      addNewActive={addNewActive}
                      currentContractor={currentContractor}
                      // handleEditChange={handleEditChange}
                      // editLicense={editLicense}

                      // formik={contractorFormik}
                    />
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {!addNewActive &&
                      Object.keys(currentContractor).length > 0 ? (
                        <ErrorTooltip
                          title="Edited address will be overwritten"
                          arrow
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -18], // Adjust the second value to bring it closer vertically
                                },
                              },
                            ],
                          }}
                        >
                          <Button
                            disabled={addNewActive}
                            onClick={handleUpdateLicense}
                            disableRipple
                            sx={{
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                            endIcon={
                              <RefreshIcon
                                className={fetching ? "rotate" : ""}
                              />
                            }
                          >
                            Sync Latest Data
                          </Button>
                        </ErrorTooltip>
                      ) : (
                        <Button
                          disabled={
                            addNewActive ||
                            Object.keys(currentContractor).length === 0
                          }
                          onClick={handleUpdateLicense}
                          disableRipple
                          sx={{
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          endIcon={
                            <RefreshIcon className={fetching ? "rotate" : ""} />
                          }
                        >
                          Sync Latest Data
                        </Button>
                      )}
                    </Box>
                    <Box
                      sx={{
                        pt: 0,
                        mt: -1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{ cursor: "default" }}
                        align="center"
                        variant="caption"
                      >
                        {contractorFormik?.values?.last_sync
                          ? convertIsoToLocalFormat(
                              contractorFormik?.values?.last_sync,
                              currentUser?.subuser
                                ? currentUser?.subuser?.timezone
                                : currentUser?.user?.personal?.[0]?.timeZone ||
                                    "America/New_York",
                              "cccc, LLLL dd, yyyy hh:mm a"
                            )
                          : ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      <Typography
                        color="primary"
                        className={addNewActive ? "moveUp" : ""}
                        sx={{
                          fontWeight: "bold",
                          position: "absolute",
                          top: addNewActive ? "10px" : "35%",
                          transition: "top 1s, font-size 1s", // Add font-size transition
                          fontSize: editLicense ? "2rem" : "2.25rem", // Corresponding to h4 and h3 sizes
                        }}
                      >
                        {editLicense
                          ? "UPDATE CONTRACTOR"
                          : currentUser?.user?.contractors.length >=
                            parseInt(currentUser?.tier?.license)
                          ? "LIMIT REACHED"
                          : "Add New Contractor"}
                      </Typography>
                      {!addNewActive && (
                        <Typography
                          className="fadeIn"
                          sx={{
                            position: "absolute",
                            top: "42%",
                            transition: "fade 1s",
                          }}
                          color="primary"
                          align="center"
                          variant="h4"
                        >
                          {currentUser?.user?.contractors.length +
                            "/" +
                            currentUser?.tier?.license}
                        </Typography>
                      )}

                      <IconButton
                        disabled={
                          !editLicense &&
                          currentUser?.user?.contractors.length >=
                            parseInt(currentUser?.tier?.license)
                        }
                        onClick={handleAddClick}
                        sx={{
                          color: "#4caf50", // Green color
                          fontSize: 80, // Increase the size
                          "&:hover": {
                            color: "#388e3c", // Darker green on hover
                          },
                          position: "absolute",
                          top: addNewActive ? "20px" : "47%",
                          // marginTop: showLicenseInput ? "38px" : "0",
                          transition: "top 1s",
                        }}
                        className={
                          addNewActive
                            ? "fadeInBtn"
                            : !showAddInput && "growBtn"
                        }
                      >
                        {addNewActive ? (
                          <RemoveCircleIcon color="error" fontSize="inherit" />
                        ) : (
                          <AddCircleIcon fontSize="inherit" />
                        )}
                      </IconButton>
                      {showAddInput && foundContractor ? (
                        <Box
                          className={addNewActive ? "fadeIn" : "fadeOut"}
                          sx={{ mt: 11, px: 1, transition: "fade 1s" }}
                        >
                          <Grid spacing={1} container>
                            <Grid item xs={12}>
                              <FormControl
                                disabled={editLicense}
                                fullWidth
                                variant="filled"
                                error={
                                  !editLicense &&
                                  (Boolean(
                                    contractorFormik.errors?.selectedUser
                                  ) ||
                                    !licenseeMatch)
                                }
                              >
                                <InputLabel>Assign This License To:</InputLabel>
                                <Select
                                  name="selectedUser"
                                  onBlur={contractorFormik.handleBlur}
                                  value={
                                    editLicense
                                      ? editUserList?.[0]?.email
                                      : contractorFormik.values?.selectedUser ||
                                        ""
                                  }
                                  onChange={(e) =>
                                    handleAssignToChange(e, contractorFormik)
                                  }
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        marginTop: "1.5px",
                                        maxHeight: 150, // Set the desired max height for the dropdown menu
                                        width: "auto",
                                        maxWidth: "100%",
                                      },
                                    },
                                  }}
                                  sx={{
                                    maxWidth: "100%", // Set the desired max-width for the input element
                                    "& .MuiTypography-body2": {
                                      whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                                    },
                                  }}
                                >
                                  {editLicense
                                    ? editUserList?.map((u, index) => (
                                        <MenuItem key={index} value={u?.email}>
                                          <Typography variant="body2">
                                            {u?.fullName}
                                          </Typography>
                                        </MenuItem>
                                      ))
                                    : userList?.map((u, index) => (
                                        <MenuItem key={index} value={u?.email}>
                                          <Typography variant="body2">
                                            {u?.fullName}
                                          </Typography>
                                        </MenuItem>
                                      ))}
                                </Select>
                                {/* Display the error message */}
                                {(contractorFormik.errors?.selectedUser ||
                                  !contractorFormik.values?.licenseeMatch) &&
                                  !editLicense && (
                                    <Typography
                                      ml={1}
                                      mt={0.5}
                                      variant="caption"
                                      color="error"
                                    >
                                      {contractorFormik.errors?.selectedUser ||
                                        (Boolean(
                                          contractorFormik.errors
                                            ?.selectedUser || !licenseeMatch
                                        )
                                          ? "No registered user matches the licensee name you are trying to use. Please add the licensee as a registered user before proceeding."
                                          : "")}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                disabled
                                size="small"
                                error={
                                  !!(
                                    contractorFormik.touched.firstName &&
                                    contractorFormik.errors.firstName
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.firstName &&
                                  contractorFormik.errors.firstName
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="First Name"
                                name="firstName"
                                value={contractorFormik.values.firstName}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                disabled
                                size="small"
                                onChange={contractorFormik.handleChange}
                                label="Middle Name"
                                name="middleName"
                                value={contractorFormik.values.middleName}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                disabled
                                size="small"
                                error={
                                  !!(
                                    contractorFormik.touched.lastName &&
                                    contractorFormik.errors.lastName
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.lastName &&
                                  contractorFormik.errors.lastName
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="Last Name"
                                name="lastName"
                                value={contractorFormik.values.lastName}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={editLicense}
                                size="small"
                                error={
                                  !!(
                                    contractorFormik.touched.businessName &&
                                    contractorFormik.errors.businessName
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.businessName &&
                                  contractorFormik.errors.businessName
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="Business Name"
                                name="businessName"
                                value={contractorFormik.values.businessName}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <TextField
                                size="small"
                                error={
                                  !!(
                                    contractorFormik.touched.address1 &&
                                    contractorFormik.errors.address1
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.address1 &&
                                  contractorFormik.errors.address1
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="Address Line 1"
                                name="address1"
                                value={contractorFormik.values.address1}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                size="small"
                                error={
                                  !!(
                                    contractorFormik.touched.address2 &&
                                    contractorFormik.errors.address2
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.address2 &&
                                  contractorFormik.errors.address2
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="Address Line 2"
                                name="address2"
                                value={contractorFormik.values.address2}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                size="small"
                                error={
                                  !!(
                                    contractorFormik.touched.address3 &&
                                    contractorFormik.errors.address3
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.address3 &&
                                  contractorFormik.errors.address3
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="Address Line 3"
                                name="address3"
                                value={contractorFormik.values.address3}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <TextField
                                size="small"
                                error={
                                  !!(
                                    contractorFormik.touched.city &&
                                    contractorFormik.errors.city
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.city &&
                                  contractorFormik.errors.city
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="City"
                                name="city"
                                value={contractorFormik.values.city}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                size="small"
                                error={
                                  !!(
                                    contractorFormik.touched.state &&
                                    contractorFormik.errors.state
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.state &&
                                  contractorFormik.errors.state
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="State"
                                name="state"
                                value={contractorFormik.values.state}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                size="small"
                                error={
                                  !!(
                                    contractorFormik.touched.zip &&
                                    contractorFormik.errors.zip
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.zip &&
                                  contractorFormik.errors.zip
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="Zip Code"
                                name="zip"
                                value={contractorFormik.values.zip}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>
                            {/* address1: "", address2: "", address3: "", state: "",
                            zip: "", */}
                            <Grid item xs={6}>
                              <Autocomplete
                                sx={{ width: "100%" }}
                                options={countiesForLicense}
                                value={
                                  contractorFormik.values.mainAddressCounty
                                } // This should match the selected value
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    label="County"
                                    error={Boolean(
                                      contractorFormik.touched
                                        .mainAddressCounty &&
                                        contractorFormik.errors
                                          .mainAddressCounty
                                    )} // Show error if touched and error exists
                                    helperText={
                                      contractorFormik.touched
                                        .mainAddressCounty &&
                                      contractorFormik.errors.mainAddressCounty
                                    }
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  // Manually update the form value
                                  contractorFormik.setFieldValue(
                                    "mainAddressCounty",
                                    newValue
                                  );
                                }}
                                ListboxProps={{
                                  style: {
                                    maxHeight: "150px",
                                    overflowY: "auto",
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                size="small"
                                error={
                                  !!(
                                    contractorFormik.touched.email &&
                                    contractorFormik.errors.email
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.email &&
                                  contractorFormik.errors.email
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="Email Address"
                                name="email"
                                value={contractorFormik.values.email}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>
                            {/* <Grid item xs={3} mt={0.5}>
                              <Button disabled={!licenseeMatch}>
                                Validate
                              </Button>
                            </Grid> */}
                            <Grid item xs={12}>
                              <TextField
                                size="small"
                                error={
                                  !!(
                                    contractorFormik.touched.mobile &&
                                    contractorFormik.errors.mobile
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.mobile &&
                                  contractorFormik.errors.mobile
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={handlePhoneChange}
                                label="Mobile Phone"
                                name="mobile"
                                value={contractorFormik.values.mobile}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>
                            {/* <Grid item xs={3} mt={0.5}>
                              <Button disabled={!licenseeMatch}>
                                Validate
                              </Button>
                            </Grid> */}
                            <Grid item xs={12}>
                              <Typography
                                align="center"
                                variant="h6"
                                color="error"

                                // sx={{ minHeight: "1.4rem" }}
                              >
                                {addContractorError}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <LoadingButton
                                  disabled={!licenseeMatch}
                                  loading={addContractorLoading}
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    setAddContractorError("");
                                    contractorFormik.handleSubmit();
                                  }}
                                >
                                  {editLicense
                                    ? "UPDATE CONTRACTOR"
                                    : " ADD CONTRACTOR"}
                                </LoadingButton>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      ) : (
                        showLicenseInput &&
                        !foundContractor && (
                          <Box sx={{ mt: 0, px: 1 }}>
                            <TextField
                              disabled={searchLoading}
                              label="License Number"
                              variant="filled"
                              value={licenseNumber}
                              onChange={(e) =>
                                handleLicenseNumberInputChange(e)
                              }
                              fullWidth
                              className="fadeIn"
                              inputProps={{
                                style: {
                                  fontSize: "32px",
                                  fontWeight: "bold",
                                  color: licenseOnError ? "red" : undefined,
                                },
                              }}
                              sx={
                                licenseOnError
                                  ? {
                                      mb: 2,
                                      "& .MuiFilledInput-root": {
                                        "&:before": {
                                          borderBottomColor: "red",
                                        },
                                        "&:hover:not(.Mui-disabled):before": {
                                          borderBottomColor: "red",
                                        },
                                        "&.Mui-focused:before": {
                                          borderBottomColor: "red",
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "red", // Optional: Change the label color to red
                                      },
                                    }
                                  : { mb: 2 }
                              }
                            />
                            <Typography
                              align="center"
                              variant="h6"
                              color="error"
                              mb={1}
                              sx={{ minHeight: "1.4rem" }}
                            >
                              {licenseInputError}
                            </Typography>
                            <Box
                              className="fadeIn"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <LoadingButton
                                loading={searchLoading}
                                onClick={handleSearchLicense}
                                disabled={searchDisabled}
                                color="success"
                                sx={{ minWidth: "158px" }}
                                variant="contained"
                              >
                                SEARCH
                              </LoadingButton>
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                  </Grid>
                </Grid>
                {fromPermit?.fromPermit && !isOpen && (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <Button
                      onClick={() => {
                        navigate("/permits/create", {
                          state: fromPermit,
                        });
                      }}
                      disabled={addNewActive}
                      variant="contained"
                    >
                      Back to Create Permit Package
                    </Button>
                  </Box>
                )}
              </Card>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default Contractors;
