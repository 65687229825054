import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { authGuest } from "../../store/actions/guestActions";
import { setRole, updateSession } from "../../store/actions/sessionsActions";

const SigneeLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);

  const regTokenQuery = queryParams.get("token");
  const taskIDQuery = queryParams.get("ti");
  const notarizationIDQuery = queryParams.get("ni");
  const emailQuery = queryParams.get("email");
  const reasonQuery = queryParams.get("reason");
  const registered =
    queryParams.get("u_reg") === "false"
      ? false
      : queryParams.get("u_reg") === "true"
      ? true
      : undefined;

  useEffect(() => {
    // Build parameters based on the available query parameters
    const params = {};
    if (
      regTokenQuery &&
      taskIDQuery &&
      notarizationIDQuery &&
      emailQuery &&
      reasonQuery &&
      registered !== undefined
    ) {
      Object.assign(params, {
        regTokenQuery,
        taskIDQuery,
        notarizationIDQuery,
        emailQuery,
        reasonQuery,
        registered,
      });
    }

    if (Object.keys(params).length > 0) {
      login(params);
    } else {
      navigate("/");
    }
  }, [
    regTokenQuery,
    taskIDQuery,
    notarizationIDQuery,
    emailQuery,
    reasonQuery,
    registered,
    navigate,
    dispatch,
  ]);

  const login = async (params) => {
    const result = await dispatch(authGuest(params));
    if (result && result?.data?.success === true) {
      const url = result.data.data.url;
      delete result.data.data.url;
      //? dispatch(updateSession(result.data.data));
      // await dispatch(updateSession(result?.data?.data));
      // navigate(url);
      sessionStorage.setItem("__nocCreator__r_rid", JSON.stringify(["guest"]));
      sessionStorage.setItem("exp", result?.data?.data?.expiry);

      dispatch(setRole("guest"));
      await dispatch(updateSession(result?.data?.data))
        .then(() => {
          navigate(url);
        })
        .catch((error) => {
          console.error("Session update failed", error);
          navigate("/error");
        });
    } else {
      navigate("/");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress color="warning" size="10rem" />
    </Box>
  );
};

export default SigneeLogin;
