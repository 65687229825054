import {
  Box,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SigneeRegisterInfo from "../popover/SigneeRegisterInfo";

const NewNoc = ({
  value,
  text,
  tabletSize,
  mobileSize,
  between1200and1405,
  isFadingOut,
  newNocTitle,
  setNewNocTitle,
}) => {
  const title =
    "The available free forms will expire after the 30-day period. Once the free forms are used or the time expires, they will not reset or renew.";
  return (
    <Card
      sx={{
        minHeight: mobileSize ? undefined : tabletSize ? "171px" : "203px",
        position: "relative",
      }}
    >
      {tabletSize ? (
        <IconButton
          onClick={() => setNewNocTitle(!newNocTitle)}
          sx={{ position: "absolute", right: 0, top: 0 }}
        >
          <HelpOutlineIcon color="primary" />
        </IconButton>
      ) : (
        <Tooltip title={title} placement="top">
          <HelpOutlineIcon
            color="primary"
            sx={{ position: "absolute", right: 5, top: 10 }}
          />
        </Tooltip>
      )}

      {newNocTitle && (
        <SigneeRegisterInfo
          infoText={title}
          isFadingOut={isFadingOut}
          top={35}
        />
      )}

      <CardContent
        sx={{
          px: tabletSize ? 1.5 : between1200and1405 ? 1 : undefined,
          pt: mobileSize ? 1.5 : tabletSize ? 3 : undefined,
          pb: tabletSize ? 1.5 : undefined,
        }}
      >
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              color="text.secondary"
              variant={tabletSize ? "h6" : "overline"}
            >
              Free Forms Available for:
            </Typography>
            <Typography variant="h5">{text} Days</Typography>
          </Stack>
          <Stack pr={1} pt={1}>
            <img
              alt="pdfIcon"
              src="/img/dashboard/pdfIcon.png"
              style={{
                maxWidth: mobileSize ? "3rem" : "3.5rem",
                height: "auto",
              }}
            />
          </Stack>
        </Stack>
        <Box sx={{ mt: 3 }}>
          <LinearProgress value={value} variant="determinate" />
        </Box>
      </CardContent>
    </Card>
  );
};

export default NewNoc;
