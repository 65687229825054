import {
  Card,
  CardContent,
  Button,
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { LoadingButton } from "@mui/lab";
import { useTour } from "@reactour/tour";

const SubusersGrid = ({
  subusersRow,
  formik,
  isExpanded,
  setIsExpanded,
  isCancelExpanded,
  setIsCancelExpanded,
  subuserError,
  setSubuserError,
  subuserLoading,
  setRemoveSubuserLoading,
  removeSubuserLoading,
  addedText,
  currentUser,
  selectedUser,
  setSelectedUser,
  requestsSelectionModel,
  setRequestsSelectionModel,
  tabletSize,
  mobileSize,
  handleFetch,
  fetching,
  currentSession,
}) => {
  const [loadingRows, setLoadingRows] = useState([]);
  const [gridKey, setGridKey] = useState(0);
  const { isOpen } = useTour();

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const subusersColumnsSmall = [
    {
      field: "name",
      headerName: "Current Users",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const creationDate = new Date(
          params?.row?.creationDate
        ).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });

        const lastLoginDate = isNaN(new Date(params?.row?.lastLogin))
          ? ""
          : new Date(params?.row?.lastLogin).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });

        return (
          <>
            <Box sx={{ display: "flex", width: "100%", pr: 1 }}>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  // borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  pb: 0.2,
                  pl: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {params?.row?.role}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "0.85rem" }}>
                  {params.value}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color:
                      params?.row?.status === "active"
                        ? "#2e7d32"
                        : params?.row?.status === "pending"
                        ? "red"
                        : "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {capitalizeFirstLetter(params.row?.status)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {params?.row?.email}
                </Typography>
              </Box>

              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  flexDirection: "column",
                  // borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  pb: 0.2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {creationDate}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "0.85rem", visibility: "hidden" }}
                >
                  {params.value}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color:
                      params?.row?.status === "active"
                        ? "#2e7d32"
                        : "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {lastLoginDate}
                </Typography>
              </Box>
            </Box>
          </>
        );
      },
    },
  ];

  const subusersColumns = [
    { field: "id", headerName: "User ID", flex: 1 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: false,
    },
    {
      field: "creationDate",
      headerName: "Creation Date",
      flex: 1,
      editable: false,
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 1,
      editable: false,
    },
    {
      field: "remove",
      headerName: "",
      width: 50,
      renderCell: (params) => {
        const rowIndex = params.row.id;

        if (params?.row?.status && params?.row?.status === "pending") {
          return (
            <>
              {loadingRows[rowIndex] ? (
                <CircularProgress size={25} />
              ) : (
                <Tooltip title="Cancel Invitation" arrow>
                  <IconButton
                    variant="contained"
                    color="error"
                    onClick={() => {
                      handleCancelSubUser(params.row?.email, rowIndex);
                    }}
                    className="delete-icon"
                  >
                    <DeleteIcon width={25} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        } else {
          // Return null or an empty div if you don't want to render anything
          return null;
        }
      },
    },
  ];

  const handleCancelSubUser = async (email, rowIndex) => {
    try {
      // Set the loading state for the specific row to true
      setLoadingRows((prevLoadingRows) => {
        const newLoadingRows = [...prevLoadingRows];
        newLoadingRows[rowIndex] = true;
        return newLoadingRows;
      });

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/subuser/cancel-invitation`,
        {
          email,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );
      setLoadingRows((prevLoadingRows) => {
        const newLoadingRows = [...prevLoadingRows];
        newLoadingRows[rowIndex] = false;
        return newLoadingRows;
      });
    } catch (e) {
      setLoadingRows((prevLoadingRows) => {
        const newLoadingRows = [...prevLoadingRows];
        newLoadingRows[rowIndex] = false;
        return newLoadingRows;
      });
    }
  };

  const getUserData = (params, event, details) => {
    if (!event.target.closest(".delete-icon")) {
      if (requestsSelectionModel.includes(params.id)) {
        setRequestsSelectionModel([]);
        setGridKey((prevKey) => prevKey + 1);
        setSelectedUser(null);
      } else {
        setRequestsSelectionModel([params.id]);
        setSelectedUser(params.row);
      }
    }
  };

  // Boolean(
  //   currentUser?.subusers.length >=
  //     (parseInt(currentUser?.tier?.subuser, 10) || 0)
  // ) || Boolean(tabletSize && selectedUser);

  return (
    <>
      <Card className="step23">
        <CardContent
          sx={{
            p: tabletSize ? 0 : undefined,
            position: tabletSize ? "relative" : undefined,
          }}
        >
          {tabletSize && (
            <IconButton
              disabled={
                currentUser?.subusers.length >=
                (parseInt(currentUser?.tier?.subuser, 10) || 0)
              }
              sx={{
                position: "absolute",
                right: 30,
                top: 10,
                zIndex: 999999,
                color: "#fff",
              }}
              className={fetching ? "rotate" : ""}
              onClick={() => handleFetch(currentSession)}
            >
              <RefreshIcon />
            </IconButton>
          )}

          {((parseInt(currentUser?.tier?.subuser, 10) || null) === 0 ||
            !currentUser?.tier?.subuser) &&
          currentUser?.tier?.subuser !== "Unlimited" ? (
            <Typography
              mt={2}
              align="center"
              color="primary"
              variant="h6"
              px={5}
            >
              Additional users are not permitted under your subscription. To add
              additional users, please change your subscription.
            </Typography>
          ) : !subusersRow || subusersRow?.length === 0 ? (
            <Typography mt={2} align="center" color="primary" variant="h6">
              No additional users have been added yet.
            </Typography>
          ) : (
            <DataGrid
              key={gridKey}
              className="cell"
              sx={{
                "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "& .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none !important",
                },
                "& .MuiDataGrid-cell": {
                  borderColor: tabletSize ? "rgba(0, 0, 0, 0.1)" : undefined, // Change cell border color
                  p: tabletSize ? 0 : undefined,
                },
                "& .MuiDataGrid-columnHeader": tabletSize
                  ? {
                      backgroundColor: "#333333", // Change column header background color
                      color: "white",
                      fontSize: "15px",
                      paddingLeft: 1,
                      paddingRight: 2,
                      margin: 0,
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 0,
                    }
                  : {},
                "& .MuiDataGrid-columnHeaders": {
                  padding: 0,
                  margin: 0,
                  // borderTopRightRadius: 0,
                  // borderTopLeftRadius: 0,
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
              }}
              rows={subusersRow || []}
              columns={tabletSize ? subusersColumnsSmall : subusersColumns}
              getRowId={(row) => row?.id}
              rowHeight={tabletSize ? 85 : undefined}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              onRowClick={(params, event, details) => {
                getUserData(params, event, details);
              }}
              disableColumnFilter={tabletSize}
            />
          )}
          {/* ADD SUBUSER */}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Accordion
              expanded={isExpanded}
              elevation={0}
              sx={{ width: "100%" }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: "none",
                  cursor: "default",
                  "&.MuiAccordionSummary-root:hover": {
                    cursor: "default",
                  },
                  height: "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {isExpanded ? (
                    <Button
                      size={tabletSize ? "small" : undefined}
                      fullWidth={tabletSize}
                      disabled={Boolean(currentUser?.subusers.length >= 20)}
                      sx={{ cursor: "pointer", minWidth: "178.17px" }}
                      variant="contained"
                      color="error"
                      startIcon={<RemoveIcon />}
                      onClick={() => {
                        setIsExpanded(false);
                        setSubuserError("");
                        formik.resetForm();
                      }}
                    >
                      Hide
                    </Button>
                  ) : currentUser?.tier?.subuser === "0" ? (
                    <></>
                  ) : (
                    <Button
                      size={tabletSize ? "small" : undefined}
                      fullWidth={tabletSize}
                      disabled={
                        (currentUser?.tier?.subuser !== "Unlimited" &&
                          Boolean(
                            currentUser?.subusers.length >=
                              (parseInt(currentUser?.tier?.subuser, 10) || 0)
                          )) ||
                        Boolean(tabletSize && selectedUser)
                      }
                      sx={{
                        cursor: "pointer",
                        minWidth: "178.17px",
                      }}
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        if (isOpen) {
                          return;
                        }
                        setIsExpanded(true);
                      }}
                    >
                      Add User
                    </Button>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  boxShadow: tabletSize
                    ? "3px 5px 6px 6px rgba(0, 0, 0, 0.2)"
                    : undefined,
                  mb: tabletSize ? 3 : undefined,
                  mx: tabletSize ? 1 : undefined,
                  borderRadius: tabletSize ? "10px" : undefined,
                }}
              >
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Grid container spacing={tabletSize ? 1 : 2}>
                    <Grid item xs={12}>
                      <Typography
                        variant={tabletSize ? "h6" : "h5"}
                        align="center"
                        mb={tabletSize ? 1 : 2}
                        mt={tabletSize ? 1 : undefined}
                      >
                        Add New User
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        size={tabletSize ? "small" : undefined}
                        name="first"
                        label="First Name"
                        error={
                          formik.touched.first && Boolean(formik.errors.first)
                        }
                        helperText={formik.touched.first && formik.errors.first}
                        value={formik.values.first}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        size={tabletSize ? "small" : undefined}
                        name="last"
                        label="Last Name"
                        error={
                          formik.touched.last && Boolean(formik.errors.last)
                        }
                        helperText={formik.touched.last && formik.errors.last}
                        value={formik.values.last}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        size={tabletSize ? "small" : undefined}
                        name="email"
                        label="Email Address"
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl
                        fullWidth
                        variant="filled"
                        error={!!(formik.touched.role && formik.errors.role)}
                      >
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                          size={tabletSize ? "small" : undefined}
                          labelId="license_types"
                          name="role"
                          label="Role Type"
                          value={formik.values.role}
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: "1.5px",
                                maxHeight: 150, // Set the desired max height for the dropdown menu
                                width: "auto",
                                maxWidth: "100%",
                              },
                            },
                          }}
                          sx={{
                            maxWidth: "100%", // Set the desired max-width for the input element
                            "& .MuiTypography-body2": {
                              whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                            },
                          }}
                        >
                          {["Basic User", "Power User"].map(function (user, i) {
                            return (
                              <MenuItem key={i} value={user}>
                                <Typography variant="body2">{user}</Typography>
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {formik.touched.role && formik.errors.role
                            ? formik.errors.role
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography
                      variant={tabletSize ? "body2" : "body1"}
                      color="error"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{
                        mt: tabletSize ? 1 : 2,
                        minHeight: tabletSize ? undefined : "1.5rem",
                      }}
                    >
                      {subuserError}
                    </Typography>
                    <LoadingButton
                      size={tabletSize ? "small" : "large"}
                      loading={subuserLoading}
                      type="submit"
                      variant="contained"
                      sx={{ mt: tabletSize ? 0 : 2 }}
                      fullWidth
                    >
                      Add User
                    </LoadingButton>
                  </Box>
                </form>
              </AccordionDetails>
            </Accordion>
          </Box>
          {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Accordion
              expanded={isCancelExpanded}
              elevation={0}
              sx={{ width: "100%" }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: "none",
                  cursor: "default",
                  "&.MuiAccordionSummary-root:hover": {
                    cursor: "default",
                  },
                  height: "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {isCancelExpanded ? (
                    <Button
                      size={tabletSize ? "small" : undefined}
                      fullWidth={tabletSize}
                      disabled={
                        Boolean(!selectedUser) ||
                        Boolean(selectedUser?.status === "pending")
                      }
                      sx={{ cursor: "pointer", minWidth: "178.16px" }}
                      variant="contained"
                      color="error"
                      startIcon={<RemoveIcon />}
                      onClick={() => {
                        setIsCancelExpanded(false);
                      }}
                    >
                      Hide
                    </Button>
                  ) : currentUser?.tier?.subuser === "0" ? (
                    <></>
                  ) : (
                    <Button
                      size={tabletSize ? "small" : undefined}
                      fullWidth={tabletSize}
                      disabled={
                        Boolean(!selectedUser) ||
                        (Boolean(selectedUser?.status === "pending") &&
                          Boolean(!tabletSize))
                      }
                      sx={{ cursor: "pointer", minWidth: "178.16px" }}
                      variant="contained"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        setIsCancelExpanded(true);
                      }}
                    >
                      Remove User
                    </Button>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  boxShadow: tabletSize
                    ? "3px 5px 6px 6px rgba(0, 0, 0, 0.2)"
                    : undefined,
                  mb: tabletSize ? 3 : undefined,
                  mx: tabletSize ? 1 : undefined,
                  borderRadius: tabletSize ? "10px" : undefined,
                }}
              >
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Grid container spacing={tabletSize ? 1 : 2}>
                    <Grid item xs={12}>
                      <Typography
                        variant={tabletSize ? "h6" : "h5"}
                        align="center"
                        mb={tabletSize ? 1 : 2}
                        mt={tabletSize ? 1 : undefined}
                      >
                        Remove User
                      </Typography>
                      <Typography
                        variant={tabletSize ? "body2" : "h6"}
                        align="center"
                      >
                        Are you certain about permanently removing this subuser?
                        This action is irreversible.
                      </Typography>
                      <Typography
                        variant={tabletSize ? "body2" : "h6"}
                        align="center"
                        color="error"
                        mb={2}
                      >
                        After removing the subuser, all documents,
                        notarizations, and recording processes will be
                        transferred to your account.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled
                        fullWidth
                        size={tabletSize ? "small" : undefined}
                        name="first"
                        label="First Name"
                        value={selectedUser?.first || ""}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        size={tabletSize ? "small" : undefined}
                        disabled
                        fullWidth
                        name="last"
                        label="Last Name"
                        value={selectedUser?.last || ""}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        size={tabletSize ? "small" : undefined}
                        name="email"
                        label="Email Address"
                        disabled
                        value={selectedUser?.email || ""}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl disabled fullWidth variant="filled">
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                          size={tabletSize ? "small" : undefined}
                          labelId="license_types"
                          name="role"
                          label="Role Type"
                          value={selectedUser?.role || ""}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: "1.5px",
                                maxHeight: 150, // Set the desired max height for the dropdown menu
                                width: "auto",
                                maxWidth: "100%",
                              },
                            },
                          }}
                          sx={{
                            maxWidth: "100%", // Set the desired max-width for the input element
                            "& .MuiTypography-body2": {
                              whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                            },
                          }}
                        >
                          {["Power User", "Basic User"].map(function (user, i) {
                            return (
                              <MenuItem key={i} value={user}>
                                <Typography variant="body2">{user}</Typography>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography
                      variant="body1"
                      color="error"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{
                        mt: 2,
                        minHeight: tabletSize ? undefined : "1.5rem",
                      }}
                    >
                      {subuserError}
                    </Typography>
                    <LoadingButton
                      size={tabletSize ? "small" : "large"}
                      onClick={handleRemoveSubUser}
                      loading={removeSubuserLoading}
                      color="error"
                      variant="contained"
                      sx={{ mt: 2 }}
                      fullWidth
                    >
                      Confirm Remove User Permanently
                    </LoadingButton>
                  </Box>
                </form>
              </AccordionDetails>
            </Accordion>
          </Box> */}
          <Typography
            variant="body1"
            sx={{ color: "#4caf50" }}
            fontWeight="bold"
            textAlign="center"
          >
            {addedText}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default SubusersGrid;
