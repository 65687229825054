import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  FormHelperText,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useRef } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TimePicker, DatePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";

const ScheduleRONComponent = (props) => {
  const expandableRef = useRef(null);
  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const largeTabletSize = useMediaQuery((theme) =>
    theme.breakpoints.down("lg")
  );

  const startInstantCheck = async () => {
    await props?.handleImmediateRON(); //! DO THIS
    props?.setSchedule((prev) => ({
      ...prev,
      expeditedSelectedBtn: true,
      furtherSelectedBtn: false,
    }));
    setTimeout(() => {
      expandableRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300); // Adjust time based on your collapse animation time
  };

  const handleDateChange = (newDate) => {
    props?.setSchedule((prev) => ({
      ...prev,
      tempDate: newDate,
    }));
  };

  const handleTimeChange = (newTime) => {
    props?.setSchedule((prev) => ({
      ...prev,
      tempTime: newTime,
    }));
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleFurtherClick = async () => {
    if (!props?.schedule?.furtherSelectedBtn) {
      startFurtherCheck();
    }
  };
  const handleExpeditedClick = async () => {
    if (!props?.schedule?.expeditedSelectedBtn) {
      startInstantCheck();
    }
  };

  const startFurtherCheck = async () => {
    props?.setSchedule((prev) => ({
      ...prev,
      furtherSelectedBtn: true,
      expeditedSelectedBtn: false,
    }));
    setTimeout(() => {
      expandableRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300); // Adjust time based on your collapse animation time
  };

  function formatSlotToUSDateTime(slot) {
    const date = new Date(slot.year, slot.month - 1, slot.day_of_month); // JavaScript months are 0-indexed
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    // Convert 'HH:MM' format to 12-hour format with AM/PM
    const [hours, minutes] = slot.time.split(":");
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
    const amPm = hours < 12 ? "AM" : "PM";
    const formattedTime = `${hours12}:${minutes} ${amPm}`;

    return `${formattedDate}, ${formattedTime}`;
  }

  function convertObjectToDayjs(dateInfo) {
    // Day.js month is zero-indexed, subtract 1
    const monthZeroIndexed = dateInfo.month - 1;

    // Constructing the full date string
    const dateString = `${dateInfo.year}-${String(
      monthZeroIndexed + 1
    ).padStart(2, "0")}-${String(dateInfo.day_of_month).padStart(2, "0")}`;

    // Constructing the time string
    const timeString = dateInfo.time;

    // Creating Day.js objects for date and time
    const date = dayjs(dateString);
    const time = dayjs(timeString, "HH:mm");

    // Setting the states
    props?.setSchedule((prev) => ({
      ...prev,
      tempDate: date,
      tempTime: time,
    }));
  }

  return (
    <Box>
      <Box sx={{ mb: 2, px: 0.5 }}>
        <Typography variant="h6" align="center" color="primary">
          {!props?.isScheduled &&
            "Would you like to check if a notary is available to assist you now?"}
        </Typography>
        {!props?.isScheduled && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 1,
              mt: 2,
              mb: 5,
            }}
          >
            <Button
              size="small"
              disabled={
                props?.schedule?.isInstantAvailable ||
                props?.schedule?.checkInstantLoading ||
                props?.schedule?.expeditedSelectedBtn
              }
              onClick={handleFurtherClick}
              fullWidth={tabletSize}
              sx={{ minWidth: "15rem" }}
              variant={
                props?.schedule?.furtherSelectedBtn ? "contained" : "outlined"
              }
            >
              NO
            </Button>
            <LoadingButton
              size="small"
              disabled={props?.schedule?.furtherSelectedBtn}
              loading={props?.schedule?.yesLoading}
              onClick={handleExpeditedClick}
              fullWidth={tabletSize}
              sx={{ minWidth: "15rem" }}
              variant={
                props?.schedule?.expeditedSelectedBtn ? "contained" : "outlined"
              }
            >
              YES
            </LoadingButton>
          </Box>
        )}
      </Box>
      {/* EXPEDITED APPOINTMENT */}
      {props?.isScheduled && (
        <>
          <Typography
            color="primary"
            align="center"
            variant="h6"
            px={2}
            sx={{ fontStyle: "italic" }}
          >
            You can easily join sessions directly from the Notarization tab,
            making it simple to manage your notarization tasks and stay up to
            date
          </Typography>
          <Box mb={3}>
            <Typography variant="h3" mt={13} align="center" color="primary">
              The session will begin at:
            </Typography>
            <Typography variant="h4" align="center" sx={{ color: "#4caf50" }}>
              {props?.ronDate}
            </Typography>
          </Box>
          {props?.fiveMinBeforeSession && (
            <Typography
              color="primary"
              variant="body1"
              fontWeight="bold"
              align="center"
            >
              You may join the session up to 5 minutes before the scheduled
              start time.
            </Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
            <Button
              disabled={!props?.fiveMinBeforeSession}
              color="success"
              size="large"
              onClick={() => {
                props?.setOpen(false);
                window.open(props?.sessionUrl, "_blank");
              }}
              variant="contained"
              sx={{ minWidth: "25rem" }}
            >
              Join Session
            </Button>
          </Box>
        </>
      )}
      <Collapse in={props?.schedule?.expeditedSelectedBtn}>
        <div ref={expandableRef}>
          <Grid container spacing={mobileSize ? 1 : 2} sx={{ mb: 2 }}>
            {props?.schedule?.expeditedSelectedBtn && (
              <>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sx={{ mx: mobileSize ? "0px" : "auto" }}
                >
                  <Card elevation={mobileSize ? 0 : 4}>
                    {props?.schedule?.startInstantTimer &&
                      props?.schedule?.instantAccepted === false && (
                        <Box
                          sx={{
                            backgroundColor: "#FF9800",
                            borderRadius: "20px 20px 0 0",
                            p: 1,
                            mt: mobileSize ? 2 : 0,
                          }}
                        >
                          <Typography
                            align="center"
                            color="#fff"
                            fontWeight="bold"
                            variant="body2"
                          >
                            {props?.schedule?.immediateRequested === true
                              ? `Maximum waiting time left ${formatTime(
                                  props?.schedule?.instantTimeLeft
                                )}`
                              : `Selected Date And Time Is Reserved For: ${formatTime(
                                  props?.schedule?.instantTimeLeft
                                )}`}
                          </Typography>
                        </Box>
                      )}
                    <CardContent sx={{ pt: mobileSize ? 0 : 2, px: 0 }}>
                      <Typography
                        variant={mobileSize ? "h6" : "h5"}
                        align="center"
                        sx={{
                          mb: mobileSize ? 1 : 1,
                          mt: mobileSize ? 3 : 0,
                        }}
                      >
                        {props?.schedule?.isInstantAvailable
                          ? "Expedited Notarization"
                          : "Expedited Appointments"}
                      </Typography>
                      {props?.schedule?.immediateRequested === true &&
                        props?.schedule?.startInstantTimer === true &&
                        props?.schedule?.instantAccepted === false && (
                          <>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              sx={{ width: "100%", fontWeight: "bold" }}
                            >
                              <Typography
                                align="center"
                                variant="tbody2"
                                sx={{ mb: 4, mr: 1, color: "#10b981" }}
                              >
                                Please wait while a notary is being assigned to
                                your expedited notarization request. Note:
                                Notaries have up to 5 minutes to accept your
                                request.
                              </Typography>
                            </Stack>
                          </>
                        )}
                      {props?.schedule?.instantAccepted === true && (
                        <>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            sx={{ width: "100%", fontWeight: "bold" }}
                          >
                            <Typography
                              align="center"
                              variant="tbody2"
                              sx={{ mb: 4, mr: 1, color: "#10b981" }}
                            >
                              Please wait while the system validates and
                              prepares your document for notarization. This
                              could take up to 60 seconds.
                            </Typography>
                          </Stack>
                        </>
                      )}

                      {props?.schedule?.instantData?.success && (
                        <>
                          <Typography px={1} align="center">
                            We currently have{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "24px",
                                color: "#4caf50",
                              }}
                            >
                              {props?.schedule?.instantData?.success
                                ? props?.schedule?.instantData?.notaries.length
                                : 0}
                            </span>{" "}
                            {props?.schedule?.instantData?.notaries.length > 1
                              ? "notaries"
                              : "notary"}{" "}
                            that might be available to assist you at:
                          </Typography>
                          {props?.schedule?.isInstantAvailable && (
                            <Typography
                              sx={{ fontWeight: "bold" }}
                              align="center"
                            >
                              {props?.schedule?.instantData?.dateString}
                            </Typography>
                          )}
                          {props?.schedule?.immediateRequested === true &&
                          props?.schedule?.startInstantTimer === true ? (
                            <></>
                          ) : (
                            <>
                              <Typography align="center" fontWeight="bold">
                                Would you like to submit a request for an
                                appointment?
                              </Typography>
                              <Typography align="center">
                                Please note that availability is not guaranteed.
                              </Typography>
                            </>
                          )}
                        </>
                      )}

                      <Typography align="center" color="error">
                        {props?.schedule?.instantError}
                      </Typography>
                      <Grid
                        container
                        spacing={mobileSize ? 0 : 1}
                        pl={mobileSize ? 0.5 : tabletSize ? 1 : 2}
                        pr={mobileSize ? 0.5 : tabletSize ? 1 : 2}
                      >
                        <Grid
                          sx={{ order: mobileSize ? 2 : 1 }}
                          item
                          sm={
                            !props?.schedule?.isInstantAvailable &&
                            props?.schedule?.instantError
                              ? 12
                              : 6
                          }
                          xs={12}
                        >
                          <Button
                            onClick={() => {
                              if (
                                !props?.schedule?.isInstantAvailable &&
                                props?.schedule?.instantError
                              ) {
                                props?.setSchedule((prev) => ({
                                  ...prev,
                                  expeditedSelectedBtn: false,
                                  furtherSelectedBtn: true,
                                  instantError: "",
                                }));
                                setTimeout(() => {
                                  expandableRef.current?.scrollIntoView({
                                    behavior: "smooth",
                                  });
                                }, 300);
                              } else if (!props?.schedule?.isInstantAvailable) {
                                props?.setSchedule((prev) => ({
                                  ...prev,
                                  expeditedSelectedBtn: false,
                                }));
                              } else {
                                props?.setSchedule((prev) => ({
                                  ...prev,
                                  instantTimeLeft: 0,
                                  canceledInstant: true,
                                  instantData: {},
                                  isInstantAvailable: false,
                                  expeditedSelectedBtn: false,
                                }));
                              }
                            }}
                            disabled={
                              Boolean(props?.schedule?.checkInstantLoading) ||
                              Boolean(props?.schedule?.instantAccepted) ||
                              Boolean(props?.schedule?.immediateLock)
                            }
                            fullWidth
                            sx={{ mt: mobileSize ? 1 : 2 }}
                            variant="contained"
                            color={
                              !props?.schedule?.isInstantAvailable &&
                              props?.schedule?.instantError
                                ? "success"
                                : "error"
                            }
                          >
                            {!props?.schedule?.isInstantAvailable &&
                            props?.schedule?.instantError
                              ? "Schedule for a later time"
                              : "Cancel"}
                          </Button>
                        </Grid>
                        {!props?.schedule?.isInstantAvailable &&
                        props?.schedule?.instantError ? (
                          <></>
                        ) : (
                          <Grid
                            sx={{ order: mobileSize ? 1 : 2 }}
                            item
                            sm={6}
                            xs={12}
                          >
                            <LoadingButton
                              type={
                                props?.schedule?.isInstantAvailable
                                  ? "submit"
                                  : "button"
                              }
                              loading={
                                props?.schedule?.checkInstantLoading ||
                                props?.schedule?.immediateRequested ||
                                props?.schedule?.instantAccepted
                              }
                              onClick={() => {
                                if (!props?.schedule?.isInstantAvailable) {
                                  startInstantCheck();
                                  props?.setSchedule((prev) => ({
                                    ...prev,
                                    instantError: "",
                                  }));
                                } else if (
                                  props?.schedule?.isInstantAvailable
                                ) {
                                  props?.setSchedule((prev) => ({
                                    ...prev,
                                    submitting: true,
                                  }));
                                  props?.handleImmediateRON();
                                  //   formik.submitForm();
                                }
                              }}
                              disabled={
                                Boolean(props?.scheduledDate) ||
                                Boolean(props?.schedule?.slots.length !== 0) ||
                                Boolean(props?.schedule?.checkDateLoading)
                              }
                              fullWidth
                              variant="contained"
                              sx={{ mt: mobileSize ? 1 : 2 }}
                              color={
                                props?.schedule?.isInstantAvailable
                                  ? "success"
                                  : "warning"
                              }
                              startIcon={
                                props?.schedule?.isInstantAvailable &&
                                props?.schedule?.immediateRequested === false &&
                                props?.schedule?.checkInstantLoading ===
                                  false ? (
                                  <InputAdornment position="start">
                                    <CheckCircleIcon
                                      style={{ color: "white" }}
                                    />
                                  </InputAdornment>
                                ) : null
                              }
                            >
                              {props?.schedule?.isInstantAvailable
                                ? "Send Request"
                                : "Check Availability"}
                            </LoadingButton>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </Collapse>

      {/* FURTHER APPOINTMENT */}
      <Collapse in={props?.schedule?.furtherSelectedBtn}>
        <div ref={expandableRef}>
          <Grid container spacing={mobileSize ? 1 : 2} sx={{ mb: 2 }}>
            <Grid item xs={12} lg={6} sx={{ mx: mobileSize ? "0px" : "auto" }}>
              <Card elevation={mobileSize ? 0 : 4}>
                {props?.schedule?.startTimer &&
                  props?.schedule?.instantAccepted === false && (
                    <Box
                      sx={{
                        backgroundColor: "#FF9800",
                        borderRadius: "20px 20px 0 0",
                        p: 1,
                        mt: mobileSize ? 2 : 0,
                        mb:
                          !props?.schedule?.isManage && mobileSize
                            ? 2
                            : undefined,
                      }}
                    >
                      <Typography
                        align="center"
                        color="#fff"
                        fontWeight="bold"
                        variant="body2"
                      >
                        {props?.schedule?.furtherRequested === true
                          ? `Maximum waiting time left ${formatTime(
                              props?.schedule?.scheduleTimeLeft
                            )}`
                          : `Selected Date And Time Is Reserved For: ${formatTime(
                              props?.schedule?.scheduleTimeLeft
                            )}`}
                      </Typography>
                    </Box>
                  )}
                <CardContent
                  sx={{ pt: mobileSize ? 0 : 2, px: tabletSize ? 1 : 2 }}
                >
                  <Typography
                    variant={mobileSize ? "h6" : "h5"}
                    align="center"
                    sx={{
                      mb: props?.schedule?.isAvailable || mobileSize ? 2 : 3,
                    }}
                  >
                    Schedule a later appointment date and time
                  </Typography>
                  {props?.schedule?.furtherRequested === true &&
                    props?.schedule?.startTimer === true &&
                    props?.schedule?.instantAccepted === false && (
                      <>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          sx={{ width: "100%", fontWeight: "bold" }}
                        >
                          <Typography
                            align="center"
                            variant="h6"
                            sx={{
                              mb: 0,
                              mr: 1,
                              mt: mobileSize ? 2 : undefined,
                              color: "#10b981",
                            }}
                          >
                            Please wait while a notary is being assigned to your
                            notarization request. Note: Notaries have up to 5
                            minutes to accept your request.
                          </Typography>
                        </Stack>
                      </>
                    )}
                  {props?.schedule?.instantAccepted === true && (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{ width: "100%", fontWeight: "bold" }}
                      >
                        <Typography
                          align="center"
                          variant="tbody2"
                          sx={{ mb: 4, mr: 1, color: "#10b981" }}
                        >
                          Please wait while the system validates and prepares
                          your document for notarization. This could take up to
                          60 seconds.
                        </Typography>
                      </Stack>
                    </>
                  )}

                  {props?.schedule?.furtherData?.success && (
                    <>
                      <Typography px={1} align="center">
                        We currently have{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "24px",
                            color: "#4caf50",
                          }}
                        >
                          {props?.schedule?.furtherData?.success
                            ? props?.schedule?.furtherData?.notaries.length
                            : 0}
                        </span>{" "}
                        {props?.schedule?.furtherData?.notaries.length > 1
                          ? "notaries"
                          : "notary"}{" "}
                        that might be available to assist you.
                      </Typography>
                      {props?.schedule?.isInstantAvailable && (
                        <Typography sx={{ fontWeight: "bold" }} align="center">
                          {props?.schedule?.furtherData?.dateString}
                        </Typography>
                      )}
                      {props?.schedule?.furtherRequested === true &&
                      props?.schedule?.startTimer === true ? (
                        <></>
                      ) : (
                        <Typography pb={1} align="center" fontWeight="bold">
                          Would you like to check if a notary is available to
                          assist you on your selected date and time?
                        </Typography>
                      )}
                    </>
                  )}

                  <Typography align="center" color="error">
                    {props?.schedule?.furtherError}
                  </Typography>

                  <Box
                    sx={{ display: "flex", gap: 2, justifyContent: "center" }}
                  >
                    <DatePicker
                      label="Select Date"
                      value={props?.schedule?.tempDate}
                      onChange={handleDateChange}
                      // renderInput={(params) => <TextField {...params} />}
                      // slotProps={{textField: {...params}}}
                      disabled={
                        Boolean(props?.schedule?.isInstantAvailable) ||
                        Boolean(props?.schedule?.checkInstantLoading) ||
                        Boolean(props?.schedule?.immediateRequested) ||
                        Boolean(props?.schedule?.furtherData?.success)
                      }
                      disablePast
                    />
                    <TimePicker
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                      }}
                      closeOnSelect={false}
                      label="Select Time"
                      sx={{
                        borderRadius: "10px",
                      }}
                      value={props?.schedule?.tempTime}
                      onChange={handleTimeChange}
                      // renderInput={(params) => <TextField {...params} />}
                      disabled={
                        Boolean(props?.schedule?.furtherData?.success) ||
                        Boolean(props?.schedule?.isInstantAvailable) ||
                        Boolean(props?.schedule?.checkInstantLoading) ||
                        Boolean(props?.schedule?.immediateRequested) ||
                        !props?.schedule?.tempDate
                      }
                      minutesStep={5}
                    />
                  </Box>

                  {/* <FormHelperText sx={{ color: "error.main" }}>
                    {props?.scheduledDate}
                  </FormHelperText> */}
                  <FormHelperText
                    sx={{ textAlign: "center", color: "error.main" }}
                  >
                    {props?.schedule?.unavailableText}
                  </FormHelperText>
                  <Grid container spacing={1}>
                    <Grid
                      sx={{ order: mobileSize ? 2 : 1 }}
                      item
                      sm={6}
                      xs={12}
                    >
                      <Button
                        onClick={() => {
                          console.log("CLICKED");
                          console.log(props?.schedule?.checkDateLoading);
                          console.log(props?.schedule?.furtherRequested);
                          console.log(props?.schedule?.instantAccepted);
                          if (props?.schedule?.startTimer) {
                            props?.setSchedule((prev) => ({
                              ...prev,
                              scheduleTimeLeft: 0,
                            }));
                          }
                          props?.setScheduledDate(null);
                          props?.setSchedule((prev) => ({
                            ...prev,
                            tempDate: null,
                            tempTime: null,
                            slots: [],
                            isAvailable: false,
                            furtherSelectedBtn: false,
                            furtherDateObj: null,
                            unavailableText: "",
                            furtherData: null,
                            checkDateLoading: false,
                            furtherRequested: false,
                            isFurtherAvailable: false,
                            instantAccepted: false,
                          }));
                        }}
                        disabled={Boolean(props?.schedule?.instantAccepted)}
                        fullWidth
                        sx={{ mt: mobileSize ? 0 : 2 }}
                        variant="contained"
                        color="error"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      sx={{ order: mobileSize ? 1 : 2 }}
                      item
                      sm={6}
                      xs={12}
                    >
                      <LoadingButton
                        type={
                          props?.schedule?.isInstantAvailable
                            ? "submit"
                            : "button"
                        }
                        loading={
                          props?.schedule?.checkDateLoading ||
                          props?.schedule?.furtherRequested ||
                          props?.schedule?.instantAccepted
                        }
                        onClick={async () => {
                          if (!props?.schedule?.isFurtherAvailable) {
                            await props?.handleStartClick();
                            props?.setSchedule((prev) => ({
                              ...prev,
                              slotIndex: -1,
                            }));

                            setTimeout(() => {
                              expandableRef.current?.scrollIntoView({
                                behavior: "smooth",
                              });
                            }, 300);
                          } else if (props?.schedule?.isFurtherAvailable) {
                            props?.setSchedule((prev) => ({
                              ...prev,
                              submitting: true,
                            }));
                            props?.handleStartClick();
                            // formik.submitForm(); //! RUN FUNCTION
                          }

                          // startFurtherCheck();
                        }}
                        disabled={
                          Boolean(!props?.schedule?.furtherDateObj) ||
                          Boolean(!props?.schedule?.tempDate) ||
                          Boolean(!props?.schedule?.tempTime) ||
                          Boolean(props?.schedule?.isAvailable) ||
                          Boolean(props?.schedule?.checkInstantLoading)
                        }
                        fullWidth
                        variant="contained"
                        sx={{ mt: mobileSize ? 1 : 2 }}
                        color={
                          props?.schedule?.isFurtherAvailable
                            ? "success"
                            : "warning"
                        }
                        startIcon={
                          props?.schedule?.isFurtherAvailable &&
                          props?.schedule?.furtherRequested === false &&
                          props?.schedule?.checkInstantLoading === false ? (
                            <InputAdornment position="start">
                              <CheckCircleIcon style={{ color: "white" }} />
                            </InputAdornment>
                          ) : null
                        }
                      >
                        {props?.schedule?.isFurtherAvailable
                          ? "Send Request"
                          : "Check Availability"}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Collapse>

      <Grid container spacing={largeTabletSize ? 1 : 2} px={1} align="center">
        {props?.schedule?.slots.length !== 0 && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography variant="h6" color="primary">
              Optimal Scheduling Options
            </Typography>
          </Grid>
        )}

        {props?.schedule?.slots?.map((slot, index) => (
          <Grid item md={4} sm={6} xs={12} key={index}>
            <Button
              //   disabled={
              //     Boolean(props?.type === "manage") &&
              //     Boolean(propertyDetails?.appointment)
              //       ? Boolean(areDatesEqual(slot, propertyDetails.appointment))
              //       : false
              //   }
              onClick={() => {
                if (props?.schedule?.slotIndex === index) {
                  props?.setSchedule((prev) => ({
                    ...prev,
                    tempDate: null,
                    tempTime: null,
                    furtherDateObj: null,
                    slotIndex: -1,
                  }));
                } else {
                  convertObjectToDayjs(slot);

                  props?.setSchedule((prev) => ({
                    ...prev,
                    slotIndex: index,
                    unavailableText: "",
                  }));
                }
              }}
              fullWidth
              variant={
                props?.schedule?.slotIndex === index ? "contained" : "outlined"
              }
            >
              {formatSlotToUSDateTime(slot)}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ScheduleRONComponent;
