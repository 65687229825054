export const categoryData = [
  {
    categoryLabel: "Exterior Doors",
    categoryCode: "a",
    subCategory: [
      { label: "Swinging", code: "1" },
      { label: "Sliding", code: "2" },
      { label: "Sectional", code: "3" },
      { label: "Roll-up", code: "4" },
      { label: "Automatic", code: "5" },
      { label: "Other", code: "6" },
    ],
  },
  {
    categoryLabel: "Windows",
    categoryCode: "b",
    subCategory: [
      { label: "Single Hung", code: "1" },
      { label: "Horizontal Slider", code: "2" },
      { label: "Casement", code: "3" },
      { label: "Double Hung", code: "4" },
      { label: "Fixed", code: "5" },
      { label: "Awning", code: "6" },
      { label: "Pass-through", code: "7" },
      { label: "Projected", code: "8" },
      { label: "Mullion", code: "9" },
      { label: "Wind Breaker", code: "10" },
      { label: "Dual Action", code: "11" },
      { label: "Other", code: "12" },
    ],
  },
  {
    categoryLabel: "Panel Wall",
    categoryCode: "c",
    subCategory: [
      { label: "Siding", code: "1" },
      { label: "Soffits", code: "2" },
      { label: "EIFS", code: "3" },
      { label: "Storefront", code: "4" },
      { label: "Curtain Walls", code: "5" },
      { label: "Wall Louvers", code: "6" },
      { label: "Glass Block", code: "7" },
      { label: "Membrane", code: "8" },
      { label: "Greenhouse", code: "9" },
      { label: "New Technology", code: "10" },
      { label: "Other", code: "11" },
    ],
  },
  {
    categoryLabel: "Roofing Products",
    categoryCode: "d",
    subCategory: [
      { label: "Asphalt Shingles", code: "1" },
      { label: "Underlayments", code: "2" },
      { label: "Roofing Fasteners", code: "3" },
      { label: "Non-structural Roof Metal", code: "4" },
      { label: "Built-up Roofing", code: "5" },
      { label: "Modified Bitumen", code: "6" },
      { label: "Single-ply Roofing", code: "7" },
      { label: "Roofing Tiles", code: "8" },
      { label: "Roofing Insulation", code: "9" },
      { label: "Water-proofing", code: "10" },
      { label: "Wood Shingles/Shakes", code: "11" },
      { label: "Roofing Slate", code: "12" },
      { label: "Liquid-applied Roofing", code: "13" },
      { label: "Cement-adhesive Coats", code: "14" },
      { label: "Roof Tile Adhesive", code: "15" },
      { label: "Spray-applied Polyurethane Roofing", code: "16" },
      { label: "Other", code: "17" },
    ],
  },
  {
    categoryLabel: "Shutters",
    categoryCode: "e",
    subCategory: [
      { label: "Accordion", code: "1" },
      { label: "Bahama", code: "2" },
      { label: "Storm Panels", code: "3" },
      { label: "Colonial", code: "4" },
      { label: "Roll-up", code: "5" },
      { label: "Equipment", code: "6" },
      { label: "Other", code: "7" },
    ],
  },
  {
    categoryLabel: "Structural Components",
    categoryCode: "f",
    subCategory: [
      { label: "Wood Connector/Anchor", code: "1" },
      { label: "Truss plates", code: "2" },
      { label: "Engineered Lumber", code: "3" },
      { label: "Railing", code: "4" },
      { label: "Coolers/Freezers", code: "5" },
      { label: "Concrete Admixtures", code: "6" },
      { label: "Material", code: "7" },
      { label: "Insulation Forms", code: "8" },
      { label: "Plastics", code: "9" },
      { label: "Deck-roof", code: "10" },
      { label: "Wall", code: "11" },
      { label: "Sheds", code: "12" },
      { label: "Other", code: "13" },
    ],
  },
  {
    categoryLabel: "Skylights",
    categoryCode: "g",
    subCategory: [
      { label: "Skylight", code: "1" },
      { label: "Other", code: "2" },
    ],
  },
  {
    categoryLabel: "Impact Protective Systems",
    categoryCode: "h",
    subCategory: [
      { label: "Fixed", code: "1" },
      { label: "Operable", code: "2" },
      { label: "Removable", code: "3" },
    ],
  },
];
