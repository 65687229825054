import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { useSignIn, useClerk } from "@clerk/clerk-react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { updateSession } from "../../store/actions/sessionsActions";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Modal,
} from "@mui/material";
import { DateTime } from "luxon";

const DynamicAuth = () => {
  // const { signIn } = useSignIn();
  // const { signOut } = useClerk();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [defEmail, setDefEmail] = useState("");
  const [defPwd, setDefPwd] = useState("");
  const [remember, setRemember] = useState(false);

  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedImage, setSelectedImage] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [qrCodeSrc, setQRCodeSrc] = useState(null);
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isPasting, setIsPasting] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [processLoad, setProcessLoad] = useState(false);
  const [tryLeft, setTryLeft] = useState(0);
  const [blocked, setBlocked] = useState(false);
  const [iconsBlocked, setIconsBlocked] = useState(false);
  const [blockedText, setBlockedText] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromQuery = queryParams.get("token");
  const sessionIdQuery = queryParams.get("session_id");
  const [step, setStep] = useState(1); // To track the current step
  const [emailLoading, setEmailLoading] = useState(false);
  const [mobileInputDisabled, setMobileInputDisabled] = useState(false);
  const [mobileText, setMobileText] = useState("");
  const [memorable, setMemorable] = useState("");
  const [memorableText, setMemorableText] = useState("");
  const [memorableSelections, setMemorableSelection] = useState([]);
  const [faReady, setFaReady] = useState(false);
  const [extended, setExtended] = useState(false);
  const [authHelp, setAuthHelp] = useState(false);
  const initialValues = {
    mobile: "",
    code: "",
  };

  useEffect(() => {
    if (authHelp && !mobileSize) {
      setAuthHelp(false);
    }
  }, [authHelp, mobileSize]);

  useEffect(() => {
    if (blocked) {
      const timer = setInterval(() => {
        navigate("/login");
        clearInterval(timer);
      }, 6000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [blocked, step]);

  useEffect(() => {
    const areAllFieldsFilled = inputValues.every((val) => val !== "");

    const callAuth = async () => {
      setProcessLoad(true);
      const code = inputValues.join("");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/auth-user`,
          {
            code: code,
            token: tokenFromQuery,
            memorable: memorable,
            memorableText: memorableText,
          },
          { withCredentials: true }
        );
        if (response.data.success) {
          const role = response?.data?.data?.returnRole;

          if (role) {
            const expiryDuration = parseInt(process.env.REACT_APP_SESSION_EXP);
            const currentUtcTime = DateTime.utc();
            const expiryTime = currentUtcTime
              .plus({ seconds: expiryDuration })
              .toSeconds();

            sessionStorage.setItem("exp", Math.floor(expiryTime));
            // Set the role in sessionStorage
            sessionStorage.setItem("__nocCreator__r_rid", JSON.stringify(role));
          }

          await dispatch(updateSession(response?.data?.data));
          navigate("/dashboard");
        }
      } catch (e) {
        setProcessLoad(false);
        if (e.response.data.message === "blocked") {
          setBlocked(true);
          if (e.response.data.data == null) {
            setTryLeft(3);
          } else {
            setTryLeft(e.response.data.data);
          }

          setBlockedText(
            "You have been blocked due to too many unsuccessful requests. Please try logging in again after an hour."
          );
        } else if (e.response.data.message === "countDown") {
          setBlockedText(
            `You have only ${e.response.data.data} more attempts left. After that, you will be temporarily blocked from authentication for an hour.`
          );
          setTryLeft(e.response.data.data);
        }

        setInputValues(["", "", "", "", "", ""]);
      }
    };

    if (areAllFieldsFilled) {
      callAuth();
    }
  }, [inputValues]);

  useEffect(() => {
    if (!tokenFromQuery) {
      navigate("/login");
      return;
    }

    const savePaidUser = async () => {
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/save-checkout-user`,
          {
            token: sessionIdQuery,
          }
        );
      } catch (e) {}
    };

    const process_data = async () => {
      try {
        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/get-user-detail`,
          {
            token: tokenFromQuery,
          }
        );

        if (tokenResponse?.data?.success) {
          const maskedEmail = maskEmail(tokenResponse.data.data);
          setMemorableSelection(tokenResponse.data.memorableList);
          setUserEmail(maskedEmail);
          // setUserEmail(tokenResponse.data.data);
        }
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/generate_qr`,
          {
            token: tokenFromQuery,
          },
          {
            responseType: "blob",
          }
        );
        if (response?.status === 200) {
          const qrCodeUrl = URL.createObjectURL(new Blob([response.data]));
          setQRCodeSrc(qrCodeUrl);
          setPageLoading(false);
        } else {
          navigate("/login");
          throw new Error("Network response was not ok");
        }
        //}
      } catch (error) {
        if (error?.response?.data?.message === "blocked") {
          setBlocked(true);
          setBlockedText(
            "You have been blocked due to too many unsuccessful requests. Please try again later."
          );
          if (error?.response?.data?.data == null) {
            setTryLeft(3);
          } else {
            setTryLeft(error?.response?.data?.data);
          }
        } else {
          console.error("Error fetching QR code:", error);
          navigate("/login");
        }
      }
    };

    if (sessionIdQuery) {
      savePaidUser();
    }
    process_data();
  }, []);

  const handleEmailFa = async () => {
    try {
      setEmailLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/email_auth`,
        {
          token: tokenFromQuery,
          memorable: memorable,
          memorableText: memorableText,
        },
        { withCredentials: true }
      );
      if (response.data.success) {
        const role = response?.data?.data?.returnRole;

        if (role) {
          const expiryDuration = parseInt(process.env.REACT_APP_SESSION_EXP);
          const currentUtcTime = DateTime.utc();
          const expiryTime = currentUtcTime
            .plus({ seconds: expiryDuration })
            .toSeconds();

          sessionStorage.setItem("exp", Math.floor(expiryTime));
          // Set the role in sessionStorage
          sessionStorage.setItem("__nocCreator__r_rid", JSON.stringify(role));
        }

        await dispatch(updateSession(response?.data?.data)).then(() => {
          navigate("/dashboard");
        });
      } else {
        setEmailLoading(false);
      }
    } catch (e) {
      setEmailLoading(false);
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");

    if (pasteData.length === 6) {
      const newInputValues = pasteData.split("");
      setInputValues(newInputValues);
      setIsPasting(true);
      setTimeout(() => {
        setIsPasting(false);
      }, 0);
      event.preventDefault(); // Prevent default paste behavior
    }
  };

  const handlePhoneInputChange = (event, formik) => {
    const { value } = event.target;
    if (value.length > 12) {
      return;
    }

    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }
    formik.setFieldValue("mobile", formattedPhoneNumber);
  };

  const handleInputChange = (index, value) => {
    if (!isPasting && value.length > 1) {
      value = value.slice(0, 1); // Allow only one character
    }
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value && index < 5) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (!value && index > 0) {
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleImageClick = (index, formik) => {
    formik?.resetForm();
    setInputValues(["", "", "", "", "", ""]);
    if (selectedImage === index) {
      setSelectedImage(null);
    } else {
      setSelectedImage(index);
    }
  };

  const validationSchemas = [
    // Validation schema for Step 1
    Yup.object({
      mobile: Yup.string()
        .min(12, "Number is too short")
        .max(12, "Number is too long")
        .required("Mobile number is required"),
    }),
    // Validation schema for Step 2 (code)
    Yup.object({
      code: Yup.string().required("Verification code is required"),
    }),
  ];

  useEffect(() => {
    const getItemFromLocalStorage = (key) => {
      const item = localStorage.getItem(key);
      return JSON.parse(item); // If the item is an object, parse it back to an object
    };

    const userData = getItemFromLocalStorage("ujsffq");

    if (userData) {
      setDefEmail(userData.email);
      setDefPwd(userData.password);
      setRemember(true);
    }
  }, []);

  useEffect(() => {
    if (memorableText === "" || memorable === "" || memorableText.length < 4) {
      setFaReady(false);
    } else {
      setFaReady(true);
      // setFromMemorableBlocked(false);
      // setIconsBlocked(false);
    }
  }, [memorableText, memorable]);

  // Generate a random six-character number

  const mobileFormik = useFormik({
    initialValues,
    validationSchema: validationSchemas[step - 1], // Use the schema for the current step
    onSubmit: async (values, { setSubmitting, setFieldError, setTouched }) => {
      setLoading(true);
      const send_sms = async () => {
        try {
          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/sms/sendVerify`,
            {
              mobile: values["mobile"],
              token: tokenFromQuery,
            }
          );
          return resp;
        } catch (e) {}
      };

      if (step === 1) {
        const send_response = await send_sms();

        if (send_response.data.success === true) {
          setStep(2);
          setMobileInputDisabled(true);
          setMobileText(
            "Your verification code has been successfully sent. Please check your text message and enter the code you received to proceed"
          );
          setIconsBlocked(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(true);
        const verify_sms = async () => {
          try {
            const resp = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/getVerify`,
              {
                code: values["code"],
                token: tokenFromQuery,
                memorable: memorable,
                memorableText: memorableText,
              },
              { withCredentials: true }
            );
            return resp;
          } catch (e) {
            values["code"] = "";
            setLoading(false);
            setMobileText("Invalid Verification Code");

            setTouched({ code: true });
          }
        };

        const smsResp = await verify_sms();

        if (smsResp.data.success) {
          const role = smsResp?.data?.data?.returnRole;

          if (role) {
            const expiryDuration = parseInt(process.env.REACT_APP_SESSION_EXP);
            const currentUtcTime = DateTime.utc();
            const expiryTime = currentUtcTime
              .plus({ seconds: expiryDuration })
              .toSeconds();

            sessionStorage.setItem("exp", Math.floor(expiryTime));
            // Set the role in sessionStorage
            sessionStorage.setItem("__nocCreator__r_rid", JSON.stringify(role));
          }

          await dispatch(updateSession(smsResp?.data?.data));
          navigate("/dashboard");
        } else {
          values["code"] = "";
          setLoading(false);
          setMobileText("Invalid Verification Code");
        }
      }
    },
  });

  const maskEmail = (email) => {
    const atPosition = email.indexOf("@");
    const lastDotPosition = email.lastIndexOf(".");
    if (atPosition !== -1 && lastDotPosition > atPosition) {
      // Mask the part before the "@"
      const charsToMask = Math.max(
        1,
        Math.floor(email.slice(0, atPosition).length / 2)
      );
      const maskedFirstPart =
        email.slice(0, charsToMask) + "*".repeat(atPosition - charsToMask);

      // Mask the part between "@" and the last dot
      const domainStart = atPosition + 1;
      const domainEnd = lastDotPosition;
      const domainLength = domainEnd - domainStart;
      const charsToMaskInDomain = Math.max(1, Math.floor(domainLength / 2));
      const maskedDomainPart =
        "*".repeat(charsToMaskInDomain) +
        email.slice(domainStart + charsToMaskInDomain, domainEnd);

      // Combine all parts together
      return (
        maskedFirstPart + "@" + maskedDomainPart + email.slice(lastDotPosition)
      );
    } else {
      return email;
    }
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            mobileSize
              ? {
                  display: "flex",
                  justifyContent: "center",
                  overflow: "auto",
                  width: "100%",
                  height: "100vh",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",
                  width: "100%",
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }
          }
        >
          <Box
            sx={
              mobileSize
                ? {
                    backgroundColor: "#fff",
                    py: "10px",
                    px: "10px",
                    width: "100%",
                    my: 2,
                  }
                : {
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    maxWidth: 900,
                    maxHeight: "95vh",
                    borderRadius: "25px",
                    px: 2,
                    py: "10px",
                    width: "100%",
                    pt: "2rem",
                    pb: 2,
                  }
            }
          >
            {/* <div
              style={
                isSmallScreen
                  ? { width: "100%", padding: "0rem" }
                  : { padding: "0rem" }
              }
            > */}
            <Stack spacing={1}>
              <Box
                sx={{ cursor: "pointer" }}
                display="flex"
                justifyContent="center"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  style={{
                    maxWidth: "10rem",
                    width: "100%",
                    height: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "-12px",
                    display: "block",
                  }}
                  alt="Permit Rockstar Logo"
                  src="/img/website_img/logoText.png"
                />
              </Box>
            </Stack>
            <Typography
              sx={{ mb: 2 }}
              color="primary"
              variant={mobileSize ? "h5" : "h4"}
              align="center"
            >
              Enabling 2FA for Secure Access to Our Platform
            </Typography>
            <Typography
              color="primary"
              variant={mobileSize ? "body2" : "body1"}
              align="center"
            >
              At Permit Rockstar, your security is paramount. To safeguard your
              account and data, we require Two-Factor Authentication (2FA). This
              extra layer of protection helps prevent unauthorized access and
              keeps your information secure. Setting up 2FA is quick, easy, and
              essential for safe use of our platform.
            </Typography>

            <Typography
              sx={{ mb: mobileSize ? 2 : 0.5, mt: 2 }}
              color="primary"
              variant="h5"
              align="center"
            >
              Please Select a 2FA method you want to use
            </Typography>

            <Grid
              container
              spacing={0}
              sx={{
                border: mobileSize ? "2px solid #fff" : "2px solid #d3d3d3",
                p: mobileSize ? 0 : 3,
                px: mobileSize ? 0.5 : undefined,
                borderRadius: "10px",
                boxShadow: !mobileSize && "5px 2px 7px 2px rgba(0, 0, 0, 0.2)",
              }}
            >
              {images.map((image, index) => (
                <Grid item xs={4} key={index}>
                  <Box
                    sx={{
                      mr:
                        (index === 0 && !mobileSize) ||
                        (index === 1 && !mobileSize)
                          ? "20px"
                          : (index === 0 && mobileSize) ||
                            (index === 1 && mobileSize)
                          ? "10px"
                          : "0px",
                      borderRadius: "10px",
                      position: "relative",
                      flexGrow: 1,
                      zIndex: hovered === index ? 3 : 1,
                      boxShadow: "3px 2px 3px 2px rgba(0, 0, 0, 0.3)",
                      backgroundColor:
                        selectedImage === index ? "#f3f3f3" : "#fff",
                      flexDirection: "column",
                      cursor: iconsBlocked || loading ? "default" : "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "100%",
                      transform:
                        hovered === index || selectedImage === index
                          ? "scale(1.1)"
                          : "scale(1)",
                      transition: "transform 0.3s ease",
                    }}
                    onClick={() =>
                      !iconsBlocked && !loading
                        ? handleImageClick(index, mobileFormik)
                        : null
                    }
                    onMouseEnter={() =>
                      !iconsBlocked && !loading ? setHovered(index) : null
                    }
                    onMouseLeave={() => {
                      selectedImage !== index && setHovered(null);
                    }}
                    // onMouseLeave={handleImageLeave}
                  >
                    <Box
                      sx={{
                        pb: mobileSize ? 0 : 3,
                        pt: mobileSize ? 0 : 1,
                        mt: image.alt === "email" ? 5 : 0,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        alt={image.alt}
                        src={image.src}
                        width={
                          mobileSize
                            ? selectedImage === index
                              ? image.width - 40 * 1.1
                              : image.width - 40
                            : tabletSize
                            ? selectedImage === index
                              ? image.width - 20 * 1.1
                              : image.width - 20
                            : selectedImage === index
                            ? image.width * 1.1
                            : image.width
                        }
                      />
                    </Box>
                    {!mobileSize && (
                      <Typography variant="h6" pb={2}>
                        {image?.alt?.charAt(0)?.toUpperCase() +
                          image?.alt?.slice(1)}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              ))}
              {selectedImage === 0 && extended && (
                <>
                  <Grid
                    sx={{
                      mt: 3,
                      maxWidth: "91vw",
                      overflowX: "auto",
                      borderRadius: "10px",
                      backgroundColor: "#f3f3f3",
                      boxShadow: "3px 3px 4px 4px rgba(0, 0, 0, 0.2)",
                      p: mobileSize ? 1 : 4,
                    }}
                    item
                    xs={12}
                  >
                    <Box sx={{}}>
                      <Typography
                        variant={mobileSize ? "h6" : "h5"}
                        align="center"
                      >
                        {userEmail}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <LoadingButton
                      // SUBMIT //
                      onClick={handleEmailFa}
                      loading={emailLoading}
                      fullWidth
                      size={mobileSize ? "medium" : "large"}
                      sx={{ mt: mobileSize ? 2 : 1, p: mobileSize ? 1 : 2 }}
                      variant="contained"
                    >
                      Complete Registration
                    </LoadingButton>
                  </Grid>
                </>
              )}
              {selectedImage === 1 && extended && (
                <>
                  <form
                    style={{ width: "100%" }}
                    noValidate
                    autoComplete="off"
                    onSubmit={mobileFormik.handleSubmit}
                  >
                    <Typography
                      sx={{ mt: 4 }}
                      color="primary"
                      variant="h6"
                      align="center"
                    >
                      In order to complete your registration, please confirm
                      your mobile phone number
                    </Typography>
                    <Grid container spacing={mobileSize ? 1 : 2}>
                      <Grid sx={{ mt: 1 }} item xs={12} sm={6} md={4}>
                        <TextField
                          error={
                            !!(
                              mobileFormik.touched.mobile &&
                              mobileFormik.errors.mobile
                            )
                          }
                          helperText={
                            mobileFormik.touched.mobile &&
                            mobileFormik.errors.mobile
                          }
                          disabled={mobileInputDisabled || loading}
                          name="mobile"
                          label="Mobile Number"
                          variant="filled"
                          fullWidth
                          onBlur={mobileFormik.handleBlur}
                          onKeyUp={(e) =>
                            handlePhoneInputChange(e, mobileFormik)
                          }
                          value={mobileFormik.values.mobile}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +1
                              </InputAdornment>
                            ),
                            inputProps: { pattern: "[0-9]*" },
                          }}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            if (e.target.value.length > 12) {
                              return;
                            } else {
                              mobileFormik.handleChange(e);
                            }
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        mt={1}
                        mb={
                          mobileFormik.errors.mobile || mobileFormik.errors.code
                            ? "20px"
                            : "0px"
                        }
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {mobileFormik.values.code.length < 6 ? (
                          <>
                            <LoadingButton
                              fullWidth={mobileSize}
                              loading={loading}
                              disabled={
                                mobileFormik.values.mobile.length !== 12 ||
                                Boolean(mobileText)
                              }
                              variant="contained"
                              type="submit"
                              color="warning"
                              endIcon={<SendIcon />}
                            >
                              {mobileText
                                ? "Verification Code Sent"
                                : "Send Verification Code"}
                            </LoadingButton>
                          </>
                        ) : (
                          <>
                            <LoadingButton
                              fullWidth={mobileSize}
                              loading={loading}
                              variant="contained"
                              type="submit"
                              color="success"
                              endIcon={<AssignmentTurnedInIcon />}
                            >
                              Complete Registration
                            </LoadingButton>
                          </>
                        )}
                      </Grid>
                      {tabletSize && (
                        <>
                          <Grid item xs={12}>
                            <Typography
                              variant={
                                mobileText === "Invalid Verification Code"
                                  ? "h6"
                                  : "body2"
                              }
                              textAlign="center"
                              my={0}
                              color={
                                mobileText === "Invalid Verification Code"
                                  ? "error"
                                  : "#004976"
                              }
                              fontWeight={
                                mobileText === "Invalid Verification Code"
                                  ? "bold"
                                  : "normal"
                              }
                            >
                              {mobileText}
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <Grid
                        sx={{ mt: tabletSize ? 0 : 1 }}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                      >
                        {step === 2 && (
                          <TextField
                            error={
                              !!(
                                mobileFormik.touched.code &&
                                mobileFormik.errors.code
                              )
                            }
                            fullWidth
                            helperText={
                              mobileFormik.touched.code &&
                              mobileFormik.errors.code
                            }
                            name="code"
                            value={mobileFormik.values.code}
                            label="Verification Code"
                            variant="filled"
                            onChange={(e) => {
                              // Set the field value, filtering out non-digits
                              mobileFormik.setFieldValue(
                                "code",
                                e.target.value.replace(/\D/g, "")
                              );

                              // If the current message is about an invalid code, update it
                              if (mobileText === "Invalid Verification Code") {
                                setMobileText(
                                  "Your verification code has been successfully sent. Please check your text message and enter the code you received to proceed."
                                );
                              }
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={12}>
                        {iconsBlocked && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mt: tabletSize ? 0.7 : 0,
                              mb: mobileSize && 2,
                            }}
                          >
                            <Button
                              variant="outlined"
                              fullWidth={tabletSize}
                              size="medium"
                              color="error"
                              onClick={() => {
                                setIconsBlocked(false);
                                setSelectedImage(null);
                                setExtended(false);
                                setMobileText("");
                                setMobileInputDisabled(false);
                                setStep(1);
                                mobileFormik.resetForm();
                              }}
                            >
                              Change 2FA Method
                            </Button>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    {!tabletSize && (
                      <Grid item xs={12}>
                        <Typography
                          variant={
                            mobileText === "Invalid Verification Code"
                              ? "h6"
                              : "body2"
                          }
                          // sx={{ minHeight: "1.5rem" }}
                          textAlign="center"
                          my={1}
                          color={
                            mobileText === "Invalid Verification Code"
                              ? "error"
                              : "#004976"
                          }
                          fontWeight={
                            mobileText === "Invalid Verification Code"
                              ? "bold"
                              : "normal"
                          }
                        >
                          {mobileText}
                        </Typography>
                      </Grid>
                    )}
                  </form>
                </>
              )}
              {selectedImage === 2 && extended && (
                <>
                  {mobileSize ? (
                    <>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              mb: 0,
                              mt:
                                selectedImage === 1 && mobileSize && extended
                                  ? 0
                                  : 3,
                            }}
                            color="primary"
                            variant="h5"
                            align="center"
                          >
                            Set Up Authenticator
                          </Typography>
                          <IconButton
                            onClick={() => {
                              setAuthHelp(true);
                            }}
                            sx={{ mt: 3 }}
                          >
                            <HelpOutlineIcon color="success" />
                          </IconButton>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            mb: 0,
                          }}
                          color="primary"
                          variant="body2"
                          align="center"
                        >
                          (Please scan your QR code below)
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        sx={{
                          mt: 3,
                          overflowY: "auto",
                          maxHeight: "15rem",
                        }}
                        item
                        xs={12}
                        sm={6}
                      >
                        <Typography variant="h5">
                          Set Up Authenticator
                        </Typography>
                        <List>
                          <ListItem>
                            <ListItemText>
                              <Link
                                // component={NextLink}
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  // ml: "auto",
                                  mt: 2,
                                  cursor: "pointer",
                                }}
                                target="_blank"
                                underline="hover"
                                variant="subtitle2"
                                rel="noopener noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                              >
                                1. Download Google Authenticator
                              </Link>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              2. On your mobile device, go to your{" "}
                              <Link
                                // component={NextLink}
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  // ml: "auto",
                                  mt: 2,
                                  cursor: "pointer",
                                }}
                                target="_blank"
                                underline="hover"
                                variant="subtitle2"
                                rel="noopener noreferrer"
                                href="https://myaccount.google.com/?pli=1&nlr=1"
                              >
                                Google Account
                              </Link>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              3. At the top, tap the Security tab
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              4. Under "You can add more sign-in options," tap
                              Authenticator
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              5. Tap Set up authenticator
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              6. Follow the on-screen steps
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={6}>
                    <Box
                      display="flex"
                      justifyContent="center" // Center horizontally
                      alignItems="center" // Center vertically
                      height="100%" // Ensure the box takes the full height
                    >
                      {qrCodeSrc ? (
                        <img width={200} src={qrCodeSrc} alt="QR Code" />
                      ) : (
                        <p>Loading QR Code...</p>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      backgroundColor: "rgba(76, 160, 239, 0.2)",
                      boxShadow: "3px 4px 10px 5px rgba(0, 0, 0, 0.2)",
                      py: 1,
                      pl: mobileSize ? 0 : 2,
                      borderRadius: "10px",
                    }}
                  >
                    <Grid
                      order={tabletSize ? 2 : 1}
                      item
                      xs={12}
                      md={6}
                      // sx={{
                      //   display: "flex",
                      //   alignItems: "center",
                      //   width: "50%",
                      // }}
                    >
                      <Typography
                        align={tabletSize ? "center" : "left"}
                        mt={tabletSize ? 1 : 0}
                        pr={tabletSize ? 1 : 0}
                        color="error"
                        fontWeight="bold"
                      >
                        {blockedText
                          ? blockedText
                          : "To complete your registration, please enter the code generated by the authenticator."}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      order={tabletSize ? 1 : 2}

                      // sx={
                      //   processLoad
                      //     ? { display: "flex", ml: "auto", mr: "auto" }
                      //     : { display: "flex", ml: "auto" }
                      // }
                    >
                      {processLoad ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              height: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <CircularProgress color="warning" size="5rem" />
                          </Box>
                        </>
                      ) : (
                        <Box
                          sx={{
                            mt: tabletSize ? 1 : 0,
                            mx: "6px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: 1,
                          }}
                        >
                          {inputValues.map((value, index) => (
                            <TextField
                              disabled={blocked}
                              id={`input-${index}`}
                              value={value}
                              variant="outlined"
                              onChange={(e) =>
                                handleInputChange(index, e.target.value)
                              }
                              onPaste={index === 0 ? handlePaste : undefined}
                              inputProps={{
                                maxLength: 1,
                                style: {
                                  textAlign: "center",
                                  padding: mobileSize && "5px",
                                  fontSize: "28px",
                                  fontWeight: "bold",
                                },
                              }}
                              sx={{
                                width: mobileSize ? "30px" : "48px",
                                textAlign: "center",
                                border: "3px solid #000",
                                borderRadius: "10px",
                              }}
                            />
                          ))}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            {typeof selectedImage !== "undefined" && selectedImage !== null && (
              <>
                <Typography
                  sx={{
                    mb: 0,
                    mt: selectedImage === 1 && mobileSize && extended ? 0 : 3,
                  }}
                  color="primary"
                  variant={mobileSize ? "h6" : "h5"}
                  align="center"
                >
                  {!faReady || !extended
                    ? "Please enter a security question in case you loose access to your preferred 2FA method"
                    : "Your selected security question information"}
                </Typography>
              </>
            )}

            <Grid container spacing={2} sx={{ mt: 0 }}>
              {typeof selectedImage !== "undefined" &&
                selectedImage !== null && (
                  <>
                    <Grid item md={6} xs={12}>
                      <FormControl
                        fullWidth
                        variant="filled"
                        disabled={extended}
                      >
                        <InputLabel>Select a security question</InputLabel>
                        <Select
                          label="Select"
                          value={memorable}
                          onChange={(e) => setMemorable(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: "1.5px",
                                maxHeight: 150, // Set the desired max height for the dropdown menu
                                width: "auto",
                                maxWidth: "100%",
                              },
                            },
                          }}
                          sx={{
                            maxWidth: "100%", // Set the desired max-width for the input element
                            "& .MuiTypography-body2": {
                              whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                            },
                          }}
                        >
                          {memorableSelections?.map(function (item, i) {
                            return (
                              <MenuItem key={i} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        disabled={extended}
                        fullWidth
                        label="Please enter the answer to your security question"
                        type="text"
                        onChange={(e) => {
                          setMemorableText(e.target.value);
                        }}
                        value={memorableText}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ mt: 0, justifyContent: "center" }}
            >
              {typeof selectedImage !== "undefined" &&
                selectedImage !== null && (
                  <>
                    <Grid
                      item
                      sm={6}
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        mb: mobileSize ? 2 : 0,
                      }}
                    >
                      <Button
                        onClick={() => {
                          setMemorableText("");
                          setMemorable("");
                          setExtended(false);
                        }}
                        disabled={!extended}
                        variant="contained"
                        size="small"
                        color="warning"
                      >
                        Change
                      </Button>
                    </Grid>

                    <Grid item sm={6}>
                      <Button
                        disabled={!faReady || extended}
                        onClick={() => setExtended(true)}
                        variant="contained"
                        size="small"
                        sx={{ width: "79.65px" }}
                      >
                        Save
                      </Button>
                    </Grid>
                  </>
                )}
            </Grid>
            {/* </div> */}
          </Box>
        </Box>
      )}
      <Modal open={authHelp} onClose={() => setAuthHelp(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100vw", // Full width
            bgcolor: "background.paper",
            boxShadow: 24,
            px: 4,
            pb: 1,
            pt: 1,
            mx: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h5">Set Up Authenticator</Typography>
            </Box>

            <IconButton onClick={() => setAuthHelp(false)} sx={{ mr: 2 }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText>
                <Link
                  // component={NextLink}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  target="_blank"
                  underline="hover"
                  variant="subtitle2"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                >
                  1. Download Google Authenticator
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText>
                2. On your mobile device, go to your{" "}
                <Link
                  // component={NextLink}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    // ml: "auto",

                    cursor: "pointer",
                  }}
                  target="_blank"
                  underline="hover"
                  variant="subtitle2"
                  rel="noopener noreferrer"
                  href="https://myaccount.google.com/?pli=1&nlr=1"
                >
                  Google Account
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText>3. At the top, tap the Security tab</ListItemText>
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText>
                4. Under "You can add more sign-in options," tap Authenticator
              </ListItemText>
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText>5. Tap Set up authenticator</ListItemText>
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText>6. Follow the on-screen steps</ListItemText>
            </ListItem>
          </List>
        </Box>
      </Modal>
    </>
  );
};

const images = [
  {
    alt: "email",
    src: "/img/logos/email.png",
    // width: 120,
    width: 110,
  },
  {
    alt: "mobile",
    src: "/img/logos/mobile.png",
    // width: 180,
    width: 180,
  },
  {
    alt: "authenticator",
    src: "/img/logos/auth.png",
    // width: 167,
    width: 167,
  },
];

export default DynamicAuth;
