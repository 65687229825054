import React, { useState, useEffect } from "react";

const ScrollingText = ({ expiredSubscription, failedCounties }) => {
  const [scrollingText, setScrollingText] = useState("");

  useEffect(() => {
    let textToScroll = "";
    if (expiredSubscription) {
      textToScroll = `ALERT:  **** ${expiredSubscription} **** `;
    } else if (failedCounties && failedCounties.length > 0) {
      textToScroll = `**** ALERT:  **** ${failedCounties
        .map(
          (county) =>
            `${county} County Appraiser's website is temporarily unavailable due to technical issues.`
        )
        .join(" **** ")} ****`;
    }

    setScrollingText(textToScroll);
  }, [expiredSubscription, failedCounties]);

  return <div className="scroll-left-animation">{scrollingText}</div>;
};

export default ScrollingText;
