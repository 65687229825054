import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Stack,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";

const TotpResetAuth = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largeTabletSize = useMediaQuery(theme.breakpoints.down("lg"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isPasting, setIsPasting] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [tryLeft, setTryLeft] = useState(0);
  const [blocked, setBlocked] = useState(false);
  const [blockedText, setBlockedText] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromQuery = queryParams.get("token");
  const [hidden, setHidden] = useState(false);
  const [onTimeout, setOnTimeout] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (blocked) {
        navigate("/login");
        clearInterval(timer);
      }
    }, 6000);
    return () => {
      clearInterval(timer);
    };
  }, [blocked]);

  const callAuth = async () => {
    const allFilled = inputValues.every((value) => value.trim() !== "");

    if (!allFilled) {
      if (onTimeout) {
        return;
      }
      setOnTimeout(true);
      setBlockedText("Please fill in all fields");
      setTimeout(() => {
        setBlockedText("");
        setOnTimeout(false);
      }, 4000);

      return;
    }
    const code = inputValues.join("");
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/totp-reset-pass`,
        {
          code: code,
          token: tokenFromQuery,
        },
        { withCredentials: true }
      );
      if (response.data.success) {
        setLoading(false);
        setPageLoading(false);
        setHidden(true);
        setBlockedText("");
      }
    } catch (e) {
      if (e.response.data.message === "blocked") {
        setBlocked(true);
        if (e.response.data.data == null) {
          setTryLeft(3);
        } else {
          setTryLeft(e.response.data.data);
        }

        setBlockedText(
          "You have been blocked due to too many unsuccessful requests. Please try logging in again after an hour."
        );
      } else if (e.response.data.message === "countDown") {
        setBlockedText(
          `You have only ${e.response.data.data} more attempts left. After that, you will be temporarily blocked from authentication for an hour.`
        );
        setTryLeft(e.response.data.data);
      }

      setInputValues(["", "", "", "", "", ""]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!tokenFromQuery) {
      navigate("/login");
      return;
    }

    const process_data = async () => {
      try {
        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/totp-reset-verify`,
          {
            token: tokenFromQuery,
          }
        );
        if (tokenResponse.data.success) {
          setUserEmail(tokenResponse.data.data);
          setPageLoading(false);
        } else {
          navigate("/login");
          throw new Error("Network response was not ok");
        }
        //}
      } catch (error) {
        if (error.response.data.message === "blocked") {
          setBlocked(true);
          setBlockedText(
            "You have been blocked due to too many unsuccessful requests. Please try again later."
          );
          if (error.response.data.data == null) {
            setTryLeft(3);
          } else {
            setTryLeft(error.response.data.data);
          }
        } else {
          console.error("Error fetching QR code:", error);
          navigate("/login");
        }
      }
    };

    process_data();
  }, []);

  const goLogin = () => {
    navigate("/login");
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");

    if (pasteData.length === 6) {
      const newInputValues = pasteData.split("");
      setInputValues(newInputValues);
      setIsPasting(true);
      setTimeout(() => {
        setIsPasting(false);
      }, 0);
      event.preventDefault(); // Prevent default paste behavior
    }
  };

  const handleInputChange = (index, value) => {
    if (!isPasting && value.length > 1) {
      value = value.slice(0, 1); // Allow only one character
    }
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value && index < 5) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (!value && index > 0) {
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            mobileSize
              ? {
                  justifyContent: "center",
                  overflowX: "hidden",
                  px: 1,
                  minHeight: "100vh",
                  overflowY: "hidden",
                  display: "flex",
                  flexDirection: "column",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",

                  minHeight: "100vh",
                  overflow: "hidden",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }
          }
        >
          <Box
            sx={
              mobileSize
                ? {
                    backgroundColor: "#fff",
                    py: "1rem",
                    maxWidth: "100%",
                    flexGrow: 1,
                  }
                : {
                    backgroundColor: "#fff",
                    mt: 2,
                    maxWidth: largePC ? "1100px" : "70%",
                    borderRadius: "25px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                    px: 3,
                    py: "10px",
                    width: "80%",
                    pt: "2rem",
                  }
            }
          >
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Box
                sx={{ cursor: "pointer" }}
                display="flex"
                justifyContent="center"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  style={{
                    width: mobileSize ? "75%" : "10rem",
                  }}
                  alt="Permit Rockstar Logo"
                  src="/img/website_img/PermitRockstar.png"
                />
              </Box>
            </Stack>
            <Typography
              sx={{ mb: 2 }}
              color="primary"
              variant={mobileSize ? "h5" : "h4"}
              align="center"
            >
              {hidden
                ? "Your password has been changed successfully"
                : "Reset Your Password"}
            </Typography>
            {!hidden && (
              <Typography color="primary" variant="body1" align="center">
                If you have not already done so, please open Google
                Authenticator and enter the code below
              </Typography>
            )}

            <Box
              sx={{
                borderRadius: "10px",
                backgroundColor: "transparent",
                flexDirection: "column",
                cursor: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                alt="authenticator"
                src="/img/logos/2falogo.png"
                width="80%"
                style={{ maxWidth: !mobileSize ? "250px" : undefined }}
              />
            </Box>
          </Box>
          <Box
            sx={
              !mobileSize
                ? {
                    backgroundColor: "#fff",
                    mb: 2,
                    maxWidth: largePC ? "1100px" : "70%",
                    borderRadius: "25px",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    px: 3,
                    py: "10px",
                    width: "80%",
                    pt: "2rem",
                  }
                : { mb: 0, backgroundColor: "#fff" }
            }
          >
            <Grid container>
              <Grid item xs={12}>
                {hidden ? (
                  <Typography></Typography> // If you intend to show something when 'hidden' is true, put content here.
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        mt: tabletSize ? 0 : 0,
                        mx: "6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: 1,
                        overflow: "auto",
                        width: "100%",
                      }}
                    >
                      {inputValues.map((value, index) => (
                        <TextField
                          key={index}
                          disabled={blocked}
                          id={`input-${index}`}
                          value={value}
                          variant="outlined"
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          onPaste={index === 0 ? handlePaste : undefined}
                          inputProps={{
                            maxLength: 1,
                            style: {
                              textAlign: "center",
                              padding: mobileSize ? "5px" : undefined,
                              fontSize: "28px",
                              fontWeight: "bold",
                            },
                          }}
                          sx={{
                            width: mobileSize ? "35px" : "48px",
                            minWidth: mobileSize ? "15px" : undefined,
                            textAlign: "center",
                            border: "3px solid #000",
                            borderRadius: "10px",
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>

            <Grid container justifyContent="center" mb={2} mt={5}>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <LoadingButton
                  onClick={hidden ? goLogin : callAuth}
                  disabled={blocked}
                  loading={loading}
                  fullWidth
                  size="large"
                  sx={{ mt: 0, p: 2 }}
                  type="submit"
                  variant="contained"
                >
                  {hidden ? "Back to Login" : "S U B M I T"}
                </LoadingButton>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ mt: 2, minHeight: mobileSize ? "0rem" : "1.5rem" }}
              >
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="error"
                  fontWeight="bold"
                >
                  {blockedText}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TotpResetAuth;
