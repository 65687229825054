import React from "react";
import { Grid } from "@mui/material";
import LoginComponent from "../auth/LoginComponent";

const Login = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <LoginComponent />
      </Grid>
    </Grid>
  );
};

export default Login;
